// Generated by purs version 0.15.13
import * as BankingReady_Data_Color from "../BankingReady.Data.Color/index.js";
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Shape from "../BankingReady.Foreign.Excel.Shape/index.js";
import * as BankingReady_Foreign_Excel_ShapeCollection from "../BankingReady.Foreign.Excel.ShapeCollection/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetRoute);
var postDCFValuationCommandEffect = function (context) {
    return function (store) {
        return function (route) {
            return function (v) {
                var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
                var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
                var shapeCollection = BankingReady_Foreign_Excel_Worksheet.shapes(worksheet);
                return function __do() {
                    var shape1 = BankingReady_Foreign_Excel_ShapeCollection.addGeometricShape(shapeCollection)(BankingReady_Foreign_Excel.Rectangle.value)();
                    BankingReady_Foreign_Excel_Shape.setFillColor(shape1)(BankingReady_Data_Color.LightBlue.value)();
                    BankingReady_Foreign_Excel_Shape.setWidth(shape1)(store.os)(BankingReady_Data_ColumnWidth.ColumnWidth30.value)();
                    return Data_Unit.unit;
                };
            };
        };
    };
};
export {
    postDCFValuationCommandEffect
};
