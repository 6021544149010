"use strict"

export const setFillColorImpl = function (shape) {
    return function (color) {
        return function () {
            shape.set({fill: {foregroundColor: color}});
        };
    };
};

export const setVisible = function (shape) {
    return function (shapeVisible) {
        return function () {
            shape.set({visible: shapeVisible})
        };
    };
};

export const setWidthImpl = function (shape) {
    return function (shapeWidth) {
        return function () {
            shape.set({width: shapeWidth})
        };
    };
};
