// Generated by purs version 0.15.13
import * as BankingReady_Data_Quiz from "../BankingReady.Data.Quiz/index.js";
var upcomingQuizQuestions = /* #__PURE__ */ (function () {
    return [ {
        questionTextArray: [ "The Calculation of Levered Free Cash Flow is which of the following?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "NOPAT + D&A + Non-Cash Adj. +/- Working Capital Changes \u2013 Capex" ],
        answerB: [ "NOPAT + D&A + Non-Cash Adj. +/- Working Capital Changes \u2013 Net Debt Servicing" ],
        answerC: [ "NOPAT + D&A + Non-Cash Adj. +/- Working Capital Changes \u2013 Capex \u2013 Net Debt Servicing" ],
        answerD: [ "NOPAT + D&A + Non-Cash Adj. +/- Working Capital Changes" ],
        explanationA: [ "This is incorrect. The correct answer is (c). When calculating Levered Free Cash Flow we use the traditional formula ", "for Unlevered Free Cash Flow, but we must also make an adjustment for the Net Debt Servicing (Interest Paid, ", "Interest Earned and Debt Repaid) of the company. This then leaves us with the claims available to the equity investors." ],
        explanationB: [ "This is incorrect. The correct answer is (c). When calculating Levered Free Cash Flow we use the traditional formula ", "for Unlevered Free Cash Flow. This includes netting out the Capital Expenditure of the business because it is a large ", "cash expense. We must also make an adjustment for the Net Debt Servicing (Interest Paid, Interest Earned and Debt ", "Repaid) of the company. This then leaves us with the claims available to the equity investors." ],
        explanationC: [ "This is correct. Levered Free Cash Flow follows the traditional formula for of Unlevered Free Cash Flow but also nets ", "out the impact of Net Debt Servicing (Interest Paid, Interest Earned and Debt Repaid). This leaves us with the claims ", "available to the equity investors." ],
        explanationD: [ "This is incorrect. The correct answer is (c). When calculating Levered Free Cash Flow we use the traditional formula ", "for Unlevered Free Cash Flow. This includes netting out the Capital Expenditure of the business because it is a large ", "cash expense. We must also make an adjustment for the Net Debt Servicing (Interest Paid, Interest Earned and Debt ", "Repaid) of the company. This then leaves us with the claims available to the equity investors." ]
    }, {
        questionTextArray: [ "Who are the claimants of Unlevered Free Cash Flows?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Equity Investors" ],
        answerB: [ "Debt Investors" ],
        answerC: [ "All Investors" ],
        answerD: [ "All Investors except Equity Investors" ],
        explanationA: [ "This is incorrect. The correct answer is (c). Unlevered Free Cash Flows accounts for the claims of All Investors. If we  ", "assume that all Investors have a claim to NOPAT, and we do not net out our interest payments then the FCF we ", "remain with represents the claims of all the capital providers." ],
        explanationB: [ "This is incorrect. The correct answer is (c). Unlevered Free Cash Flows accounts for the claims of All Investors. If we  ", "assume that all Investors have a claim to NOPAT, and we do not net out our interest payments then the FCF we ", "remain with represents the claims of all the capital providers." ],
        explanationC: [ "This is correct. The Unlevered Free Cash Flows are the claims of All Investors in a capital structure. That is why there ", "is no Net Debt Repayment taken out." ],
        explanationD: [ "This is incorrect. The correct answer is (c). Unlevered Free Cash Flows accounts for the claims of All Investors. If we  ", "assume that all Investors have a claim to NOPAT, and we do not net out our interest payments then the FCF we ", "remain with represents the claims of all the capital providers." ]
    }, {
        questionTextArray: [ "How can one alter the Unlevered Free Cash Flow formulae to achieve Levered Free Cash Flow without starting from NOPAT?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Swapping NOPAT and Net Income." ],
        answerB: [ "Swapping NOPAT and Net Income and Adjusting for Debt Repayment." ],
        answerC: [ "Swapping NOPAT and Net Income and adding back Interest Expenses." ],
        answerD: [ "Swapping NOPAT and Revenue." ],
        explanationA: [ "This is incorrect. The correct answer is (b).  If we were to swap NOPAT for Net Income in the Unlevered Free Cash ", "Flows formula, we would have adjusted for the interest paid and earned but we would not have adjusted for any ", "mandatory debt repayments." ],
        explanationB: [ "This is correct. If we were to swap NOPAT for Net Income in the Unlevered Free Cash Flows formula, we would have ", "adjusted for the interest paid and earned. Additionally, adjusting for the mandatory debt repayments would strip out ", "the final claims to cash of the Debt Investors." ],
        explanationC: [ "This is incorrect. The correct answer is (b).  If we were to swap NOPAT for Net Income in the Unlevered Free Cash ", "Flows formula, we would have adjusted for the interest paid and earned but adding back the interest expense would ", "be readding the claims of the Debt Investors." ],
        explanationD: [ "This is incorrect. The correct answer is (b).  If we were to swap NOPAT for Revenue, we would not be accounting for ", "the expenses of the Income Statement which reduce our cash. This is why we start with NOPAT and can swap it to ", "Net Income. Then we would have adjusted for the interest paid and earned. Additionally, adjusting for the mandatory ", "debt repayments would strip out the final claims to cash of the Debt Investors." ]
    }, {
        questionTextArray: [ "What is the most unlikely projection period we would use for a business?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "1 year." ],
        answerB: [ "5 years." ],
        answerC: [ "7 years." ],
        answerD: [ "10 years." ],
        explanationA: [ "This is correct. It would be relatively uncommon to forecast one year future for an overall business projection. This ", "does not enable enough time to realise a business plan." ],
        explanationB: [ "This is incorrect. The correct answer is (a). It would be relatively uncommon to forecast one year future for an overall ", "business projection. This does not enable enough time to realise a business plan." ],
        explanationC: [ "This is incorrect. The correct answer is (a). It would be relatively uncommon to forecast one year future for an overall ", "business projection. This does not enable enough time to realise a business plan." ],
        explanationD: [ "This is incorrect. The correct answer is (a). It would be relatively uncommon to forecast one year future for an overall ", "business projection. This does not enable enough time to realise a business plan." ]
    }, {
        questionTextArray: [ "What is the best definition of the WACC?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "The opportunity cost of investors in the business." ],
        answerB: [ "The opportunity cost of common stock and debt investors of the business." ],
        answerC: [ "The opportunity cost of each provider of capital to the business in equal weightings." ],
        answerD: [ "The opportunity cost of each provider of capital to the business in proportional weightings." ],
        explanationA: [ "This is incorrect. The correct answer is (d). The Weighted Average Cost of Capital averages the cost of all investors in ", "the business\u2019s capital structure but their proportions are weighted i.e. by the percentage of the capital structure each ", "component makes up." ],
        explanationB: [ "This is incorrect. The correct answer is (d). The Weighted Average Cost of Capital averages the cost of all investors ", "(which can include components beyond common stock and debt such as preferred equity) in the business\u2019s capital ", "structure but their proportions are weighted i.e. by the percentage of the capital structure each component makes up." ],
        explanationC: [ "This is incorrect. The correct answer is (d). The Weighted Average Cost of Capital averages the cost of all investors in ", "the business\u2019s capital structure but their proportions are weighted i.e. by the percentage of the capital structure each ", "component makes up not equally." ],
        explanationD: [ "This is correct. The Weighted Average Cost of Capital averages the cost of all investors in the business\u2019s capital ", "structure but their proportions are weighted i.e. by the percentage of the capital structure each component makes up." ]
    }, {
        questionTextArray: [ "A company\u2019s peers have a median Unlevered Beta of 0.77. If the company faces a 20% tax rate and 60% debt financing in its ", "capital structure. What is the Levered Beta for the company?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "1.18" ],
        answerB: [ "1.39" ],
        answerC: [ "1.69" ],
        answerD: [ "1.93" ],
        explanationA: [ "This is incorrect. The correct answer is (c). In your calculation you have calculated a ratio of Equity-to-Debt which ", "when used will reduce the Levered Beta given that in this example the capital structure has greater debt than equity. ", "The correct formula: ", "Levered B = Unlevered Beta * (1 + (1 \u2013 Tax Rate) * Debt/Equity). ", "Therefore we get, ", "Levered Beta = 0.77 * (1 + (1 \u2013 20%) * 60%/40%) = 1.69." ],
        explanationB: [ "This is incorrect. The correct answer is (c). In your calculation you forgotten to include the ratio of Debt-to-Equity ", "which reduces the premium we would expect when the debt is greater than the equity. ", "The correct formula: ", "Levered B = Unlevered Beta * (1 + (1 \u2013 Tax Rate) * Debt/Equity). ", "Therefore we get, ", "Levered Beta = 0.77 * (1 + (1 \u2013 20%) * 60%/40%) = 1.69." ],
        explanationC: [ "This is correct. ", "Levered B = Unlevered Beta * (1 + (1 \u2013 Tax Rate) * Debt/Equity). ", "Therefore we get, ", "Levered Beta = 0.77 * (1 + (1 \u2013 20%) * 60%/40%) = 1.69." ],
        explanationD: [ "This is incorrect. The correct answer is (c). In your calculation you forgotten to include the tax-shield which reduces ", "the debt costs and thus reduces Levered Beta.", "The correct formula: ", "Levered B = Unlevered Beta * (1 + (1 \u2013 Tax Rate) * Debt/Equity). ", "Therefore we get, ", "Levered Beta = 0.77 * (1 + (1 \u2013 20%) * 60%/40%) = 1.69." ]
    }, {
        questionTextArray: [ "A company has a Levered Beta of 0.85. In the current market conditions the yield on a 10-Y US Treasury is 4.5% and the ", "average equity return is 10%. What is the cost of equity?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "4.68%" ],
        answerB: [ "9.18%" ],
        answerC: [ "10.00%" ],
        answerD: [ "13.00%" ],
        explanationA: [ "This is incorrect. The correct answer is (b). In your calculations you have not included the Risk-Free Rate as the base ", "of the formula. ", "The formula for the Cost of Equity is as follows: ", "Cost of Equity = Risk-Free Rate + Levered Beta * (Equity Return \u2013 Risk Free Rate). ", "The Risk-Free Rate is 4.5% (the US Treasury Rate), the Risk Premium (Equity Return \u2013 Risk-Free Rate) is ", "10% - 4.5% = 5.5% ", "Cost of Equity = 4.5% + 0.85 * 5.5% = 9.18%" ],
        explanationB: [ "This is correct. The formula for the Cost of Equity is as follows: ", "Cost of Equity = Risk-Free Rate + Levered Beta * (Equity Return \u2013 Risk Free Rate). ", "The Risk-Free Rate is 4.5% (the US Treasury Rate), the Risk Premium (Equity Return \u2013 Risk-Free Rate) is ", "10% - 4.5% = 5.5% ", "Cost of Equity = 4.5% + 0.85 * 5.5% = 9.18%" ],
        explanationC: [ "This is incorrect. The correct answer is (b). In your calculations you have not included the Levered Beta which scales ", "the equity premium by the correlation of our company and equity markets. ", "The formula for the Cost of Equity is as follows: ", "Cost of Equity = Risk-Free Rate + Levered Beta * (Equity Return \u2013 Risk Free Rate). ", "The Risk-Free Rate is 4.5% (the US Treasury Rate), the Risk Premium (Equity Return \u2013 Risk-Free Rate) is ", "10% - 4.5% = 5.5% ", "Cost of Equity = 4.5% + 0.85 * 5.5% = 9.18%" ],
        explanationD: [ "This is incorrect. The correct answer is (b). In your calculations you have used the total equity return instead of ", "finding the equity premium, or the return on top of the Risk-Free Rate. ", "The formula for the Cost of Equity is as follows: ", "Cost of Equity = Risk-Free Rate + Levered Beta * (Equity Return \u2013 Risk Free Rate). ", "The Risk-Free Rate is 4.5% (the US Treasury Rate), the Risk Premium (Equity Return \u2013 Risk-Free Rate) is ", "10% - 4.5% = 5.5% ", "Cost of Equity = 4.5% + 0.85 * 5.5% = 9.18%" ]
    }, {
        questionTextArray: [ "A company has $100m of common stock funding, $200m in debt financing and $50m in financing from a preferred equity group. ", "The company has a cost of equity of 8.5%, faces an interest rate of 6% on its debt obligations and is mandated to pay a $2.5m ", "preferred dividend each year. What is the company\u2019s WACC? (assume the company has a 20% tax rate)" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "5.53%" ],
        answerB: [ "5.89%" ],
        answerC: [ "6.03%" ],
        answerD: [ "6.57%" ],
        explanationA: [ "This is incorrect. The correct answer is (b). In your calculations you have misinterpreted the preferred equity yield as ", "2.5% and not calculated it as a percentage of the overall amount of preferred equity invested. ", "The correct formula is the following: ", "", "WACC = Cost of Equity * % of Equity + Cost of Debt * (1 \u2013 Tax Rate) * % of Debt + Cost of Preferred Equity ", "             * % of Preferred Equity ", "", "There is a total funding of $100 + $200 + $50 = $350. Using the ratio of each investment to the total we can calculate ", "the percentages of each component in the capital structure: ", "Equity: $100/$350 = 29%, Debt: $200/$350 = 57%, Preferred Equity: $50/$350 = 14% ", "The final value we need to calculate is the preferred yield which is 2.5/50 = 5% ", "Therefore when we put the values into our WACC formula we get: ", "WACC = 8.5% * 29% + 6% * (1-20%) * 57% + 5% * 14% = 5.89%" ],
        explanationB: [ "This is correct. The correct formula is the following:", "", "WACC = Cost of Equity * % of Equity + Cost of Debt * (1 \u2013 Tax Rate) * % of Debt + Cost of Preferred Equity ", "             * % of Preferred Equity ", "", "There is a total funding of $100 + $200 + $50 = $350. Using the ratio of each investment to the total we can calculate ", "the percentages of each component in the capital structure: ", "Equity: $100/$350 = 29%, Debt: $200/$350 = 57%, Preferred Equity: $50/$350 = 14% ", "The final value we need to calculate is the preferred yield which is 2.5/50 = 5% ", "Therefore when we put the values into our WACC formula we get: ", "WACC = 8.5% * 29% + 6% * (1-20%) * 57% + 5% * 14% = 5.89%" ],
        explanationC: [ "This is incorrect. The correct answer is (b). In your calculations you have excluded the preferred equity component of ", "the capital structure. The correct formula is the following:", "", "WACC = Cost of Equity * % of Equity + Cost of Debt * (1 \u2013 Tax Rate) * % of Debt + Cost of Preferred Equity ", "             * % of Preferred Equity ", "", "There is a total funding of $100 + $200 + $50 = $350. Using the ratio of each investment to the total we can calculate ", "the percentages of each component in the capital structure: ", "Equity: $100/$350 = 29%, Debt: $200/$350 = 57%, Preferred Equity: $50/$350 = 14% ", "The final value we need to calculate is the preferred yield which is 2.5/50 = 5% ", "Therefore when we put the values into our WACC formula we get: ", "WACC = 8.5% * 29% + 6% * (1-20%) * 57% + 5% * 14% = 5.89%" ],
        explanationD: [ "This is incorrect. The correct answer is (b). In your calculations you have not included the tax-shield that applies to ", "the debt interest rate. Interest rate is tax-deductible reducing the amount of cash we will pay. This reduces the risk ", "of the business and so \u201creduces our cost of debt\u201d. The correct formula is the following:", "", "WACC = Cost of Equity * % of Equity + Cost of Debt * (1 \u2013 Tax Rate) * % of Debt + Cost of Preferred Equity ", "             * % of Preferred Equity ", "", "There is a total funding of $100 + $200 + $50 = $350. Using the ratio of each investment to the total we can calculate ", "the percentages of each component in the capital structure: ", "Equity: $100/$350 = 29%, Debt: $200/$350 = 57%, Preferred Equity: $50/$350 = 14% ", "The final value we need to calculate is the preferred yield which is 2.5/50 = 5% ", "Therefore when we put the values into our WACC formula we get: ", "WACC = 8.5% * 29% + 6% * (1-20%) * 57% + 5% * 14% = 5.89%" ]
    }, {
        questionTextArray: [ "What principal leads us to discount our projected cash flows to the present?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "Double Spend of Money." ],
        answerB: [ "Inflation." ],
        answerC: [ "Annuity Growth." ],
        answerD: [ "Time Value of Money." ],
        explanationA: [ "This is incorrect. The correct answer is (d). We use a discount on the cash flows because a $ today is worth more ", "than a $ tomorrow. Future cash flows have to be discounted to reflect the risk and the opportunity cost of not ", "investing in something else." ],
        explanationB: [ "This is incorrect. The correct answer is (d). We use a discount on the cash flows because a $ today is worth more ", "than a $ tomorrow. Future cash flows have to be discounted to reflect the risk and the opportunity cost of not ", "investing in something else." ],
        explanationC: [ "This is incorrect. The correct answer is (d). We use a discount on the cash flows because a $ today is worth more ", "than a $ tomorrow. Future cash flows have to be discounted to reflect the risk and the opportunity cost of not ", "investing in something else." ],
        explanationD: [ "This is correct. We use a discount on the cash flows because a $ today is worth more than a $ tomorrow. Future cash ", "flows have to be discounted to reflect the risk and the opportunity cost of not investing in something else." ]
    }, {
        questionTextArray: [ "What happens to the discount factor for each consecutive period?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Decreases." ],
        answerB: [ "Increases." ],
        answerC: [ "Remains Unchanged." ],
        answerD: [ "Alternates between Positive and Negative." ],
        explanationA: [ "This is incorrect. The correct answer is (b). The discount factor is what we multiple our cash flows by to get the ", "present value. In period one this is (1/WACC). In each following period we multiple it again i.e. in period one we ", "discount once in period two we discount twice etc (1/WACC)^n. So as we consecutively move into the next period,  ", "we increase the discount factor." ],
        explanationB: [ "This is correct. The discount factor is what we multiple our cash flows by to get the present value. In period one this ", "is (1/WACC). In each following period we multiple it again i.e. in period one we discount once in period two we ", "discount twice etc (1/WACC)^n. So as we consecutively move into the next period, we increase the discount factor." ],
        explanationC: [ "This is incorrect. The correct answer is (b). The discount factor is what we multiple our cash flows by to get the ", "present value. In period one this is (1/WACC). In each following period we multiple it again i.e. in period one we ", "discount once in period two we discount twice etc (1/WACC)^n. So as we consecutively move into the next period,  ", "we increase the discount factor." ],
        explanationD: [ "This is incorrect. The correct answer is (b). The discount factor is what we multiple our cash flows by to get the ", "present value. In period one this is (1/WACC). In each following period we multiple it again i.e. in period one we ", "discount once in period two we discount twice etc (1/WACC)^n. So as we consecutively move into the next period,  ", "we increase the discount factor." ]
    } ];
})();
var firstQuizQuestion = /* #__PURE__ */ (function () {
    return {
        questionTextArray: [ "What is the difference between Discounted Cash Flow and other types of valuations?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Discounted Cash Flows look at the relative value of a company in comparison to its peers." ],
        answerB: [ "Discounted Cash Flows look at the intrinsic value of a company.", "" ],
        answerC: [ "Discounted Cash Flows look at the ceiling price or maximum that can be paid for a company given restrictions on input ", "parameters such as debt and interest rates." ],
        answerD: [ "Discounted Cash Flows look at the value of recent transactions that have been similar and we can imply the valuation from the ", "averages of multiples from the peers." ],
        explanationA: [ "This is incorrect. The correct answer is (b) because Discounted Cash Flow modelling forecasts the cash the company produces and ", "then discounts them to find their worth in the present period." ],
        explanationB: [ "Correct. Discounted Cash Flow modelling forecasts the cash the company produces and then discounts them to find their ", "worth in the present period." ],
        explanationC: [ "This is incorrect. The correct answer is (b) because Discounted Cash Flow modelling forecasts the cash the company produces and ", "then discounts them to find their worth in the present period." ],
        explanationD: [ "This is incorrect. The correct answer is (b) because Discounted Cash Flow modelling forecasts the cash the company ", "produces and then discounts them to find their worth in the present period. " ]
    };
})();
export {
    firstQuizQuestion,
    upcomingQuizQuestions
};
