// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var treasuryStockMethodDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We first look for the company\u2019s common shares outstanding and the current share price. This can be found in their most recent financial report (10-K or 10-Q). The shares outstanding are typically on the first page. For example if you open the statement for Kellogg and look at its first page (pdf page 3). This second to last line has the current common shares outstanding of 342m shares. Non-US companies often do not include the common share count on the first page and so it requires some searching to find the outstanding number."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 10,
        colIdx: 3,
        number: 341.67,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we look for the current market price of the shares. We can find the current share price from any source that provides current stock market prices like Yahoo Finance or Bloomberg."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 3,
        number: 73.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Using these values we can calculate the Equity Value as we have learnt before. "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=D9*D11",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we need to look at securities that could be dilutive to our common share count. For Kellogg the only dilutive securities are stock options\xa0provided to management that you can find on page 76 (pdf page 78). \xa0There are other types of securities that may be dilutive however they are beyond the scope of this course."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So the first piece of information that we are looking for is the number of outstanding options available. On January 1st there are 15 million potential shares outstanding within the options. We use outstanding instead of exercisable because in the event of a merger many options become exercisable at the point of the transaction. We also need the price that these options are exercisable at. We use the weighted average strike price of $64."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 11,
        colIdx: 7,
        number: 15.0,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 11,
        colIdx: 8,
        number: 64.0,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We then have to breakdown how this will impact the common share count. We will break it down into small steps then provide the general formula we can use to encompass all of these steps."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we create 15 million new shares. This is where the company allows the employees to purchase shares for the strike price. This will increase the total number of shares by the amount that has been exercised (in our case the total amount outstanding)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 5,
        text: "1. New shares created: 15 million"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The company receives the cash proceeds for the new shares it issues. So the employees get the shares at the exercise price. Now you can see that it is in the best interest of the owner of these options to exercise them when the current share price is higher than their strike price. This is known as being \u2018in-the-money\u2019. So in our example the 15 million shares worth $64 each gives us proceeds of 15*$64 = $960M."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 5,
        text: "2. Cash proceeds given to company for the new shares: 15*64 = 960"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We then assume that the company will use the proceeds to repurchase shares at the current market price. They do this to reduce the dilutive effect on the pre-existing shareholders. In our example we have proceeds of $960M and each share can be bought in the market for $73 (as of 5th of December 2022). So Kellogg can use the $960M to purchase shares in the market. It can buy back 960/73 = 13.2M shares."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 5,
        text: "3. Kellogg can repurchase shares at the current market price: 960/73 = 13.2"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The dilutive impact is the net difference between the new shares that are issued and the number of shares the company can repurchase with the proceeds from the issue. So for Kellogg there is 15 million shares created and they would be able to repurchase around 13.2 million shares. Therefore the dilutive effect is 15 \u2013 13.2 = 1.85 (accounting for rounding)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 5,
        text: "4. Difference between shares created/repurchased is the dilution: 15-13.2 = 1.85"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To make this a simple formula we have to explain these steps in excel. We will use an IF function to determine when we do and do not include an options dependent on the share price."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So when the current share price is below the strike price the options are not exercisable and the dilutive effect does not take place. So our IF function will test for whether the share price is lower than the strike price, if this is true then the dilution will be 0. This will write as =IF(Share Price < Strike Price, 0, \u2026)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When the strike price is lower than the current price then we do have the dilutive effect. When this is the case, what happens to our share count? Well first we get the additional shares. However, we have to net out (subtract) the amount repurchased. This is the product of the number of shares and the strike price (the proceeds) divided by the current share price to give us the amount of shares we can repurchase. Easily put Count \u2013 (Count * Strike Price) / Current Price). This makes up the next part of the IF function which becomes =IF(Share Price < Strike Price, 0, Count \u2013 (Count * Strike Price) / Current Price)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 5,
        text: "5. Dilution = IF(Share Price < Strike Price, 0, Count - (Count * Strike) / Current Price)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can now add this to our calculations, and we find the dilutive effect of these options."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.LeftBottom.value,
        formula: "=IF(D9<I12,0,H12-(H12*I12)/D9)",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 7,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        }, {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can see that the formula gives us the same answer as that of our hand-held calculations of 1.85 shares. This can now flow into our equity value calculations."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=J12",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 9,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We add the common share count and the additional dilutive share count to get us a total diluted share count of 343.5 million."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=D11+D12",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can now calculate the equity value for the diluted share count: share price * diluted share count. This gives us the diluted equity value of $25077M or $25.1B. Now in comparison to the equity value without the dilutive effect of 24.9B, there is a $200M difference. You can see how this can add up when we add additional tranches of options and other types of securities."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=D9*D13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 12,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To recap, we have now worked through how to calculate the dilutive share count and see the impact this has on equity value. First, we find the common share count. We then look at the number of additional shares outstanding within the current stock options and the strike price they can be exercised at. This gives us the proceeds the options will generate (if they are in-the-money) and we can expect Kellogg to go back into the market and repurchase shares to reduce the dilutive effect. The net impact of the additional shares and the repurchasing gives us the dilutive effect. "
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "You can change the current share price to see what happens when the options are not in-the-money and how the price change generally impacts the dilution."
    })) ] ];
})();
export {
    treasuryStockMethodDescriptions
};
