// Generated by purs version 0.15.13
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Component_Login from "../BankingReady.Component.Login/index.js";
import * as BankingReady_Component_Router_AccountingRouter from "../BankingReady.Component.Router.AccountingRouter/index.js";
import * as BankingReady_Component_Router_CompanyValueRouter from "../BankingReady.Component.Router.CompanyValueRouter/index.js";
import * as BankingReady_Component_Router_DCFValuationRouter from "../BankingReady.Component.Router.DCFValuationRouter/index.js";
import * as BankingReady_Component_Router_IntroToFinanceRouter from "../BankingReady.Component.Router.IntroToFinanceRouter/index.js";
import * as BankingReady_Component_Router_QuizRouter from "../BankingReady.Component.Router.QuizRouter/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Page_Home from "../BankingReady.Page.Home/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var slot = /* #__PURE__ */ Halogen_HTML.slot()({
    reflectSymbol: function () {
        return "login";
    }
})(Data_Ord.ordUnit);
var slot_ = /* #__PURE__ */ Halogen_HTML.slot_();
var slot_1 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "home";
    }
})(Data_Ord.ordUnit);
var slot_2 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "introToFinance";
    }
})(Data_Ord.ordUnit);
var slot_3 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "accounting";
    }
})(Data_Ord.ordUnit);
var slot_4 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "companyValue";
    }
})(Data_Ord.ordUnit);
var slot_5 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "dcfValuation";
    }
})(Data_Ord.ordUnit);
var slot_6 = /* #__PURE__ */ slot_({
    reflectSymbol: function () {
        return "quiz";
    }
})(Data_Ord.ordUnit);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var when = /* #__PURE__ */ Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM);
var notEq = /* #__PURE__ */ Data_Eq.notEq(/* #__PURE__ */ Data_Maybe.eqMaybe(BankingReady_Data_Route.eqRoute));
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var Navigate = /* #__PURE__ */ (function () {
    function Navigate(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Navigate.create = function (value0) {
        return function (value1) {
            return new Navigate(value0, value1);
        };
    };
    return Navigate;
})();
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var HandleLogin = /* #__PURE__ */ (function () {
    function HandleLogin(value0) {
        this.value0 = value0;
    };
    HandleLogin.create = function (value0) {
        return new HandleLogin(value0);
    };
    return HandleLogin;
})();
var component = function (dictMonadAff) {
    var component1 = BankingReady_Component_Login.component(dictMonadAff);
    var component2 = BankingReady_Page_Home.component(dictMonadAff);
    var component3 = BankingReady_Component_Router_IntroToFinanceRouter.component(dictMonadAff);
    var component4 = BankingReady_Component_Router_AccountingRouter.component(dictMonadAff);
    var component5 = BankingReady_Component_Router_CompanyValueRouter.component(dictMonadAff);
    var component6 = BankingReady_Component_Router_DCFValuationRouter.component(dictMonadAff);
    var component7 = BankingReady_Component_Router_QuizRouter.component(dictMonadAff);
    return function (dictMonadStore) {
        var component8 = component1(dictMonadStore);
        var component9 = component3(dictMonadStore);
        var component10 = component4(dictMonadStore);
        var component11 = component5(dictMonadStore);
        var component12 = component6(dictMonadStore);
        var component13 = component7(dictMonadStore);
        return function (dictNavigate) {
            var component14 = component2(dictNavigate);
            var component15 = component9(dictNavigate);
            var component16 = component10(dictNavigate);
            var component17 = component11(dictNavigate);
            var component18 = component12(dictNavigate);
            var component19 = component13(dictNavigate);
            var navigate = BankingReady_Capability_Navigate.navigate(BankingReady_Capability_Navigate.navigateHalogenM(dictNavigate));
            return function (dictManageDialog) {
                var component20 = component8(dictManageDialog);
                return function (dictManageExcelEditor) {
                    var component21 = component15(dictManageExcelEditor);
                    var component22 = component16(dictManageExcelEditor);
                    var component23 = component17(dictManageExcelEditor);
                    var component24 = component18(dictManageExcelEditor);
                    return function (dictManageExcelQuiz) {
                        var component25 = component19(dictManageExcelQuiz);
                        var render = function (v) {
                            if (v.route instanceof Data_Maybe.Just) {
                                if (v.route.value0 instanceof BankingReady_Data_Route.Login) {
                                    return slot(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component20)(Data_Unit.unit)(HandleLogin.create);
                                };
                                if (v.route.value0 instanceof BankingReady_Data_Route.Home) {
                                    return slot_1(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component14)(Data_Unit.unit);
                                };
                                if (v.route.value0 instanceof BankingReady_Data_Route.IntroToFinance) {
                                    return slot_2(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component21)(v.route.value0.value0);
                                };
                                if (v.route.value0 instanceof BankingReady_Data_Route.Accounting) {
                                    return slot_3(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component22)(v.route.value0.value0);
                                };
                                if (v.route.value0 instanceof BankingReady_Data_Route.CompanyValue) {
                                    return slot_4(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component23)(v.route.value0.value0);
                                };
                                if (v.route.value0 instanceof BankingReady_Data_Route.DCFValuation) {
                                    return slot_5(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component24)(v.route.value0.value0);
                                };
                                if (v.route.value0 instanceof BankingReady_Data_Route.Quiz) {
                                    return slot_6(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component25)(v.route.value0.value0);
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router (line 94, column 15 - line 115, column 77): " + [ v.route.value0.constructor.name ]);
                            };
                            if (v.route instanceof Data_Maybe.Nothing) {
                                return Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Oh no! That page wasn't found.") ]);
                            };
                            throw new Error("Failed pattern match at BankingReady.Component.Router (line 93, column 22 - line 119, column 59): " + [ v.route.constructor.name ]);
                        };
                        var handleQuery = function (v) {
                            return bind(get)(function (v1) {
                                return discard(when(notEq(v1.route)(new Data_Maybe.Just(v.value0)))(modify_(function (v2) {
                                    var $84 = {};
                                    for (var $85 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $85)) {
                                            $84[$85] = v2[$85];
                                        };
                                    };
                                    $84.route = new Data_Maybe.Just(v.value0);
                                    return $84;
                                })))(function () {
                                    return pure(new Data_Maybe.Just(v.value1));
                                });
                            });
                        };
                        var handleAction = function (v) {
                            if (v instanceof Initialize) {
                                return navigate(BankingReady_Data_Route.Login.value);
                            };
                            if (v instanceof HandleLogin) {
                                return navigate(BankingReady_Data_Route.Home.value);
                            };
                            throw new Error("Failed pattern match at BankingReady.Component.Router (line 80, column 18 - line 90, column 26): " + [ v.constructor.name ]);
                        };
                        return Halogen_Component.mkComponent({
                            initialState: function (v) {
                                return {
                                    route: Data_Maybe.Nothing.value
                                };
                            },
                            render: render,
                            "eval": Halogen_Component.mkEval({
                                receive: Halogen_Component.defaultEval.receive,
                                finalize: Halogen_Component.defaultEval.finalize,
                                handleQuery: handleQuery,
                                handleAction: handleAction,
                                initialize: new Data_Maybe.Just(Initialize.value)
                            })
                        });
                    };
                };
            };
        };
    };
};
export {
    Navigate,
    Initialize,
    HandleLogin,
    component
};
