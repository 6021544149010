"use strict"

// Properties

export const getCharts = function (worksheet) {
    return worksheet.charts;
};

export const freezePanes = function (worksheet) {
    return worksheet.freezePanes;
};

export const shapes = function (worksheet) {
    return worksheet.shapes;
};


// Methods

export const activate = function (worksheet) {
    return function () {
        worksheet.activate()
    };
};

export const deleteWorksheet = function (worksheet) {
    return function () {
        worksheet.delete();
    };
};

export const getRangeImpl = function (worksheet) {
    return function (address) {
        return worksheet.getRange(address);
    };
};

export const getRangeByIndexes = function (worksheet) {
    return function (startRow) {
        return function (startColumn) {
            return function (rowCount) {
                return function (columnCount) {
                    return worksheet.getRangeByIndexes(startRow, startColumn, rowCount, columnCount);
                };
            };
        };
    };
};

export const showGridlines = function (worksheet) {
    return function (bool) {
        return function () {
            worksheet.set({ showGridlines: bool })
        };
    };
};

export const setVisible = function (worksheet) {
    return function () {
         worksheet.set({ visibility: "Visible" })
    };
};

export const setVeryHidden = function (worksheet) {
    return function () {
         worksheet.set({ visibility: "VeryHidden" })
    };
};
