// Generated by purs version 0.15.13
import * as BankingReady_Foreign_Excel_Range from "../BankingReady.Foreign.Excel.Range/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJString));
var gEncodeJsonCons1 = /* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil);
var gEncodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(Data_Argonaut_Encode_Class.encodeJsonJNumber))(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "weight";
    }
})())({
    reflectSymbol: function () {
        return "tintAndShade";
    }
})())({
    reflectSymbol: function () {
        return "style";
    }
})())({
    reflectSymbol: function () {
        return "color";
    }
})())()));
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonMaybe(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons2(/* #__PURE__ */ gEncodeJsonCons2(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "top";
    }
})())({
    reflectSymbol: function () {
        return "right";
    }
})())({
    reflectSymbol: function () {
        return "left";
    }
})())({
    reflectSymbol: function () {
        return "bottom";
    }
})())()))(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "horizontalAlignment";
    }
})())({
    reflectSymbol: function () {
        return "borders";
    }
})())()))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "format";
    }
})())());
var Normal = /* #__PURE__ */ (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Top = /* #__PURE__ */ (function () {
    function Top() {

    };
    Top.value = new Top();
    return Top;
})();
var Bottom = /* #__PURE__ */ (function () {
    function Bottom() {

    };
    Bottom.value = new Bottom();
    return Bottom;
})();
var Right = /* #__PURE__ */ (function () {
    function Right() {

    };
    Right.value = new Right();
    return Right;
})();
var LeftBottom = /* #__PURE__ */ (function () {
    function LeftBottom() {

    };
    LeftBottom.value = new LeftBottom();
    return LeftBottom;
})();
var TopBottom = /* #__PURE__ */ (function () {
    function TopBottom() {

    };
    TopBottom.value = new TopBottom();
    return TopBottom;
})();
var Outside = /* #__PURE__ */ (function () {
    function Outside() {

    };
    Outside.value = new Outside();
    return Outside;
})();
var Green = /* #__PURE__ */ (function () {
    function Green() {

    };
    Green.value = new Green();
    return Green;
})();
var Black = /* #__PURE__ */ (function () {
    function Black() {

    };
    Black.value = new Black();
    return Black;
})();
var topBorder = function (cellBorder) {
    return {
        format: new Data_Maybe.Just({
            horizontalAlignment: Data_Maybe.Nothing.value,
            borders: new Data_Maybe.Just({
                left: Data_Maybe.Nothing.value,
                top: new Data_Maybe.Just(cellBorder),
                bottom: Data_Maybe.Nothing.value,
                right: Data_Maybe.Nothing.value
            })
        })
    };
};
var rightBorder = function (cellBorder) {
    return {
        format: new Data_Maybe.Just({
            horizontalAlignment: Data_Maybe.Nothing.value,
            borders: new Data_Maybe.Just({
                left: Data_Maybe.Nothing.value,
                top: Data_Maybe.Nothing.value,
                bottom: Data_Maybe.Nothing.value,
                right: new Data_Maybe.Just(cellBorder)
            })
        })
    };
};
var noCellBorder = /* #__PURE__ */ (function () {
    return {
        style: new Data_Maybe.Just("None"),
        color: Data_Maybe.Nothing.value,
        tintAndShade: Data_Maybe.Nothing.value,
        weight: Data_Maybe.Nothing.value
    };
})();
var leftBorder = function (cellBorder) {
    return {
        format: new Data_Maybe.Just({
            horizontalAlignment: Data_Maybe.Nothing.value,
            borders: new Data_Maybe.Just({
                left: new Data_Maybe.Just(cellBorder),
                top: Data_Maybe.Nothing.value,
                bottom: Data_Maybe.Nothing.value,
                right: Data_Maybe.Nothing.value
            })
        })
    };
};
var greenCellBorder = /* #__PURE__ */ (function () {
    return {
        color: new Data_Maybe.Just("green"),
        style: new Data_Maybe.Just("Continuous"),
        tintAndShade: Data_Maybe.Nothing.value,
        weight: new Data_Maybe.Just("Medium")
    };
})();
var bottomBorder = function (cellBorder) {
    return {
        format: new Data_Maybe.Just({
            horizontalAlignment: Data_Maybe.Nothing.value,
            borders: new Data_Maybe.Just({
                left: Data_Maybe.Nothing.value,
                top: Data_Maybe.Nothing.value,
                bottom: new Data_Maybe.Just(cellBorder),
                right: Data_Maybe.Nothing.value
            })
        })
    };
};
var setOuterBorder = function (worksheet) {
    return function (v) {
        return function (leftCellBorder) {
            return function (topCellBorder) {
                return function (bottomCellBorder) {
                    return function (rightCellBorder) {
                        var edgeRangeLeft = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(v.rowIdx)(v.colIdx)(v.rowCount)(1);
                        var edgeRangeTop = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(v.rowIdx)(v.colIdx)(1)(v.columnCount);
                        var edgeRangeBottom = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)((v.rowIdx + v.rowCount | 0) - 1 | 0)(v.colIdx)(1)(v.columnCount);
                        var edgeRangeRight = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(v.rowIdx)((v.colIdx + v.columnCount | 0) - 1 | 0)(v.rowCount)(1);
                        return function __do() {
                            BankingReady_Foreign_Excel_Range.setCellProperties(edgeRangeLeft)(map(function (v1) {
                                return [ encodeJson(leftBorder(leftCellBorder)) ];
                            })(Data_Array.range(1)(v.rowCount)))();
                            BankingReady_Foreign_Excel_Range.setCellProperties(edgeRangeTop)([ map(function (v1) {
                                return encodeJson(topBorder(topCellBorder));
                            })(Data_Array.range(1)(v.columnCount)) ])();
                            BankingReady_Foreign_Excel_Range.setCellProperties(edgeRangeBottom)([ map(function (v1) {
                                return encodeJson(bottomBorder(bottomCellBorder));
                            })(Data_Array.range(1)(v.columnCount)) ])();
                            return BankingReady_Foreign_Excel_Range.setCellProperties(edgeRangeRight)(map(function (v1) {
                                return [ encodeJson(rightBorder(rightCellBorder)) ];
                            })(Data_Array.range(1)(v.rowCount)))();
                        };
                    };
                };
            };
        };
    };
};
var blackCellBorder = /* #__PURE__ */ (function () {
    return {
        color: new Data_Maybe.Just("black"),
        style: new Data_Maybe.Just("Continuous"),
        tintAndShade: Data_Maybe.Nothing.value,
        weight: new Data_Maybe.Just("Thin")
    };
})();
var drawBottomBorder = function (worksheet) {
    return function (rangeSelector) {
        return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(noCellBorder)(blackCellBorder)(noCellBorder);
    };
};
var drawTopBorder = function (worksheet) {
    return function (rangeSelector) {
        return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(blackCellBorder)(noCellBorder)(noCellBorder);
    };
};
var drawTopBottomBorder = function (worksheet) {
    return function (rangeSelector) {
        return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(blackCellBorder)(blackCellBorder)(noCellBorder);
    };
};
var highlightBorder = function (worksheet) {
    return function (rangeSelector) {
        return function (v) {
            if (v instanceof Green) {
                return setOuterBorder(worksheet)(rangeSelector)(greenCellBorder)(greenCellBorder)(greenCellBorder)(greenCellBorder);
            };
            if (v instanceof Black) {
                return setOuterBorder(worksheet)(rangeSelector)(blackCellBorder)(blackCellBorder)(blackCellBorder)(blackCellBorder);
            };
            throw new Error("Failed pattern match at BankingReady.Utility.Excel.Border (line 70, column 43 - line 87, column 22): " + [ v.constructor.name ]);
        };
    };
};
var removeHighlightBorder = function (worksheet) {
    return function (rangeSelector) {
        return function (v) {
            if (v instanceof Normal) {
                return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(noCellBorder)(noCellBorder)(noCellBorder);
            };
            if (v instanceof Top) {
                return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(blackCellBorder)(noCellBorder)(noCellBorder);
            };
            if (v instanceof Bottom) {
                return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(noCellBorder)(blackCellBorder)(noCellBorder);
            };
            if (v instanceof Right) {
                return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(noCellBorder)(noCellBorder)(blackCellBorder);
            };
            if (v instanceof LeftBottom) {
                return setOuterBorder(worksheet)(rangeSelector)(blackCellBorder)(noCellBorder)(blackCellBorder)(noCellBorder);
            };
            if (v instanceof TopBottom) {
                return setOuterBorder(worksheet)(rangeSelector)(noCellBorder)(blackCellBorder)(blackCellBorder)(noCellBorder);
            };
            if (v instanceof Outside) {
                return setOuterBorder(worksheet)(rangeSelector)(blackCellBorder)(blackCellBorder)(blackCellBorder)(blackCellBorder);
            };
            throw new Error("Failed pattern match at BankingReady.Utility.Excel.Border (line 90, column 49 - line 152, column 22): " + [ v.constructor.name ]);
        };
    };
};
export {
    Green,
    Black,
    Normal,
    Top,
    Bottom,
    Right,
    LeftBottom,
    TopBottom,
    Outside,
    drawTopBorder,
    drawBottomBorder,
    drawTopBottomBorder,
    highlightBorder,
    removeHighlightBorder,
    setOuterBorder,
    leftBorder,
    topBorder,
    bottomBorder,
    rightBorder,
    blackCellBorder,
    greenCellBorder,
    noCellBorder
};
