// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var calculatingEquityValueDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "There are 3 main ways of valuing the equity portion of a business."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 6,
        colIdx: 1,
        text: "Equity Value Calculation Methods"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The first and most common is to take the shares outstanding and multiple it by the current share price. Of course this is how the equity value of a publicly traded company is calculated. This method could not be used for private companies."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "1. Market Value of Equity"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The second is through the Market Value of Total Assets - Market Value of Total Liabilities (or more accurately of the liabilities and equities except common shareholders). Now this should be familiar to the book value of equity that we calculated earlier in the course. This is the same process however we use the market value of assets instead if the book value (today\u2019s value instead of historical cost)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 2,
        text: "2. Net Book Value of Assets"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In practice this is the most difficult to do. For example, Kellogg\u2019s factories are easily assessable (although this may be impractical if we do not have their consent) but how would we value its brands?"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The final method is using the valuation in the last round of funding, or the valuation given from a third party. This is the most common way of valuing private companies. This involves looking at what its valuation was in the last round, identifying what has changed since then and making any adjustments to the valuation."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 9,
        colIdx: 2,
        text: "3. Previous Funding Valuation"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Below is an example where we have a company with a share price of $20 that has 200 shares outstanding."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 12,
        colIdx: 3,
        number: 20.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 13,
        colIdx: 3,
        number: 200.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can use these values to calculate the equity value. What is the equity value? Press next to find out."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=D13*D14",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 12,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTempText({
        rowIdx: 14,
        colIdx: 4,
        text: "<--------------"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTempText({
        rowIdx: 14,
        colIdx: 5,
        text: "'= Share Price * Shares Outstanding"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As you can see the equity value is $4,000. You can edit these inputs and see how it changes the equity value."
    })) ] ];
})();
export {
    calculatingEquityValueDescriptions
};
