// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as Data_Show from "../Data.Show/index.js";
var show = /* #__PURE__ */ Data_Show.show(BankingReady_Foreign_Excel.showChartType);
var show1 = /* #__PURE__ */ Data_Show.show(BankingReady_Foreign_Excel.showChartSeriesBy);
var addChart = function (chartCol) {
    return function (chartType) {
        return function (range) {
            return function (chartSeriesBy) {
                return $foreign.addChartImpl(chartCol)(show(chartType))(range)(show1(chartSeriesBy));
            };
        };
    };
};
export {
    addChartImpl,
    getChart
} from "./foreign.js";
export {
    addChart
};
