// Generated by purs version 0.15.13
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
var changesInEquityAndEnterpriseValueWorksheetCommands = /* #__PURE__ */ (function () {
    return [ new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGridlines({
        showGridlines: false
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial.value), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "A:A",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "B:B",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "C:C",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "D:D",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "E:E",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "F:F",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "G:G",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "H:I",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "J:J",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "K:K",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "L:Z",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawOuterBorder({
        rangeSelector: {
            rowIdx: 1,
            colIdx: 1,
            rowCount: 4,
            columnCount: 12
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 5,
        colIdx: 1,
        text: "Key Formulae"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 2,
        text: "Equity Value = Share Price * Shares Outstanding"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "Enterprise Value = Equity Value + Debt - Cash + Preferred Stock + Non-controlling interest"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 9,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        title: "Scenario 1"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 1,
        text: "Share Price"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 10,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 1,
        text: "Share Outstanding"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 11,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Equity Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 1,
        text: "(+) Long-term Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 14,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 1,
        text: "(-) Cash and Cash Equivalent"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 15,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 1,
        text: "(+) Preferred Stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 16,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 1,
        text: "(+) Non-controlling Interest"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 17,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Enterprise Value"
    })) ];
})();
export {
    changesInEquityAndEnterpriseValueWorksheetCommands
};
