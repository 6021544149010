"use strict"

export const getContext2D = function (canvas) {
    return function () {
        return canvas.getContext("2d");
    };
};

export const setFont = function (context) {
    return function (fontString) {
        return function () {
            context.font = fontString;
            return context;
        };
    };
};

export const measureTextWidth = function (context) {
    return function (text) {
        return function () {
            return context.measureText(text).width;
        };
    };
};
