// Generated by purs version 0.15.13
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Profunctor from "../Data.Profunctor/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Record from "../Record/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Parser from "../Routing.Duplex.Parser/index.js";
import * as Routing_Duplex_Printer from "../Routing.Duplex.Printer/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var append = /* #__PURE__ */ Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter);
var apply = /* #__PURE__ */ Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser);
var map = /* #__PURE__ */ Data_Functor.map(Routing_Duplex_Parser.functorRouteParser);
var alt = /* #__PURE__ */ Control_Alt.alt(Routing_Duplex_Parser.altRouteParser);
var dimap = /* #__PURE__ */ Data_Profunctor.dimap(Routing_Duplex.profunctorRouteDuplex);
var noArgs = /* #__PURE__ */ (function () {
    return Control_Applicative.pure(Routing_Duplex.applicativeRouteDuplex)(Data_Generic_Rep.NoArguments.value);
})();
var gRouteProduct = {
    gRouteDuplexCtr: identity
};
var gRouteNoArguments = {
    gRouteDuplexCtr: identity
};
var gRouteDuplexCtr = function (dict) {
    return dict.gRouteDuplexCtr;
};
var product = function (dictGRouteDuplexCtr) {
    var gRouteDuplexCtr1 = gRouteDuplexCtr(dictGRouteDuplexCtr);
    return function (v) {
        return function (l) {
            var v1 = gRouteDuplexCtr1(l);
            var enc = function (v2) {
                return append(v.value0(v2.value0))(v1.value0(v2.value1));
            };
            var dec = apply(map(Data_Generic_Rep.Product.create)(map(Data_Generic_Rep.Argument)(v.value1)))(v1.value1);
            return new Routing_Duplex.RouteDuplex(enc, dec);
        };
    };
};
var gRouteDuplex = function (dict) {
    return dict.gRouteDuplex;
};
var gRouteSum = function (dictGRouteDuplex) {
    var gRouteDuplex1 = gRouteDuplex(dictGRouteDuplex);
    return function (dictGRouteDuplex1) {
        var gRouteDuplex2 = gRouteDuplex(dictGRouteDuplex1);
        return {
            gRouteDuplex: function (r) {
                var v = gRouteDuplex1(r);
                var v1 = gRouteDuplex2(r);
                var enc = function (v2) {
                    if (v2 instanceof Data_Generic_Rep.Inl) {
                        return v.value0(v2.value0);
                    };
                    if (v2 instanceof Data_Generic_Rep.Inr) {
                        return v1.value0(v2.value0);
                    };
                    throw new Error("Failed pattern match at Routing.Duplex.Generic (line 33, column 11 - line 35, column 22): " + [ v2.constructor.name ]);
                };
                var dec = alt(map(Data_Generic_Rep.Inl.create)(v.value1))(map(Data_Generic_Rep.Inr.create)(v1.value1));
                return new Routing_Duplex.RouteDuplex(enc, dec);
            }
        };
    };
};
var sum = function (dictGeneric) {
    var from = Data_Generic_Rep.from(dictGeneric);
    var to = Data_Generic_Rep.to(dictGeneric);
    return function (dictGRouteDuplex) {
        var $66 = dimap(from)(to);
        var $67 = gRouteDuplex(dictGRouteDuplex);
        return function ($68) {
            return $66($67($68));
        };
    };
};
var gRouteConstructor = function (dictIsSymbol) {
    var get = Record.get(dictIsSymbol)();
    return function () {
        return function (dictGRouteDuplexCtr) {
            var gRouteDuplexCtr1 = gRouteDuplexCtr(dictGRouteDuplexCtr);
            return {
                gRouteDuplex: function (r) {
                    var v = Routing_Duplex.end(gRouteDuplexCtr1(get(Type_Proxy["Proxy"].value)(r)));
                    var enc = function (v1) {
                        return v.value0(v1);
                    };
                    var dec = map(Data_Generic_Rep.Constructor)(v.value1);
                    return new Routing_Duplex.RouteDuplex(enc, dec);
                }
            };
        };
    };
};
var gRouteArgument = {
    gRouteDuplexCtr: identity
};
var gRouteAll = {
    gRouteDuplexCtr: function (v) {
        return new Routing_Duplex.RouteDuplex(function (v1) {
            return v.value0(v1);
        }, map(Data_Generic_Rep.Argument)(v.value1));
    }
};
export {
    gRouteDuplex,
    gRouteDuplexCtr,
    sum,
    product,
    noArgs,
    gRouteSum,
    gRouteConstructor,
    gRouteProduct,
    gRouteNoArguments,
    gRouteArgument,
    gRouteAll
};
