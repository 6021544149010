// Generated by purs version 0.15.13
import * as BankingReady_Data_Quiz from "../BankingReady.Data.Quiz/index.js";
var upcomingQuizQuestions = /* #__PURE__ */ (function () {
    return [ {
        questionTextArray: [ "Which scenario would create the highest company value?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Cash flows of $100, discount rate of 4%, growth rate of 1%" ],
        answerB: [ "Cash flows of $200, discount rate of 6%, growth rate of 0.5%" ],
        answerC: [ "Cash flows of $150, discount rate of 5%, no growth rate" ],
        answerD: [ "Cash flows of $300, discount rate of 10%, growth rate of 1.5%" ],
        explanationA: [ "This is incorrect. Our formula for company value is Cash Flows/(Discount Rate \u2013 Growth Rate). Using these inputs we ", "get 100/(4%-1%) = 3333.33. The correct answer is (b) which gives us a value of 3636.36." ],
        explanationB: [ "Correct. Our formula for company value is Cash Flows/(Discount Rate \u2013 Growth Rate). Using these inputs we get ", "200/(6%-0.5%) = 3636.36." ],
        explanationC: [ "This is incorrect. Our formula for company value is Cash Flows/(Discount Rate \u2013 Growth Rate). Using these inputs we ", "get 150/(5%) = 3000. The correct answer is (b) which gives us a value of 3636.36." ],
        explanationD: [ "This is incorrect. Our formula for company value is Cash Flows/(Discount Rate \u2013 Growth Rate). Using these inputs we ", "get 300/(10%-1.5%) = 3529.41. The correct answer is (b) which gives us a value of 3636.36." ]
    }, {
        questionTextArray: [ "Which of the following scenarios cannot be true?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "Enterprise Value > Equity Value" ],
        answerB: [ "Equity Value < Enterprise Value" ],
        answerC: [ "Enterprise Value < 0" ],
        answerD: [ "Equity Value < 0" ],
        explanationA: [ "This is incorrect. Enterprise Value can be larger than Equity Value when we add more non-operating liabilities than ", "net out non-operating assets. For example, if a company has an Equity Value of 100 with cash of 10 and debts of 20 ", "gives an Enterprise Value of 100 \u2013 10 + 20 = 110. The correct answer is (d) because Equity Value is ", "share price * no. shares and share price cannot be negative." ],
        explanationB: [ "This is incorrect. Enterprise Value can be less than Equity Value when we add less non-operating liabilities than net ", "out non-operating assets. For example, if a company has an Equity Value of 100 with cash of 20 and debts of 10 gives ", "an Enterprise Value of 100 - 20 + 10 = 90. The correct answer is (d) because Equity Value is share price * no. shares ", "and share price cannot be negative." ],
        explanationC: [ "This is incorrect. Enterprise Value can be negative when we have a significant portion of non-operating assets. ", "For example, if a company has an Equity Value of 100, a cash position of 150 and debts of 40 then it has an Enterprise ", "Value of 100 -150 + 40 = -10. The correct answer is (d) because Equity Value is share price * no. shares and share ", "price cannot be negative." ],
        explanationD: [ "Correct. Equity Value is share price * no. shares and share price cannot be negative." ]
    }, {
        questionTextArray: [ "A company is trading at a share price of 55 and there are 40m shares outstanding. On its Balance Sheet, it has 250m in cash, ", "800m in debt of which 200m is due within the next year. It also has received funding from a preferred equity investor of 150m. ", "On the balance sheet are 50m in inventories, 40m in accounts receivable, 80m of accounts payable and 30m of prepaid ", "expenses. What is the company's Enterprise Value?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "2900" ],
        answerB: [ "2730" ],
        answerC: [ "3100" ],
        answerD: [ "3400" ],
        explanationA: [ "Correct. (55*40) = 2200. 2200 + 800 \u2013 250 + 150 = 2900" ],
        explanationB: [ "This is incorrect. In your calculation you have adjusted for working capital items. These are operating assets and ", "liabilities and so are not included in our adjustments to Equity Value. The correct answer is (a) as shown in the ", "following formulae: Enterprise Value = Equity Value + Net Debt + Preferred Stock + Non-controlling Interest = ", "(55 * 40) + (800 - 250) + 150 + 0 = 2900." ],
        explanationC: [ "This is incorrect. In your calculation you have double counted a portion of the debt. There is 800m of debt, 600 is due ", "in later than a years\u2019 time and 200 is due within the year. Now despite the difference in maturity they are both ", "treated in the same way in our formula. The correct answer is (a) as shown in the following formulae: ", "Enterprise Value = Equity Value + Net Debt + Preferred Stock + Non-controlling Interest = ", "(55 * 40) + (800 - 250) + 150 + 0 = 2900." ],
        explanationD: [ "This is incorrect. In your calculations you have added your cash balance and not subtracted it. This is wrong because ", "cash is a non-operating asset. An easy tip is to consider the formula including net debt or (debt \u2013 cash) instead of the ", "items individually. This is because cash can be used to reduce our debt balance reducing how much we would owe. ", "The correct answer is (a) as shown in the following formulae:", "Enterprise Value = Equity Value + Net Debt + Preferred Stock + Non-controlling Interest = ", "(55 * 40) + (800 - 250) + 150 + 0 = 2900." ]
    }, {
        questionTextArray: [ "Which of the following will change the Enterprise Value?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "Company raises 200m in new debt." ],
        answerB: [ "Company raises 200m in new equity." ],
        answerC: [ "The company makes credit sales of 200m of its inventories to customers." ],
        answerD: [ "None of the above." ],
        explanationA: [ "This is incorrect. When we raise 200m in debt the non-operating liabilities we adjust for by adding increases by 200m. ", "However, we also increase the cash available, and this offsets this increase. The correct answer is (d) because both (a) ", "and (b) involve increases in funding offset by increases in cash. (c) impacts the operating assets and so has no impact in ", "our formula. Therefore, no scenario is this question changes the Enterprise Value." ],
        explanationB: [ "This is incorrect. When we raise 200m in equity our Equity Value will increase by 200m. However, we also increase the ", "cash available, and this offsets this increase. The correct answer is (d) because both (a) and (b) involve increases in ", "funding offset by increases in cash. (c) impacts the operating assets and so has no impact in our formula. Therefore, no ", "scenario is this question changes the Enterprise Value." ],
        explanationC: [ "This is incorrect. When we make a sale of 200m the inventories will be reduced but because this has been made on ", "credit, we have an increase in accounts receivable. Therefore, these changes do not impact the formula directly but also ", "do not change our cash position. The correct answer is (d) because both (a) and (b) involve increases in funding offset by ", "increases in cash. (c) impacts the operating assets and so has no impact in our formula. Therefore, no scenario is this " ],
        explanationD: [ "Correct. Both (a) and (b) involve increases in funding offset by increases in cash. (c) impacts the operating assets and so ", "has no impact in our formula. Therefore, no scenario is this question changes the Enterprise Value." ]
    }, {
        questionTextArray: [ "At what current share price is a stock option out-of-the-money?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Share Price < Strike Price" ],
        answerB: [ "Share Price > Strike Price" ],
        answerC: [ "Share Price = Strike Price" ],
        answerD: [ "Share Price \u2260 Strike Price" ],
        explanationA: [ "Correct. The strike price is what the owner of the option can buy the stock at and so it is in their interest to purchase the ", "stock when their price is lower than the current share price. Therefore, they are out-of-the-money when the strike is ", "higher than the current share price. " ],
        explanationB: [ "This is incorrect. The correct answer is (a) because the strike price is what the owner of the option can buy the stock at ", "and so it is in their interest to purchase the stock when their price is lower than the current share price. Therefore, they ", "are out-of-the-money when the strike is higher than the current share price. " ],
        explanationC: [ "This is incorrect. The correct answer is (a) because the strike price is what the owner of the option can buy the stock at ", "and so it is in their interest to purchase the stock when their price is lower than the current share price. Therefore, they ", "are out-of-the-money when the strike is higher than the current share price. " ],
        explanationD: [ "This is incorrect. The correct answer is (a) because the strike price is what the owner of the option can buy the stock at ", "and so it is in their interest to purchase the stock when their price is lower than the current share price. Therefore, they ", "are out-of-the-money when the strike is higher than the current share price. " ]
    }, {
        questionTextArray: [ "A company has 240m shares trading at $8 each. It also has 3 tranches of options (strike price). ", "A) 10m shares ($5), B) 8m shares ($7.25), C) 8m shares ($8.25). ", "What is the Equity Value?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "1920m" ],
        answerB: [ "1956m" ],
        answerC: [ "1954m" ],
        answerD: [ "1950m" ],
        explanationA: [ "This is incorrect. You have calculated the Equity Value without accounting for the dilutive shares from the ", "in-the-money options. The correct answer is (b). To get this answer we take our market capitalisation ", "(8 * 240) = 1920. We then adjust for tranches A and B that are in-the-money. This increases shares by 18m but with ", "the proceeds (10 * 5 + 8 * 7.25) = 108 we can repurchase shares at the current share price 108 / 8 = 13.5. Therefore, ", "our total dilution of shares is 18m \u2013 13.5m or 4.5m shares. These additional shares give us an Equity Value of 1956m." ],
        explanationB: [ "Correct. To get this answer we take our market capitalisation (8 * 240) = 1920. We then adjust for tranches A and B ", "that are in-the-money. This increases shares by 18m but with the proceeds (10 * 5 + 8 * 7.25) = 108 we can ", "repurchase shares at the current share price 108 / 8 = 13.5. Therefore, our total dilution of shares is 18m \u2013 13.5m or ", "4.5m shares. These additional shares give us an Equity Value of 1956m." ],
        explanationC: [ "This is incorrect. In this calculation you have followed the correct processes in the Treasury Stock Method but have ", "mistakenly added Tranche C to the dilution. Tranche C is out-of-the-money because the option strike price is greater ", "than the current trading price. The correct answer is (b). To get this answer we take our market capitalisation ", "(8 * 240) = 1920. We then adjust for tranches A and B that are in-the-money. This increases shares by 18m but with ", "the proceeds (10 * 5 + 8 * 7.25) = 108 we can repurchase shares at the current share price 108 / 8 = 13.5. Therefore, ", "our total dilution of shares is 18m \u2013 13.5m or 4.5m shares. These additional shares give us an Equity Value of 1956m." ],
        explanationD: [ "This is incorrect. In this calculation you have followed the correct processes in the Treasury Stock Method but have ", "mistakenly forgotten to add Tranche B to the dilution. Tranche B is in-the-money because the option strike price is ", "less than the current trading price. The correct answer is (b). To get this answer we take our market capitalisation ", "(8 * 240) = 1920. We then adjust for tranches A and B that are in-the-money. This increases shares by 18m but with ", "the proceeds (10 * 5 + 8 * 7.25) = 108 we can repurchase shares at the current share price 108 / 8 = 13.5. Therefore, ", "our total dilution of shares is 18m \u2013 13.5m or 4.5m shares. These additional shares give us an Equity Value of 1956m." ]
    }, {
        questionTextArray: [ "Which of the following is not a value ratio?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "EV/EBITDA" ],
        answerB: [ "Equity Value / Net Income" ],
        answerC: [ "Total Debt / EBITDA" ],
        answerD: [ "EV / EBIT" ],
        explanationA: [ "This is incorrect. This ratio takes one of the key value metrics EV and divides it by an operating metric EBITDA. The ", "correct answer is (c) because it is a form of leverage multiple." ],
        explanationB: [ "This is incorrect. This ratio takes one of the key value metrics Equity Value and divides it by an operating metric Net ", "Income. The correct answer is (c) because it is a form of leverage multiple." ],
        explanationC: [ "Correct. This is a form of leverage multiple." ],
        explanationD: [ "This is incorrect. This ratio takes one of the key value metrics EV and divides it by an operating metric EBIT. The ", "correct answer is (c) because it is a form of leverage multiple." ]
    }, {
        questionTextArray: [ "Suppose we have calculated EV/EBITDA and P/E for a company. We then find out that we have miscalculated our Income ", "Statement and that Tax Rate was in fact 2% larger. Which multiple is impacted?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "EV/EBITDA" ],
        answerB: [ "P/E" ],
        answerC: [ "EV/EBITDA and P/E" ],
        answerD: [ "None" ],
        explanationA: [ "This is incorrect. EBITDA is unaffected by changes in taxes because they are stripped out. The correct answer is (b) ", "because P/E has earnings as the denominator and so would be impacted by a change in taxes. " ],
        explanationB: [ "Correct. P/E has earnings as the denominator and so would be impacted by a change in taxes." ],
        explanationC: [ "This is incorrect. EBITDA is unaffected by changes in taxes because they are stripped out. The correct answer is (b) ", "because P/E has earnings as the denominator and so would be impacted by a change in taxes. There is no impact to ", "EV/EBITDA." ],
        explanationD: [ "This is incorrect. EBITDA is unaffected by changes in taxes because they are stripped out. The correct answer is (b) ", "because P/E has earnings as the denominator and so would be impacted by a change in taxes. There is no impact to ", "EV/EBITDA." ]
    }, {
        questionTextArray: [ "Which multiple is typically the highest?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "EV/Sales" ],
        answerB: [ "EV/EBIT" ],
        answerC: [ "EV/EBITDA" ],
        answerD: [ "It is dependent on other factors." ],
        explanationA: [ "This is incorrect. The numerator is equal for all answers, so our point of difference is within the operating metric. ", "Sales is the largest number of our Income Statement and so would great the smallest multiple. The correct answer is ", "(b) since EBIT is the smallest operating metric." ],
        explanationB: [ "Correct. The numerator is equal for all answers, so our point of difference is within the operating metric. Since EBIT ", "is the smallest operating metric, this would produce the highest multiple." ],
        explanationC: [ "This is incorrect. The numerator is equal for all answers, so our point of difference is within the operating metric. ", "EBITDA is larger than EBIT because we add back depreciation and amortization charges. A larger operating metric ", "will reduce the multiple. The correct answer is (b) since EBIT is the smallest operating metric." ],
        explanationD: [ "This is incorrect. The numerator is equal for all answers, so our point of difference is within the operating metric. A ", "larger operating metric will reduce the multiple. The correct answer is (b) since EBIT is the smallest operating metric." ]
    }, {
        questionTextArray: [ "As an investor in the Common Stock of a company which ratios best demonstrates my value?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "P/E" ],
        answerB: [ "EV/Sales" ],
        answerC: [ "EV/EBITDA" ],
        answerD: [ "EV/EBIT" ],
        explanationA: [ "Correct. As an investor we do care about all the ratios but given we have chosen to invest in the Equity of the ", "company we would be more focused on the P/E ratio. " ],
        explanationB: [ "This is incorrect. As an investor we do care about all the ratios but given we have chosen to invest in the Equity of the ", "company we would be more focused on the P/E ratio. Therefore, the correct answer is (a)." ],
        explanationC: [ "This is incorrect. As an investor we do care about all the ratios but given we have chosen to invest in the Equity of the ", "company we would be more focused on the P/E ratio. Therefore, the correct answer is (a)." ],
        explanationD: [ "This is incorrect. As an investor we do care about all the ratios but given we have chosen to invest in the Equity of the ", "company we would be more focused on the P/E ratio. Therefore, the correct answer is (a)." ]
    }, {
        questionTextArray: [ "If a company has 38m shares outstanding at a price of $12 per share and the company has net income of 70m, what is its P/E?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "6.48x" ],
        answerB: [ "6.49x" ],
        answerC: [ "6.50x" ],
        answerD: [ "6.51x" ],
        explanationA: [ "This is incorrect. The correct answer is (d). 38m shares at $12 each gives us a market capitalisation or equity value of ", "(38 * 12) = 456m. The ratio between this and the net income is equivalent to P/E, it is just on a total and not per ", "share basis, but these are equivalent. 456/70 = 6.51x." ],
        explanationB: [ "This is incorrect. The correct answer is (d). 38m shares at $12 each gives us a market capitalisation or equity value of ", "(38 * 12) = 456m. The ratio between this and the net income is equivalent to P/E, it is just on a total and not per ", "share basis, but these are equivalent. 456/70 = 6.51x." ],
        explanationC: [ "This is incorrect. The correct answer is (d). 38m shares at $12 each gives us a market capitalisation or equity value of ", "(38 * 12) = 456m. The ratio between this and the net income is equivalent to P/E, it is just on a total and not per ", "share basis, but these are equivalent. 456/70 = 6.51x." ],
        explanationD: [ "Correct. 38m shares at $12 each gives us a market capitalisation or equity value of (38 * 12) = 456m. The ratio ", "between this and the net income is equivalent to P/E, it is just on a total and not per share basis, but these are ", "equivalent. 456/70 = 6.51x." ]
    }, {
        questionTextArray: [ "A company has 40m shares outstanding at a price of $15 per share and the company has net income of 70m. It paid 15m in ", "taxes and interest, 30m in office costs and 20m of depreciation. On its Balance Sheet there is 200m in cash and 375m in debt. ", "What is the EV/EBITDA multiple for this company?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "8.57x" ],
        answerB: [ "7.38x" ],
        answerC: [ "5.74x" ],
        answerD: [ "6.00x" ],
        explanationA: [ "This is incorrect. You have calculated the P/E of the company. The correct answer is (b). First, we calculate the ", "Equity Value of the company which is (40m * 15) = 600m. Next, we adjust for the non-operating Balance Sheet items, ", "in this case just for net debt. 600 + 375 \u2013 200 = 775. This is our Enterprise Value. Next, we need to calculate the ", "operating metric for the denominator. We can work backwards from net income to find EBITDA, adding back our tax ", "and interest to get back to EBIT and then add back our depreciation to get to EBITDA (there is no amortisation in this ", "question). This gives us 70 + 15 + 20 = 105. Finally, we find the ratio of EV and EBITDA. 775/105 = 7.38x." ],
        explanationB: [ "Correct. First, we calculate the Equity Value of the company which is (40m * 15) = 600m. Next, we adjust for the ", "non-operating Balance Sheet items, in this case just for net debt. 600 + 375 \u2013 200 = 775. This is our Enterprise Value. ", "Next, we need to calculate the operating metric for the denominator. We can work backwards from net income to ", "find EBITDA, adding back our tax and interest to get back to EBIT and then add back our depreciation to get to EBITDA ", "(there is no amortisation in this question). This gives us 70 + 15 + 20 = 105. Finally, we find the ratio of EV and ", "EBITDA. 775/105 = 7.38x." ],
        explanationC: [ "This is incorrect. You have calculated the incorrect EBITDA value because you included the office costs. These should ", "remain included in the calculations. The correct answer is (b). First, we calculate the Equity Value of the company ", "which is (40m * 15) = 600m. Next, we adjust for the non-operating Balance Sheet items, in this case just for net debt. ", "600 + 375 \u2013 200 = 775. This is our Enterprise Value. Next, we need to calculate the operating metric for the ", "denominator. We can work backwards from net income to find EBITDA, adding back our tax and interest to get back ", "to EBIT and then add back our depreciation to get to EBITDA (there is no amortisation in this question). This gives us ", "70 + 15 + 20 = 105. Finally, we find the ratio of EV and EBITDA. 775/105 = 7.38x." ],
        explanationD: [ "This is incorrect. Your calculation has found the ratio between market capitalisation and EBITDA. Unfortunately, this is ", "an improper multiple because market cap is the value attributable to the Equity Investors whereas EBITDA is an ", "operating metric available to all investors (it is before interest expenses). Therefore, we are not comparing ", "apples-to-apples. The correct answer is (b). First, we calculate the Equity Value of the company which is ", "(40m * 15) = 600m. Next, we adjust for the non-operating Balance Sheet items, in this case just for net debt. ", "600 + 375 \u2013 200 = 775. This is our Enterprise Value. Next, we need to calculate the operating metric for the ", "denominator. We can work backwards from net income to find EBITDA, adding back our tax and interest to get back ", "to EBIT and then add back our depreciation to get to EBITDA (there is no amortisation in this question). This gives us ", "70 + 15 + 20 = 105. Finally, we find the ratio of EV and EBITDA. 775/105 = 7.38x." ]
    } ];
})();
var firstQuizQuestion = /* #__PURE__ */ (function () {
    return {
        questionTextArray: [ "What best describes the difference between Equity and Enterprise Value?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Equity Value is the public value of the company whereas Enterprise Value is the private value of the company." ],
        answerB: [ "Equity Value is always smaller than Enterprise Value because Enterprise Value is the value that more stakeholders ", "are attributed to." ],
        answerC: [ "Equity Value is attributed to equity investors such as common shareholders, but Enterprise Value is attributed to all ", "investors in the capital structure." ],
        answerD: [ "Equity Value is the value of all public shares whereas Enterprise Value is the value of all the debt holders." ],
        explanationA: [ "This is incorrect.  Equity Value is often the value of the public shareholders, but it can also be the value of shares that ", "are not traded. Equally, Enterprise Value is the worth of all investors so in the case of publicly traded companies, their ", "shares value would be included. The correct answer is (c) because Equity Value is the worth to equity investment in ", "the business and Enterprise Value is the worth to all investors in the capital structure. We get this by taking Equity ", "Value and adjusting for non-operating Balance Sheet Items." ],
        explanationB: [ "This is incorrect. Remember the Enterprise Value formulae that involves netting out the non-operating assets such as ", "cash from Equity Value. This means a high enough cash position on the Balance Sheet could give us negative EV. The ", "correct answer is (c) because Equity Value is the worth to equity investment in the business and Enterprise Value is ", "the worth to all investors in the capital structure. We get this by taking Equity Value and adjusting for non-operating ", "Balance Sheet Items." ],
        explanationC: [ "Correct. Equity Value is the worth to equity investment in the business and Enterprise Value is the worth to all ", "investors in the capital structure. We get this by taking Equity Value and adjusting for non-operating Balance Sheet ", "Items." ],
        explanationD: [ "This is incorrect. Equity Value can be the value of the public investors but can also include other types of Equity ", "Investors. Additionally, they may not be publicly traded shares. Enterprise Value is the worth to all investors not just ", "the debt holders of the business. The correct answer is (c) because Equity Value is the worth to equity investment in ", "the business and Enterprise Value is the worth to all investors in the capital structure. We get this by taking Equity" ]
    };
})();
export {
    firstQuizQuestion,
    upcomingQuizQuestions
};
