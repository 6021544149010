// Generated by purs version 0.15.13
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var typesOfFCFWorksheetCommands = /* #__PURE__ */ (function () {
    return [ new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGridlines({
        showGridlines: false
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial.value), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "A:B",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "C:C",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "D:D",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth40.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "E:F",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "G:G",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "H:H",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth50.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "I:J",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "K:L",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawOuterBorder({
        rangeSelector: {
            rowIdx: 1,
            colIdx: 2,
            rowCount: 3,
            columnCount: 8
        }
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.FreezeRows({
        rowCount: 5
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 5,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Free Cash Flow"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldItalicLine({
        rangeSelector: {
            rowIdx: 5,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        text: "FCF = EBITDA - Capex"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 2,
        text: "EBITDA"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "(-) Capex"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "FCF"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Unlevered Free Cash Flow"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldItalicLine({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        text: "UFCF = NOPAT + D&A + Non-Cash Adj. +/- Working Capital Change - Capex"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 2,
        text: "Tax Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 11,
        colIdx: 4,
        number: 0.2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 2,
        text: "NOPAT"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 2,
        text: "D&A (+ Other)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 2,
        text: "(-/+) Change in Net Working Capital"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 2,
        text: "(-) Capex"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "UFCF"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Levered Free Cash Flow"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldItalicLine({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        text: "LFCF = Net Income + D&A + Non-Cash Adj. +/- Working Capital Change - Capex "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldItalicLine({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 7,
            rowCount: 1,
            columnCount: 1
        },
        text: "            +/- Net Debt Borrowings"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 2,
        text: "Net Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 2,
        text: "D&A (+ Other)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 2,
        text: "(-/+) Change in Net Working Capital"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 2,
        text: "(-) Capex"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 23,
        colIdx: 2,
        text: "(+/-) Net Debt Borrowings"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "LFCF"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 26,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Income Statement"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 26,
        colIdx: 4,
        formula: "=DATE(2021,12,31)",
        numberFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 2,
        text: "Revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 27,
        colIdx: 4,
        number: 14181.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 28,
        colIdx: 2,
        text: "Cost of Goods Sold"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 28,
        colIdx: 4,
        number: -9621.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Gross Profit"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 29,
        colIdx: 4,
        formula: "=SUM(E28:E29)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 31,
        colIdx: 2,
        text: "Sales, Marketing & General Admin"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 31,
        colIdx: 4,
        number: -2139.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 32,
        colIdx: 2,
        text: "Research & Development"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 32,
        colIdx: 4,
        number: -134.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 33,
        colIdx: 2,
        text: "Depreciation & Amortisation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 33,
        colIdx: 4,
        number: -467.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 34,
        colIdx: 2,
        text: "Stock Based Compensation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 34,
        colIdx: 4,
        number: -68.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Total Operating Expense"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 35,
        colIdx: 4,
        formula: "=SUM(E32:E35)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Operating Income (EBIT)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 37,
        colIdx: 4,
        formula: "=SUM(E30,E36)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 39,
        colIdx: 2,
        text: "Interest Expense"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 39,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 39,
        colIdx: 4,
        number: -223.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 40,
        colIdx: 2,
        text: "Interest Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawBottomBorder({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 40,
        colIdx: 4,
        number: 437.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 41,
        colIdx: 2,
        text: "Gain / (Loss) on Asset Sales"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 41,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 41,
        colIdx: 4,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 42,
        colIdx: 2,
        text: "Goodwill Impairment"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 42,
        colIdx: 4,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 43,
        colIdx: 2,
        text: "Asset Write-downs"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 43,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 43,
        colIdx: 4,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 44,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Pre-tax Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 44,
        colIdx: 4,
        formula: "=SUM(E38:E44)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 46,
        colIdx: 2,
        text: "Income Tax"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 46,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 46,
        colIdx: 4,
        number: -474.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 47,
        colIdx: 2,
        text: "Earnings (loss) from unconsolidated entities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 47,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 47,
        colIdx: 4,
        number: 3.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 48,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Net Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 48,
        colIdx: 4,
        formula: "=SUM(E45:E48)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 26,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        title: "Cash Flow Statement"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 26,
        colIdx: 8,
        formula: "=DATE(2021,12,31)",
        numberFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertSubHeader({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        title: "Operating Activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldLine({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 7,
            rowCount: 1,
            columnCount: 2
        },
        text: "Net income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 28,
        colIdx: 8,
        number: 1495.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 29,
        colIdx: 7,
        text: "(+) Depreciation & amortisation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 8,
        number: 467.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 30,
        colIdx: 7,
        text: "(+) Stock based compensation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 30,
        colIdx: 8,
        number: 68.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 31,
        colIdx: 7,
        text: "Changes in operating assets & liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 32,
        colIdx: 7,
        text: "Accounts receivable"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 32,
        colIdx: 8,
        number: 48.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 33,
        colIdx: 7,
        text: "Inventories"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 33,
        colIdx: 8,
        number: -114.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 34,
        colIdx: 7,
        text: "Accounts payable"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 34,
        colIdx: 8,
        number: 137.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 35,
        colIdx: 7,
        text: "Accrued expenses"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 35,
        colIdx: 8,
        number: -140.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 36,
        colIdx: 7,
        text: "(+/-) other changes"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 36,
        colIdx: 8,
        number: -260.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        text: "Net cash provided by (used in) operating activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 37,
        colIdx: 8,
        formula: "=SUM(I29:I37)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertSubHeader({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        title: "Investing Activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 40,
        colIdx: 7,
        text: "(-) Capital expenditure"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 40,
        colIdx: 8,
        number: -553.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 41,
        colIdx: 7,
        text: "(+/-) Net sales (purchase) of securities and other"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 41,
        colIdx: 8,
        number: 25.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 42,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        text: "Net cash provided by (used in) investing activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 42,
        colIdx: 8,
        formula: "=SUM(I41:I42)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertSubHeader({
        rangeSelector: {
            rowIdx: 44,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        title: "Financing Activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 45,
        colIdx: 7,
        text: "(+/-) Net issuances (reductions) of long-term debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 45,
        colIdx: 8,
        number: -399.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 46,
        colIdx: 7,
        text: "(+/-) Net issuances (repurchases) of common stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 46,
        colIdx: 8,
        number: -177.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 47,
        colIdx: 7,
        text: "Cash dividends and other (including FX)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 47,
        colIdx: 8,
        number: -746.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 48,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        text: "Net cash provided by (used in) financing activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 48,
        colIdx: 8,
        formula: "=SUM(I46:I48)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 50,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        text: "Increase (decrease) in cash and cash equivalents"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 50,
        colIdx: 8,
        formula: "=SUM(I38,I43,I49)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 51,
        colIdx: 6,
        text: "Cash and cash equivalents at beginning of the period"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 51,
        colIdx: 8,
        number: 435.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 52,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        text: "Cash and cash equivalents at the end of the period"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 52,
        colIdx: 8,
        formula: "=SUM(I51:I52)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ];
})();
export {
    typesOfFCFWorksheetCommands
};
