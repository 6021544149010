"use strict"

// Properties
export const rowIndex = function (range) {
    return function () {
        return range.rowIndex;
    };
};

export const getWorksheet = function (range) {
    return range.worksheet;
};


// Methods
export const copyFromImpl = function (range) {
    return function (sourceRange) {
        return function (copyTypeString) {
            return function () {
                return range.copyFrom(sourceRange, copyTypeString);
            };
        };
    };
};

export const groupImpl = function (range) {
    return function (groupOptionString) {
        return function () {
            return range.group(groupOptionString);
        };
    };
};

export const hideGroupDetailsImpl = function (range) {
    return function (groupOptionString) {
        return function () {
            return range.hideGroupDetails(groupOptionString);
        };
    };
};

export const loadAll = function (range) {
    return function () {
        return range.load({$all: true});
    };
};

export const insertDown = function (range) {
    return function () {
        return range.insert("Down");
    };
};

export const select = function (range) {
    return function () {
        return range.select();
    };
};

export const set = function (range) {
    return function (properties) {
        return function () {
            return range.set(properties);
        }; 
    };
};

export const setCellProperties = function (range) {
    return function (cellPropertiesData) {
        return function () {
            return range.setCellProperties(cellPropertiesData);
        };
    };
};

export const setColumnWidthImpl = function (range) {
    return function (columnWidthPx) {
        return function () {
            return range.set({format: { columnWidth: columnWidthPx }});
        };
    };
};

export const setHorizontalAlignmentImpl = function (range) {
    return function (horizontalAlignmentString) {
        return function () {
            return range.set({format: {horizontalAlignment: horizontalAlignmentString}});
        };
    };
};

export const setFontImpl = function (range) {
    return function (fontName) {
        return function (fontSize) {
            return function () {
                return range.set({format: { font: { name: fontName, size: fontSize }}});
            };
        };
    };
};

export const showGroupDetailsImpl = function (range) {
    return function (groupOptionString) {
        return function () {
            return range.showGroupDetails(groupOptionString);
        };
    };
};

export const ungroupImpl = function (range) {
    return function (groupOptionString) {
        return function () {
            return range.ungroup(groupOptionString);
        };
    };
};
