// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
var roleOfIBDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "An Investment Bank (IB) is a financial services company that acts as an intermediary in large complex financial transactions. They are often involved in all parts of a company\u2019s life, and we explain their role through this cycle. Always keep in mind that the intermediary process connects borrowers/requirers and lenders/providers of capital."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When a company is new or growing it often needs funding and may turn to an IB to find suitable avenues to raise money. Here the IB will either find a private investor perhaps a large pension fund looking to make a risky investment. Alternatively, it may help to value the company fairly to issue stock for the first time, known as an Initial Public Offering (IPO). During an IPO the bank often values the shares to be issued, finds initial investors, can book run (pre-purchase) the shares, and generally facilitates the process."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "1. Equity valuation for IPOs and Private Equity placement"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "A few years later the company has grown employees but is looking to raise financing. However, the current large shareholders do not want to dilute their shares, so they want to find an alternative to the current capital structure. They hire the IB to raise the company some debt. This involves finding the appropriate level of debt that is affordable for the company and the correct interest rate to compensate investors for the risk."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 2,
        text: "2. Assessing creditworthiness and raising debt"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To expand the company is looking to branch into a new geography but has little experience in the operations within this new continent. It opts to purchase a company that already operates in the region. It hires its IB to run a Buy-Side Merger and Acquisition (M&A) process. It will find a target and price it accordingly trying to get the best price bid (there may be others)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 9,
        colIdx: 2,
        text: "3. Buy-Side M&A process, valuing targets, pitching and offer creation"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "At the same time, the seller will hire another bank to value it and find the best price they can sell. Of course, one IB wants as high a price as possible and the other as low a price as possible and it is this intermediation that finds the common ground. They also manage the process, data transfer, and aid in the negotiation of how the seller is to be paid (stock or cash)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 2,
        text: "4. Sell-Side M&A process, valuing assets, receiving offers"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "After a half-decade has passed the company has remained relatively stagnant after its geographical expansion failing to generate enough income to justify it. In this process, an unfriendly (hostile) investor has bought stock and is offering every shareholder an opportunity to sell their shares with the aim of taking control of the company. The company can then go back to its trusted IB to manage a \u2018takeover defence\u2019 to find ways to incentivise shareholders to remain invested and not sell to the Activist Investor."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 2,
        text: "5. Takeover defence"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "After staving off the attack the company decides it is time for a change and plans to sell its acquired international business. In doing so it hires the IB to run a Sell-Side M&A transaction. Once the company has sold the international business it decides it needs to use this money to restructure its capital structure and remove the external debt it currently holds to prevent further stagnation. It discusses changes in capital structure reform with the Restructuring bankers at its IB that find a solution by paying down the majority of the debt and distributing cash to shareholders through dividends and stock repurchases."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 2,
        text: "6. Restructuring to reduce debt and improve liquidity position"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, after a further few years of treading water, the company has suffered too great a loss to recuperate or restore trust in its investors. It recalls its restructuring bankers whilst its shareholders and the small number of remaining debt holders hire separate IBs, and they hold discussions on how the company will be sold off and liquidated to pay out the different stakeholders."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 2,
        text: "7. Liquidation to value and sell assets, and return value to stakeholders"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now does that story sound interesting, well that is some of the work that IBs and its bankers work on. Now a caveat is that it isn\u2019t all action and there is lots of due diligence, the process running and modelling that is to come with the milestones, but IBs provide a role of managing relationships and situations when companies are making major changes to their businesses."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Of course, the most typical roles include M&A or other products such as Restructuring, or a role covering a variety of products but for an Industry such as Healthcare, Tech or Energy. There are also roles in Sales & Trading, but this course is not centred around the role of Market functions of IBs."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The key role of the investment banker as a junior, is to produce the Excel models and calculations that go into the presentations that are given to management, investors, and various stakeholders. There is also process running responsibilities but that is not for this course to teach."
    })) ] ];
})();
export {
    roleOfIBDescriptions
};
