// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var terminologyDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This module aims introduce a beginner to the core concepts of finance. For those who know what different financial instruments are, and the time value of money is, this section may not be necessary for you. This section is definition heavy for the purpose of grounding any individual who may not have come across these terms before."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we go through what the key financial instruments are. These are commonly split into 2 key groups: Stocks and Bonds."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "A key term used in finance is the phrase fungible or fungibility which essentially means the ability of a good or asset to be interchanged with other individual goods of the same type. A great example of fungibility is money, \xa310 is \xa310 no matter how it is delivered to you (notes or coins). The \xa310 can be given to you in \xa31 coins or \xa35 notes or any manner of other ways. This interchangeability is what gives rise to the fungibility of money."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 2,
        text: "Fungibility: \xa310 === \xa35 and \xa35 === \xa32, \xa32, \xa32, \xa32, and \xa32"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The next term is Financial Instrument. These are assets (or things of value) that can be traded (often on exchanges). These instruments often enable the flow and transfer of capital (anything that has value or benefits to its owner) between investors. These can be in the form of cash, a contractual right to receive or deliver cash to an individual or entity."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 2,
        text: "Financial Instrument: Asset that is traded, enabling flow of capital between investors"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Key examples of Financial Instruments are Stocks and Bonds."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Stocks (synonyms: equity or equities), represent the ownership of an entity and in the case of finance we are referring to the ownership of a fraction of the company that issues the shares. Each unit of stock is a \u2018share\u2019 that entitles the owner a proportion of the corporation\u2019s assets and profits, equal to the proportion of the stock they own."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 2,
        text: "Stocks: Ownership/Equity stake in a business"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Stocks are predominantly bought or sold on a stock exchange. The most famous exchanges are the New York Stock Exchange (NYSE), the NASDAQ, and the London Stock Exchange (LSE)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 3,
        text: "Traded on exchanges"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Shareholders are often given a right to vote in company affairs at meetings during certain points in the year and the more shares you own and control the greater your decision making through the appointment of directors on the board."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 3,
        text: "Right to vote"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now stocks can generate a return (or income) for us in two ways. The first is through them paying a dividend. The right to the company\u2019s earnings does not necessarily mean that the company is going to give you it at the end of the year. In fact the company often retains earnings to fuel operations for the following years. In some cases they can announce a dividend which is a cash distribution to shareholders proportional to their ownership."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 3,
        text: "Generate returns through dividend and capital appreciation"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, if I own 50% of a company\u2019s stock and they announce a \xa3100 dividend I am entitled to 50% of the dividend, so \xa350. If a dividend is announced all common shareholders receive a portion and the company cannot discriminate on how it pays to."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Dividend"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 2,
        text: "Ownership Stake"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 16,
        colIdx: 4,
        number: 0.5,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 2,
        text: "Announced Dividend"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 17,
        colIdx: 4,
        number: 100.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Return"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=E17*E18",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 16,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 17,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The other way in which a return can be generated is through speculating on the movement of the stock price. For example, if we purchase a stock for \xa320 and a year later sell it for \xa330, we have made \xa310 profit. We refer to this as a long position."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        title: "Price Return"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 2,
        text: "Purchase Price"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 21,
        colIdx: 4,
        number: 20.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 2,
        text: "Exit Price"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputNumber({
        rowIdx: 22,
        colIdx: 4,
        number: 30.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 23,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Profit"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 23,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=E23-E22",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 21,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 22,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We often represent this appreciation as a percentage \xa310/\xa320 = 50%, a 50% return."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        text: "Return"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=E24/E22",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value,
        references: [ {
            rangeSelector: {
                rowIdx: 21,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 23,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.TopBottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can also benefit if we bet on the price going down. This involves taking a short position in a company\u2019s stock which is an advanced topic. However, it involves borrowing the stock and selling it to the market. Then buying it in the market when the price decreases to repay the borrower. The point here is we do not just benefit from the increase in prices but also if they fall. We also have an instrument called an option that can be made to bet on the movement of a price."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now returning to the long position, of course we could have a dividend paid and a price rise simultaneously and so a stock investor does not need to only choose one."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our next key instrument is debt which is used in the facilitation of borrowing. Companies can borrow funds from others in a formal arrangement to help them grow. The Company becomes the borrower (or debtor). They arrange with a lender (or creditor) who will provide them with funding. These arrangements can be in forms known as bonds, notes, or loans. The differences are largely a matter of legal definition, but we save this for an advanced course."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 6,
        text: "Bonds: Facilitates borrowing for a business (debtor), with funds provided by a lender (creditor)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 6,
            rowCount: 1,
            columnCount: 6
        },
        title: "Bond Payments"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 9,
        text: "Year 1"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 10,
        text: "Year 2"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 11,
        text: "Year 3"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 6,
        text: "Debt Drawdown"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 16,
        colIdx: 8
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 6,
        text: "Interest Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 17,
        colIdx: 8
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 6,
        text: "Annual Interest Payment"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 6,
        text: "Interest Payment"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 21,
            colIdx: 6,
            rowCount: 1,
            columnCount: 6
        },
        text: "Profit"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Debt is provided by the creditor on a contractual fixed term. This means the creditor provides the debt today, by funding a company with cash, with a guarantee that the company will return it at a set date in the future. This future date is called the maturity date."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 7,
        text: "Contractual fixed term"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When \xa3100 is lent to the borrower, the actual distribution of the funds is referred to as the drawdown of the debt. Examples of this include when the bond is issued and the funds are paid to the borrower immediately, or when an overdraft is set up so that when the company uses the available funds. These are both examples of the drawdown of debt."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 16,
        colIdx: 8,
        number: 100.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now, the lender will require some compensation for providing the debt to the company. Consider lending \xa3100 today only to receive the \xa3100 back in 3 years.  Therefore, to incentivise lenders, the company will pay a coupon (a small fraction of the total debt). This is known as interest. The interest rate is the percentage of the amount of debt (or face value) that is paid each period."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 7,
        text: "Pays a regular percentage each period called interest"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 17,
        colIdx: 8,
        number: 5.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 17,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we calculate our interest payment. To do this, we take our debt drawdown and multiply it by our interest rate. This gives us \xa3100 * 5% = \xa35. In this example, we will assume that the interest rate is the same each year."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I17*I18",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 16,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 17,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we want to link the interest payment to each year. We can do this for Year 1 by pressing \u2018=\u2019 and then highlighting cell H19 where we calculated the interest payment. Before we press the enter, we press the F4 key. This will anchor the cell. Anchoring will fix the column and/or the row of a cell so when we copy the formula the column and/or row does not change. In this example, we are anchoring both and we can see this is true because the H and 19 have $\u2019s before them. If we hit F4 again it will cycle through the various anchoring options."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 9,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=$I$19",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 18,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To see this in action let\u2019s copy across the formula we just created. First, we highlight the cell with the original link in it. We then hold SHIFT and press the right arrow key twice to highlight the two cells to the right where we want to show the interest payment. Finally, we can press CTRL + R to paste the first formulae to the right."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 9,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 9,
            rowCount: 1,
            columnCount: 3
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we see the interest payments are populated in the columns for Years 2 and 3, with a \xa35 payment each year. If we highlight the formula in each cell, we notice that the column and row references have not changed because they were anchored. This meant the cell we linked to did not change when we copied across."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 9,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can use the SUM function and highlight our 3 years of interest payments to calculate the total of all the interest payments."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 21,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(J21:L21)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 20,
                colIdx: 9,
                rowCount: 1,
                columnCount: 3
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As we can see our total profit is \xa315 (the total of 3 years of \xa35 of interest payments)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 21,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can see that we are guaranteed our \xa315 of profits, which works out as a 5% return each year, but we do not share in any upside like when investing in a stock. We also do not have the choice to be paid extra through a dividend. However, these payments are a contractual obligation that comes with repercussions if not followed. We can see that debt has a guaranteed return making it less risky but in doing so the return profile is typically lower than a stock."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now there is an additional feature of debt that makes it less risky compared to equity. This is called seniority (debt is senior to equity). This means that if the company goes bankrupt (stops operating and sells what it owns and repays everyone), the debt has a priority of being repaid first, importantly before the equity owners of the business. This is done through legal contracts (not discussed in the scope of this course but is worth being aware of the brief reason). Now this does not mean they will always get repaid when a company is in distress, but they are prioritised when the capital providers are repaid."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 7,
        text: "Guaranteed return but lower risk through seniority"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 7,
            rowCount: 1,
            columnCount: 2
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The key takeaway is that stocks and bonds are the two most common ways to fund a company."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When a company starts or needs more funds it often can raise equity (stock) or debt. These instruments are sold to investors who provide the company with their fungible cash. The investors buy stock in the hope of a dividend payout or a stock appreciation if earnings improve. Those who invest in the debt are more risk-averse taking their guaranteed return but do not share in any of the upside potential that equity does. Equity investors on the other hand face the risk of not making a return."
    })) ] ];
})();
export {
    terminologyDescriptions
};
