// Generated by purs version 0.15.13
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Page_AccountingQuiz from "../BankingReady.Page.AccountingQuiz/index.js";
import * as BankingReady_Page_CompanyValueQuiz from "../BankingReady.Page.CompanyValueQuiz/index.js";
import * as BankingReady_Page_DCFValuationQuiz from "../BankingReady.Page.DCFValuationQuiz/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var slot = /* #__PURE__ */ Halogen_HTML.slot();
var slot1 = /* #__PURE__ */ slot({
    reflectSymbol: function () {
        return "accountingQuiz";
    }
})(Data_Ord.ordUnit);
var slot2 = /* #__PURE__ */ slot({
    reflectSymbol: function () {
        return "companyValueQuiz";
    }
})(Data_Ord.ordUnit);
var slot3 = /* #__PURE__ */ slot({
    reflectSymbol: function () {
        return "dcfValuationQuiz";
    }
})(Data_Ord.ordUnit);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var when = /* #__PURE__ */ Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM);
var notEq = /* #__PURE__ */ Data_Eq.notEq(BankingReady_Data_Route.eqQuizRoute);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var Receive = /* #__PURE__ */ (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var HandleAccountingQuiz = /* #__PURE__ */ (function () {
    function HandleAccountingQuiz(value0) {
        this.value0 = value0;
    };
    HandleAccountingQuiz.create = function (value0) {
        return new HandleAccountingQuiz(value0);
    };
    return HandleAccountingQuiz;
})();
var HandleCompanyValueQuiz = /* #__PURE__ */ (function () {
    function HandleCompanyValueQuiz(value0) {
        this.value0 = value0;
    };
    HandleCompanyValueQuiz.create = function (value0) {
        return new HandleCompanyValueQuiz(value0);
    };
    return HandleCompanyValueQuiz;
})();
var HandleDCFValuationQuiz = /* #__PURE__ */ (function () {
    function HandleDCFValuationQuiz(value0) {
        this.value0 = value0;
    };
    HandleDCFValuationQuiz.create = function (value0) {
        return new HandleDCFValuationQuiz(value0);
    };
    return HandleDCFValuationQuiz;
})();
var component = function (dictMonadAff) {
    var component1 = BankingReady_Page_AccountingQuiz.component(dictMonadAff);
    var component2 = BankingReady_Page_CompanyValueQuiz.component(dictMonadAff);
    var component3 = BankingReady_Page_DCFValuationQuiz.component(dictMonadAff);
    return function (dictMonadStore) {
        return function (dictNavigate) {
            var navigate = BankingReady_Capability_Navigate.navigate(BankingReady_Capability_Navigate.navigateHalogenM(dictNavigate));
            return function (dictManageExcelQuiz) {
                var component4 = component1(dictManageExcelQuiz);
                var component5 = component2(dictManageExcelQuiz);
                var component6 = component3(dictManageExcelQuiz);
                var render = function (v) {
                    if (v.route instanceof BankingReady_Data_Route.AccountingQuiz) {
                        return slot1(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component4)(Data_Unit.unit)(HandleAccountingQuiz.create);
                    };
                    if (v.route instanceof BankingReady_Data_Route.CompanyValueQuiz) {
                        return slot2(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component5)(Data_Unit.unit)(HandleCompanyValueQuiz.create);
                    };
                    if (v.route instanceof BankingReady_Data_Route.DCFValuationQuiz) {
                        return slot3(Type_Proxy["Proxy"].value)(Data_Unit.unit)(component6)(Data_Unit.unit)(HandleDCFValuationQuiz.create);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.QuizRouter (line 76, column 22 - line 84, column 110): " + [ v.route.constructor.name ]);
                };
                var handleAction = function (v) {
                    if (v instanceof Receive) {
                        return bind(get)(function (v1) {
                            return when(notEq(v1.route)(v.value0))(modify_(function (v2) {
                                var $38 = {};
                                for (var $39 in v2) {
                                    if ({}.hasOwnProperty.call(v2, $39)) {
                                        $38[$39] = v2[$39];
                                    };
                                };
                                $38.route = v.value0;
                                return $38;
                            }));
                        });
                    };
                    if (v instanceof HandleAccountingQuiz) {
                        return navigate(BankingReady_Data_Route.Home.value);
                    };
                    if (v instanceof HandleCompanyValueQuiz) {
                        return navigate(BankingReady_Data_Route.Home.value);
                    };
                    if (v instanceof HandleDCFValuationQuiz) {
                        return navigate(BankingReady_Data_Route.Home.value);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.QuizRouter (line 56, column 18 - line 73, column 24): " + [ v.constructor.name ]);
                };
                return Halogen_Component.mkComponent({
                    initialState: function (quizRoute) {
                        return {
                            route: quizRoute
                        };
                    },
                    render: render,
                    "eval": Halogen_Component.mkEval({
                        handleQuery: Halogen_Component.defaultEval.handleQuery,
                        initialize: Halogen_Component.defaultEval.initialize,
                        finalize: Halogen_Component.defaultEval.finalize,
                        handleAction: handleAction,
                        receive: function ($49) {
                            return Data_Maybe.Just.create(Receive.create($49));
                        }
                    })
                });
            };
        };
    };
};
export {
    Receive,
    HandleAccountingQuiz,
    HandleCompanyValueQuiz,
    HandleDCFValuationQuiz,
    component
};
