"use strict"

export const runImpl = function (batch) {
    return function () {
        return window.Excel.run(batch(new Excel.RequestContext()));
    };
};

export const syncImpl = function (context) {
    return function () {
        return context.sync();
    };
};
