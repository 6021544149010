// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Partial_Unsafe from "../Partial.Unsafe/index.js";
var unsafeFromRight = /* #__PURE__ */ Data_Either.either(function (v) {
    return Partial_Unsafe.unsafeCrashWith("This should never happen! If you see this message, please file a bug report in the `purescript-b64` issue tracker.");
})(/* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn));
var uint8ArrayToBtoaSafeString = function (u8) {
    return $foreign.uint8ArrayToBtoaSafeStringImpl(u8);
};
var toUrlSafe = /* #__PURE__ */ (function () {
    var $5 = Data_String_Common.replaceAll("=")("");
    var $6 = Data_String_Common.replaceAll("/")("_");
    var $7 = Data_String_Common.replaceAll("+")("-");
    return function ($8) {
        return $5($6($7($8)));
    };
})();
var toRfc4648 = /* #__PURE__ */ (function () {
    var $9 = Data_String_Common.replaceAll("-")("+");
    var $10 = Data_String_Common.replaceAll("_")("/");
    return function ($11) {
        return $9($10($11));
    };
})();
var asUint8Array = function (arr) {
    return $foreign.asUint8ArrayImpl(arr);
};
var unsafeStringToUint8ArrayOfCharCodes = /* #__PURE__ */ (function () {
    var $12 = Data_Functor.map(Data_Functor.functorArray)(Data_Enum.fromEnum(Data_String_CodePoints.boundedEnumCodePoint));
    return function ($13) {
        return asUint8Array($12(Data_String_CodePoints.toCodePointArray($13)));
    };
})();
export {
    atobIsDefined,
    btoaIsDefined
} from "./foreign.js";
export {
    uint8ArrayToBtoaSafeString,
    unsafeFromRight,
    unsafeStringToUint8ArrayOfCharCodes,
    toUrlSafe,
    toRfc4648
};
