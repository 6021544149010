// Generated by purs version 0.15.13
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel_Chart from "../BankingReady.Foreign.Excel.Chart/index.js";
import * as BankingReady_Foreign_Excel_ChartCollection from "../BankingReady.Foreign.Excel.ChartCollection/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetRoute);
var postCompanyValueCommandEffect = function (context) {
    return function (route) {
        return function (v) {
            var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
            var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
            var sourceDataRange = BankingReady_Utility_Excel_Cell.getRangeByRangeSelector(worksheet)(v.value0.rangeSelector);
            var chartCollection = BankingReady_Foreign_Excel_Worksheet.getCharts(worksheet);
            return function __do() {
                var chart = BankingReady_Foreign_Excel_ChartCollection.addChart(chartCollection)(v.value0.chartType)(sourceDataRange)(v.value0.seriesBy)();
                BankingReady_Foreign_Excel_Chart.setChartName(chart)(v.value0.name)();
                BankingReady_Foreign_Excel_Chart.setChartFontSize(chart)(12)();
                BankingReady_Foreign_Excel_Chart.setVerticalAxesGridlinesVisible(chart)(false)();
                BankingReady_Foreign_Excel_Chart.setTitleVisible(chart)(false)();
                BankingReady_Foreign_Excel_Chart.setLegendRight(chart)();
                BankingReady_Foreign_Excel_Chart.setDataLabelsVisible(chart)();
                var startCellRange = BankingReady_Utility_Excel_Cell.getRangeByRangeSelector(worksheet)({
                    rowIdx: 6,
                    colIdx: 7,
                    rowCount: 1,
                    columnCount: 1
                });
                var endCellRange = BankingReady_Utility_Excel_Cell.getRangeByRangeSelector(worksheet)({
                    rowIdx: 23,
                    colIdx: 13,
                    rowCount: 1,
                    columnCount: 1
                });
                return BankingReady_Foreign_Excel_Chart.setChartPosition(chart)(startCellRange)(endCellRange)();
            };
        };
    };
};
export {
    postCompanyValueCommandEffect
};
