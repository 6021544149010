// Generated by purs version 0.15.13
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Routing_Duplex.applicativeRouteDuplex);
var product = /* #__PURE__ */ Routing_Duplex_Generic.product(Routing_Duplex_Generic.gRouteAll);
var gsepStringString = {
    gsep: function (a) {
        return function (b) {
            return Routing_Duplex.prefix(a)(Routing_Duplex.prefix(b)(pure(Data_Unit.unit)));
        };
    }
};
var gsepStringRoute = function (dictGRouteDuplexCtr) {
    var gRouteDuplexCtr = Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr);
    return {
        gsep: function (a) {
            var $15 = Routing_Duplex.prefix(a);
            return function ($16) {
                return $15(gRouteDuplexCtr($16));
            };
        }
    };
};
var gsepRouteString = function (dictGRouteDuplexCtr) {
    return {
        gsep: (function () {
            var $17 = Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr);
            return function ($18) {
                return Routing_Duplex.suffix($17($18));
            };
        })()
    };
};
var gsepProduct = function (dictGRouteDuplexCtr) {
    return {
        gsep: Routing_Duplex_Generic.product(dictGRouteDuplexCtr)
    };
};
var gsep = function (dict) {
    return dict.gsep;
};
var gparamsString = function (dictRouteDuplexParams) {
    var params = Routing_Duplex.params(dictRouteDuplexParams);
    return {
        gparams: function (a) {
            var $19 = Routing_Duplex.prefix(a);
            return function ($20) {
                return $19(params($20));
            };
        }
    };
};
var gparamsRoute = function (dictRouteDuplexParams) {
    var params = Routing_Duplex.params(dictRouteDuplexParams);
    return {
        gparams: function (a) {
            var $21 = product(a);
            return function ($22) {
                return $21(params($22));
            };
        }
    };
};
var gparams = function (dict) {
    return dict.gparams;
};
export {
    gparams,
    gsep,
    gsepStringString,
    gsepStringRoute,
    gsepRouteString,
    gsepProduct,
    gparamsString,
    gparamsRoute
};
