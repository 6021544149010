// Generated by purs version 0.15.13
import * as Data_Profunctor_Choice from "../Data.Profunctor.Choice/index.js";
var _Right = function (dictChoice) {
    return Data_Profunctor_Choice.right(dictChoice);
};
var _Left = function (dictChoice) {
    return Data_Profunctor_Choice.left(dictChoice);
};
export {
    _Left,
    _Right
};
export {
    left,
    right
} from "../Data.Profunctor.Choice/index.js";
