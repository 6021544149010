// Generated by purs version 0.15.13
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_TraversableWithIndex from "../Data.TraversableWithIndex/index.js";
import * as Effect from "../Effect/index.js";
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetRoute);
var forWithIndex = /* #__PURE__ */ Data_TraversableWithIndex.forWithIndex(Effect.applicativeEffect)(Data_TraversableWithIndex.traversableWithIndexArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var postAccountingCommandEffect = function (context) {
    return function (route) {
        return function (v) {
            var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
            var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
            return function __do() {
                BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v.value0.targetRowIdx)(v.value0.targetColIdx - 1 | 0)("<--------------")();
                forWithIndex(v.value0.textArray)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v.value0.targetRowIdx + idx | 0)(v.value0.targetColIdx)(elem);
                    };
                })();
                var textRowCount = Data_Array.length(v.value0.textArray);
                var textRangeSelector = {
                    rowIdx: v.value0.targetRowIdx,
                    colIdx: v.value0.targetColIdx,
                    rowCount: textRowCount,
                    columnCount: 7
                };
                return BankingReady_Utility_Excel_Border.highlightBorder(worksheet)(textRangeSelector)(BankingReady_Utility_Excel_Border.Black.value)();
            };
        };
    };
};
var cleanAccountingCommandEffect = function (context) {
    return function (route) {
        return function (v) {
            var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
            var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
            var textRowCount = Data_Array.length(v.value0.textArray);
            var emptyTextArray = map(function (v1) {
                return "";
            })(Data_Array.range(1)(textRowCount));
            return function __do() {
                BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v.value0.targetRowIdx)(v.value0.targetColIdx - 1 | 0)("")();
                forWithIndex(emptyTextArray)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(v.value0.targetRowIdx + idx | 0)(v.value0.targetColIdx)(elem);
                    };
                })();
                var textRangeSelector = {
                    rowIdx: v.value0.targetRowIdx,
                    colIdx: v.value0.targetColIdx,
                    rowCount: textRowCount,
                    columnCount: 7
                };
                return BankingReady_Utility_Excel_Border.removeHighlightBorder(worksheet)(textRangeSelector)(BankingReady_Utility_Excel_Border.Normal.value)();
            };
        };
    };
};
export {
    postAccountingCommandEffect,
    cleanAccountingCommandEffect
};
