// Generated by purs version 0.15.13
import * as BankingReady_Capability_Excel_Quiz from "../BankingReady.Capability.Excel.Quiz/index.js";
import * as BankingReady_Data_Quiz from "../BankingReady.Data.Quiz/index.js";
import * as BankingReady_Data_Quiz_CompanyValue from "../BankingReady.Data.Quiz.CompanyValue/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Utility_HTML from "../BankingReady.Utility.HTML/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var eq = /* #__PURE__ */ Data_Eq.eq(BankingReady_Data_Quiz.eqSelection);
var show = /* #__PURE__ */ Data_Show.show(BankingReady_Data_Quiz.showSelection);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var NotAnswered = /* #__PURE__ */ (function () {
    function NotAnswered(value0) {
        this.value0 = value0;
    };
    NotAnswered.create = function (value0) {
        return new NotAnswered(value0);
    };
    return NotAnswered;
})();
var Answered = /* #__PURE__ */ (function () {
    function Answered(value0) {
        this.value0 = value0;
    };
    Answered.create = function (value0) {
        return new Answered(value0);
    };
    return Answered;
})();
var Finish = /* #__PURE__ */ (function () {
    function Finish() {

    };
    Finish.value = new Finish();
    return Finish;
})();
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Finalize = /* #__PURE__ */ (function () {
    function Finalize() {

    };
    Finalize.value = new Finalize();
    return Finalize;
})();
var CheckAnswer = /* #__PURE__ */ (function () {
    function CheckAnswer(value0) {
        this.value0 = value0;
    };
    CheckAnswer.create = function (value0) {
        return new CheckAnswer(value0);
    };
    return CheckAnswer;
})();
var NextQuestion = /* #__PURE__ */ (function () {
    function NextQuestion() {

    };
    NextQuestion.value = new NextQuestion();
    return NextQuestion;
})();
var component = function (dictMonadAff) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()));
    return function (dictManageExcelQuiz) {
        var manageExcelQuizHalogenM = BankingReady_Capability_Excel_Quiz.manageExcelQuizHalogenM(dictManageExcelQuiz);
        var createQuestionWorksheet = BankingReady_Capability_Excel_Quiz.createQuestionWorksheet(manageExcelQuizHalogenM);
        var deleteQuestionWorksheet = BankingReady_Capability_Excel_Quiz.deleteQuestionWorksheet(manageExcelQuizHalogenM);
        var writeQuestionExplanation = BankingReady_Capability_Excel_Quiz.writeQuestionExplanation(manageExcelQuizHalogenM);
        var deleteAndCreateNewQuestionWorksheet = BankingReady_Capability_Excel_Quiz.deleteAndCreateNewQuestionWorksheet(manageExcelQuizHalogenM);
        var render = function (state) {
            var quizButton = function (selection) {
                return Halogen_HTML_Elements.div(append([ BankingReady_Utility_HTML.css("my-3 flex h-16 w-72 items-center rounded border border-slate-700 text-base shadow " + (function () {
                    if (state instanceof NotAnswered) {
                        return "cursor-pointer bg-gray-50 hover:border-slate-950 hover:text-zinc-950 active:shadow-md";
                    };
                    if (state instanceof Answered) {
                        var $32 = eq(state.value0.currentQuestion.correctAnswer)(selection);
                        if ($32) {
                            return "cursor-default bg-green-100";
                        };
                        var $33 = eq(state.value0.selected)(selection);
                        if ($33) {
                            return "cursor-default bg-red-200";
                        };
                        return "cursor-default bg-gray-50 text-zinc-500";
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 146, column 18 - line 152, column 65): " + [ state.constructor.name ]);
                })()) ])((function () {
                    if (state instanceof NotAnswered) {
                        return [ Halogen_HTML_Events.onClick(function (v) {
                            return new CheckAnswer(selection);
                        }) ];
                    };
                    if (state instanceof Answered) {
                        return [  ];
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 154, column 14 - line 156, column 27): " + [ state.constructor.name ]);
                })()))([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("Answer " + show(selection)) ]) ]);
            };
            var nextButton = Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex h-12 w-[350px] flex-row justify-end px-8 pb-4 text-base") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              mb-3 flex h-9 w-16 cursor-pointer select-none items-center justify-center \x0a              rounded border border-slate-700 bg-gray-50 text-zinc-700 shadow \x0a              hover:border-slate-950 hover:text-zinc-950 active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v) {
                return NextQuestion.value;
            }) ])([ Halogen_HTML_Core.text("Next") ]) ]);
            return Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex min-h-screen flex-col items-center justify-center bg-white text-zinc-900") ])(append([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("h-9 min-w-full pl-6 pt-4 text-sm") ])([ Halogen_HTML_Elements.a([ BankingReady_Utility_HTML.css("cursor-pointer hover:underline hover:underline-offset-2"), BankingReady_Utility_HTML.safeHref(BankingReady_Data_Route.Home.value) ])([ Halogen_HTML_Core.text("Home") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex grow select-none flex-col items-center justify-center " + (function () {
                if (state instanceof NotAnswered) {
                    return "pb-9 text-zinc-700";
                };
                if (state instanceof Answered) {
                    return "pt-3";
                };
                throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 128, column 20 - line 130, column 39): " + [ state.constructor.name ]);
            })()) ])([ quizButton(BankingReady_Data_Quiz.A.value), quizButton(BankingReady_Data_Quiz.B.value), quizButton(BankingReady_Data_Quiz.C.value), quizButton(BankingReady_Data_Quiz.D.value) ]) ])((function () {
                if (state instanceof NotAnswered) {
                    return [  ];
                };
                if (state instanceof Answered) {
                    return [ nextButton ];
                };
                throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 137, column 14 - line 139, column 39): " + [ state.constructor.name ]);
            })()));
        };
        var handleAction = function (v) {
            if (v instanceof Initialize) {
                return bind(get)(function (v1) {
                    if (v1 instanceof Answered) {
                        return liftEffect(Effect_Console.log("Initialize should only run from NotAnswered state."));
                    };
                    if (v1 instanceof NotAnswered) {
                        return createQuestionWorksheet(BankingReady_Data_Route.CompanyValueQuiz.value)(v1.value0.currentQuestion);
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 76, column 29 - line 78, column 98): " + [ v1.constructor.name ]);
                });
            };
            if (v instanceof Finalize) {
                return deleteQuestionWorksheet(BankingReady_Data_Route.CompanyValueQuiz.value);
            };
            if (v instanceof CheckAnswer) {
                return bind(get)(function (v1) {
                    if (v1 instanceof Answered) {
                        return liftEffect(Effect_Console.log("CheckAnswer should only run from NotAnswered state."));
                    };
                    if (v1 instanceof NotAnswered) {
                        return discard(writeQuestionExplanation(BankingReady_Data_Route.CompanyValueQuiz.value)(v1.value0.currentQuestion)(v.value0))(function () {
                            return modify_(function (v2) {
                                return new Answered({
                                    selected: v.value0,
                                    currentQuestion: v1.value0.currentQuestion,
                                    upcomingQuestions: v1.value0.upcomingQuestions
                                });
                            });
                        });
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 83, column 40 - line 92, column 12): " + [ v1.constructor.name ]);
                });
            };
            if (v instanceof NextQuestion) {
                return bind(get)(function (v1) {
                    if (v1 instanceof NotAnswered) {
                        return liftEffect(Effect_Console.log("NextQuestion should only run from Answered state."));
                    };
                    if (v1 instanceof Answered) {
                        var v2 = Data_Array.uncons(v1.value0.upcomingQuestions);
                        if (v2 instanceof Data_Maybe.Just) {
                            return discard(deleteAndCreateNewQuestionWorksheet(BankingReady_Data_Route.CompanyValueQuiz.value)(v2.value0.head))(function () {
                                return modify_(function (v3) {
                                    return new NotAnswered({
                                        currentQuestion: v2.value0.head,
                                        upcomingQuestions: v2.value0.tail
                                    });
                                });
                            });
                        };
                        if (v2 instanceof Data_Maybe.Nothing) {
                            return Halogen_Query_HalogenM.raise(Finish.value);
                        };
                        throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 97, column 9 - line 109, column 27): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 94, column 31 - line 109, column 27): " + [ v1.constructor.name ]);
                });
            };
            throw new Error("Failed pattern match at BankingReady.Page.CompanyValueQuiz (line 75, column 18 - line 109, column 27): " + [ v.constructor.name ]);
        };
        return Halogen_Component.mkComponent({
            initialState: function (v) {
                return new NotAnswered({
                    currentQuestion: BankingReady_Data_Quiz_CompanyValue.firstQuizQuestion,
                    upcomingQuestions: BankingReady_Data_Quiz_CompanyValue.upcomingQuizQuestions
                });
            },
            render: render,
            "eval": Halogen_Component.mkEval({
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                handleAction: handleAction,
                initialize: new Data_Maybe.Just(Initialize.value),
                finalize: new Data_Maybe.Just(Finalize.value)
            })
        });
    };
};
export {
    Finish,
    NotAnswered,
    Answered,
    Initialize,
    Finalize,
    CheckAnswer,
    NextQuestion,
    component
};
