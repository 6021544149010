// Generated by purs version 0.15.13
var Size10 = /* #__PURE__ */ (function () {
    function Size10() {

    };
    Size10.value = new Size10();
    return Size10;
})();
var Size13 = /* #__PURE__ */ (function () {
    function Size13() {

    };
    Size13.value = new Size13();
    return Size13;
})();
var Arial = /* #__PURE__ */ (function () {
    function Arial() {

    };
    Arial.value = new Arial();
    return Arial;
})();
var showFont = {
    show: function (v) {
        return "Arial";
    }
};
var pt = function (v) {
    if (v instanceof Size10) {
        return 10;
    };
    if (v instanceof Size13) {
        return 13;
    };
    throw new Error("Failed pattern match at BankingReady.Data.Font (line 16, column 6 - line 18, column 15): " + [ v.constructor.name ]);
};
export {
    Arial,
    Size10,
    Size13,
    pt,
    showFont
};
