// Generated by purs version 0.15.13
import * as BankingReady_Capability_ExcelEditor_Effect_Accounting from "../BankingReady.Capability.ExcelEditor.Effect.Accounting/index.js";
import * as BankingReady_Capability_ExcelEditor_Effect_CompanyValue from "../BankingReady.Capability.ExcelEditor.Effect.CompanyValue/index.js";
import * as BankingReady_Capability_ExcelEditor_Effect_DCFValuation from "../BankingReady.Capability.ExcelEditor.Effect.DCFValuation/index.js";
import * as BankingReady_Capability_ExcelEditor_Effect_General from "../BankingReady.Capability.ExcelEditor.Effect.General/index.js";
import * as BankingReady_Capability_ExcelEditor_Effect_Worksheet from "../BankingReady.Capability.ExcelEditor.Effect.Worksheet/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Data_Worksheet_Accounting_AccountingIntro from "../BankingReady.Data.Worksheet.Accounting.AccountingIntro/index.js";
import * as BankingReady_Data_Worksheet_Accounting_BalanceSheet from "../BankingReady.Data.Worksheet.Accounting.BalanceSheet/index.js";
import * as BankingReady_Data_Worksheet_Accounting_BalanceSheetIntro from "../BankingReady.Data.Worksheet.Accounting.BalanceSheetIntro/index.js";
import * as BankingReady_Data_Worksheet_Accounting_CashFlowStatement from "../BankingReady.Data.Worksheet.Accounting.CashFlowStatement/index.js";
import * as BankingReady_Data_Worksheet_Accounting_CashFlowStatementIntro from "../BankingReady.Data.Worksheet.Accounting.CashFlowStatementIntro/index.js";
import * as BankingReady_Data_Worksheet_Accounting_IncomeStatement from "../BankingReady.Data.Worksheet.Accounting.IncomeStatement/index.js";
import * as BankingReady_Data_Worksheet_Accounting_IncomeStatementIntro from "../BankingReady.Data.Worksheet.Accounting.IncomeStatementIntro/index.js";
import * as BankingReady_Data_Worksheet_Accounting_StatementChanges from "../BankingReady.Data.Worksheet.Accounting.StatementChanges/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_CalculatingEnterpriseValue from "../BankingReady.Data.Worksheet.CompanyValue.CalculatingEnterpriseValue/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_CalculatingEquityValue from "../BankingReady.Data.Worksheet.CompanyValue.CalculatingEquityValue/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_ChangesInEquityAndEnterpriseValue from "../BankingReady.Data.Worksheet.CompanyValue.ChangesInEquityAndEnterpriseValue/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_CompanyValueIntro from "../BankingReady.Data.Worksheet.CompanyValue.CompanyValueIntro/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_DilutionToEquity from "../BankingReady.Data.Worksheet.CompanyValue.DilutionToEquity/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_EquityAndEnterpriseValue from "../BankingReady.Data.Worksheet.CompanyValue.EquityAndEnterpriseValue/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_IncomeStatementMultiples from "../BankingReady.Data.Worksheet.CompanyValue.IncomeStatementMultiples/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_MarketVsIntrinsic from "../BankingReady.Data.Worksheet.CompanyValue.MarketVsIntrinsic/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_MultiplesIntro from "../BankingReady.Data.Worksheet.CompanyValue.MultiplesIntro/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_RealLifeAnalogy from "../BankingReady.Data.Worksheet.CompanyValue.RealLifeAnalogy/index.js";
import * as BankingReady_Data_Worksheet_CompanyValue_TreasuryStockMethod from "../BankingReady.Data.Worksheet.CompanyValue.TreasuryStockMethod/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_BusinessProjections from "../BankingReady.Data.Worksheet.DCFValuation.BusinessProjections/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_CostOfEquity from "../BankingReady.Data.Worksheet.DCFValuation.CostOfEquity/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_DCFValuationIntro from "../BankingReady.Data.Worksheet.DCFValuation.DCFValuationIntro/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_DCFValuationOutro from "../BankingReady.Data.Worksheet.DCFValuation.DCFValuationOutro/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_DiscountingAndTerminalValues from "../BankingReady.Data.Worksheet.DCFValuation.DiscountingAndTerminalValues/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_ProjectingFCF from "../BankingReady.Data.Worksheet.DCFValuation.ProjectingFCF/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_TypesOfFCF from "../BankingReady.Data.Worksheet.DCFValuation.TypesOfFCF/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_WACCAndDiscounting from "../BankingReady.Data.Worksheet.DCFValuation.WACCAndDiscounting/index.js";
import * as BankingReady_Data_Worksheet_IntroToFinance_RoleOfIB from "../BankingReady.Data.Worksheet.IntroToFinance.RoleOfIB/index.js";
import * as BankingReady_Data_Worksheet_IntroToFinance_Terminology from "../BankingReady.Data.Worksheet.IntroToFinance.Terminology/index.js";
import * as BankingReady_Data_Worksheet_IntroToFinance_TimeValueOfMoney from "../BankingReady.Data.Worksheet.IntroToFinance.TimeValueOfMoney/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
var for_ = /* #__PURE__ */ Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetRoute);
var postExcelEditorCommandEffect = function (context) {
    return function (store) {
        return function (route) {
            return function (v) {
                if (v instanceof BankingReady_Data_ExcelEditorCommand.General) {
                    return BankingReady_Capability_ExcelEditor_Effect_General.postGeneralCommandEffect(context)(store)(route)(v.value0);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.Worksheet) {
                    return BankingReady_Capability_ExcelEditor_Effect_Worksheet.postWorksheetCommandEffect(context)(store)(route)(v.value0);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.AccountingCmd) {
                    return BankingReady_Capability_ExcelEditor_Effect_Accounting.postAccountingCommandEffect(context)(route)(v.value0);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.CompanyValueCmd) {
                    return BankingReady_Capability_ExcelEditor_Effect_CompanyValue.postCompanyValueCommandEffect(context)(route)(v.value0);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.DCFValuationCmd) {
                    return BankingReady_Capability_ExcelEditor_Effect_DCFValuation.postDCFValuationCommandEffect(context)(store)(route)(v.value0);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 41, column 52 - line 46, column 103): " + [ v.constructor.name ]);
            };
        };
    };
};
var postExcelEditorCommandArrayEffect = function (context) {
    return function (store) {
        return function (route) {
            return function (cmdArray) {
                return for_(cmdArray)(function (cmd) {
                    return postExcelEditorCommandEffect(context)(store)(route)(cmd);
                });
            };
        };
    };
};
var postWorksheetCommandsEffect = function (context) {
    return function (store) {
        return function (route) {
            if (route instanceof BankingReady_Data_Route.Login) {
                return pure(Data_Unit.unit);
            };
            if (route instanceof BankingReady_Data_Route.Home) {
                return pure(Data_Unit.unit);
            };
            if (route instanceof BankingReady_Data_Route.IntroToFinance) {
                if (route.value0 instanceof BankingReady_Data_Route.Terminology) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_IntroToFinance_Terminology.terminologyWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.RoleOfIB) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_IntroToFinance_RoleOfIB.roleOfIBWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.TimeValueOfMoney) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_IntroToFinance_TimeValueOfMoney.timeValueOfMoneyWorksheetCommands);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 86, column 41 - line 92, column 97): " + [ route.value0.constructor.name ]);
            };
            if (route instanceof BankingReady_Data_Route.Accounting) {
                if (route.value0 instanceof BankingReady_Data_Route.AccountingIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_AccountingIntro.accountingIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.IncomeStatementIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_IncomeStatementIntro.incomeStatementIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.IncomeStatement) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_IncomeStatement.incomeStatementWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.BalanceSheetIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_BalanceSheetIntro.balanceSheetIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.BalanceSheet) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_BalanceSheet.balanceSheetWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.CashFlowStatementIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_CashFlowStatementIntro.cashFlowStatementIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.CashFlowStatement) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_CashFlowStatement.cashFlowStatementWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.StatementChanges) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_Accounting_StatementChanges.statementChangeWorksheetCommands);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 94, column 33 - line 110, column 96): " + [ route.value0.constructor.name ]);
            };
            if (route instanceof BankingReady_Data_Route.CompanyValue) {
                if (route.value0 instanceof BankingReady_Data_Route.CompanyValueIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_CompanyValueIntro.companyValueIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.MarketVsIntrinsic) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_MarketVsIntrinsic.marketVsIntrinsicWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.RealLifeAnalogy) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_RealLifeAnalogy.realLifeAnalogyWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.CalculatingEquityValue) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_CalculatingEquityValue.calculatingEquityValueWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.CalculatingEnterpriseValue) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_CalculatingEnterpriseValue.calculatingEnterpriseValueWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.EquityAndEnterpriseValue) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_EquityAndEnterpriseValue.equityAndEnterpriseValueWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.ChangesInEquityAndEnterpriseValue) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_ChangesInEquityAndEnterpriseValue.changesInEquityAndEnterpriseValueWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.DilutionToEquity) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_DilutionToEquity.dilutionToEquityWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.TreasuryStockMethod) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_TreasuryStockMethod.treasuryStockMethodWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.MultiplesIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_MultiplesIntro.multiplesIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.IncomeStatementMultiples) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_CompanyValue_IncomeStatementMultiples.incomeStatementMultiplesWorksheetCommands);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 112, column 37 - line 134, column 105): " + [ route.value0.constructor.name ]);
            };
            if (route instanceof BankingReady_Data_Route.DCFValuation) {
                if (route.value0 instanceof BankingReady_Data_Route.DCFValuationIntro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_DCFValuationIntro.dcfValuationIntroWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.TypesOfFCF) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_TypesOfFCF.typesOfFCFWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.BusinessProjections) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_BusinessProjections.businessProjectionsWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.ProjectingFCF) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_ProjectingFCF.projectingFCFWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.WACCAndDiscounting) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_WACCAndDiscounting.waccAndDiscountingWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.CostOfEquity) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_CostOfEquity.costOfEquityWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.DiscountingAndTerminalValues) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_DiscountingAndTerminalValues.discountingAndTerminalValuesWorksheetCommands);
                };
                if (route.value0 instanceof BankingReady_Data_Route.DCFValuationOutro) {
                    return postExcelEditorCommandArrayEffect(context)(store)(route)(BankingReady_Data_Worksheet_DCFValuation_DCFValuationOutro.dcfValuationOutroWorksheetCommands);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 136, column 37 - line 152, column 98): " + [ route.value0.constructor.name ]);
            };
            if (route instanceof BankingReady_Data_Route.Quiz) {
                return pure(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 81, column 51 - line 154, column 22): " + [ route.constructor.name ]);
        };
    };
};
var deleteWorksheetEffect = function (context) {
    return function (route) {
        var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
        var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
        return BankingReady_Foreign_Excel_Worksheet.deleteWorksheet(worksheet);
    };
};
var cleanExcelEditorCommandEffect = function (context) {
    return function (store) {
        return function (route) {
            return function (v) {
                if (v instanceof BankingReady_Data_ExcelEditorCommand.General) {
                    return BankingReady_Capability_ExcelEditor_Effect_General.cleanGeneralCommandEffect(context)(store)(route)(v.value0);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.Worksheet) {
                    return pure(Data_Unit.unit);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.AccountingCmd) {
                    return BankingReady_Capability_ExcelEditor_Effect_Accounting.cleanAccountingCommandEffect(context)(route)(v.value0);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.CompanyValueCmd) {
                    return pure(Data_Unit.unit);
                };
                if (v instanceof BankingReady_Data_ExcelEditorCommand.DCFValuationCmd) {
                    return pure(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect (line 49, column 53 - line 54, column 33): " + [ v.constructor.name ]);
            };
        };
    };
};
var addWorksheetEffect = function (context) {
    return function (route) {
        var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
        var v = BankingReady_Foreign_Excel_Workbook.addWorksheet(workbook)(showExcelWorksheet(route));
        return pure(Data_Unit.unit);
    };
};
var activateWorksheetEffect = function (context) {
    return function (route) {
        var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
        var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
        return BankingReady_Foreign_Excel_Worksheet.activate(worksheet);
    };
};
export {
    postExcelEditorCommandEffect,
    cleanExcelEditorCommandEffect,
    postExcelEditorCommandArrayEffect,
    addWorksheetEffect,
    activateWorksheetEffect,
    deleteWorksheetEffect,
    postWorksheetCommandsEffect
};
