// Generated by purs version 0.15.13
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as Halogen_Subscription from "../Halogen.Subscription/index.js";
import * as Unsafe_Reference from "../Unsafe.Reference/index.js";
var unless = /* #__PURE__ */ Control_Applicative.unless(Effect.applicativeEffect);
var Selector = function (x) {
    return x;
};
var selectEq = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function ($14) {
        return Selector((function (v) {
            return {
                eq: eq,
                select: v
            };
        })($14));
    };
};
var selectEmitter = function (v) {
    return function (emitter) {
        return Halogen_Subscription.makeEmitter(function (push) {
            return function __do() {
                var previousDerivedRef = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                var subscription = Halogen_Subscription.subscribe(emitter)(function (store) {
                    return function __do() {
                        var previousDerived = Effect_Ref.read(previousDerivedRef)();
                        var newDerived = v.select(store);
                        var isUnchanged = Data_Maybe.maybe(false)(v.eq(newDerived))(previousDerived);
                        return unless(isUnchanged)(function __do() {
                            Effect_Ref.write(new Data_Maybe.Just(newDerived))(previousDerivedRef)();
                            return push(newDerived)();
                        })();
                    };
                })();
                return Halogen_Subscription.unsubscribe(subscription);
            };
        });
    };
};
var selectAll = {
    eq: Unsafe_Reference.unsafeRefEq,
    select: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var select = function (eq) {
    return function ($15) {
        return Selector((function (v) {
            return {
                eq: eq,
                select: v
            };
        })($15));
    };
};
export {
    Selector,
    select,
    selectEq,
    selectAll,
    selectEmitter
};
