// Generated by purs version 0.15.13
import * as BankingReady_AppM from "../BankingReady.AppM/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Hash from "../Routing.Hash/index.js";
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM);
var navigateAppM = {
    navigate: /* #__PURE__ */ (function () {
        var $8 = Effect_Class.liftEffect(BankingReady_AppM.monadEffectAppM);
        var $9 = Routing_Duplex.print(BankingReady_Data_Route.routeCodec);
        return function ($10) {
            return $8(Routing_Hash.setHash($9($10)));
        };
    })(),
    Monad0: function () {
        return BankingReady_AppM.monadAppM;
    }
};
var navigate = function (dict) {
    return dict.navigate;
};
var navigateHalogenM = function (dictNavigate) {
    return {
        navigate: (function () {
            var $11 = lift(dictNavigate.Monad0());
            var $12 = navigate(dictNavigate);
            return function ($13) {
                return $11($12($13));
            };
        })(),
        Monad0: function () {
            return Halogen_Query_HalogenM.monadHalogenM;
        }
    };
};
export {
    navigate,
    navigateHalogenM,
    navigateAppM
};
