// Generated by purs version 0.15.13
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as JSURI from "../JSURI/index.js";
import * as Routing_Duplex_Types from "../Routing.Duplex.Types/index.js";
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var RoutePrinter = function (x) {
    return x;
};
var semigroupRoutePrinter = {
    append: function (v) {
        return function (v1) {
            return function ($33) {
                return v1(v($33));
            };
        };
    }
};
var put = function (str) {
    return function (state) {
        return {
            params: state.params,
            hash: state.hash,
            segments: Data_Array.snoc(state.segments)(str)
        };
    };
};
var printPath = function (v) {
    var unsafeEncodeURIComponent = function ($35) {
        return fromJust(JSURI["encodeURIComponent"]($35));
    };
    var printSegments = function (v1) {
        if (v1.length === 1 && v1[0] === "") {
            return "/";
        };
        return Data_String_Common.joinWith("/")(map(unsafeEncodeURIComponent)(v1));
    };
    var printParam = function (v1) {
        return function (v2) {
            if (v2 === "") {
                return unsafeEncodeURIComponent(v1);
            };
            return unsafeEncodeURIComponent(v1) + ("=" + unsafeEncodeURIComponent(v2));
        };
    };
    var printParams = function (v1) {
        if (v1.length === 0) {
            return "";
        };
        return "?" + Data_String_Common.joinWith("&")(map(Data_Tuple.uncurry(printParam))(v1));
    };
    var printHash = function (v1) {
        if (v1 === "") {
            return "";
        };
        return "#" + v1;
    };
    return printSegments(v.segments) + (printParams(v.params) + printHash(v.hash));
};
var param = function (key) {
    return function (val) {
        return function (state) {
            return {
                segments: state.segments,
                hash: state.hash,
                params: Data_Array.cons(new Data_Tuple.Tuple(key, val))(state.params)
            };
        };
    };
};
var newtypeRoutePrinter = {
    Coercible0: function () {
        return undefined;
    }
};
var run = /* #__PURE__ */ (function () {
    var $36 = Data_Function.applyFlipped(Routing_Duplex_Types.emptyRouteState);
    var $37 = Data_Newtype.unwrap();
    return function ($38) {
        return printPath($36($37($38)));
    };
})();
var monoidRoutePRinter = {
    mempty: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn),
    Semigroup0: function () {
        return semigroupRoutePrinter;
    }
};
var mempty = /* #__PURE__ */ Data_Monoid.mempty(monoidRoutePRinter);
var hash = function (h) {
    return function (v) {
        return {
            segments: v.segments,
            params: v.params,
            hash: h
        };
    };
};
var flag = function (key) {
    return function (val) {
        if (val) {
            return param(key)("");
        };
        if (Data_Boolean.otherwise) {
            return mempty;
        };
        throw new Error("Failed pattern match at Routing.Duplex.Printer (line 39, column 1 - line 39, column 42): " + [ key.constructor.name, val.constructor.name ]);
    };
};
export {
    RoutePrinter,
    put,
    param,
    flag,
    hash,
    run,
    printPath,
    newtypeRoutePrinter,
    semigroupRoutePrinter,
    monoidRoutePRinter
};
