// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
var dcfValuationIntroDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 4,
        colIdx: 2,
        text: "Recall from our introduction module the simple formula for the value of a company."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 5,
        colIdx: 2,
        text: "We take the cash flows of the company, and we discount it by some discount factor (combining the growth rate and the discount rate):"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 7,
        colIdx: 3,
        text: "Company Value = Cash Flow / (Growth Rate - Discount Rate)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Scroll({
        rowIdx: 4,
        rowCount: 4
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 9,
        colIdx: 2,
        text: "Well in practice it's slightly more complex than this because our cash flows can change over time. Take a business selling lemonade that starts with a few "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 2,
        text: "neighbourhood stalls in its first year but by year 10 has bottles on the shelves of every supermarket."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 2,
        text: "To adjust for this complexity, we can take 2 approaches:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Scroll({
        rowIdx: 9,
        rowCount: 4
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 14,
        colIdx: 2,
        text: "Discounted Cash Flow (DCF) Valuation: "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 3,
        text: "This involves projecting the cash flows of a business into the future and discounting them back to the present day. The sum of these cash flows is the value of the "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 3,
        text: "company. We call this an intrinsic valuation of the company because it\u2019s based on its cash flow generation and is not dependent on the valuation of other companies."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Scroll({
        rowIdx: 14,
        rowCount: 3
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 18,
        colIdx: 2,
        text: "Multiples Valuations:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 3,
        text: "This involves calculating the financial ratios we showed in our Accounting Module, such as EV/EBITDA and P/E for companies similar to ours. Then we use the "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 3,
        text: "averages of these ratios to inform how we value our company based on its own EBITDA or earnings."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Scroll({
        rowIdx: 18,
        rowCount: 3
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 2,
        text: "In this module, we focus on building a DCF valuation for Kellogg, and the module is structured as follows."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Scroll({
        rowIdx: 22,
        rowCount: 1
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 24,
        colIdx: 2,
        text: "First, we will walk through how Free Cash Flow is calculated and its different forms. Next, we will explain how to forecast a basic business plan, using different ways to"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 25,
        colIdx: 2,
        text: "break down revenues."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 26,
        colIdx: 2,
        text: "Then we explain the key lines of a simple DCF model and the quick ways we can project line items, which are useful in a case study."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 2,
        text: "After we give full treatment to how the WACC is calculated given it is always a \u2018hot\u2019 interview topic."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 28,
        colIdx: 2,
        text: "To finish this module, we tie it all together to value Kellogg's cash flows and discuss how we use a Terminal Value to treat the remaining value outside of our projections."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 29,
        colIdx: 2,
        text: "From this, we can derive a final value for the company."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Scroll({
        rowIdx: 24,
        rowCount: 6
    })) ] ];
})();
export {
    dcfValuationIntroDescriptions
};
