// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_String_Base64_Internal from "../Data.String.Base64.Internal/index.js";
import * as Data_TextDecoder from "../Data.TextDecoder/index.js";
import * as Data_TextEncoder from "../Data.TextEncoder/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var encodeNode = function (s) {
    return $foreign.encodeNodeImpl(s);
};
var btoa = function (str) {
    return $foreign.btoaImpl(Data_Either.Left.create, Data_Either.Right.create, str);
};
var encode = function (str) {
    if (Data_String_Base64_Internal.btoaIsDefined) {
        return Data_String_Base64_Internal.unsafeFromRight(btoa(Data_String_Base64_Internal.uint8ArrayToBtoaSafeString(Data_TextEncoder.encodeUtf8(str))));
    };
    return encodeNode(str);
};
var encodeUrl = function ($5) {
    return Data_String_Base64_Internal.toUrlSafe(encode($5));
};
var atob = function (str) {
    return $foreign.atobImpl(Data_Either.Left.create, Data_Either.Right.create, str);
};
var decode = function (str) {
    if (Data_String_Base64_Internal.atobIsDefined) {
        return bind(map(Data_String_Base64_Internal.unsafeStringToUint8ArrayOfCharCodes)(atob(Data_String_Base64_Internal.toRfc4648(str))))(Data_TextDecoder.decodeUtf8);
    };
    return $foreign.decodeNodeImpl(Data_Either.Left.create, Data_Either.Right.create, Data_String_Base64_Internal.toRfc4648(str));
};
export {
    atob,
    btoa,
    decode,
    encode,
    encodeUrl
};
