// Generated by purs version 0.15.13
import * as BankingReady_Data_OS from "../BankingReady.Data.OS/index.js";
import * as Data_Int from "../Data.Int/index.js";
var ColumnWidth1 = /* #__PURE__ */ (function () {
    function ColumnWidth1() {

    };
    ColumnWidth1.value = new ColumnWidth1();
    return ColumnWidth1;
})();
var ColumnWidth2 = /* #__PURE__ */ (function () {
    function ColumnWidth2() {

    };
    ColumnWidth2.value = new ColumnWidth2();
    return ColumnWidth2;
})();
var ColumnWidth8 = /* #__PURE__ */ (function () {
    function ColumnWidth8() {

    };
    ColumnWidth8.value = new ColumnWidth8();
    return ColumnWidth8;
})();
var ColumnWidth10 = /* #__PURE__ */ (function () {
    function ColumnWidth10() {

    };
    ColumnWidth10.value = new ColumnWidth10();
    return ColumnWidth10;
})();
var ColumnWidth20 = /* #__PURE__ */ (function () {
    function ColumnWidth20() {

    };
    ColumnWidth20.value = new ColumnWidth20();
    return ColumnWidth20;
})();
var ColumnWidth30 = /* #__PURE__ */ (function () {
    function ColumnWidth30() {

    };
    ColumnWidth30.value = new ColumnWidth30();
    return ColumnWidth30;
})();
var ColumnWidth40 = /* #__PURE__ */ (function () {
    function ColumnWidth40() {

    };
    ColumnWidth40.value = new ColumnWidth40();
    return ColumnWidth40;
})();
var ColumnWidth50 = /* #__PURE__ */ (function () {
    function ColumnWidth50() {

    };
    ColumnWidth50.value = new ColumnWidth50();
    return ColumnWidth50;
})();
var px = function (v) {
    if (v instanceof ColumnWidth1) {
        return 12;
    };
    if (v instanceof ColumnWidth2) {
        return 19;
    };
    if (v instanceof ColumnWidth8) {
        return 61;
    };
    if (v instanceof ColumnWidth10) {
        return 75;
    };
    if (v instanceof ColumnWidth20) {
        return 145;
    };
    if (v instanceof ColumnWidth30) {
        return 215;
    };
    if (v instanceof ColumnWidth40) {
        return 285;
    };
    if (v instanceof ColumnWidth50) {
        return 355;
    };
    throw new Error("Failed pattern match at BankingReady.Data.ColumnWidth (line 19, column 6 - line 27, column 23): " + [ v.constructor.name ]);
};
var pxOS = function (os) {
    return function (columnWidth) {
        if (os instanceof BankingReady_Data_OS.Windows) {
            return Data_Int.round(Data_Int.toNumber(px(columnWidth)) / BankingReady_Data_OS.fontDPIRatio);
        };
        if (os instanceof BankingReady_Data_OS.Mac) {
            return px(columnWidth);
        };
        if (os instanceof BankingReady_Data_OS.Unknown) {
            return Data_Int.round(Data_Int.toNumber(px(columnWidth)) / BankingReady_Data_OS.fontDPIRatio);
        };
        throw new Error("Failed pattern match at BankingReady.Data.ColumnWidth (line 30, column 23 - line 33, column 74): " + [ os.constructor.name ]);
    };
};
export {
    ColumnWidth1,
    ColumnWidth2,
    ColumnWidth8,
    ColumnWidth10,
    ColumnWidth20,
    ColumnWidth30,
    ColumnWidth40,
    ColumnWidth50,
    px,
    pxOS
};
