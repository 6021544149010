"use strict"

export const getSelectedRange = function (workbook) {
    return workbook.getSelectedRange();
};

export const addWorksheet = function (workbook) {
    return function (sheetName) {
        return workbook.worksheets.add(sheetName);
    };
};

export const getActiveCell = function (workbook) {
    return function () {
        return workbook.getActiveCell();
    };
};

export const getWorksheet = function (workbook) {
    return function (sheetName) {
        return workbook.worksheets.getItem(sheetName);
    };
};

export const getStyle = function (workbook) {
    return function (styleName) {
        return workbook.styles.getItem(styleName);
    };
};
