// Generated by purs version 0.15.13
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Cont_Trans from "../Control.Monad.Cont.Trans/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_Fork_Class from "../Control.Monad.Fork.Class/index.js";
import * as Control_Monad_Identity_Trans from "../Control.Monad.Identity.Trans/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as Control_Monad_RWS_Trans from "../Control.Monad.RWS.Trans/index.js";
import * as Control_Monad_Reader_Class from "../Control.Monad.Reader.Class/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_State_Trans from "../Control.Monad.State.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_Hooks_HookM from "../Halogen.Hooks.HookM/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_Store_Select from "../Halogen.Store.Select/index.js";
import * as Halogen_Subscription from "../Halogen.Subscription/index.js";
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var map = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM);
var lift1 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Hooks_HookM.monadTransHookM);
var lift2 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Cont_Trans.monadTransContT);
var lift3 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT);
var lift4 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Identity_Trans.monadTransIdentityT);
var lift5 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Maybe_Trans.monadTransMaybeT);
var lift6 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT);
var lift7 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT);
var StoreT = function (x) {
    return x;
};
var monadWriterStoreT = function (dictMonadWriter) {
    return Control_Monad_Reader_Trans.monadWriterReaderT(dictMonadWriter);
};
var monadTransStoreT = Control_Monad_Reader_Trans.monadTransReaderT;
var lift8 = /* #__PURE__ */ Control_Monad_Trans_Class.lift(monadTransStoreT);
var monadThrowStoreT = function (dictMonadThrow) {
    return Control_Monad_Reader_Trans.monadThrowReaderT(dictMonadThrow);
};
var monadTellStoreT = function (dictMonadTell) {
    return Control_Monad_Reader_Trans.monadTellReaderT(dictMonadTell);
};
var monadStoreT = function (dictMonad) {
    return Control_Monad_Reader_Trans.monadReaderT(dictMonad);
};
var monadStateStoreT = function (dictMonadState) {
    return Control_Monad_Reader_Trans.monadStateReaderT(dictMonadState);
};
var monadRecStoreT = function (dictMonadRec) {
    return Control_Monad_Reader_Trans.monadRecReaderT(dictMonadRec);
};
var monadForkStoreT = function (dictMonadFork) {
    return Control_Monad_Fork_Class.monadForkReaderT(dictMonadFork);
};
var monadKillStoreT = function (dictMonadKill) {
    var MonadThrow1 = dictMonadKill.MonadThrow1();
    var lift9 = lift8(MonadThrow1.Monad0());
    var kill = Control_Monad_Fork_Class.kill(dictMonadKill);
    var monadForkStoreT1 = monadForkStoreT(dictMonadKill.MonadFork0());
    var monadThrowStoreT1 = monadThrowStoreT(MonadThrow1);
    return {
        kill: function (e) {
            var $245 = kill(e);
            return function ($246) {
                return lift9($245($246));
            };
        },
        MonadFork0: function () {
            return monadForkStoreT1;
        },
        MonadThrow1: function () {
            return monadThrowStoreT1;
        }
    };
};
var monadErrorStoreT = function (dictMonadError) {
    return Control_Monad_Reader_Trans.monadErrorReaderT(dictMonadError);
};
var monadEffectStoreT = function (dictMonadEffect) {
    return Control_Monad_Reader_Trans.monadEffectReader(dictMonadEffect);
};
var monadStoreStoreT = function (dictMonadEffect) {
    var Monad0 = dictMonadEffect.Monad0();
    var bind2 = Control_Bind.bind(Control_Monad_Reader_Trans.bindReaderT(Monad0.Bind1()));
    var ask = Control_Monad_Reader_Class.ask(Control_Monad_Reader_Trans.monadAskReaderT(Monad0));
    var liftEffect1 = Effect_Class.liftEffect(Control_Monad_Reader_Trans.monadEffectReader(dictMonadEffect));
    var pure2 = Control_Applicative.pure(Monad0.Applicative0());
    var monadEffectStoreT1 = monadEffectStoreT(dictMonadEffect);
    return {
        getStore: bind2(ask)(function (store) {
            return liftEffect1(Effect_Ref.read(store.value));
        }),
        updateStore: function (action) {
            return bind2(ask)(function (store) {
                return liftEffect1(function __do() {
                    var current = Effect_Ref.read(store.value)();
                    var newStore = store.reducer(current)(action);
                    Effect_Ref.write(newStore)(store.value)();
                    return Halogen_Subscription.notify(store.listener)(newStore)();
                });
            });
        },
        emitSelected: function (selector) {
            var $247 = Halogen_Store_Select.selectEmitter(selector);
            return function ($248) {
                return pure2($247((function (v) {
                    return v.emitter;
                })($248)));
            };
        },
        MonadEffect0: function () {
            return monadEffectStoreT1;
        }
    };
};
var monadContStoreT = function (dictMonadCont) {
    return Control_Monad_Reader_Trans.monadContReaderT(dictMonadCont);
};
var monadBracketStoreT = function (dictMonadBracket) {
    var monadBracketReaderT = Control_Monad_Fork_Class.monadBracketReaderT(dictMonadBracket);
    var bracket = Control_Monad_Fork_Class.bracket(monadBracketReaderT);
    var uninterruptible = Control_Monad_Fork_Class.uninterruptible(monadBracketReaderT);
    var MonadError1 = dictMonadBracket.MonadError1();
    var monadKillStoreT1 = monadKillStoreT(dictMonadBracket.MonadKill0());
    var monadErrorStoreT1 = monadErrorStoreT(MonadError1);
    return {
        bracket: function (v) {
            return function (release) {
                return function (run) {
                    return bracket(v)(function (c) {
                        return function (a) {
                            var v1 = release(c)(a);
                            return v1;
                        };
                    })(function (a) {
                        var v1 = run(a);
                        return v1;
                    });
                };
            };
        },
        uninterruptible: function (v) {
            return uninterruptible(v);
        },
        never: lift8((MonadError1.MonadThrow0()).Monad0())(Control_Monad_Fork_Class.never(dictMonadBracket)),
        MonadKill0: function () {
            return monadKillStoreT1;
        },
        MonadError1: function () {
            return monadErrorStoreT1;
        }
    };
};
var monadAskStoreT = function (dictMonadAsk) {
    var Monad0 = dictMonadAsk.Monad0();
    var monadStoreT1 = monadStoreT(Monad0);
    return {
        ask: lift8(Monad0)(Control_Monad_Reader_Class.ask(dictMonadAsk)),
        Monad0: function () {
            return monadStoreT1;
        }
    };
};
var monadReaderStoreT = function (dictMonadReader) {
    var local = Control_Monad_Reader_Class.local(dictMonadReader);
    var monadAskStoreT1 = monadAskStoreT(dictMonadReader.MonadAsk0());
    return {
        local: function (f) {
            return function (v) {
                var $249 = local(f);
                return function ($250) {
                    return $249(v($250));
                };
            };
        },
        MonadAsk0: function () {
            return monadAskStoreT1;
        }
    };
};
var monadAffStoreT = function (dictMonadAff) {
    return Effect_Aff_Class.monadAffReader(dictMonadAff);
};
var functorStoreT = function (dictFunctor) {
    return Control_Monad_Reader_Trans.functorReaderT(dictFunctor);
};
var distributiveStoreT = function (dictDistributive) {
    return Control_Monad_Reader_Trans.distributiveReaderT(dictDistributive);
};
var bindStoreT = function (dictBind) {
    return Control_Monad_Reader_Trans.bindReaderT(dictBind);
};
var applyStoreT = function (dictApply) {
    return Control_Monad_Reader_Trans.applyReaderT(dictApply);
};
var applicativeStoreT = function (dictApplicative) {
    return Control_Monad_Reader_Trans.applicativeReaderT(dictApplicative);
};
var updateStore = function (dict) {
    return dict.updateStore;
};
var runAndEmitStoreT = function (dictMonad) {
    var hoist = Halogen_Component.hoist(((dictMonad.Bind1()).Apply0()).Functor0());
    return function (initialStore) {
        return function (reducer) {
            return function (component) {
                return bind1(liftEffect(function __do() {
                    var value = Effect_Ref["new"](initialStore)();
                    var v = Halogen_Subscription.create();
                    return {
                        value: value,
                        emitter: v.emitter,
                        listener: v.listener,
                        reducer: reducer
                    };
                }))(function (hs) {
                    return pure1({
                        emitter: hs.emitter,
                        component: hoist(function (v) {
                            return Control_Monad_Reader_Trans.runReaderT(v)(hs);
                        })(component)
                    });
                });
            };
        };
    };
};
var runStoreT = function (dictMonad) {
    var runAndEmitStoreT1 = runAndEmitStoreT(dictMonad);
    return function (initialStore) {
        return function (reducer) {
            return function (component) {
                return map(function (v) {
                    return v.component;
                })(runAndEmitStoreT1(initialStore)(reducer)(component));
            };
        };
    };
};
var mapStoreT = function (f) {
    return function (v) {
        return Control_Monad_Reader_Trans.mapReaderT(f)(v);
    };
};
var getStore = function (dict) {
    return dict.getStore;
};
var emitSelected = function (dict) {
    return dict.emitSelected;
};
var monadStoreHalogenM = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift(MonadEffect0.Monad0());
    var monadEffectHalogenM = Halogen_Query_HalogenM.monadEffectHalogenM(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $251 = updateStore(dictMonadStore);
            return function ($252) {
                return lift9($251($252));
            };
        })(),
        emitSelected: (function () {
            var $253 = emitSelected(dictMonadStore);
            return function ($254) {
                return lift9($253($254));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectHalogenM;
        }
    };
};
var monadStoreHookM = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift1(MonadEffect0.Monad0());
    var monadEffectHookM = Halogen_Hooks_HookM.monadEffectHookM(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $255 = updateStore(dictMonadStore);
            return function ($256) {
                return lift9($255($256));
            };
        })(),
        emitSelected: (function () {
            var $257 = emitSelected(dictMonadStore);
            return function ($258) {
                return lift9($257($258));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectHookM;
        }
    };
};
var monadStoreContT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift2(MonadEffect0.Monad0());
    var monadEffectContT = Control_Monad_Cont_Trans.monadEffectContT(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $259 = updateStore(dictMonadStore);
            return function ($260) {
                return lift9($259($260));
            };
        })(),
        emitSelected: (function () {
            var $261 = emitSelected(dictMonadStore);
            return function ($262) {
                return lift9($261($262));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectContT;
        }
    };
};
var monadStoreExceptT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift3(MonadEffect0.Monad0());
    var monadEffectExceptT = Control_Monad_Except_Trans.monadEffectExceptT(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $263 = updateStore(dictMonadStore);
            return function ($264) {
                return lift9($263($264));
            };
        })(),
        emitSelected: (function () {
            var $265 = emitSelected(dictMonadStore);
            return function ($266) {
                return lift9($265($266));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectExceptT;
        }
    };
};
var monadStoreIdentityT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift4(MonadEffect0.Monad0());
    var monadEffectIdentityT = Control_Monad_Identity_Trans.monadEffectIdentityT(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $267 = updateStore(dictMonadStore);
            return function ($268) {
                return lift9($267($268));
            };
        })(),
        emitSelected: (function () {
            var $269 = emitSelected(dictMonadStore);
            return function ($270) {
                return lift9($269($270));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectIdentityT;
        }
    };
};
var monadStoreMaybeT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift5(MonadEffect0.Monad0());
    var monadEffectMaybe = Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $271 = updateStore(dictMonadStore);
            return function ($272) {
                return lift9($271($272));
            };
        })(),
        emitSelected: (function () {
            var $273 = emitSelected(dictMonadStore);
            return function ($274) {
                return lift9($273($274));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectMaybe;
        }
    };
};
var monadStoreRWST = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var getStore1 = getStore(dictMonadStore);
    var updateStore1 = updateStore(dictMonadStore);
    var emitSelected1 = emitSelected(dictMonadStore);
    return function (dictMonoid) {
        var lift9 = Control_Monad_Trans_Class.lift(Control_Monad_RWS_Trans.monadTransRWST(dictMonoid))(Monad0);
        var monadEffectRWS = Control_Monad_RWS_Trans.monadEffectRWS(dictMonoid)(MonadEffect0);
        return {
            getStore: lift9(getStore1),
            updateStore: function ($275) {
                return lift9(updateStore1($275));
            },
            emitSelected: function ($276) {
                return lift9(emitSelected1($276));
            },
            MonadEffect0: function () {
                return monadEffectRWS;
            }
        };
    };
};
var monadStoreReaderT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift6(MonadEffect0.Monad0());
    var monadEffectReader = Control_Monad_Reader_Trans.monadEffectReader(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $277 = updateStore(dictMonadStore);
            return function ($278) {
                return lift9($277($278));
            };
        })(),
        emitSelected: (function () {
            var $279 = emitSelected(dictMonadStore);
            return function ($280) {
                return lift9($279($280));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectReader;
        }
    };
};
var monadStoreStateT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var lift9 = lift7(MonadEffect0.Monad0());
    var monadEffectState = Control_Monad_State_Trans.monadEffectState(MonadEffect0);
    return {
        getStore: lift9(getStore(dictMonadStore)),
        updateStore: (function () {
            var $281 = updateStore(dictMonadStore);
            return function ($282) {
                return lift9($281($282));
            };
        })(),
        emitSelected: (function () {
            var $283 = emitSelected(dictMonadStore);
            return function ($284) {
                return lift9($283($284));
            };
        })(),
        MonadEffect0: function () {
            return monadEffectState;
        }
    };
};
var monadStoreWriterT = function (dictMonadStore) {
    var MonadEffect0 = dictMonadStore.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var getStore1 = getStore(dictMonadStore);
    var updateStore1 = updateStore(dictMonadStore);
    var emitSelected1 = emitSelected(dictMonadStore);
    return function (dictMonoid) {
        var lift9 = Control_Monad_Trans_Class.lift(Control_Monad_Writer_Trans.monadTransWriterT(dictMonoid))(Monad0);
        var monadEffectWriter = Control_Monad_Writer_Trans.monadEffectWriter(dictMonoid)(MonadEffect0);
        return {
            getStore: lift9(getStore1),
            updateStore: function ($285) {
                return lift9(updateStore1($285));
            },
            emitSelected: function ($286) {
                return lift9(emitSelected1($286));
            },
            MonadEffect0: function () {
                return monadEffectWriter;
            }
        };
    };
};
export {
    emitSelected,
    getStore,
    updateStore,
    StoreT,
    runStoreT,
    runAndEmitStoreT,
    mapStoreT,
    functorStoreT,
    applyStoreT,
    applicativeStoreT,
    bindStoreT,
    monadStoreT,
    monadEffectStoreT,
    monadAffStoreT,
    monadThrowStoreT,
    monadErrorStoreT,
    monadTellStoreT,
    monadWriterStoreT,
    monadStateStoreT,
    monadContStoreT,
    monadRecStoreT,
    monadForkStoreT,
    monadKillStoreT,
    monadBracketStoreT,
    distributiveStoreT,
    monadTransStoreT,
    monadAskStoreT,
    monadReaderStoreT,
    monadStoreStoreT,
    monadStoreHalogenM,
    monadStoreHookM,
    monadStoreContT,
    monadStoreExceptT,
    monadStoreIdentityT,
    monadStoreMaybeT,
    monadStoreRWST,
    monadStoreReaderT,
    monadStoreStateT,
    monadStoreWriterT
};
