// Generated by purs version 0.15.13
var Local = /* #__PURE__ */ (function () {
    function Local() {

    };
    Local.value = new Local();
    return Local;
})();
var Dev = /* #__PURE__ */ (function () {
    function Dev() {

    };
    Dev.value = new Dev();
    return Dev;
})();
var Prod = /* #__PURE__ */ (function () {
    function Prod() {

    };
    Prod.value = new Prod();
    return Prod;
})();
export {
    Local,
    Dev,
    Prod
};
