// Generated by purs version 0.15.13
import * as Data_Bounded_Generic from "../Data.Bounded.Generic/index.js";
import * as Data_Enum_Generic from "../Data.Enum.Generic/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Duplex_Generic from "../Routing.Duplex.Generic/index.js";
import * as Routing_Duplex_Generic_Syntax from "../Routing.Duplex.Generic.Syntax/index.js";
var gsep = /* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsep(/* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments));
var genericEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericEnumConstructor(Data_Enum_Generic.genericEnumNoArguments);
var genericTopConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericTopConstructor(Data_Bounded_Generic.genericTopNoArguments);
var genericEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericEnumSum(genericEnumConstructor)(genericTopConstructor);
var genericBottomConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericBottomConstructor(Data_Bounded_Generic.genericBottomNoArguments);
var genericBottomSum = /* #__PURE__ */ Data_Bounded_Generic.genericBottomSum(genericBottomConstructor);
var genericEnumSum1 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumConstructor)(genericBottomConstructor))(genericBottomSum);
var genericEnumSum2 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumSum1)(genericBottomSum))(genericBottomSum))(genericBottomSum))(genericBottomSum))(genericBottomSum);
var genericEnumSum3 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumSum2)(genericBottomSum))(genericBottomSum))(genericBottomSum);
var genericTopSum = /* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(genericTopConstructor));
var genericBoundedEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericBoundedEnumConstructor(Data_Enum_Generic.genericBoundedEnumNoArguments);
var genericBoundedEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericBoundedEnumSum(genericBoundedEnumConstructor);
var genericBoundedEnumSum1 = /* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(genericBoundedEnumConstructor));
var genericTopSum1 = /* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(genericTopSum)))));
var genericBoundedEnumSum2 = /* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(genericBoundedEnumSum1)))));
var genericBoundedEnumSum3 = /* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(/* #__PURE__ */ genericBoundedEnumSum(genericBoundedEnumSum2)));
var gsep1 = /* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsep(/* #__PURE__ */ Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll));
var AccountingQuiz = /* #__PURE__ */ (function () {
    function AccountingQuiz() {

    };
    AccountingQuiz.value = new AccountingQuiz();
    return AccountingQuiz;
})();
var CompanyValueQuiz = /* #__PURE__ */ (function () {
    function CompanyValueQuiz() {

    };
    CompanyValueQuiz.value = new CompanyValueQuiz();
    return CompanyValueQuiz;
})();
var DCFValuationQuiz = /* #__PURE__ */ (function () {
    function DCFValuationQuiz() {

    };
    DCFValuationQuiz.value = new DCFValuationQuiz();
    return DCFValuationQuiz;
})();
var Terminology = /* #__PURE__ */ (function () {
    function Terminology() {

    };
    Terminology.value = new Terminology();
    return Terminology;
})();
var RoleOfIB = /* #__PURE__ */ (function () {
    function RoleOfIB() {

    };
    RoleOfIB.value = new RoleOfIB();
    return RoleOfIB;
})();
var TimeValueOfMoney = /* #__PURE__ */ (function () {
    function TimeValueOfMoney() {

    };
    TimeValueOfMoney.value = new TimeValueOfMoney();
    return TimeValueOfMoney;
})();
var DCFValuationIntro = /* #__PURE__ */ (function () {
    function DCFValuationIntro() {

    };
    DCFValuationIntro.value = new DCFValuationIntro();
    return DCFValuationIntro;
})();
var TypesOfFCF = /* #__PURE__ */ (function () {
    function TypesOfFCF() {

    };
    TypesOfFCF.value = new TypesOfFCF();
    return TypesOfFCF;
})();
var BusinessProjections = /* #__PURE__ */ (function () {
    function BusinessProjections() {

    };
    BusinessProjections.value = new BusinessProjections();
    return BusinessProjections;
})();
var ProjectingFCF = /* #__PURE__ */ (function () {
    function ProjectingFCF() {

    };
    ProjectingFCF.value = new ProjectingFCF();
    return ProjectingFCF;
})();
var WACCAndDiscounting = /* #__PURE__ */ (function () {
    function WACCAndDiscounting() {

    };
    WACCAndDiscounting.value = new WACCAndDiscounting();
    return WACCAndDiscounting;
})();
var CostOfEquity = /* #__PURE__ */ (function () {
    function CostOfEquity() {

    };
    CostOfEquity.value = new CostOfEquity();
    return CostOfEquity;
})();
var DiscountingAndTerminalValues = /* #__PURE__ */ (function () {
    function DiscountingAndTerminalValues() {

    };
    DiscountingAndTerminalValues.value = new DiscountingAndTerminalValues();
    return DiscountingAndTerminalValues;
})();
var DCFValuationOutro = /* #__PURE__ */ (function () {
    function DCFValuationOutro() {

    };
    DCFValuationOutro.value = new DCFValuationOutro();
    return DCFValuationOutro;
})();
var CompanyValueIntro = /* #__PURE__ */ (function () {
    function CompanyValueIntro() {

    };
    CompanyValueIntro.value = new CompanyValueIntro();
    return CompanyValueIntro;
})();
var MarketVsIntrinsic = /* #__PURE__ */ (function () {
    function MarketVsIntrinsic() {

    };
    MarketVsIntrinsic.value = new MarketVsIntrinsic();
    return MarketVsIntrinsic;
})();
var RealLifeAnalogy = /* #__PURE__ */ (function () {
    function RealLifeAnalogy() {

    };
    RealLifeAnalogy.value = new RealLifeAnalogy();
    return RealLifeAnalogy;
})();
var CalculatingEquityValue = /* #__PURE__ */ (function () {
    function CalculatingEquityValue() {

    };
    CalculatingEquityValue.value = new CalculatingEquityValue();
    return CalculatingEquityValue;
})();
var CalculatingEnterpriseValue = /* #__PURE__ */ (function () {
    function CalculatingEnterpriseValue() {

    };
    CalculatingEnterpriseValue.value = new CalculatingEnterpriseValue();
    return CalculatingEnterpriseValue;
})();
var EquityAndEnterpriseValue = /* #__PURE__ */ (function () {
    function EquityAndEnterpriseValue() {

    };
    EquityAndEnterpriseValue.value = new EquityAndEnterpriseValue();
    return EquityAndEnterpriseValue;
})();
var ChangesInEquityAndEnterpriseValue = /* #__PURE__ */ (function () {
    function ChangesInEquityAndEnterpriseValue() {

    };
    ChangesInEquityAndEnterpriseValue.value = new ChangesInEquityAndEnterpriseValue();
    return ChangesInEquityAndEnterpriseValue;
})();
var DilutionToEquity = /* #__PURE__ */ (function () {
    function DilutionToEquity() {

    };
    DilutionToEquity.value = new DilutionToEquity();
    return DilutionToEquity;
})();
var TreasuryStockMethod = /* #__PURE__ */ (function () {
    function TreasuryStockMethod() {

    };
    TreasuryStockMethod.value = new TreasuryStockMethod();
    return TreasuryStockMethod;
})();
var MultiplesIntro = /* #__PURE__ */ (function () {
    function MultiplesIntro() {

    };
    MultiplesIntro.value = new MultiplesIntro();
    return MultiplesIntro;
})();
var IncomeStatementMultiples = /* #__PURE__ */ (function () {
    function IncomeStatementMultiples() {

    };
    IncomeStatementMultiples.value = new IncomeStatementMultiples();
    return IncomeStatementMultiples;
})();
var AccountingIntro = /* #__PURE__ */ (function () {
    function AccountingIntro() {

    };
    AccountingIntro.value = new AccountingIntro();
    return AccountingIntro;
})();
var IncomeStatementIntro = /* #__PURE__ */ (function () {
    function IncomeStatementIntro() {

    };
    IncomeStatementIntro.value = new IncomeStatementIntro();
    return IncomeStatementIntro;
})();
var IncomeStatement = /* #__PURE__ */ (function () {
    function IncomeStatement() {

    };
    IncomeStatement.value = new IncomeStatement();
    return IncomeStatement;
})();
var BalanceSheetIntro = /* #__PURE__ */ (function () {
    function BalanceSheetIntro() {

    };
    BalanceSheetIntro.value = new BalanceSheetIntro();
    return BalanceSheetIntro;
})();
var BalanceSheet = /* #__PURE__ */ (function () {
    function BalanceSheet() {

    };
    BalanceSheet.value = new BalanceSheet();
    return BalanceSheet;
})();
var CashFlowStatementIntro = /* #__PURE__ */ (function () {
    function CashFlowStatementIntro() {

    };
    CashFlowStatementIntro.value = new CashFlowStatementIntro();
    return CashFlowStatementIntro;
})();
var CashFlowStatement = /* #__PURE__ */ (function () {
    function CashFlowStatement() {

    };
    CashFlowStatement.value = new CashFlowStatement();
    return CashFlowStatement;
})();
var StatementChanges = /* #__PURE__ */ (function () {
    function StatementChanges() {

    };
    StatementChanges.value = new StatementChanges();
    return StatementChanges;
})();
var Login = /* #__PURE__ */ (function () {
    function Login() {

    };
    Login.value = new Login();
    return Login;
})();
var Home = /* #__PURE__ */ (function () {
    function Home() {

    };
    Home.value = new Home();
    return Home;
})();
var IntroToFinance = /* #__PURE__ */ (function () {
    function IntroToFinance(value0) {
        this.value0 = value0;
    };
    IntroToFinance.create = function (value0) {
        return new IntroToFinance(value0);
    };
    return IntroToFinance;
})();
var Accounting = /* #__PURE__ */ (function () {
    function Accounting(value0) {
        this.value0 = value0;
    };
    Accounting.create = function (value0) {
        return new Accounting(value0);
    };
    return Accounting;
})();
var CompanyValue = /* #__PURE__ */ (function () {
    function CompanyValue(value0) {
        this.value0 = value0;
    };
    CompanyValue.create = function (value0) {
        return new CompanyValue(value0);
    };
    return CompanyValue;
})();
var DCFValuation = /* #__PURE__ */ (function () {
    function DCFValuation(value0) {
        this.value0 = value0;
    };
    DCFValuation.create = function (value0) {
        return new DCFValuation(value0);
    };
    return DCFValuation;
})();
var Quiz = /* #__PURE__ */ (function () {
    function Quiz(value0) {
        this.value0 = value0;
    };
    Quiz.create = function (value0) {
        return new Quiz(value0);
    };
    return Quiz;
})();
var showExcelWorksheetQuizRoute = {
    showExcelWorksheet: function (v) {
        if (v instanceof AccountingQuiz) {
            return "Accounting Quiz";
        };
        if (v instanceof CompanyValueQuiz) {
            return "Company Value Quiz";
        };
        if (v instanceof DCFValuationQuiz) {
            return "DCF Valuation Quiz";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 216, column 24 - line 219, column 45): " + [ v.constructor.name ]);
    }
};
var showExcelWorksheetIntroToFinanceRoute = {
    showExcelWorksheet: function (v) {
        if (v instanceof Terminology) {
            return "Terminology";
        };
        if (v instanceof RoleOfIB) {
            return "Role of an IB";
        };
        if (v instanceof TimeValueOfMoney) {
            return "Time Value Of Money";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 80, column 24 - line 83, column 46): " + [ v.constructor.name ]);
    }
};
var showExcelWorksheetDCFValuationRoute = {
    showExcelWorksheet: function (v) {
        if (v instanceof DCFValuationIntro) {
            return "DCF Valuation Introduction";
        };
        if (v instanceof TypesOfFCF) {
            return "Types of FCF";
        };
        if (v instanceof BusinessProjections) {
            return "Business Projections";
        };
        if (v instanceof ProjectingFCF) {
            return "Projecting FCF";
        };
        if (v instanceof WACCAndDiscounting) {
            return "WACC and Discounting";
        };
        if (v instanceof CostOfEquity) {
            return "Cost of Equity";
        };
        if (v instanceof DiscountingAndTerminalValues) {
            return "Discounting and Terminal Values";
        };
        if (v instanceof DCFValuationOutro) {
            return "DCF Valuation Outro";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 248, column 24 - line 256, column 47): " + [ v.constructor.name ]);
    }
};
var showExcelWorksheetCompanyValueRoute = {
    showExcelWorksheet: function (v) {
        if (v instanceof CompanyValueIntro) {
            return "Company Value Intro";
        };
        if (v instanceof MarketVsIntrinsic) {
            return "Market vs Intrinsic";
        };
        if (v instanceof CalculatingEquityValue) {
            return "Calculating Equity Value";
        };
        if (v instanceof CalculatingEnterpriseValue) {
            return "Calculating Enterprise Value";
        };
        if (v instanceof EquityAndEnterpriseValue) {
            return "Equity and Enterprise Value";
        };
        if (v instanceof ChangesInEquityAndEnterpriseValue) {
            return "Changes in EqV and EV";
        };
        if (v instanceof RealLifeAnalogy) {
            return "Real Life Analogy";
        };
        if (v instanceof DilutionToEquity) {
            return "Dilution To Equity";
        };
        if (v instanceof TreasuryStockMethod) {
            return "Treasury Stock Method";
        };
        if (v instanceof MultiplesIntro) {
            return "Multiples Intro";
        };
        if (v instanceof IncomeStatementMultiples) {
            return "Income Statement Multiples";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 171, column 24 - line 182, column 61): " + [ v.constructor.name ]);
    }
};
var showExcelWorksheetAccountingRoute = {
    showExcelWorksheet: function (v) {
        if (v instanceof AccountingIntro) {
            return "Accounting Intro";
        };
        if (v instanceof IncomeStatementIntro) {
            return "Income Statement Intro";
        };
        if (v instanceof IncomeStatement) {
            return "Income Statement";
        };
        if (v instanceof BalanceSheetIntro) {
            return "Balance Sheet Intro";
        };
        if (v instanceof BalanceSheet) {
            return "Balance Sheet";
        };
        if (v instanceof CashFlowStatementIntro) {
            return "Cash Flow Statement Intro";
        };
        if (v instanceof CashFlowStatement) {
            return "Cash Flow Statement";
        };
        if (v instanceof StatementChanges) {
            return "Statement Changes";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 119, column 24 - line 127, column 44): " + [ v.constructor.name ]);
    }
};
var showExcelWorksheet = function (dict) {
    return dict.showExcelWorksheet;
};
var showExcelWorksheet1 = /* #__PURE__ */ showExcelWorksheet(showExcelWorksheetIntroToFinanceRoute);
var showExcelWorksheet2 = /* #__PURE__ */ showExcelWorksheet(showExcelWorksheetAccountingRoute);
var showExcelWorksheet3 = /* #__PURE__ */ showExcelWorksheet(showExcelWorksheetCompanyValueRoute);
var showExcelWorksheet4 = /* #__PURE__ */ showExcelWorksheet(showExcelWorksheetDCFValuationRoute);
var showExcelWorksheet5 = /* #__PURE__ */ showExcelWorksheet(showExcelWorksheetQuizRoute);
var showExcelWorksheetRoute = {
    showExcelWorksheet: function (v) {
        if (v instanceof Login) {
            return "";
        };
        if (v instanceof Home) {
            return "";
        };
        if (v instanceof IntroToFinance) {
            return showExcelWorksheet1(v.value0);
        };
        if (v instanceof Accounting) {
            return showExcelWorksheet2(v.value0);
        };
        if (v instanceof CompanyValue) {
            return showExcelWorksheet3(v.value0);
        };
        if (v instanceof DCFValuation) {
            return showExcelWorksheet4(v.value0);
        };
        if (v instanceof Quiz) {
            return showExcelWorksheet5(v.value0);
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 48, column 24 - line 56, column 51): " + [ v.constructor.name ]);
    }
};
var genericRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Login.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Home.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new IntroToFinance(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new Accounting(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new CompanyValue(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new DCFValuation(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
            return new Quiz(x.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 32, column 1 - line 32, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Login) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Home) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof IntroToFinance) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof Accounting) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof CompanyValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof DCFValuation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof Quiz) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))));
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 32, column 1 - line 32, column 48): " + [ x.constructor.name ]);
    }
};
var genericQuizRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return AccountingQuiz.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return CompanyValueQuiz.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return DCFValuationQuiz.value;
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 204, column 1 - line 204, column 56): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AccountingQuiz) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CompanyValueQuiz) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof DCFValuationQuiz) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 204, column 1 - line 204, column 56): " + [ x.constructor.name ]);
    }
};
var quizRouteCodec = /* #__PURE__ */ Routing_Duplex_Generic.sum(genericQuizRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "AccountingQuiz";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CompanyValueQuiz";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DCFValuationQuiz";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))))({
    AccountingQuiz: /* #__PURE__ */ gsep("accounting-quiz")(Routing_Duplex_Generic.noArgs),
    CompanyValueQuiz: /* #__PURE__ */ gsep("company-value-quiz")(Routing_Duplex_Generic.noArgs),
    DCFValuationQuiz: /* #__PURE__ */ gsep("dcf-valuation-quiz")(Routing_Duplex_Generic.noArgs)
});
var genericIntroToFinanceRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Terminology.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return RoleOfIB.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return TimeValueOfMoney.value;
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 63, column 1 - line 63, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Terminology) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof RoleOfIB) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof TimeValueOfMoney) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 63, column 1 - line 63, column 76): " + [ x.constructor.name ]);
    }
};
var introToFinanceRouteCodec = /* #__PURE__ */ Routing_Duplex_Generic.sum(genericIntroToFinanceRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Terminology";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "RoleOfIB";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "TimeValueOfMoney";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))))({
    Terminology: /* #__PURE__ */ gsep("terminology")(Routing_Duplex_Generic.noArgs),
    RoleOfIB: /* #__PURE__ */ gsep("role-of-ib")(Routing_Duplex_Generic.noArgs),
    TimeValueOfMoney: /* #__PURE__ */ gsep("time-value-of-money")(Routing_Duplex_Generic.noArgs)
});
var genericDCFValuationRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return DCFValuationIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return TypesOfFCF.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return BusinessProjections.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return ProjectingFCF.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return WACCAndDiscounting.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return CostOfEquity.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return DiscountingAndTerminalValues.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))) {
            return DCFValuationOutro.value;
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 231, column 1 - line 231, column 72): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DCFValuationIntro) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof TypesOfFCF) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof BusinessProjections) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof ProjectingFCF) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof WACCAndDiscounting) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof CostOfEquity) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof DiscountingAndTerminalValues) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
        };
        if (x instanceof DCFValuationOutro) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))));
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 231, column 1 - line 231, column 72): " + [ x.constructor.name ]);
    }
};
var genericCompanyValueRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return CompanyValueIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return MarketVsIntrinsic.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return RealLifeAnalogy.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return CalculatingEquityValue.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return CalculatingEnterpriseValue.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return EquityAndEnterpriseValue.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return ChangesInEquityAndEnterpriseValue.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return DilutionToEquity.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return TreasuryStockMethod.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return MultiplesIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))))) {
            return IncomeStatementMultiples.value;
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 154, column 1 - line 154, column 72): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CompanyValueIntro) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof MarketVsIntrinsic) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof RealLifeAnalogy) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof CalculatingEquityValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof CalculatingEnterpriseValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof EquityAndEnterpriseValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof ChangesInEquityAndEnterpriseValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
        };
        if (x instanceof DilutionToEquity) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))));
        };
        if (x instanceof TreasuryStockMethod) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))))));
        };
        if (x instanceof MultiplesIntro) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))))))));
        };
        if (x instanceof IncomeStatementMultiples) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))))))));
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 154, column 1 - line 154, column 72): " + [ x.constructor.name ]);
    }
};
var genericAccountingRoute = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return AccountingIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return IncomeStatementIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return IncomeStatement.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return BalanceSheetIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return BalanceSheet.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return CashFlowStatementIntro.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return CashFlowStatement.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))) {
            return StatementChanges.value;
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 102, column 1 - line 102, column 68): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AccountingIntro) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof IncomeStatementIntro) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof IncomeStatement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof BalanceSheetIntro) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof BalanceSheet) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof CashFlowStatementIntro) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof CashFlowStatement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
        };
        if (x instanceof StatementChanges) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))));
        };
        throw new Error("Failed pattern match at BankingReady.Data.Route (line 102, column 1 - line 102, column 68): " + [ x.constructor.name ]);
    }
};
var eqQuizRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AccountingQuiz && y instanceof AccountingQuiz) {
                return true;
            };
            if (x instanceof CompanyValueQuiz && y instanceof CompanyValueQuiz) {
                return true;
            };
            if (x instanceof DCFValuationQuiz && y instanceof DCFValuationQuiz) {
                return true;
            };
            return false;
        };
    }
};
var eq = /* #__PURE__ */ Data_Eq.eq(eqQuizRoute);
var ordQuizRoute = {
    compare: function (x) {
        return function (y) {
            if (x instanceof AccountingQuiz && y instanceof AccountingQuiz) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AccountingQuiz) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AccountingQuiz) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CompanyValueQuiz && y instanceof CompanyValueQuiz) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CompanyValueQuiz) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CompanyValueQuiz) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DCFValuationQuiz && y instanceof DCFValuationQuiz) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at BankingReady.Data.Route (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqQuizRoute;
    }
};
var compare = /* #__PURE__ */ Data_Ord.compare(ordQuizRoute);
var eqIntroToFinanceRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Terminology && y instanceof Terminology) {
                return true;
            };
            if (x instanceof RoleOfIB && y instanceof RoleOfIB) {
                return true;
            };
            if (x instanceof TimeValueOfMoney && y instanceof TimeValueOfMoney) {
                return true;
            };
            return false;
        };
    }
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(eqIntroToFinanceRoute);
var ordIntroToFinanceRoute = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Terminology && y instanceof Terminology) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Terminology) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Terminology) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof RoleOfIB && y instanceof RoleOfIB) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof RoleOfIB) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof RoleOfIB) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TimeValueOfMoney && y instanceof TimeValueOfMoney) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at BankingReady.Data.Route (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqIntroToFinanceRoute;
    }
};
var compare1 = /* #__PURE__ */ Data_Ord.compare(ordIntroToFinanceRoute);
var eqDCFValuationRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DCFValuationIntro && y instanceof DCFValuationIntro) {
                return true;
            };
            if (x instanceof TypesOfFCF && y instanceof TypesOfFCF) {
                return true;
            };
            if (x instanceof BusinessProjections && y instanceof BusinessProjections) {
                return true;
            };
            if (x instanceof ProjectingFCF && y instanceof ProjectingFCF) {
                return true;
            };
            if (x instanceof WACCAndDiscounting && y instanceof WACCAndDiscounting) {
                return true;
            };
            if (x instanceof CostOfEquity && y instanceof CostOfEquity) {
                return true;
            };
            if (x instanceof DiscountingAndTerminalValues && y instanceof DiscountingAndTerminalValues) {
                return true;
            };
            if (x instanceof DCFValuationOutro && y instanceof DCFValuationOutro) {
                return true;
            };
            return false;
        };
    }
};
var eq2 = /* #__PURE__ */ Data_Eq.eq(eqDCFValuationRoute);
var ordDCFValuationRoute = {
    compare: function (x) {
        return function (y) {
            if (x instanceof DCFValuationIntro && y instanceof DCFValuationIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DCFValuationIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DCFValuationIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TypesOfFCF && y instanceof TypesOfFCF) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof TypesOfFCF) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TypesOfFCF) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BusinessProjections && y instanceof BusinessProjections) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BusinessProjections) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BusinessProjections) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ProjectingFCF && y instanceof ProjectingFCF) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ProjectingFCF) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ProjectingFCF) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof WACCAndDiscounting && y instanceof WACCAndDiscounting) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WACCAndDiscounting) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WACCAndDiscounting) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CostOfEquity && y instanceof CostOfEquity) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CostOfEquity) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CostOfEquity) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DiscountingAndTerminalValues && y instanceof DiscountingAndTerminalValues) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DiscountingAndTerminalValues) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DiscountingAndTerminalValues) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DCFValuationOutro && y instanceof DCFValuationOutro) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at BankingReady.Data.Route (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqDCFValuationRoute;
    }
};
var compare2 = /* #__PURE__ */ Data_Ord.compare(ordDCFValuationRoute);
var eqCompanyValueRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CompanyValueIntro && y instanceof CompanyValueIntro) {
                return true;
            };
            if (x instanceof MarketVsIntrinsic && y instanceof MarketVsIntrinsic) {
                return true;
            };
            if (x instanceof RealLifeAnalogy && y instanceof RealLifeAnalogy) {
                return true;
            };
            if (x instanceof CalculatingEquityValue && y instanceof CalculatingEquityValue) {
                return true;
            };
            if (x instanceof CalculatingEnterpriseValue && y instanceof CalculatingEnterpriseValue) {
                return true;
            };
            if (x instanceof EquityAndEnterpriseValue && y instanceof EquityAndEnterpriseValue) {
                return true;
            };
            if (x instanceof ChangesInEquityAndEnterpriseValue && y instanceof ChangesInEquityAndEnterpriseValue) {
                return true;
            };
            if (x instanceof DilutionToEquity && y instanceof DilutionToEquity) {
                return true;
            };
            if (x instanceof TreasuryStockMethod && y instanceof TreasuryStockMethod) {
                return true;
            };
            if (x instanceof MultiplesIntro && y instanceof MultiplesIntro) {
                return true;
            };
            if (x instanceof IncomeStatementMultiples && y instanceof IncomeStatementMultiples) {
                return true;
            };
            return false;
        };
    }
};
var eq3 = /* #__PURE__ */ Data_Eq.eq(eqCompanyValueRoute);
var ordCompanyValueRoute = {
    compare: function (x) {
        return function (y) {
            if (x instanceof CompanyValueIntro && y instanceof CompanyValueIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CompanyValueIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CompanyValueIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof MarketVsIntrinsic && y instanceof MarketVsIntrinsic) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof MarketVsIntrinsic) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof MarketVsIntrinsic) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof RealLifeAnalogy && y instanceof RealLifeAnalogy) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof RealLifeAnalogy) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof RealLifeAnalogy) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CalculatingEquityValue && y instanceof CalculatingEquityValue) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CalculatingEquityValue) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CalculatingEquityValue) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CalculatingEnterpriseValue && y instanceof CalculatingEnterpriseValue) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CalculatingEnterpriseValue) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CalculatingEnterpriseValue) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof EquityAndEnterpriseValue && y instanceof EquityAndEnterpriseValue) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EquityAndEnterpriseValue) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EquityAndEnterpriseValue) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ChangesInEquityAndEnterpriseValue && y instanceof ChangesInEquityAndEnterpriseValue) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ChangesInEquityAndEnterpriseValue) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ChangesInEquityAndEnterpriseValue) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DilutionToEquity && y instanceof DilutionToEquity) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DilutionToEquity) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DilutionToEquity) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TreasuryStockMethod && y instanceof TreasuryStockMethod) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof TreasuryStockMethod) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TreasuryStockMethod) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof MultiplesIntro && y instanceof MultiplesIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof MultiplesIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof MultiplesIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof IncomeStatementMultiples && y instanceof IncomeStatementMultiples) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at BankingReady.Data.Route (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqCompanyValueRoute;
    }
};
var compare3 = /* #__PURE__ */ Data_Ord.compare(ordCompanyValueRoute);
var eqAccountingRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AccountingIntro && y instanceof AccountingIntro) {
                return true;
            };
            if (x instanceof IncomeStatementIntro && y instanceof IncomeStatementIntro) {
                return true;
            };
            if (x instanceof IncomeStatement && y instanceof IncomeStatement) {
                return true;
            };
            if (x instanceof BalanceSheetIntro && y instanceof BalanceSheetIntro) {
                return true;
            };
            if (x instanceof BalanceSheet && y instanceof BalanceSheet) {
                return true;
            };
            if (x instanceof CashFlowStatementIntro && y instanceof CashFlowStatementIntro) {
                return true;
            };
            if (x instanceof CashFlowStatement && y instanceof CashFlowStatement) {
                return true;
            };
            if (x instanceof StatementChanges && y instanceof StatementChanges) {
                return true;
            };
            return false;
        };
    }
};
var eq4 = /* #__PURE__ */ Data_Eq.eq(eqAccountingRoute);
var eqRoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Login && y instanceof Login) {
                return true;
            };
            if (x instanceof Home && y instanceof Home) {
                return true;
            };
            if (x instanceof IntroToFinance && y instanceof IntroToFinance) {
                return eq1(x.value0)(y.value0);
            };
            if (x instanceof Accounting && y instanceof Accounting) {
                return eq4(x.value0)(y.value0);
            };
            if (x instanceof CompanyValue && y instanceof CompanyValue) {
                return eq3(x.value0)(y.value0);
            };
            if (x instanceof DCFValuation && y instanceof DCFValuation) {
                return eq2(x.value0)(y.value0);
            };
            if (x instanceof Quiz && y instanceof Quiz) {
                return eq(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var ordAccountingRoute = {
    compare: function (x) {
        return function (y) {
            if (x instanceof AccountingIntro && y instanceof AccountingIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AccountingIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AccountingIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof IncomeStatementIntro && y instanceof IncomeStatementIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof IncomeStatementIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof IncomeStatementIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof IncomeStatement && y instanceof IncomeStatement) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof IncomeStatement) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof IncomeStatement) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BalanceSheetIntro && y instanceof BalanceSheetIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BalanceSheetIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BalanceSheetIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BalanceSheet && y instanceof BalanceSheet) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BalanceSheet) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BalanceSheet) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CashFlowStatementIntro && y instanceof CashFlowStatementIntro) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CashFlowStatementIntro) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CashFlowStatementIntro) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CashFlowStatement && y instanceof CashFlowStatement) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CashFlowStatement) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CashFlowStatement) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof StatementChanges && y instanceof StatementChanges) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at BankingReady.Data.Route (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqAccountingRoute;
    }
};
var compare4 = /* #__PURE__ */ Data_Ord.compare(ordAccountingRoute);
var ordRoute = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Login && y instanceof Login) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Login) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Login) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Home && y instanceof Home) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Home) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Home) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof IntroToFinance && y instanceof IntroToFinance) {
                return compare1(x.value0)(y.value0);
            };
            if (x instanceof IntroToFinance) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof IntroToFinance) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Accounting && y instanceof Accounting) {
                return compare4(x.value0)(y.value0);
            };
            if (x instanceof Accounting) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Accounting) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CompanyValue && y instanceof CompanyValue) {
                return compare3(x.value0)(y.value0);
            };
            if (x instanceof CompanyValue) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CompanyValue) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DCFValuation && y instanceof DCFValuation) {
                return compare2(x.value0)(y.value0);
            };
            if (x instanceof DCFValuation) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DCFValuation) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Quiz && y instanceof Quiz) {
                return compare(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at BankingReady.Data.Route (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqRoute;
    }
};
var enumIntroToFinanceRoute = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericIntroToFinanceRoute)(genericEnumSum1),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericIntroToFinanceRoute)(genericEnumSum1),
    Ord0: function () {
        return ordIntroToFinanceRoute;
    }
};
var enumDCFValuationRoute = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericDCFValuationRoute)(genericEnumSum2),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericDCFValuationRoute)(genericEnumSum2),
    Ord0: function () {
        return ordDCFValuationRoute;
    }
};
var enumCompanyValueRoute = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericCompanyValueRoute)(genericEnumSum3),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericCompanyValueRoute)(genericEnumSum3),
    Ord0: function () {
        return ordCompanyValueRoute;
    }
};
var enumAccountingRoute = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericAccountingRoute)(genericEnumSum2),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericAccountingRoute)(genericEnumSum2),
    Ord0: function () {
        return ordAccountingRoute;
    }
};
var dcfValuationRouteCodec = /* #__PURE__ */ Routing_Duplex_Generic.sum(genericDCFValuationRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DCFValuationIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "TypesOfFCF";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "BusinessProjections";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "ProjectingFCF";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "WACCAndDiscounting";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CostOfEquity";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DiscountingAndTerminalValues";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DCFValuationOutro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments)))))))))({
    DCFValuationIntro: /* #__PURE__ */ gsep("dcf-valuation-intro")(Routing_Duplex_Generic.noArgs),
    TypesOfFCF: /* #__PURE__ */ gsep("types-of-fcf")(Routing_Duplex_Generic.noArgs),
    BusinessProjections: /* #__PURE__ */ gsep("business-projections")(Routing_Duplex_Generic.noArgs),
    ProjectingFCF: /* #__PURE__ */ gsep("projecting-fcf")(Routing_Duplex_Generic.noArgs),
    WACCAndDiscounting: /* #__PURE__ */ gsep("wacc-and-discounting")(Routing_Duplex_Generic.noArgs),
    CostOfEquity: /* #__PURE__ */ gsep("cost-of-equity")(Routing_Duplex_Generic.noArgs),
    DiscountingAndTerminalValues: /* #__PURE__ */ gsep("discounting-and-terminal-values")(Routing_Duplex_Generic.noArgs),
    DCFValuationOutro: /* #__PURE__ */ gsep("dcf-valuation-outro")(Routing_Duplex_Generic.noArgs)
});
var companyValueRouteCodec = /* #__PURE__ */ Routing_Duplex_Generic.sum(genericCompanyValueRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CompanyValueIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "MarketVsIntrinsic";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "RealLifeAnalogy";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CalculatingEquityValue";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CalculatingEnterpriseValue";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "EquityAndEnterpriseValue";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "ChangesInEquityAndEnterpriseValue";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DilutionToEquity";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "TreasuryStockMethod";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "MultiplesIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "IncomeStatementMultiples";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))))))))))))({
    CompanyValueIntro: /* #__PURE__ */ gsep("company-value-intro")(Routing_Duplex_Generic.noArgs),
    MarketVsIntrinsic: /* #__PURE__ */ gsep("market-vs-intrinsic")(Routing_Duplex_Generic.noArgs),
    CalculatingEquityValue: /* #__PURE__ */ gsep("calculating-equity-value")(Routing_Duplex_Generic.noArgs),
    CalculatingEnterpriseValue: /* #__PURE__ */ gsep("calculating-enterprise-value")(Routing_Duplex_Generic.noArgs),
    EquityAndEnterpriseValue: /* #__PURE__ */ gsep("equity-and-enterprise-value")(Routing_Duplex_Generic.noArgs),
    ChangesInEquityAndEnterpriseValue: /* #__PURE__ */ gsep("changes-in-equity-and-enterprise-value")(Routing_Duplex_Generic.noArgs),
    RealLifeAnalogy: /* #__PURE__ */ gsep("real-life-analogy")(Routing_Duplex_Generic.noArgs),
    DilutionToEquity: /* #__PURE__ */ gsep("dilution-to-equity")(Routing_Duplex_Generic.noArgs),
    TreasuryStockMethod: /* #__PURE__ */ gsep("treasury-stock-method")(Routing_Duplex_Generic.noArgs),
    MultiplesIntro: /* #__PURE__ */ gsep("multiples-intro")(Routing_Duplex_Generic.noArgs),
    IncomeStatementMultiples: /* #__PURE__ */ gsep("income-statement-multiples")(Routing_Duplex_Generic.noArgs)
});
var boundedIntroToFinanceRoute = {
    top: /* #__PURE__ */ Data_Bounded_Generic.genericTop(genericIntroToFinanceRoute)(genericTopSum),
    bottom: /* #__PURE__ */ Data_Bounded_Generic.genericBottom(genericIntroToFinanceRoute)(genericBottomSum),
    Ord0: function () {
        return ordIntroToFinanceRoute;
    }
};
var boundedEnumIntroToFinanceRoute = {
    cardinality: /* #__PURE__ */ Data_Enum_Generic.genericCardinality(genericIntroToFinanceRoute)(genericBoundedEnumSum1),
    toEnum: /* #__PURE__ */ Data_Enum_Generic.genericToEnum(genericIntroToFinanceRoute)(genericBoundedEnumSum1),
    fromEnum: /* #__PURE__ */ Data_Enum_Generic.genericFromEnum(genericIntroToFinanceRoute)(genericBoundedEnumSum1),
    Bounded0: function () {
        return boundedIntroToFinanceRoute;
    },
    Enum1: function () {
        return enumIntroToFinanceRoute;
    }
};
var boundedDCFValuationRoute = {
    top: /* #__PURE__ */ Data_Bounded_Generic.genericTop(genericDCFValuationRoute)(genericTopSum1),
    bottom: /* #__PURE__ */ Data_Bounded_Generic.genericBottom(genericDCFValuationRoute)(genericBottomSum),
    Ord0: function () {
        return ordDCFValuationRoute;
    }
};
var boundedEnumDCFValuationRoute = {
    cardinality: /* #__PURE__ */ Data_Enum_Generic.genericCardinality(genericDCFValuationRoute)(genericBoundedEnumSum2),
    toEnum: /* #__PURE__ */ Data_Enum_Generic.genericToEnum(genericDCFValuationRoute)(genericBoundedEnumSum2),
    fromEnum: /* #__PURE__ */ Data_Enum_Generic.genericFromEnum(genericDCFValuationRoute)(genericBoundedEnumSum2),
    Bounded0: function () {
        return boundedDCFValuationRoute;
    },
    Enum1: function () {
        return enumDCFValuationRoute;
    }
};
var boundedCompanyValueRoute = {
    top: /* #__PURE__ */ Data_Bounded_Generic.genericTop(genericCompanyValueRoute)(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(/* #__PURE__ */ Data_Bounded_Generic.genericTopSum(genericTopSum1)))),
    bottom: /* #__PURE__ */ Data_Bounded_Generic.genericBottom(genericCompanyValueRoute)(genericBottomSum),
    Ord0: function () {
        return ordCompanyValueRoute;
    }
};
var boundedEnumCompanyValueRoute = {
    cardinality: /* #__PURE__ */ Data_Enum_Generic.genericCardinality(genericCompanyValueRoute)(genericBoundedEnumSum3),
    toEnum: /* #__PURE__ */ Data_Enum_Generic.genericToEnum(genericCompanyValueRoute)(genericBoundedEnumSum3),
    fromEnum: /* #__PURE__ */ Data_Enum_Generic.genericFromEnum(genericCompanyValueRoute)(genericBoundedEnumSum3),
    Bounded0: function () {
        return boundedCompanyValueRoute;
    },
    Enum1: function () {
        return enumCompanyValueRoute;
    }
};
var boundedAccountingRoute = {
    top: /* #__PURE__ */ Data_Bounded_Generic.genericTop(genericAccountingRoute)(genericTopSum1),
    bottom: /* #__PURE__ */ Data_Bounded_Generic.genericBottom(genericAccountingRoute)(genericBottomSum),
    Ord0: function () {
        return ordAccountingRoute;
    }
};
var boundedEnumAccountingRoute = {
    cardinality: /* #__PURE__ */ Data_Enum_Generic.genericCardinality(genericAccountingRoute)(genericBoundedEnumSum2),
    toEnum: /* #__PURE__ */ Data_Enum_Generic.genericToEnum(genericAccountingRoute)(genericBoundedEnumSum2),
    fromEnum: /* #__PURE__ */ Data_Enum_Generic.genericFromEnum(genericAccountingRoute)(genericBoundedEnumSum2),
    Bounded0: function () {
        return boundedAccountingRoute;
    },
    Enum1: function () {
        return enumAccountingRoute;
    }
};
var accountingRouteCodec = /* #__PURE__ */ Routing_Duplex_Generic.sum(genericAccountingRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "AccountingIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "IncomeStatementIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "IncomeStatement";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "BalanceSheetIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "BalanceSheet";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CashFlowStatementIntro";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CashFlowStatement";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "StatementChanges";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments)))))))))({
    AccountingIntro: /* #__PURE__ */ gsep("accounting-intro")(Routing_Duplex_Generic.noArgs),
    IncomeStatementIntro: /* #__PURE__ */ gsep("income-statement-intro")(Routing_Duplex_Generic.noArgs),
    IncomeStatement: /* #__PURE__ */ gsep("income-statement")(Routing_Duplex_Generic.noArgs),
    BalanceSheetIntro: /* #__PURE__ */ gsep("balance-sheet-intro")(Routing_Duplex_Generic.noArgs),
    BalanceSheet: /* #__PURE__ */ gsep("balance-sheet")(Routing_Duplex_Generic.noArgs),
    CashFlowStatementIntro: /* #__PURE__ */ gsep("cash-flow-statement-intro")(Routing_Duplex_Generic.noArgs),
    CashFlowStatement: /* #__PURE__ */ gsep("cash-flow-statement")(Routing_Duplex_Generic.noArgs),
    StatementChanges: /* #__PURE__ */ gsep("statement-changes")(Routing_Duplex_Generic.noArgs)
});
var routeCodec = /* #__PURE__ */ Routing_Duplex.root(/* #__PURE__ */ Routing_Duplex_Generic.sum(genericRoute)(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Login";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Home";
    }
})()(Routing_Duplex_Generic.gRouteNoArguments))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "IntroToFinance";
    }
})()(Routing_Duplex_Generic.gRouteArgument))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Accounting";
    }
})()(Routing_Duplex_Generic.gRouteArgument))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "CompanyValue";
    }
})()(Routing_Duplex_Generic.gRouteArgument))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteSum(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "DCFValuation";
    }
})()(Routing_Duplex_Generic.gRouteArgument))(/* #__PURE__ */ Routing_Duplex_Generic.gRouteConstructor({
    reflectSymbol: function () {
        return "Quiz";
    }
})()(Routing_Duplex_Generic.gRouteArgument))))))))({
    Login: /* #__PURE__ */ gsep("login")(Routing_Duplex_Generic.noArgs),
    Home: /* #__PURE__ */ gsep("home")(Routing_Duplex_Generic.noArgs),
    IntroToFinance: /* #__PURE__ */ gsep1("intro-to-finance")(introToFinanceRouteCodec),
    Accounting: /* #__PURE__ */ gsep1("accounting")(accountingRouteCodec),
    CompanyValue: /* #__PURE__ */ gsep1("company-value")(companyValueRouteCodec),
    DCFValuation: /* #__PURE__ */ gsep1("dcf-valuation")(dcfValuationRouteCodec),
    Quiz: /* #__PURE__ */ gsep1("quiz")(quizRouteCodec)
}));
export {
    showExcelWorksheet,
    Login,
    Home,
    IntroToFinance,
    Accounting,
    CompanyValue,
    DCFValuation,
    Quiz,
    routeCodec,
    Terminology,
    RoleOfIB,
    TimeValueOfMoney,
    introToFinanceRouteCodec,
    AccountingIntro,
    IncomeStatementIntro,
    IncomeStatement,
    BalanceSheetIntro,
    BalanceSheet,
    CashFlowStatementIntro,
    CashFlowStatement,
    StatementChanges,
    accountingRouteCodec,
    CompanyValueIntro,
    MarketVsIntrinsic,
    RealLifeAnalogy,
    CalculatingEquityValue,
    CalculatingEnterpriseValue,
    EquityAndEnterpriseValue,
    ChangesInEquityAndEnterpriseValue,
    DilutionToEquity,
    TreasuryStockMethod,
    MultiplesIntro,
    IncomeStatementMultiples,
    companyValueRouteCodec,
    AccountingQuiz,
    CompanyValueQuiz,
    DCFValuationQuiz,
    quizRouteCodec,
    DCFValuationIntro,
    TypesOfFCF,
    BusinessProjections,
    ProjectingFCF,
    WACCAndDiscounting,
    CostOfEquity,
    DiscountingAndTerminalValues,
    DCFValuationOutro,
    dcfValuationRouteCodec,
    genericRoute,
    eqRoute,
    ordRoute,
    showExcelWorksheetRoute,
    genericIntroToFinanceRoute,
    eqIntroToFinanceRoute,
    ordIntroToFinanceRoute,
    enumIntroToFinanceRoute,
    boundedIntroToFinanceRoute,
    boundedEnumIntroToFinanceRoute,
    showExcelWorksheetIntroToFinanceRoute,
    genericAccountingRoute,
    eqAccountingRoute,
    ordAccountingRoute,
    enumAccountingRoute,
    boundedAccountingRoute,
    boundedEnumAccountingRoute,
    showExcelWorksheetAccountingRoute,
    genericCompanyValueRoute,
    eqCompanyValueRoute,
    ordCompanyValueRoute,
    enumCompanyValueRoute,
    boundedCompanyValueRoute,
    boundedEnumCompanyValueRoute,
    showExcelWorksheetCompanyValueRoute,
    genericQuizRoute,
    eqQuizRoute,
    ordQuizRoute,
    showExcelWorksheetQuizRoute,
    genericDCFValuationRoute,
    eqDCFValuationRoute,
    ordDCFValuationRoute,
    enumDCFValuationRoute,
    boundedDCFValuationRoute,
    boundedEnumDCFValuationRoute,
    showExcelWorksheetDCFValuationRoute
};
