"use strict"

export const messageDataImpl = function (just) {
    return function (nothing) {
        return function (messageEvent) {
            return messageEvent.data !== undefined 
                ? just(messageEvent.data) 
                : nothing
        };
    };
};
