// Generated by purs version 0.15.13
import * as BankingReady_AppM from "../BankingReady.AppM/index.js";
import * as BankingReady_Foreign_Dialog from "../BankingReady.Foreign.Dialog/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_Store_Monad from "../Halogen.Store.Monad/index.js";
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(BankingReady_AppM.monadEffectAppM);
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM);
var openDialog = function (dict) {
    return dict.openDialog;
};
var manageDialogAppM = {
    openDialog: /* #__PURE__ */ Control_Bind.bind(BankingReady_AppM.bindAppM)(/* #__PURE__ */ Halogen_Store_Monad.getStore(BankingReady_AppM.monadStoreAppM))(function (v) {
        return liftEffect(BankingReady_Foreign_Dialog.openDialog(v.baseUrl)("/dialog/login"));
    }),
    closeDialog: /* #__PURE__ */ liftEffect(BankingReady_Foreign_Dialog.closeDialog),
    Monad0: function () {
        return BankingReady_AppM.monadAppM;
    }
};
var closeDialog = function (dict) {
    return dict.closeDialog;
};
var manageDialogHalogenM = function (dictManageDialog) {
    var lift1 = lift(dictManageDialog.Monad0());
    return {
        openDialog: lift1(openDialog(dictManageDialog)),
        closeDialog: lift1(closeDialog(dictManageDialog)),
        Monad0: function () {
            return Halogen_Query_HalogenM.monadHalogenM;
        }
    };
};
export {
    closeDialog,
    openDialog,
    manageDialogHalogenM,
    manageDialogAppM
};
