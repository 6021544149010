// Generated by purs version 0.15.13
import * as BankingReady_Data_Quiz from "../BankingReady.Data.Quiz/index.js";
var upcomingQuizQuestions = /* #__PURE__ */ (function () {
    return [ {
        questionTextArray: [ "What are the 2 key rules that decide whether a line item impacts the income statement?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "It must be 100% congruent with the shown year and it impacts the cash flows of the business in that period." ],
        answerB: [ "It must be 100% congruent with the shown year and it impacts the taxes the company pays." ],
        answerC: [ "It impacts the taxes the company pays and it impacts the cash flows of the business in that period." ],
        answerD: [ "It impacts the revenue the company earns and the line item corresponds to a non-cash expense." ],
        explanationA: [ "The Income Statement does contain the line items congruent with the period however does not show the cash flows of the ", "business because it includes non-cash expenses. The correct answer is (B) because the Income Statement displays all the line ", "items that impact the taxes paid within the shown period." ],
        explanationB: [ "Correct! The Income Statement displays all the line items that impact the taxes paid within the shown period." ],
        explanationC: [ "The Income Statement does contain the line items that impact our tax paid, but it does not show the cash flows of the business ", "because it includes non-cash expenses. The correct answer is (B) because the Income Statement displays all the line items that ", "impact the taxes paid within the shown period." ],
        explanationD: [ "Even though revenue is a key line item this is a key rule because the Income Statement is dependent on many other line items. ", "Additionally the Income Statement does not exclusively show non-cash expenses but includes expenses that are paid in cash. The ", "correct answer is (B) because the Income Statement displays all the line items that impact the taxes paid within the shown period." ]
    }, {
        questionTextArray: [ "Select the correct formula for the following item: Operating Profit Margin." ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "(Revenue \u2013 COGS \u2013 OpEx)/Revenue" ],
        answerB: [ "(Revenue \u2013 COGS)/Revenue" ],
        answerC: [ "COGS/OpEx" ],
        answerD: [ "(Revenue \u2013 COGS \u2013 OpEx \u2013 Interest Paid)/Revenue" ],
        explanationA: [ "Correct! (Revenue \u2013 COGS \u2013 OpEx) calculates Operating Income which expressed as a percentage of Revenue is the ", "Operating Margin." ],
        explanationB: [ "This is incorrect because the formula you have chosen corresponds to Gross Margin. Revenue \u2013 COGS calculates Gross Profit. The ", "correct answer is (A) because (Revenue \u2013 COGS \u2013 OpE)x calculates Operating Income which expressed as a percentage of Revenue ", "is the Operating Margin." ],
        explanationC: [ "This is incorrect. Remember margins are expressed as a percentage of Revenue so that will be our denominator. Additionally, ", "when we are calculating margins, we look at the amount available after a cost and not the cost as a percentage of Revenue. The ", "correct answer is (A) because (Revenue \u2013 COGS \u2013 OpEx) calculates Operating Income which expressed as a percentage of Revenue ", "is the Operating Margin." ],
        explanationD: [ "This is incorrect because the formula you have chosen corresponds to Earnings Before Tax Margin. The numerator of this equation ", "calculates Earnings Before Tax. The correct answer is (A) because (Revenue \u2013 COGS \u2013 OpEx) calculates Operating Income which ", "expressed as a percentage of Revenue is the Operating Margin." ]
    }, {
        questionTextArray: [ "Select the correct formula for the following item: Gross Profit Margin." ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Revenue/COGS" ],
        answerB: [ "(Revenue \u2013 COGS)/Revenue" ],
        answerC: [ "COGS/OpEx" ],
        answerD: [ "(Revenue \u2013 COGS \u2013 OpEx \u2013 Interest Paid)/Revenue" ],
        explanationA: [ "This is incorrect. Remember margins are expressed as a percentage of Revenue so that will be our denominator. Additionally, ", "when we are calculating margins, we look at the amount available after a cost as a percentage of Revenue. The correct answer is ", "(B) because (Revenue \u2013 COGS)( calculates Gross Profit which expressed as a percentage of Revenue is the Operating Margin." ],
        explanationB: [ "Correct! Revenue \u2013 COGS calculates Gross Profit which expressed as a percentage of Revenue is the Operating Margin." ],
        explanationC: [ "This is incorrect. Remember margins are expressed as a percentage of Revenue so that will be our denominator. Additionally, ", "when we are calculating margins, we look at the amount available after a cost and not the cost as a percentage of Revenue. The ", "correct answer is (B) because Revenue \u2013 COGS calculates Gross Profit which expressed as a percentage of Revenue is the ", "Operating Margin." ],
        explanationD: [ "This is incorrect because the formula you have chosen corresponds to Earnings Before Tax Margin. The numerator of this equation ", "calculates Earnings Before Tax. The correct answer is (B) because Revenue \u2013 COGS calculates Gross Profit which expressed as a ", "percentage of Revenue is the Operating Margin." ]
    }, {
        questionTextArray: [ "How are Cost of Goods Sold (COGS) and Operating Expenses (OpEx) different?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "OpEx is always significantly greater than COGS." ],
        answerB: [ "COGS do not exist for service-based companies." ],
        answerC: [ "COGS is 100% variable, while OpEx is 100% fixed." ],
        answerD: [ "COGS correspond to per-unit expenses, while OpEx is for expenses that cannot be linked to individual units sold." ],
        explanationA: [ "This is incorrect. Operating expenses and COGS have no relationship regarding magnitude. The correct answer is (D) because COGS ", "is 100% variable i.e., based on per-unit and OpEx corresponds to the additional expenses needed to deliver the product/service." ],
        explanationB: [ "This is incorrect. COGS often do exist for service-based companies but is referred to as \u201cCost of Services\u201d instead. This correct ", "answer is (D) because COGS is 100% variable i.e., based on per-unit and OpEx corresponds to the additional expenses needed to ", "deliver the product/service." ],
        explanationC: [ "This is incorrect. COGS is 100% variable and OpEx has mostly a fixed component however there are some variable components and ", "therefore it is not 100% fixed. The correct answer is (D) because COGS is 100% variable i.e., based on per-unit and OpEx ", "corresponds to the additional expenses needed to deliver the product/service." ],
        explanationD: [ "Correct! COGS is 100% variable i.e., based on per-unit and OpEx corresponds to the additional expenses needed to deliver the ", "product/service." ]
    }, {
        questionTextArray: [ "Rank the following in order of highest to lowest (based on the typical understanding of the metrics)." ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Revenue, Gross Profit, Pre-tax Income, Net Income" ],
        answerB: [ "Gross Profit, Operating Profit, Earnings before Interest, Tax, Depreciation and Amortization, Net Income" ],
        answerC: [ "Revenue, Operating Profit, Gross Profit, Net Income" ],
        answerD: [ "Earnings before Interest, Tax, Depreciation and Amortization, Gross Profit, Operating Profit, Net Income" ],
        explanationA: [ "Correct! Revenue is the first line on the Income Statement and so it would be the largest. Assuming the company has some ", "operating expenses and interest to pay the gross profit will be higher than pre-tax income due to these mentioned deductions. ", "Finally, given that a company pays some form of tax (in the case it has positive profits), we would expect the pre-tax to be higher ", "than net income." ],
        explanationB: [ "This is incorrect. To calculate EBITDA, you would typically add D&A back to Operating Profit, therefore it would be higher. ", "The correct answer is (A)." ],
        explanationC: [ "This is incorrect. Gross Profit is higher than Operating Profit because Gross Profit accounts for the COGS whereas Operating Profit ", "accounts for COGS and OpEx. The correct answer is (A)." ],
        explanationD: [ "This is incorrect. EBITDA cannot be larger than Gross Profit. If Gross Profit accounts for COGS and Operating Profit accounts for ", "COGS and OpEx (which contains D&A) then adding back D&A would not be large enough to exceed COGS. The only possibility is ", "D&A are the only Operating Expense and therefore Gross Profit and EBITDA would be equal, but this is unlikely. ", "The correct answer is (A)." ]
    }, {
        questionTextArray: [ "What is the Balance Sheet Identity?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Assets = Liabilities + Equity" ],
        answerB: [ "Liabilities = Equity \u2013 Assets" ],
        answerC: [ "Equity = Liabilities \u2013 Assets" ],
        answerD: [ "Assets = Liabilities / Equity" ],
        explanationA: [ "Correct! This derives from the notion that what the business owns is equal to what the business owes. Rearranging gives us ", "Assets + Liabilities = Equity which does not congruent with the previous statement." ],
        explanationB: [ "This is incorrect. Remember that this derives from the notion that what the business owns is equal to what the business owes. ", "Rearranging gives us Assets + Liabilities = Equity which does not congruent with the previous statement. The correct answer is (A)." ],
        explanationC: [ "This is incorrect. Remember that this derives from the notion that what the business owns is equal to what the business owes. ", "Rearranging gives us Assets + Equity = Liabilities which does not congruent with the previous statement. The correct answer is (A)." ],
        explanationD: [ "This is incorrect. Remember that this derives from the notion that what the business owns is equal to what the business owes. ", "The current equation does not provide this summation. The correct answer is (A)." ]
    }, {
        questionTextArray: [ "Which of the following current assets is the most liquid?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Inventories" ],
        answerB: [ "Accounts Receivable" ],
        answerC: [ "Cash and Equivalents" ],
        answerD: [ "Pre-paid Expenses" ],
        explanationA: [ "This is incorrect. Liquidity measures the ability to be converted into cash. Inventories must be sold which requires finding a buyer ", "which takes some time or may not be possible. The correct answer is (C) because cash does not need converting before it can be ", "spent." ],
        explanationB: [ "This is incorrect. Liquidity measures the ability to be converted into cash. Accounts Receivable does represent cash we will receive ", "from customers in the future however we are dependent on the customer to pay this cash and they do not necessarily have to pay ", "today but may settle at the end of their term (e.g. end of 3-month buy now and pay later arrangement). ", "The correct answer is (C) because cash does not need converting before it can be spent." ],
        explanationC: [ "Correct! Cash does not need converting before it can be spent. All other answers would need some form of conversion into cash ", "first." ],
        explanationD: [ "This is incorrect. Liquidity measures the ability to be converted into cash. Prepaid expenses represent the cash we have already ", "given to an external party for a good or service we are yet to receive. Therefore, we cannot cash this in and regain the cash spent. ", "The correct answer is (C) because cash does not need converting before it can be spent." ]
    }, {
        questionTextArray: [ "What best explains why PP&E is a Non-Current Asset?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "PP&E like factories are difficult to transport and are fixed in their location." ],
        answerB: [ "PP&E is purchased in large amounts and is difficult to sell in one whole transaction." ],
        answerC: [ "PP&E is a core part of a business, and it is unlikely to be sold because it is needed to produce the products for the business." ],
        answerD: [ "PP&E are illiquid assets that often take an extended period to sell for cash." ],
        explanationA: [ "This is incorrect because it is not the definition of a non-current asset and PP&E can include tools or vehicles which ", "are easily transportable. The correct answer is (D) because non-current refers to the liquidity of the asset. ", "Non-current assets are those that would take over a year to be converted into cash." ],
        explanationB: [ "This is incorrect. This does have some merit because the difficulty selling PP&E often leads to its illiquidity but is not ", "the defining characteristic of a non-current asset. Additionally, it does not have to be purchased in large amounts or ", "sold in one whole transaction. The correct answer is (D) because non-current refers to the liquidity of the asset. " ],
        explanationC: [ "This is incorrect. Despite being true for manufacturing businesses this is not the defining quality of a non-current ", "asset. The correct answer is (D) because non-current refers to the liquidity of the asset. Non-current assets are those ", "that would take over a year to be converted into cash." ],
        explanationD: [ "Correct! Non-current refers to the liquidity of the asset. Non-current assets are those that would take over a year to ", "be converted into cash." ]
    }, {
        questionTextArray: [ "What is the difference between current liabilities and non-current liabilities?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Current liabilities are associated with the claims on the business for its raw materials whereas non-current liabilities ", "are for those who lend to the business." ],
        answerB: [ "Current liabilities are often smaller in sum than the non-current liabilities" ],
        answerC: [ "Current liabilities are those that mature in the shortest period whereas non-current are claims on the business that ", "are due in over 1 year." ],
        answerD: [ "Current liabilities are the important claims on the business that the company chooses to pay first, while non-current ", "liabilities are the less important costs which the company can ignore." ],
        explanationA: [ "This is incorrect. The correct answer is (C) because current and non-current refer to the maturity or term of the ", "liability. Those that are due within 1-year are current and those due later are non-current." ],
        explanationB: [ "This is incorrect. The correct answer is (C) because current and non-current refer to the maturity or term of the ", "liability. Those that are due within 1-year are current and those due later are non-current." ],
        explanationC: [ "Correct! Current and non-current refer to the maturity or term of the liability. Those that are due within 1-year are ", "current and those due later are non-current." ],
        explanationD: [ "This is incorrect. The correct answer is (C) because current and non-current refer to the maturity or term of the ", "liability. Those that are due within 1-year are current and those due later are non-current." ]
    }, {
        questionTextArray: [ "What is the difference between Accounts Payable and Accrued Expenses?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Accrued expenses are for those not yet invoiced and serves as an estimate of the cost whereas Accounts Payable is ", "the exact cost to be paid once an invoice has been provided." ],
        answerB: [ "Accrued Expenses are for internal costs such as wages to employees whereas Accounts Payable are the costs external ", "to the business such as the suppliers." ],
        answerC: [ "Accounts Payable is for the expenses that are to be paid in the next 3 months on a forward-basis whereas Accrued ", "Expenses are the costs that have already been charged and the company is in arrears to pay." ],
        answerD: [ "Both Accounts Payable and Accrued Expenses are the same line item but just given different names." ],
        explanationA: [ "Correct! The key difference between Accrued Expenses and Accounts Payable is the invoicing. The former is not yet ", "invoiced but the estimated cost (ex-ante) whereas the latter is based upon the actual invoiced amount (ex-post)." ],
        explanationB: [ "This is incorrect. The correct answer is (A) because the key difference between Accrued Expenses and Accounts ", "Payable is the invoicing. The former is not yet invoiced but the estimated cost (ex-ante) whereas the latter is based ", "upon the actual invoiced amount (ex-post)." ],
        explanationC: [ "This is incorrect. The correct answer is (A) because the key difference between Accrued Expenses and Accounts ", "Payable is the invoicing. The former is not yet invoiced but the estimated cost (ex-ante) whereas the latter is based ", "upon the actual invoiced amount (ex-post)." ],
        explanationD: [ "This is incorrect. The correct answer is (A) because the key difference between Accrued Expenses and Accounts ", "Payable is the invoicing. The former is not yet invoiced but the estimated cost (ex-ante) whereas the latter is based ", "upon the actual invoiced amount (ex-post)." ]
    }, {
        questionTextArray: [ "What is/are the typical non-current liabilities?", "    i) Debt", "    ii) Preferred Stock", "    iii) Common Stock" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "(i)" ],
        answerB: [ "(i) and (ii)" ],
        answerC: [ "(ii) and (iii)" ],
        answerD: [ "(i), (ii), and (iii)" ],
        explanationA: [ "Correct! Debt represents the claims to external non-equity holders of the business." ],
        explanationB: [ "This is incorrect. Preferred Stock is a debt like instrument but is an equity claim on the business. The correct answer is ", "(A) because debt represents the claims to external non-equity holders of the business." ],
        explanationC: [ "This is incorrect because Preferred Stock despite being a debt like instrument and Common Stock are both equity ", "claims on the business. The correct answer is (A) because debt represents the claims to external non-equity holders ", "of the business." ],
        explanationD: [ "This is incorrect because Preferred Stock despite being a debt like instrument and Common Stock are both equity ", "claims on the business. The correct answer is (A) because debt represents the claims to external non-equity holders ", "of the business." ]
    }, {
        questionTextArray: [ "What are the similarities between the Liabilities and Equity on the Balance Sheet?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "Both are often instruments that require investment, interest payment and repayment." ],
        answerB: [ "Both have a finite term (time before maturity)." ],
        answerC: [ "Both increase as a result of improved profitability of the business." ],
        answerD: [ "Both represent claims of external capital providers." ],
        explanationA: [ "This is incorrect. Not all liabilities require interest payment such as zero-coupon bonds although few if any exist. ", "Additionally, Equity does not require mandatory repayment whereas most Liabilities do at some point in the future. ", "The correct answer is (D) because both Equity and Liabilities are the claims of the external providers of capital to the ", "business. They both represent the investments of those outside of the business." ],
        explanationB: [ "This is incorrect. Equity such as Common Stock have an infinite term (there is no maturity stated in the stock ", "contracts). The correct answer is (D) because both Equity and Liabilities are the claims of the external providers of ", "capital to the business. They both represent the investments of those outside of the business." ],
        explanationC: [ "This is incorrect. If the profitability of the business improves the liabilities or debt holders will still receive their ", "interest or mandatory repayment and no further income. The Equity holders, predominantly Common Stock holders ", "may receive some of these profits through dividends and may also benefit from capital appreciation. The correct", "answer is (D) because both Equity and Liabilities are the claims of the external providers of capital to the business. ", "They both represent the investments of those outside of the business." ],
        explanationD: [ "Correct! Both Equity and Liabilities are the claims of the external providers of capital to the business. They both ", "represent the investments of those outside of the business." ]
    }, {
        questionTextArray: [ "What are the 3 sections of the Cash Flow Statement in the correct order?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Cash Flow from Investing, Cash Flow from Operations, Cash Flow from Financing" ],
        answerB: [ "Cash Flow from Financing, Cash Flow from Investing, Cash Flow from Operations" ],
        answerC: [ "Cash Flow from Operations, Cash Flow from Investing, Cash Flow from Financing" ],
        answerD: [ "Cash Flow from Operations, Cash Flow from Financing, Cash Flow from Investing" ],
        explanationA: [ "This is incorrect. The correct answer is (C) because the Cash Flow Statement begins with Net Income then makes the ", "non-cash adjustments and working capital adjustments. Following that we treat the investment activities and the ", "financing activities of the business." ],
        explanationB: [ "This is incorrect. The correct answer is (C) because the Cash Flow Statement begins with Net Income then makes the ", "non-cash adjustments and working capital adjustments. Following that we treat the investment activities and the ", "financing activities of the business." ],
        explanationC: [ "Correct! The Cash Flow Statement begins with Net Income then makes the non-cash adjustments and working capital ", "adjustments. Following that we treat the investment activities and the financing activities of the business." ],
        explanationD: [ "This is incorrect. The correct answer is (C) because the Cash Flow Statement begins with Net Income then makes the ", "non-cash adjustments and working capital adjustments. Following that we treat the investment activities and the ", "financing activities of the business." ]
    }, {
        questionTextArray: [ "Why do we adjust Net Income in the Cash Flow from Operations Section?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "To reflect changes in the Balance Sheet items." ],
        answerB: [ "To reflect the changes in non-cash deductions that save the amount of tax we pay and that are inappropriately ", "categorised as Operating Activities." ],
        answerC: [ "To adjust our cash for changes that have not been recorded on the Income Statement like the purchase or equipment ", "or the taking out of loan financing." ],
        answerD: [ "To improve the cash flow that we record on the Balance Sheet." ],
        explanationA: [ "This is incorrect. The adjustments are not primarily for this reason despite us adjusting for working capital. The ", "correct answer is (B) because we adjust Net Income for the non-cash items such as depreciation which can reduce ", "our tax bill and when we must re-categorise items such as asset sales and write-downs." ],
        explanationB: [ "Correct! We adjust Net Income for the non-cash items such as depreciation which can reduce our tax bill and when ", "we must re-categorise items such as asset sales and write-downs." ],
        explanationC: [ "This is incorrect. These are not apparent on the Income Statement because they are not a part of the day-to-day cash ", "generation of the business. They would fall into Investing and Financing Activities. The correct answer is (B) because ", "we adjust Net Income for the non-cash items such as depreciation which can reduce our tax bill and when we must ", "re-categorise items such as asset sales and write-downs." ],
        explanationD: [ "This is incorrect. Even though we do often find improvements from Net Income to cash from Operations we do not do ", "this to improve our cash position but to provide an accurate level of our cash position because our Net Income is not ", "the same as our cash received. The correct answer is (B) because we adjust Net Income for the non-cash items such ", "as depreciation which can reduce our tax bill and when we must re-categorise items such as asset sales and ", "write-downs." ]
    }, {
        questionTextArray: [ "Which of the following are all non-cash expenses added back to Net Income?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Depreciation, Research and Development, Stock Based Compensation" ],
        answerB: [ "Research and Development, Stock Based Compensation, Interest Expense" ],
        answerC: [ "Depreciation, Stock Based Compensation, Amortization" ],
        answerD: [ "Stock Based Compensation, Gain on Sale of PP&E, Interest Expense" ],
        explanationA: [ "This is incorrect. Research and Development can often be a cash-expense. The correct answer is (C) because ", "Depreciation, Amortization and Stock Based Compensation are explicitly non-cash in nature." ],
        explanationB: [ "This is incorrect. Research and Development can often be a cash-expense. The correct answer is (C) because ", "Depreciation, Amortization and Stock Based Compensation are explicitly non-cash in nature." ],
        explanationC: [ "Correct! Depreciation, Amortization and Stock Based Compensation are explicitly non-cash in nature." ],
        explanationD: [ "This is incorrect. Gain on the Sale of PP&E is a cash-gain because the buyer will have provided cash to purchase the ", "equipment. The correct answer is (C) because Depreciation, Amortization and Stock Based Compensation are ", "explicitly non-cash in nature." ]
    }, {
        questionTextArray: [ "What is the formula for net working capital?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Current Assets \u2013 Current Liabilities" ],
        answerB: [ "Non-Current Assets \u2013 Non-Current Liabilities" ],
        answerC: [ "(Current Assets \u2013 Cash) \u2013 (Current Liabilities \u2013 Current Debts)" ],
        answerD: [ "(Current Liabilities \u2013 Current Debts) \u2013 (Current Assets \u2013 Cash)" ],
        explanationA: [ "This is incorrect. Although by a textbook this is the definition in banking, we often prefer to exclude the cash like ", "assets and current debt liabilities since they are not actually working capital but sources of funding. The correct ", "answer is (C)." ],
        explanationB: [ "This is incorrect. This formula focuses on non-current asset which are not working in nature. They are less subject to ", "change each period are not a part of the day-to-day trading operations. The correct answer is (C) because it looks at ", "the assets and liabilities that are needed on a day-to-day basis of operating the business (excluding any funding)." ],
        explanationC: [ "Correct! A textbook definition often takes Current Assets \u2013 Current Liabilities but in banking, we often prefer to ", "exclude the cash like assets and current debt liabilities since they are not actually working capital but sources of ", "funding." ],
        explanationD: [ "This is incorrect. Despite this having the correct terms in the formula, it has them arranged incorrectly. Net working ", "capital takes the value of assets and nets out the value of liabilities. Therefore, the correct answer is (C)." ]
    }, {
        questionTextArray: [ "Which pair of the following changes in working assets and liabilities are paired with the correct direction of impact ", "on cash flow?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Assets Increase (+), Liabilities Increase (-)" ],
        answerB: [ "Assets Increase (-), Liabilities Decrease (+)" ],
        answerC: [ "Assets Decrease (+), Liabilities Increase (+)" ],
        answerD: [ "Assets Increase (-), Liabilities Increase (-)" ],
        explanationA: [ "This is incorrect. When an asset increases, we would have used cash to acquire these assets and so would reduce our ", "cash. Similarly, when a liability increases, we have increased funding or delayed payment of cash which increases our ", "cash. Therefore, the correct answer is (C)." ],
        explanationB: [ "This is incorrect. When an asset increases, we would have used cash to acquire these assets and so would reduce our ", "cash. Similarly, when a liability increases, we have increased funding or delayed payment of cash which increases our ", "cash. Therefore, the correct answer is (C)." ],
        explanationC: [ "Correct! When an asset increases, we would have used cash to acquire these assets and so would reduce our cash. ", "Similarly, when a liability increases, we have increased funding or delayed payment of cash which increases our cash." ],
        explanationD: [ "This is incorrect. When an asset increases, we would have used cash to acquire these assets and so would reduce our ", "cash. Similarly, when a liability increases, we have increased funding or delayed payment of cash which increases our ", "cash. Therefore, the correct answer is (C)." ]
    }, {
        questionTextArray: [ "What item is not found in Cash Flow from Investing?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Purchase of PP&E" ],
        answerB: [ "Sale of Securities" ],
        answerC: [ "Repurchasing shares back from investors" ],
        answerD: [ "Acquisitions" ],
        explanationA: [ "This is incorrect. The purchase of PP&E is one of the primary functions of investing activity because it we use the cash ", "to purchase these assets. The correct answer is (C) because repurchasing shares is relating to the equity funding of ", "the business and so is a financing activity." ],
        explanationB: [ "This is incorrect. The sale of securities (that are not within the capital structure) are external investments that the ", "business uses its cash for. Since they are not a part of our own capital structure they are not financing activities and ", "fall into investing activities. The correct answer is (C) because repurchasing shares is relating to the equity funding of ", "the business and so is a financing activity." ],
        explanationC: [ "Correct! Repurchasing shares is relating to the equity funding of the business and so is a financing activity." ],
        explanationD: [ "This is incorrect. Acquisitions are the purchase of another business it involves purchasing the assets and therefore can ", "be considered an investment. The correct answer is (c) because repurchasing shares is relating to the equity funding ", "of the business and so is a financing activity." ]
    }, {
        questionTextArray: [ "What item is not found in Cash Flow from Financing?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Sale of Securities" ],
        answerB: [ "Repayment of Debt" ],
        answerC: [ "Issuance of Preferred Stock" ],
        answerD: [ "Cash Dividends" ],
        explanationA: [ "Correct! This involves the investment in securities outside of our capital structure." ],
        explanationB: [ "This is incorrect. Repayment of our debt relates to our own capital structure and so is a financing activity. The correct ", "answer is (A) because this involves the investment in securities outside of our capital structure." ],
        explanationC: [ "This is incorrect. Issuance of any form of equity relates to our own capital structure and so is a financing activity. The ", "correct answer is (A) because this involves the investment in securities outside of our capital structure." ],
        explanationD: [ "This is incorrect. Issuance of a cash dividend is releasing capital for our own shareholders. Therefore, it relates to our ", "own capital structure and so is a financing activity. The correct answer is (A) because this involves the investment in ", "securities outside of our capital structure." ]
    }, {
        questionTextArray: [ "Which financial statement can be derived from the other 2?" ],
        correctAnswer: BankingReady_Data_Quiz.C.value,
        answerA: [ "Income Statement" ],
        answerB: [ "Balance Sheet" ],
        answerC: [ "Cash Flow Statement" ],
        answerD: [ "No statement can be derived from the remaining 2 statements." ],
        explanationA: [ "This is incorrect. The correct answer is (C) because the Cash Flow Statement can be derived from the Income ", "Statement and the Balance Sheet. We have Net Income and the non-cash adjustments from the Income Statement. ", "We can get all Investing and Financing Activity from the Balance Sheet." ],
        explanationB: [ "This is incorrect. The correct answer is (C) because the Cash Flow Statement can be derived from the Income ", "Statement and the Balance Sheet. We have Net Income and the non-cash adjustments from the Income Statement. ", "We can get all Investing and Financing Activity from the Balance Sheet." ],
        explanationC: [ "Correct! This can be derived from the Income Statement and the Balance Sheet. We have Net Income and the ", "non-cash adjustments from the Income Statement. We can get all Investing and Financing Activity from the Balance ", "Sheet." ],
        explanationD: [ "This is incorrect. The correct answer is (C) because the Cash Flow Statement can be derived from the Income ", "Statement and the Balance Sheet. We have Net Income and the non-cash adjustments from the Income Statement. ", "We can get all Investing and Financing Activity from the Balance Sheet." ]
    }, {
        questionTextArray: [ "Which of the following line items from the Income Statement does not link to the balance sheet?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Revenue" ],
        answerB: [ "Cost of sales" ],
        answerC: [ "Depreciation" ],
        answerD: [ "Net income" ],
        explanationA: [ "Correct. Revenue does not flow into the Income Statement because it does not link to the Balance Sheet line items. Cost of sales ", "links to the cost of inventories on the balance sheet, depreciation will reduce the value of PP&E every period and Net Income flows ", "into retained earnings." ],
        explanationB: [ "This is incorrect. The correct answer is (a) Revenue starts the Income Statement and then is adjusted for costs and expenses to ", "arrive at Net Income. These adjustments and the result link to the Balance Sheet. Cost of sales links to the decrease of inventories ", "on the balance sheet when sold, depreciation will reduce the value of PP&E every period and Net Income flows into retained ", "earnings." ],
        explanationC: [ "This is incorrect. The correct answer is (a) Revenue starts the Income Statement and this adjusted for costs and expenses to get to ", "Net Income. These adjustments and the result link to the Balance Sheet. Cost of sales links to the decrease of inventories on the ", "balance sheet when sold, depreciation will reduce the value of PP&E every period and Net Income flows into retained earnings." ],
        explanationD: [ "This is incorrect. The correct answer is (a) Revenue starts the Income Statement and this adjusted for costs and expenses to get to ", "Net Income. These adjustments and the result link to the Balance Sheet. Cost of sales links to the decrease of inventories on the ", "balance sheet when sold, depreciation will reduce the value of PP&E every period and Net Income flows into retained earnings." ]
    }, {
        questionTextArray: [ "What is the net change of cash on the balance sheet when depreciation increases by \xa310 (assume a 40% tax rate)?" ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Cash remains unchanged" ],
        answerB: [ "Cash increases by \xa34" ],
        answerC: [ "Cash decreases by \xa34" ],
        answerD: [ "Cash decreases by \xa310" ],
        explanationA: [ "This is incorrect. The correct answer is (b). These questions are answered through the sequential changes to the Income ", "Statement, the Cash Flow Statement, then the Balance Sheet. An increase in depreciation adds an expense to the Income ", "Statement of \xa310, so our Operating Income (EBIT) decreases. At a 40% tax rate this reduces the amount of losses (remember the ", "tax shield) and so the after-tax impact is a \xa310 * (1 \u2013 40%) = \xa36 decrease in Net Income. This Net Income change flows into the ", "top of our Cash Flow Statement. However, we add back the depreciation because it is a non-cash expense. Adding back the \xa310 ", "results in Cash Flow from Operations increasing by \xa34. This change in cash flows onto the Balance Sheet where cash increases by ", "\xa34. To check this we know that this increase in \xa34 is offset by a \xa310 depreciation expenses leading assets to decrease by \xa36. This is ", "matched by a \xa36 decrease in retained earnings so the Balance Sheet identity remains balanced." ],
        explanationB: [ "Correct. These questions are answered through the sequential changes to the Income Statement, the Cash Flow Statement, then ", "the Balance Sheet. An increase in depreciation adds an expense to the Income Statement of \xa310, so our Operating Income (EBIT) ", "decreases. At a 40% tax rate this reduces the amount of losses (remember the tax shield) and so the after-tax impact is a ", "\xa310 * (1 \u2013 40%) = \xa36 decrease in Net Income. This Net Income change flows into the top of our Cash Flow Statement. However, ", "we add back the depreciation because it is a non-cash expense. Adding back the \xa310 results in Cash Flow from Operations ", "increasing by \xa34. This change in cash flows onto the Balance Sheet where cash increases by \xa34. To check this we know that this ", "increase in \xa34 is offset by a \xa310 depreciation expenses leading assets to decrease by \xa36. This is matched by a \xa36 decrease in ", "retained earnings so the Balance Sheet identity remains balanced." ],
        explanationC: [ "This is incorrect. The correct answer is (b). These questions are answered through the sequential changes to the Income ", "Statement, the Cash Flow Statement, then the Balance Sheet. An increase in depreciation adds an expense to the Income ", "Statement of \xa310, so our Operating Income (EBIT) decreases. At a 40% tax rate this reduces the amount of losses (remember the ", "tax shield) and so the after-tax impact is a \xa310 * (1 \u2013 40%) = \xa36 decrease in Net Income. This Net Income change flows into the ", "top of our Cash Flow Statement. However, we add back the depreciation because it is a non-cash expense. Adding back the \xa310 ", "results in Cash Flow from Operations increasing by \xa34. This change in cash flows onto the Balance Sheet where cash increases by ", "\xa34. To check this we know that this increase in \xa34 is offset by a \xa310 depreciation expenses leading assets to decrease by \xa36. This is ", "matched by a \xa36 decrease in retained earnings so the Balance Sheet identity remains balanced." ],
        explanationD: [ "This is incorrect. The correct answer is (b). These questions are answered through the sequential changes to the Income ", "Statement, the Cash Flow Statement, then the Balance Sheet. An increase in depreciation adds an expense to the Income ", "Statement of \xa310, so our Operating Income (EBIT) decreases. At a 40% tax rate this reduces the amount of losses (remember the ", "tax shield) and so the after-tax impact is a \xa310 * (1 \u2013 40%) = \xa36 decrease in Net Income. This Net Income change flows into the ", "top of our Cash Flow Statement. However, we add back the depreciation because it is a non-cash expense. Adding back the \xa310 ", "results in Cash Flow from Operations increasing by \xa34. This change in cash flows onto the Balance Sheet where cash increases by ", "\xa34. To check this we know that this increase in \xa34 is offset by a \xa310 depreciation expenses leading assets to decrease by \xa36. This is ", "matched by a \xa36 decrease in retained earnings so the Balance Sheet identity remains balanced." ]
    }, {
        questionTextArray: [ "What is the change to cash on the Balance Sheet when inventories decrease by \xa310 as a result of sales worth \xa320 (assume a tax rate of 40%)?" ],
        correctAnswer: BankingReady_Data_Quiz.D.value,
        answerA: [ "Cash remains unchanged" ],
        answerB: [ "Cash increases by \xa320" ],
        answerC: [ "Cash decreases by \xa36" ],
        answerD: [ "Cash decreases by \xa316" ],
        explanationA: [ "This is incorrect. The correct answer is (d). These questions are answered through the sequential changes to the Income ", "Statement, the Cash Flow Statement, then the Balance Sheet. Sales of inventories for \xa320 would increase on the Income ", "Statement. These sales were of inventories worth \xa310 so our Cost of Goods Sold expense increase by \xa310. This means our ", "Operating Income (EBIT) increases \xa310. At a 40% tax rate this reduces the profits made and so the after-tax impact is a ", "\xa310 * (1 - 40%) = \xa36 increase in Net Income. This Net Income change flows into the top of our Cash Flow Statement. However, we ", "must also adjust for the changes for net working capital. A decrease in inventories will increase our cash by the \xa310 changing ", "results in Cash Flow from Operations increasing by \xa316. This change in cash flows onto the Balance Sheet where cash increases by ", "\xa316. To check this, we know that this increase in \xa316 increases cash on the Balance Sheet but is offset the \xa310 decrease in ", "inventories leading assets to increase by \xa36. This is matched by a \xa36 increase in retained earnings, so the Balance Sheet identity ", "remains balanced." ],
        explanationB: [ "This is incorrect. The correct answer is (d). These questions are answered through the sequential changes to the Income ", "Statement, the Cash Flow Statement, then the Balance Sheet. Sales of inventories for \xa320 would increase on the Income ", "Statement. These sales were of inventories worth \xa310 so our Cost of Goods Sold expense increase by \xa310. This means our ", "Operating Income (EBIT) increases \xa310. At a 40% tax rate this reduces the profits made and so the after-tax impact is a ", "\xa310 * (1 - 40%) = \xa36 increase in Net Income. This Net Income change flows into the top of our Cash Flow Statement. However, we ", "must also adjust for the changes for net working capital. A decrease in inventories will increase our cash by the \xa310 changing ", "results in Cash Flow from Operations increasing by \xa316. This change in cash flows onto the Balance Sheet where cash increases by ", "\xa316. To check this, we know that this increase in \xa316 increases cash on the Balance Sheet but is offset the \xa310 decrease in ", "inventories leading assets to increase by \xa36. This is matched by a \xa36 increase in retained earnings, so the Balance Sheet identity ", "remains balanced." ],
        explanationC: [ "This is incorrect. The correct answer is (d). These questions are answered through the sequential changes to the Income ", "Statement, the Cash Flow Statement, then the Balance Sheet. Sales of inventories for \xa320 would increase on the Income ", "Statement. These sales were of inventories worth \xa310 so our Cost of Goods Sold expense increase by \xa310. This means our ", "Operating Income (EBIT) increases \xa310. At a 40% tax rate this reduces the profits made and so the after-tax impact is a ", "\xa310 * (1 - 40%) = \xa36 increase in Net Income. This Net Income change flows into the top of our Cash Flow Statement. However, we ", "must also adjust for the changes for net working capital. A decrease in inventories will increase our cash by the \xa310 changing ", "results in Cash Flow from Operations increasing by \xa316. This change in cash flows onto the Balance Sheet where cash increases by ", "\xa316. To check this, we know that this increase in \xa316 increases cash on the Balance Sheet but is offset the \xa310 decrease in ", "inventories leading assets to increase by \xa36. This is matched by a \xa36 increase in retained earnings, so the Balance Sheet identity ", "remains balanced." ],
        explanationD: [ "This is correct. These questions are answered through the sequential changes to the Income Statement, the Cash Flow Statement, ", "then the Balance Sheet. Sales of inventories for \xa320 would increase on the Income Statement. These sales were of inventories ", "worth \xa310 so our Cost of Goods Sold expense increase by \xa310. This means our Operating Income (EBIT) increases \xa310. At a 40% ", "tax rate this reduces the profits made and so the after-tax impact is a \xa310 * (1 - 40%) = \xa36 increase in Net Income. This ", "Net Income change flows into the top of our Cash Flow Statement. However, we must also adjust for the changes for net working ", "capital. A decrease in inventories will increase our cash by the \xa310 changing results in Cash Flow from Operations increasing by ", "\xa316. This change in cash flows onto the Balance Sheet where cash increases by \xa316. To check this, we know that this increase in ", "\xa316 increases cash on the Balance Sheet but is offset the \xa310 decrease in inventories leading assets to increase by \xa36. This is ", "matched by a \xa36 increase in retained earnings, so the Balance Sheet identity remains balanced." ]
    }, {
        questionTextArray: [ "Which of the following companies would be the most likely to collect cash from customers but not record it as revenue?" ],
        correctAnswer: BankingReady_Data_Quiz.A.value,
        answerA: [ "Web-based subscription companies" ],
        answerB: [ "An automative sales company" ],
        answerC: [ "A supermarket" ],
        answerD: [ "An online e-commerce retailer" ],
        explanationA: [ "Correct. Web-based subscription companies agree to services in the future but collect the cash up front. For example, when ", "purchasing a subscription for 1 year the company will collect the cash but only after the 12 months of subscription has been ", "\u201cdelivered\u201d will the revenue of the sale be recognised as revenue. " ],
        explanationB: [ "This is incorrect. The correct answer is (a). An automotive seller will make a sale and receive the cash upfront (or in delay) and ", "deliver the car at the same time. Thus it recognises the revenues before or at the same time as the cash. Web-based subscription ", "companies agree to services in the future but collect the cash up front. For example, when purchasing a subscription for 1 year the ", "company will collect the cash but only after the 12 months of subscription has been \u201cdelivered\u201d will the revenue of the sale be ", "recognised as revenue. " ],
        explanationC: [ "This is incorrect. The correct answer is (a). An supermarket will make a sales and is paid in cash at this point. Thus it recognises ", "the revenues before or at the same time as the cash. Web-based subscription companies agree to services in the future but ", "collect the cash up front. For example, when purchasing a subscription for 1 year the company will collect the cash but only after ", "the 12 months of subscription has been \u201cdelivered\u201d will the revenue of the sale be recognised as revenue. " ],
        explanationD: [ "This is incorrect. The correct answer is (a). An online ecommerce business will make a sale and receive the cash upfront (or in ", "delay) at the same time, despite having to ship the product (which still counts as being delivered where the revenue can be ", "recognised). Thus it recognises the revenues before or at the same time as the cash. Web-based subscription companies agree to ", "services in the future but collect the cash up front. For example, when purchasing a subscription for 1 year the company will ", "collect the cash but only after the 12 months of subscription has been \u201cdelivered\u201d will the revenue of the sale be recognised as ", "revenue. " ]
    } ];
})();
var firstQuizQuestion = /* #__PURE__ */ (function () {
    return {
        questionTextArray: [ "Which of the financial statements show information about a company at a specific snapshot of time and not of flows throughout a period." ],
        correctAnswer: BankingReady_Data_Quiz.B.value,
        answerA: [ "Income Statement" ],
        answerB: [ "Balance Sheet" ],
        answerC: [ "Cash Flow Statement" ],
        answerD: [ "None" ],
        explanationA: [ "The Income Statement contains the totals of the revenue, cost, and tax flows throughout the period. Therefore, it does not ", "represent a snapshot. The correct answer is the Balance Sheet which takes a snapshot or cross-section of the financial position of ", "the company as of the end of the period. The Income Statement displays the cumulative within the period." ],
        explanationB: [ "Correct! The Balance Sheet takes a snapshot or cross-section of the financial position of the company as of the end of the period." ],
        explanationC: [ "The Cash Flow Statement contains the flow of cash throughout the period. Therefore, it does not represent a snapshot. The correct ", "answer is the Balance Sheet which takes a snapshot or cross-section of the financial position of the company as of the end of the ", "period. The Cash Flow Statement displays the cumulative within the period." ],
        explanationD: [ "The correct answer is the Balance Sheet which takes a snapshot or cross-section of the financial position of the company as of the ", "end of the period. The Income Statement and Cash Flow Statement display the cumulative within the period." ]
    };
})();
export {
    firstQuizQuestion,
    upcomingQuizQuestions
};
