// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var incomeStatementMultiplesDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Let\u2019s look at Kellogg\u2019s Income Statement to provide some colour to the key rules. So the key metrics we are looking at here are EBIT and Net Income Attributable to Kellogg Company."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 17,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Starting with EBIT we can see that this is before the interest expense. Hence, it reflects the claims of all the investing groups. As a result we pair it with Enterprise Value when calculating a multiple. This is because Enterprise Value looks at the value available to all the investors in the company."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 17,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "From previous lessons we know Kellogg\u2019s Enterprise Value is $32B. So in calculating our ratio we take the $32B and divide it by the EBIT of $1.75B. So our formula is Enterprise Value / EBIT."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I9/D18",
        numberFormat: BankingReady_Utility_Excel_Cell.MultipleOneDecimal.value,
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 17,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "That gives as an EV/EBIT multiple of 18.4x."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we look at Net Income. Now net income has deducted the expenses relating to the debt holders (and in other cases the other providers of investment in the business). As a result we would use the Equity Value as our numerator because net income represents what is \u2018left\u2019 for the common shareholders and Equity Value is the value metric corresponding with common shareholders. From previous lessons we know Kellogg\u2019s equity value is $25.1B."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 6,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is commonly referred to as Price-to-Earnings or P/E ratio. It can be calculated on a per share term (Share Price / Earnings per share) or on a total level (Equity Value / Net Income). These 2 are equivalent i.e.: (Price * Shares Outstanding) / (Earnings * Shares Outstanding) = Equity Value / Net Income. You can see how in the total level case we can divide both numerator and denominator by total shares so the impact on our ratio is nil."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I8/D30",
        numberFormat: BankingReady_Utility_Excel_Cell.MultipleOneDecimal.value,
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives as a P/E multiple of 16.8x."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To recap, we can see that when we calculate the ratios with Equity Value, we use the operating metric that corresponds with the common shareholders, just as Equity Value does. When we are using Enterprise Value, we use the metrics that represent the claims of all the investors in the company. This keeps our comparisons \u201capples-to-apples\u201d."
    })) ] ];
})();
export {
    incomeStatementMultiplesDescriptions
};
