// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var cashFlowStatementDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 10,
            rowCount: 17,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The Cash Flow Statement begins with the Operating Activities. This represents the cash generated by (used by) the operations of the business, i.e. the day-to-day or core activities of the business."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=D30",
        references: [ {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We begin with net income as it sums up the profitability for the operations of the company. We will then make the adjustments for non-cash expenses."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 9,
            colIdx: 11,
            rowCount: 6,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The first adjustments we make are the non-cash ones. These are the expenses on the Income Statement that are non-cash expenses and so must be added back (they do not require an actual outflow of cash). Often, they improve our cash position. The benefit of including them on our Income Statement is they reduce our pre-tax income and hence our tax bill. When we add the expense back on the Cash Flow Statement, we gain the tax savings. We start with the most common non-cash adjustments such as Depreciation and Amortization (D&A) and Share Based Compensation (SBC). There are other non-cash adjustments (too advanced for this course) such as Goodwill Impairments, Asset Write-ups or Write-downs and the Gains and Losses on Asset sales."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        references: [ {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        formula: "=(-1)*D14",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Remember the definition of D&A. It is the cost we recognise on the Income Statement over time for the purchase of an asset. Now this clearly is not a cash expense since when PP&E is purchased the cash is provided (consumed for the business) at the same time. So because this was not a cash expense, we add it back to Net Income. Please remember that D&A is also tax-deductible, and this is why we can include it as an expense on the Income Statement."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*D15",
        references: [ {
            rangeSelector: {
                rowIdx: 14,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is also a non-cash expense much like depreciation. This is because Stock Based Compensation is not paid in cash, but the Income Statement recognises the value of it as an expense. Because we know there is no actual flow of cash out of the business this is added back to Net Income."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 11,
            rowCount: 8,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we consider the how Changes in the Operating Assets & Liabilities items impact the cash flow. Before going through the examples we will recap the general rules of Assets and Liabilities."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 11,
            rowCount: 8,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "1. To adjust for balance sheet items we should recall what an asset is and what a liability is. So an asset is a source of future benefits (or cash) and a liability is a use of future benefits (or cash). "
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 11,
            rowCount: 8,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "2. Think what happens when an asset is sold. This must mean that cash has flowed into the company. So when assets decrease, we recognise the increase in cash. Therefore when these assets increase between 2 periods, we must have used cash to purchase them. These changes can be calculated by taking the previous value of the asset and subtracting the new value Asset (in previous period) \u2013 Asset (in current period). But the intuitive way of thinking is easier."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 11,
            rowCount: 8,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "3. When a liability goes down? This means that a cash must have flowed out of the company to pay for a debt (such as suppliers who you pay in 3 months\u2019 time). So when liabilities decrease, we recognise a decrease in cash. Hence, when these liabilities increase, we can consider this as a source of cash (delayed payment means extra cash you have today). These changes can be calculated by [Liability (in previous period) \u2013 Liability (in current period)] * (-1). We use the negative multiple because the relationship between liabilities and cash is the opposite than cash and assets."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*(I10-H10)",
        references: [ {
            rangeSelector: {
                rowIdx: 9,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the cash they have yet to receive from customers. So an increase over a period means we have revenues recognised where the customers haven\u2019t paid in cash. A decrease over the period means the more outstanding cash has been received. So from 1,537 in 2020 to 1,489 in 2021, and hence using the formula we see that (-1)*(1,489 \u2013 1,537) = 48. This makes intuitive sense because the Accounts Receivables have decreased meaning they have utilised a source of future cash. Therefore the 48 is added back to the cash flow statement."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 17,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*(I11-H11)",
        references: [ {
            rangeSelector: {
                rowIdx: 10,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the raw materials, semi-finished and finished products that are waiting to be sold. When this increases over a period it means we have bought more of these than has been used when products are sold and vice versa. In 2020 there was 1,284 and this increased to 1,398 in 2021. So we have an increase in 114 which is reflected as a decrease on the cash flow statement."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*(I12-H12)",
        references: [ {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In this example the change is 0 but the intuition can still be explained. So when we increase prepaid expenses we have paid out in cash over the period, whereas when these decrease (or we use them) are not paying for these expenses with cash and so this saves (increases) cash."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I28-H28",
        references: [ {
            rangeSelector: {
                rowIdx: 27,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Again, recall that this is the value or cash promised to suppliers and external parties. So when this decreases over a period it means we have met these obligations and provided the cash. So when it increases, we are essentially borrowing the money and our cash increases. So in 2020 there was 2,573 and this increased to 2,710 in 2021. So accounts payable has increased by 137 which is reflected as an increase on the cash flow statement."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I30-H30",
        references: [ {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "These are the expenses we have recognised on the Income Statement but have not actually paid. Think of paying an electricity bill that is billed and recognised on the Income Statement but does not need to be paid until the following year (a year in arrears). Then when this increases, we are essentially borrowing from this company and are saving (increasing) cash today. Alternatively when this decreases over a period we are paying for expenses, so cash is being consumed (decreased). In 2020 there was 1,154 and this decreases to 1,014 in 2021. So we have a decrease by 140 which is reflected as decrease on the cash flow statement."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This line reflects the changes in atypical line items on the Balance Sheet. This may include one off cash payment for parts of operations. In most cases it provides us with a \u2018catch-all\u2019 line to put adhoc cash flow changes that may be a \u2018one-off\u2019 and would not reoccur between periods."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 22,
        colIdx: 13,
        number: -260.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 22,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 10,
            rowCount: 8,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we look at the Investing Operations of the Business. This looks at the changes of long-term assets and investments. When a company makes a purchase of an asset like a vehicle or a piece of machinery or even when it invests in another small business this will require us to take cash from our accounts (off the Balance Sheet) and give it to the external party. In the opposite case, when we sell machinery or property, we receive cash (onto the Balance Sheet). This section keeps track of all the flows of cash related to the long-term assets. Whereas operating cash flows concern themselves with the assets needed for daily operation this focuses much more on the cash flows for the long-term foundations of the business."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 26,
        colIdx: 13,
        number: -553.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 26,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the spending on long term assets (PP&E). Simply when Kellogg purchases new machines and factories it spends its cash (outflows). In this example the Net PP&E (PP&E including its depreciation that erodes its value) has increased by 114 and adding back our depreciation (excluding amortisation) of 439 we get the spending on new PP&E. Therefore, 2021 capital expenditure was 553."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 27,
        colIdx: 13,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 26,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the opposite of the previous comment. When Kellogg sells machines, it will receive some cash for it. Even if Kellogg took its old machines to a scrapyard to receive cash for the metal it would receive a small amount. This amount is the sale proceeds received. This differs from Gain/Loss of PP&E sales because it reflects the actual cash, we received not the value gained or lost on selling the machinery above or below its recorded Balance Sheet value at the time of sale. In this example there was no asset sales."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 28,
        colIdx: 13,
        number: 11.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the total inflows and outflows of investments in financial assets which are marketable securities or any form on investment such as stocks and bonds. This will be reflected in the changes of other assets (alongside other more complex changes). Often these securities are explicitly shown on the balance sheet but in this case it is not. In this period this is an increase (or net sale) of 11."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*(I19-H19)",
        references: [ {
            rangeSelector: {
                rowIdx: 18,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the asset value of a financial lease. When these increase it means we are paying for an asset which is an outflow of cash. The complexity of leases can be discussed in a more advanced version of this course. In this example there has not been any additions."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 30,
        colIdx: 13,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 30,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the cash we have paid to acquire another business. In this example there has been no acquisitions."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Much like the line we use in the Operating Activities, this line reflects the changes in atypical line items on the Balance Sheet. However, this will focus on the atypical cash payments in relation to investing. For example, the cash payment for certain fees that are not included in PP&E. As before, in most cases it provides us with a \u2018catch-all\u2019 line to put adhoc cash flow changes that may be a \u2018one-off\u2019."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 31,
        colIdx: 13,
        number: 14.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The summation is often a negative number to reflect that Kellogg is spending cash to invest in new opportunities like machinery or land for a new factory. We may be pleased with Kellogg investing in new factories and warehouses because it could mean the company is doing well and needs to produce more to meet the demand of all its customers. Of course, this may not always be good if the investments are in poor projects or if there is not enough financing to support the investments but that is where the analyst must make a judgement."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 34,
            colIdx: 10,
            rowCount: 6,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The next section is the Financing Activities. This are the activities associated with the sources of financing. For example, changes in long-term liabilities and equity (the provisions of capital). When we receive large equity and debt investments the cash inflows are recorded here. Alternatively, when we pay cash to these providers of capital there would be an outflow."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=(I27-H27)+(I36-H36)",
        references: [ {
            rangeSelector: {
                rowIdx: 26,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 35,
                colIdx: 7,
                rowCount: 1,
                columnCount: 2
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the total of debt we have raised (increasing cash) and debt we have repaid (decreasing cash). These changes are reflected in the changes in long-term debt (and the current portion of it). In 2020 total long-term debt was 7,373 and in 2021 6,974. Meaning long-term debt has decreased (cash reduced) by 399."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 36,
        colIdx: 13,
        number: -177.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 36,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the increase in additional paid in capital (the additional shares given out this year) less the stock we have repurchased. The increase in stock is driven by additional paid in capital and the reductions are increases (greater negative) in treasury stock which is the stock that is bought back. The total common stock issued and repurchased in this period is 177 repurchased. This does not match exactly with the balance sheet as this reflects other types of equity (that we do not cover in this course)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 37,
        colIdx: 13,
        number: -788.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the cash returned to shareholders. Often a company will issue a dividend meaning each unit of common stock is entitled to $x of shares. When this is physically paid (often dividends can be announced but then paid semi-annually in the following year), we recognise the cash payment here. This dividend has been reflected in the retained earnings section of equity. In this period the cash payment of dividends was 788."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Much like the line we use in the Operating and Investing Activities, this line reflects the changes in atypical line items on the Balance Sheet. However, this will focus on the atypical cash payments in relation to financing. For example, the cash payment of certain lease arrangements, or for fees relating to repayment of debt. As before, in most cases it provides us with a \u2018catch-all\u2019 line to put adhoc cash flow changes that may be a \u2018one-off\u2019."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 38,
        colIdx: 13,
        number: 58.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 38,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When this summation is significantly positive it means there has been a major inflow in capital from investors. This is very common in young companies that receive multiple rounds of investments and raise debt. When this number is negative it means we repay more of the claims. This means we have returned cash to investors. It is common to see a company with a large decrease in investing cash flows offset with large increases of financing activity to pay for it. This is because a company may raise additional capital (cash) through debt or equity, to fund a new growth idea. For example, if Kellogg raised a new piece of debt to fund its purchase of land in a new country to begin building a factory there. This would fit the inflow of financing and outflow of investing perfectly."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 41,
        colIdx: 13,
        number: -16.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 41,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the gains or losses where cash is kept in different bank accounts across the world and in different currencies. When we convert this back to USD, we may experience some gains or losses on these exchanges hence this will impact our cash position. It does not have a classification so is often left at the bottom."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 42,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(N24,N33,N40,N42)",
        references: [ {
            rangeSelector: {
                rowIdx: 23,
                colIdx: 13,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 32,
                colIdx: 13,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 39,
                colIdx: 13,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 41,
                colIdx: 13,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This is the summation of net cash from operations, investing and financing activities adjusted for the impact of exchange rates. This reflects the change in cash in the period."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 43,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=H9",
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 7,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The Cash Flow Statement starts with the cash total from 2020 (or the end of the previous period and looks to show how the cash balance changes over the course of the year until the end of 2021. The most common periods are 1-year, 9-months, or 3 months, but in this case we are focusing on 1 year."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 44,
            colIdx: 13,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=N43+N44",
        references: [ {
            rangeSelector: {
                rowIdx: 42,
                colIdx: 13,
                rowCount: 2,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.TopBottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The Cash Flow Statement concludes with End Cash. This is the summation of the net cash flow and the beginning cash."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This number should flow onto the balance sheet to reflect the end period cash. In this case we see that the numbers for the end cash match the 2021 cash on the Balance Sheet, showing our cash flow statement correctly demonstrates the cash flows of the year."
    })) ] ];
})();
export {
    cashFlowStatementDescriptions
};
