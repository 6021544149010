// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var realLifeAnalogyDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The main reason why we use Enterprise Value to value a company is because it does not change when the capital structure changes. A great real-life analogy we can explain this to you with is the process of buying and selling a house with equity and debt. So let\u2019s say you are buying a house worth $300,000 and you are going to need 50% debt and will provide 50% of the equity (your own capital). The capital structure will look like as follows:"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we assume a house price of $300,000 and we input the 50% for the percentage of the house we will fund with equity."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 7,
        colIdx: 3,
        number: 300000.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 3,
        number: 0.5,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We use the formula =1-D9 to give us the percentage of the house we will fund with debt."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 9,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=1-D9",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value,
        references: [ {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can multiply our purchase value by our percentages to get the equity and debt contribution."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=D8*D9",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 8,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=D8*D10",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 7,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 9,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We show this more visually by creating a stacked column chart."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 13,
        colIdx: 3,
        formula: "=D12+D13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 1000,
        colIdx: 2,
        text: "House Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 1001,
        colIdx: 1,
        text: "Equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 1001,
        colIdx: 2,
        formula: "=D12",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 1002,
        colIdx: 1,
        text: "Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 1002,
        colIdx: 2,
        formula: "=D13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.CompanyValueCmd(new BankingReady_Data_ExcelEditorCommand.AddRealLifeAnalogyChart({
        name: "realLifeAnalogyChart",
        chartType: BankingReady_Foreign_Excel.ColumnStacked.value,
        rangeSelector: {
            rowIdx: 1000,
            colIdx: 1,
            rowCount: 3,
            columnCount: 2
        },
        seriesBy: BankingReady_Foreign_Excel.Rows.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "However, if we do not have as much equity, then we must take on extra debt. For example, let\u2019s assume that we are buying the same house, but we can only provide 20% as a deposit. This means the equity portion we own would be the \xa360,000 we invest. The remaining portion of 80% or the difference between the total value of the house and what we paid is funded by taking on \xa3240,000 in debt."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 3,
        number: 0.2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have bought this house for \xa3300,000 with 20% in equity and 80% in debt. Let us say in 5 years we sell the house for \xa3400,000 and we have not been able to pay off any of the debt within this period. The \xa3400,000 in the new Enterprise Value of the house. Importantly, the value of our debt has not changed but the \xa3240,000 mortgage is now only 60% of the funding."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 7,
        colIdx: 5,
        number: 400000.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 11,
        colIdx: 5,
        formula: "=F8-F13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 12,
        colIdx: 5,
        formula: "=D13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 13,
        colIdx: 5,
        formula: "=F12+F13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 1001,
        colIdx: 2,
        formula: "=F12",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 1002,
        colIdx: 2,
        formula: "=F13",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 5,
            rowCount: 8,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Given that we owe this \xa3240,000 so when we sell this is paid to the bank first. Debt is paid first because it is less risky and often requires interest payments during the length of the loan. Once this is paid it leaves us with the remaining value. So, our new equity value is \xa3160,000. As the equity investor we gain the benefit of all the upside in the price increase in the property. We do however take on all of the risk because if the property value decreased and we sold then we may end with less money than what we started with."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Alternatively we may be in a situation where we can buy the house without taking on any mortgage and can provide all the value in equity. As a result our debt component will be 0% and equity will be 100%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 3,
        number: 1.0,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageNoDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now did you notice the key thing that didn\u2019t change. The amount the property was worth did not change regardless of how it was funded. This is exactly like a business because the enterprise value does not change. That is why we use this metric to value a business."
    })) ] ];
})();
export {
    realLifeAnalogyDescriptions
};
