// Generated by purs version 0.15.13
import * as BankingReady_AppM from "../BankingReady.AppM/index.js";
import * as BankingReady_Capability_ExcelEditor_Effect from "../BankingReady.Capability.ExcelEditor.Effect/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Control_Promise from "../Control.Promise/index.js";
import * as Data_Bounded from "../Data.Bounded/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unfoldable1 from "../Data.Unfoldable1/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_Store_Monad from "../Halogen.Store.Monad/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(BankingReady_AppM.bindAppM);
var getStore = /* #__PURE__ */ Halogen_Store_Monad.getStore(BankingReady_AppM.monadStoreAppM);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(BankingReady_AppM.monadAffAppM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff);
var for_ = /* #__PURE__ */ Data_Foldable.for_(Effect_Aff.applicativeAff)(Data_Foldable.foldableArray);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var enumFromTo = /* #__PURE__ */ Data_Enum.enumFromTo(BankingReady_Data_Route.enumIntroToFinanceRoute)(Data_Unfoldable1.unfoldable1Array);
var bottom = /* #__PURE__ */ Data_Bounded.bottom(BankingReady_Data_Route.boundedIntroToFinanceRoute);
var enumFromTo1 = /* #__PURE__ */ Data_Enum.enumFromTo(BankingReady_Data_Route.enumAccountingRoute)(Data_Unfoldable1.unfoldable1Array);
var bottom1 = /* #__PURE__ */ Data_Bounded.bottom(BankingReady_Data_Route.boundedAccountingRoute);
var enumFromTo2 = /* #__PURE__ */ Data_Enum.enumFromTo(BankingReady_Data_Route.enumCompanyValueRoute)(Data_Unfoldable1.unfoldable1Array);
var bottom2 = /* #__PURE__ */ Data_Bounded.bottom(BankingReady_Data_Route.boundedCompanyValueRoute);
var enumFromTo3 = /* #__PURE__ */ Data_Enum.enumFromTo(BankingReady_Data_Route.enumDCFValuationRoute)(Data_Unfoldable1.unfoldable1Array);
var bottom3 = /* #__PURE__ */ Data_Bounded.bottom(BankingReady_Data_Route.boundedDCFValuationRoute);
var lessThan = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordIntroToFinanceRoute);
var lessThan1 = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordAccountingRoute);
var lessThan2 = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordCompanyValueRoute);
var lessThan3 = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordDCFValuationRoute);
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM);
var postExcelEditorCommandArray = function (dict) {
    return dict.postExcelEditorCommandArray;
};
var manageExcelEditorAppM = {
    postExcelEditorCommandArray: function (route) {
        return function (cmdArray) {
            return bind(getStore)(function (store) {
                return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                    return Control_Promise.fromAff(discard(for_(cmdArray)(function (cmd) {
                        return liftEffect(BankingReady_Capability_ExcelEditor_Effect.postExcelEditorCommandEffect(context)(store)(route)(cmd));
                    }))(function () {
                        return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.activateWorksheetEffect(context)(route)))(function () {
                            return BankingReady_Foreign_Excel.sync(context);
                        });
                    }));
                }));
            });
        };
    },
    cleanExcelEditorCommandArray: function (route) {
        return function (cmdArray) {
            return bind(getStore)(function (store) {
                return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                    return Control_Promise.fromAff(discard(for_(cmdArray)(function (cmd) {
                        return liftEffect(BankingReady_Capability_ExcelEditor_Effect.cleanExcelEditorCommandEffect(context)(store)(route)(cmd));
                    }))(function () {
                        return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.activateWorksheetEffect(context)(route)))(function () {
                            return BankingReady_Foreign_Excel.sync(context);
                        });
                    }));
                }));
            });
        };
    },
    cleanAndPostExcelEditorCommandArray: function (route) {
        return function (prevCmdArray) {
            return function (nextCmdArray) {
                return bind(getStore)(function (store) {
                    return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                        return Control_Promise.fromAff(discard(for_(prevCmdArray)(function (prevCmd) {
                            return liftEffect(BankingReady_Capability_ExcelEditor_Effect.cleanExcelEditorCommandEffect(context)(store)(route)(prevCmd));
                        }))(function () {
                            return discard(for_(nextCmdArray)(function (nextCmd) {
                                return liftEffect(BankingReady_Capability_ExcelEditor_Effect.postExcelEditorCommandEffect(context)(store)(route)(nextCmd));
                            }))(function () {
                                return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.activateWorksheetEffect(context)(route)))(function () {
                                    return BankingReady_Foreign_Excel.sync(context);
                                });
                            });
                        }));
                    }));
                });
            };
        };
    },
    createWorksheet: function (route) {
        return bind(getStore)(function (store) {
            return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                return Control_Promise.fromAff(discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.addWorksheetEffect(context)(route)))(function () {
                    return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.postWorksheetCommandsEffect(context)(store)(route)))(function () {
                        return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.activateWorksheetEffect(context)(route)))(function () {
                            return BankingReady_Foreign_Excel.sync(context);
                        });
                    });
                }));
            }));
        });
    },
    createWorksheetsBeforeAndIncluding: function (lastRoute) {
        var routesToCreate = (function () {
            if (lastRoute instanceof BankingReady_Data_Route.IntroToFinance) {
                return map(BankingReady_Data_Route.IntroToFinance.create)(enumFromTo(bottom)(lastRoute.value0));
            };
            if (lastRoute instanceof BankingReady_Data_Route.Accounting) {
                return map(BankingReady_Data_Route.Accounting.create)(enumFromTo1(bottom1)(lastRoute.value0));
            };
            if (lastRoute instanceof BankingReady_Data_Route.CompanyValue) {
                return map(BankingReady_Data_Route.CompanyValue.create)(enumFromTo2(bottom2)(lastRoute.value0));
            };
            if (lastRoute instanceof BankingReady_Data_Route.DCFValuation) {
                return map(BankingReady_Data_Route.DCFValuation.create)(enumFromTo3(bottom3)(lastRoute.value0));
            };
            return [  ];
        })();
        return bind(getStore)(function (store) {
            return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                return Control_Promise.fromAff(discard(for_(routesToCreate)(function (route) {
                    return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.addWorksheetEffect(context)(route)))(function () {
                        return liftEffect(BankingReady_Capability_ExcelEditor_Effect.postWorksheetCommandsEffect(context)(store)(route));
                    });
                }))(function () {
                    return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.activateWorksheetEffect(context)(lastRoute)))(function () {
                        return BankingReady_Foreign_Excel.sync(context);
                    });
                }));
            }));
        });
    },
    deleteWorksheetsBeforeAndIncluding: function (lastRoute) {
        var routesToDelete = (function () {
            if (lastRoute instanceof BankingReady_Data_Route.IntroToFinance) {
                return map(BankingReady_Data_Route.IntroToFinance.create)(enumFromTo(bottom)(lastRoute.value0));
            };
            if (lastRoute instanceof BankingReady_Data_Route.Accounting) {
                return map(BankingReady_Data_Route.Accounting.create)(enumFromTo1(bottom1)(lastRoute.value0));
            };
            if (lastRoute instanceof BankingReady_Data_Route.CompanyValue) {
                return map(BankingReady_Data_Route.CompanyValue.create)(enumFromTo2(bottom2)(lastRoute.value0));
            };
            if (lastRoute instanceof BankingReady_Data_Route.DCFValuation) {
                return map(BankingReady_Data_Route.DCFValuation.create)(enumFromTo3(bottom3)(lastRoute.value0));
            };
            return [  ];
        })();
        return liftAff(BankingReady_Foreign_Excel.run(function (context) {
            return Control_Promise.fromAff(discard(for_(routesToDelete)(function (route) {
                return liftEffect(BankingReady_Capability_ExcelEditor_Effect.deleteWorksheetEffect(context)(route));
            }))(function () {
                return BankingReady_Foreign_Excel.sync(context);
            }));
        }));
    },
    deleteWorksheetsAfterAndCreateCurrent: function (prevRoute) {
        return function (currentRoute) {
            var routesToDelete = (function () {
                if (prevRoute instanceof BankingReady_Data_Route.IntroToFinance) {
                    if (currentRoute instanceof BankingReady_Data_Route.IntroToFinance) {
                        var $49 = lessThan(prevRoute.value0)(currentRoute.value0);
                        if ($49) {
                            return [  ];
                        };
                        return map(BankingReady_Data_Route.IntroToFinance.create)(enumFromTo(currentRoute.value0)(prevRoute.value0));
                    };
                    return [  ];
                };
                if (prevRoute instanceof BankingReady_Data_Route.Accounting) {
                    if (currentRoute instanceof BankingReady_Data_Route.Accounting) {
                        var $53 = lessThan1(prevRoute.value0)(currentRoute.value0);
                        if ($53) {
                            return [  ];
                        };
                        return map(BankingReady_Data_Route.Accounting.create)(enumFromTo1(currentRoute.value0)(prevRoute.value0));
                    };
                    return [  ];
                };
                if (prevRoute instanceof BankingReady_Data_Route.CompanyValue) {
                    if (currentRoute instanceof BankingReady_Data_Route.CompanyValue) {
                        var $57 = lessThan2(prevRoute.value0)(currentRoute.value0);
                        if ($57) {
                            return [  ];
                        };
                        return map(BankingReady_Data_Route.CompanyValue.create)(enumFromTo2(currentRoute.value0)(prevRoute.value0));
                    };
                    return [  ];
                };
                if (prevRoute instanceof BankingReady_Data_Route.DCFValuation) {
                    if (currentRoute instanceof BankingReady_Data_Route.DCFValuation) {
                        var $61 = lessThan3(prevRoute.value0)(currentRoute.value0);
                        if ($61) {
                            return [  ];
                        };
                        return map(BankingReady_Data_Route.DCFValuation.create)(enumFromTo3(currentRoute.value0)(prevRoute.value0));
                    };
                    return [  ];
                };
                return [  ];
            })();
            return bind(getStore)(function (store) {
                return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                    return Control_Promise.fromAff(discard(for_(routesToDelete)(function (route) {
                        return liftEffect(BankingReady_Capability_ExcelEditor_Effect.deleteWorksheetEffect(context)(route));
                    }))(function () {
                        return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.addWorksheetEffect(context)(currentRoute)))(function () {
                            return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.postWorksheetCommandsEffect(context)(store)(currentRoute)))(function () {
                                return discard(liftEffect(BankingReady_Capability_ExcelEditor_Effect.activateWorksheetEffect(context)(currentRoute)))(function () {
                                    return BankingReady_Foreign_Excel.sync(context);
                                });
                            });
                        });
                    }));
                }));
            });
        };
    },
    Monad0: function () {
        return BankingReady_AppM.monadAppM;
    }
};
var deleteWorksheetsBeforeAndIncluding = function (dict) {
    return dict.deleteWorksheetsBeforeAndIncluding;
};
var deleteWorksheetsAfterAndCreateCurrent = function (dict) {
    return dict.deleteWorksheetsAfterAndCreateCurrent;
};
var createWorksheetsBeforeAndIncluding = function (dict) {
    return dict.createWorksheetsBeforeAndIncluding;
};
var createWorksheet = function (dict) {
    return dict.createWorksheet;
};
var cleanExcelEditorCommandArray = function (dict) {
    return dict.cleanExcelEditorCommandArray;
};
var cleanAndPostExcelEditorCommandArray = function (dict) {
    return dict.cleanAndPostExcelEditorCommandArray;
};
var manageExcelEditorHalogenM = function (dictManageExcelEditor) {
    var lift1 = lift(dictManageExcelEditor.Monad0());
    var postExcelEditorCommandArray1 = postExcelEditorCommandArray(dictManageExcelEditor);
    var cleanExcelEditorCommandArray1 = cleanExcelEditorCommandArray(dictManageExcelEditor);
    var cleanAndPostExcelEditorCommandArray1 = cleanAndPostExcelEditorCommandArray(dictManageExcelEditor);
    var createWorksheet1 = createWorksheet(dictManageExcelEditor);
    var createWorksheetsBeforeAndIncluding1 = createWorksheetsBeforeAndIncluding(dictManageExcelEditor);
    var deleteWorksheetsBeforeAndIncluding1 = deleteWorksheetsBeforeAndIncluding(dictManageExcelEditor);
    var deleteWorksheetsAfterAndCreateCurrent1 = deleteWorksheetsAfterAndCreateCurrent(dictManageExcelEditor);
    return {
        postExcelEditorCommandArray: function (r) {
            return function (d) {
                return lift1(postExcelEditorCommandArray1(r)(d));
            };
        },
        cleanExcelEditorCommandArray: function (r) {
            return function (d) {
                return lift1(cleanExcelEditorCommandArray1(r)(d));
            };
        },
        cleanAndPostExcelEditorCommandArray: function (r) {
            return function (p) {
                return function (n) {
                    return lift1(cleanAndPostExcelEditorCommandArray1(r)(p)(n));
                };
            };
        },
        createWorksheet: function (r) {
            return lift1(createWorksheet1(r));
        },
        createWorksheetsBeforeAndIncluding: function (r) {
            return lift1(createWorksheetsBeforeAndIncluding1(r));
        },
        deleteWorksheetsBeforeAndIncluding: function (r) {
            return lift1(deleteWorksheetsBeforeAndIncluding1(r));
        },
        deleteWorksheetsAfterAndCreateCurrent: function (p) {
            return function (c) {
                return lift1(deleteWorksheetsAfterAndCreateCurrent1(p)(c));
            };
        },
        Monad0: function () {
            return Halogen_Query_HalogenM.monadHalogenM;
        }
    };
};
export {
    cleanAndPostExcelEditorCommandArray,
    cleanExcelEditorCommandArray,
    createWorksheet,
    createWorksheetsBeforeAndIncluding,
    deleteWorksheetsAfterAndCreateCurrent,
    deleteWorksheetsBeforeAndIncluding,
    postExcelEditorCommandArray,
    manageExcelEditorHalogenM,
    manageExcelEditorAppM
};
