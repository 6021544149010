// Generated by purs version 0.15.13
import * as BankingReady_Capability_ExcelEditor from "../BankingReady.Capability.ExcelEditor/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Utility_HTML from "../BankingReady.Utility.HTML/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var NotStarted = /* #__PURE__ */ (function () {
    function NotStarted(value0) {
        this.value0 = value0;
    };
    NotStarted.create = function (value0) {
        return new NotStarted(value0);
    };
    return NotStarted;
})();
var InProgress = /* #__PURE__ */ (function () {
    function InProgress(value0) {
        this.value0 = value0;
    };
    InProgress.create = function (value0) {
        return new InProgress(value0);
    };
    return InProgress;
})();
var Completed = /* #__PURE__ */ (function () {
    function Completed(value0) {
        this.value0 = value0;
    };
    Completed.create = function (value0) {
        return new Completed(value0);
    };
    return Completed;
})();
var PreviousLesson = /* #__PURE__ */ (function () {
    function PreviousLesson(value0) {
        this.value0 = value0;
    };
    PreviousLesson.create = function (value0) {
        return new PreviousLesson(value0);
    };
    return PreviousLesson;
})();
var NextLesson = /* #__PURE__ */ (function () {
    function NextLesson(value0) {
        this.value0 = value0;
    };
    NextLesson.create = function (value0) {
        return new NextLesson(value0);
    };
    return NextLesson;
})();
var Next = /* #__PURE__ */ (function () {
    function Next() {

    };
    Next.value = new Next();
    return Next;
})();
var Back = /* #__PURE__ */ (function () {
    function Back() {

    };
    Back.value = new Back();
    return Back;
})();
var component = function (dictMonadAff) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()));
    return function (dictManageExcelEditor) {
        var manageExcelEditorHalogenM = BankingReady_Capability_ExcelEditor.manageExcelEditorHalogenM(dictManageExcelEditor);
        var postExcelEditorCommandArray = BankingReady_Capability_ExcelEditor.postExcelEditorCommandArray(manageExcelEditorHalogenM);
        var cleanAndPostExcelEditorCommandArray = BankingReady_Capability_ExcelEditor.cleanAndPostExcelEditorCommandArray(manageExcelEditorHalogenM);
        var cleanExcelEditorCommandArray = BankingReady_Capability_ExcelEditor.cleanExcelEditorCommandArray(manageExcelEditorHalogenM);
        var render = function (state) {
            var backButton = Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a          my-3 flex h-12 w-32 cursor-pointer items-center justify-center\x0a          rounded border border-gray-400 text-base font-light text-neutral-700\x0a          shadow-sm hover:bg-gray-50 active:shadow\x0a          "), Halogen_HTML_Events.onClick(function (v) {
                return Back.value;
            }) ])([ Halogen_HTML_Core.text("Back") ]);
            return Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex min-h-screen flex-col items-center justify-center bg-white text-zinc-900") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("h-9 min-w-full pl-6 pt-4 text-sm") ])([ Halogen_HTML_Elements.a([ BankingReady_Utility_HTML.css("cursor-pointer hover:underline hover:underline-offset-2"), BankingReady_Utility_HTML.safeHref(BankingReady_Data_Route.Home.value) ])([ Halogen_HTML_Core.text("Home") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex grow select-none flex-col items-center justify-center " + (function () {
                if (state instanceof NotStarted) {
                    return "";
                };
                if (state instanceof InProgress) {
                    return "pt-[72px]";
                };
                if (state instanceof Completed) {
                    return "pt-[72px]";
                };
                throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 172, column 15 - line 175, column 43): " + [ state.constructor.name ]);
            })()) ])(append1([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a                    my-3 flex h-12 w-32 cursor-pointer items-center justify-center\x0a                    rounded bg-blue-600 text-base text-white shadow hover:bg-blue-700\x0a                    active:shadow-md\x0a                    "), Halogen_HTML_Events.onClick(function (v) {
                return Next.value;
            }) ])([ Halogen_HTML_Core.text((function () {
                if (state instanceof NotStarted) {
                    return "Start Lesson";
                };
                if (state instanceof InProgress) {
                    var $42 = Data_Array.length(state.value0.upcomingDescList) !== 0;
                    if ($42) {
                        return "Next";
                    };
                    return "Finish Lesson";
                };
                if (state instanceof Completed) {
                    return "Next Lesson";
                };
                throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 186, column 29 - line 191, column 49): " + [ state.constructor.name ]);
            })()) ]) ])((function () {
                if (state instanceof NotStarted) {
                    return [  ];
                };
                if (state instanceof InProgress) {
                    return [ backButton ];
                };
                if (state instanceof Completed) {
                    return [ backButton ];
                };
                throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 193, column 18 - line 196, column 44): " + [ state.constructor.name ]);
            })())), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("h-9 px-8 pb-4 text-center text-sm") ])([ Halogen_HTML_Core.text((function () {
                if (state instanceof NotStarted) {
                    return "0%";
                };
                if (state instanceof InProgress) {
                    return show(Data_Int.floor((Data_Int.toNumber(1 + Data_Array.length(state.value0.previousDescList) | 0) / Data_Int.toNumber((1 + Data_Array.length(state.value0.previousDescList) | 0) + Data_Array.length(state.value0.upcomingDescList) | 0)) * 100.0)) + "%";
                };
                if (state instanceof Completed) {
                    return "100%";
                };
                throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 202, column 23 - line 213, column 36): " + [ state.constructor.name ]);
            })()) ]) ]);
        };
        var initialState = function (v) {
            return new NotStarted({
                route: v.route,
                upcomingDescList: v.upcomingDescList
            });
        };
        var handleAction = function (v) {
            if (v instanceof Next) {
                return bind(get)(function (state) {
                    if (state instanceof NotStarted) {
                        var v1 = Data_Array.uncons(state.value0.upcomingDescList);
                        if (v1 instanceof Data_Maybe.Just) {
                            return discard(postExcelEditorCommandArray(state.value0.route)(v1.value0.head))(function () {
                                return modify_(function (v2) {
                                    return new InProgress({
                                        route: state.value0.route,
                                        currentDesc: v1.value0.head,
                                        previousDescList: [ [  ] ],
                                        upcomingDescList: v1.value0.tail
                                    });
                                });
                            });
                        };
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return liftEffect(Effect_Console.log("upcomingDescList in NotStarted state shouldn't be empty.'"));
                        };
                        throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 76, column 11 - line 88, column 110): " + [ v1.constructor.name ]);
                    };
                    if (state instanceof InProgress) {
                        var v1 = Data_Array.uncons(state.value0.upcomingDescList);
                        if (v1 instanceof Data_Maybe.Just) {
                            return discard(cleanAndPostExcelEditorCommandArray(state.value0.route)(state.value0.currentDesc)(v1.value0.head))(function () {
                                return modify_(function (v2) {
                                    return new InProgress({
                                        route: state.value0.route,
                                        currentDesc: v1.value0.head,
                                        previousDescList: Data_Array.cons(state.value0.currentDesc)(state.value0.previousDescList),
                                        upcomingDescList: v1.value0.tail
                                    });
                                });
                            });
                        };
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return discard(cleanExcelEditorCommandArray(state.value0.route)(state.value0.currentDesc))(function () {
                                return modify_(function (v2) {
                                    return new Completed({
                                        route: state.value0.route,
                                        previousDescList: Data_Array.cons(state.value0.currentDesc)(state.value0.previousDescList)
                                    });
                                });
                            });
                        };
                        throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 91, column 11 - line 109, column 80): " + [ v1.constructor.name ]);
                    };
                    if (state instanceof Completed) {
                        return Halogen_Query_HalogenM.raise(new NextLesson({
                            currentRoute: state.value0.route
                        }));
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 74, column 7 - line 112, column 55): " + [ state.constructor.name ]);
                });
            };
            if (v instanceof Back) {
                return bind(get)(function (state) {
                    if (state instanceof NotStarted) {
                        return Halogen_Query_HalogenM.raise(new PreviousLesson({
                            currentRoute: state.value0.route
                        }));
                    };
                    if (state instanceof InProgress) {
                        var v1 = Data_Array.uncons(state.value0.previousDescList);
                        if (v1 instanceof Data_Maybe.Just) {
                            return discard(cleanAndPostExcelEditorCommandArray(state.value0.route)(state.value0.currentDesc)(v1.value0.head))(function () {
                                return modify_(function (v2) {
                                    return new InProgress({
                                        route: state.value0.route,
                                        currentDesc: v1.value0.head,
                                        previousDescList: v1.value0.tail,
                                        upcomingDescList: Data_Array.cons(state.value0.currentDesc)(state.value0.upcomingDescList)
                                    });
                                });
                            });
                        };
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return discard(cleanExcelEditorCommandArray(state.value0.route)(state.value0.currentDesc))(function () {
                                return modify_(function (v2) {
                                    return new NotStarted({
                                        route: state.value0.route,
                                        upcomingDescList: Data_Array.cons(state.value0.currentDesc)(state.value0.upcomingDescList)
                                    });
                                });
                            });
                        };
                        throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 121, column 11 - line 139, column 80): " + [ v1.constructor.name ]);
                    };
                    if (state instanceof Completed) {
                        var v1 = Data_Array.uncons(state.value0.previousDescList);
                        if (v1 instanceof Data_Maybe.Just) {
                            return discard(postExcelEditorCommandArray(state.value0.route)(v1.value0.head))(function () {
                                return modify_(function (v2) {
                                    return new InProgress({
                                        route: state.value0.route,
                                        currentDesc: v1.value0.head,
                                        previousDescList: v1.value0.tail,
                                        upcomingDescList: [  ]
                                    });
                                });
                            });
                        };
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return liftEffect(Effect_Console.log("previousText should not be empty when in state Completed"));
                        };
                        throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 142, column 11 - line 154, column 109): " + [ v1.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 116, column 7 - line 154, column 109): " + [ state.constructor.name ]);
                });
            };
            throw new Error("Failed pattern match at BankingReady.Page.Lesson (line 71, column 18 - line 154, column 109): " + [ v.constructor.name ]);
        };
        return Halogen_Component.mkComponent({
            initialState: initialState,
            render: render,
            "eval": Halogen_Component.mkEval({
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                initialize: Halogen_Component.defaultEval.initialize,
                finalize: Halogen_Component.defaultEval.finalize,
                handleAction: handleAction
            })
        });
    };
};
export {
    PreviousLesson,
    NextLesson,
    NotStarted,
    InProgress,
    Completed,
    Next,
    Back,
    component
};
