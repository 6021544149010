// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as Data_Show from "../Data.Show/index.js";
var show = /* #__PURE__ */ Data_Show.show(BankingReady_Foreign_Excel.showGeometricShapeType);
var addGeometricShape = function (shapeCollection) {
    return function (geoShapeType) {
        return $foreign.addGeometricShapeImpl(shapeCollection)(show(geoShapeType));
    };
};
export {
    addGeometricShapeImpl
} from "./foreign.js";
export {
    addGeometricShape
};
