// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
var dcfValuationOutroDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 4,
        colIdx: 2,
        text: "First we would like to say thank you for participating in this course and we hope you now have a greater understanding of the topics for your banking spring interviews."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 2,
        text: "We sincerely believe that this course can help those grow from a beginner into a technicals pro, and if you share our enthusiasm we would appreciate you to recommend "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "the course to others."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 9,
        colIdx: 2,
        text: "We would appreciate it everyone would complete the following survey linked below:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 3,
        text: "[TODO: Insert Survey Link]"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 2,
        text: "Finally, the Banking Ready send thanks and congratulations for completing this course. "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 2,
        text: "Be sure to look out for any future developments we have, follow us on social media and please get in touch if you would like to discuss anything!"
    })) ] ];
})();
export {
    dcfValuationOutroDescriptions
};
