// Generated by purs version 0.15.13
import * as BankingReady_Foreign_Excel_Range from "../BankingReady.Foreign.Excel.Range/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Show from "../Data.Show/index.js";
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJsonArray(Data_Argonaut_Encode_Class.encodeJsonJString)))(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "values";
    }
})())());
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonNil);
var encodeJson1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons({
    reflectSymbol: function () {
        return "numberFormat";
    }
})())());
var formatIsSymbol = {
    reflectSymbol: function () {
        return "format";
    }
};
var encodeJson2 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons({
    reflectSymbol: function () {
        return "horizontalAlignment";
    }
})())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(formatIsSymbol)())());
var gEncodeJsonCons1 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJBoolean)(Data_Argonaut_Encode_Class.gEncodeJsonNil);
var fontIsSymbol = {
    reflectSymbol: function () {
        return "font";
    }
};
var encodeJson3 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "italic";
    }
})())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(fontIsSymbol)())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(formatIsSymbol)())());
var gEncodeJsonCons2 = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons({
    reflectSymbol: function () {
        return "color";
    }
})())())(Data_Argonaut_Encode_Class.gEncodeJsonNil);
var encodeJson4 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons2(fontIsSymbol)())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(formatIsSymbol)())());
var encodeJson5 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons1({
    reflectSymbol: function () {
        return "bold";
    }
})())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(fontIsSymbol)())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(formatIsSymbol)())());
var encodeJson6 = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons2({
    reflectSymbol: function () {
        return "fill";
    }
})())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)(formatIsSymbol)())());
var StandardNumberFormat = /* #__PURE__ */ (function () {
    function StandardNumberFormat() {

    };
    StandardNumberFormat.value = new StandardNumberFormat();
    return StandardNumberFormat;
})();
var NoDecimalsNumberFormat = /* #__PURE__ */ (function () {
    function NoDecimalsNumberFormat() {

    };
    NoDecimalsNumberFormat.value = new NoDecimalsNumberFormat();
    return NoDecimalsNumberFormat;
})();
var TwoDecimalsNumberFormat = /* #__PURE__ */ (function () {
    function TwoDecimalsNumberFormat() {

    };
    TwoDecimalsNumberFormat.value = new TwoDecimalsNumberFormat();
    return TwoDecimalsNumberFormat;
})();
var MultipleOneDecimal = /* #__PURE__ */ (function () {
    function MultipleOneDecimal() {

    };
    MultipleOneDecimal.value = new MultipleOneDecimal();
    return MultipleOneDecimal;
})();
var PercentageNoDecimal = /* #__PURE__ */ (function () {
    function PercentageNoDecimal() {

    };
    PercentageNoDecimal.value = new PercentageNoDecimal();
    return PercentageNoDecimal;
})();
var PercentageOneDecimal = /* #__PURE__ */ (function () {
    function PercentageOneDecimal() {

    };
    PercentageOneDecimal.value = new PercentageOneDecimal();
    return PercentageOneDecimal;
})();
var DateYearOnly = /* #__PURE__ */ (function () {
    function DateYearOnly() {

    };
    DateYearOnly.value = new DateYearOnly();
    return DateYearOnly;
})();
var showNumberFormat = {
    show: function (v) {
        if (v instanceof StandardNumberFormat) {
            return "_(* #,##0_);_(* (#,##0);_(* \"-\"_);_(@_)";
        };
        if (v instanceof NoDecimalsNumberFormat) {
            return "0";
        };
        if (v instanceof TwoDecimalsNumberFormat) {
            return "0.00;(0.00)";
        };
        if (v instanceof MultipleOneDecimal) {
            return "0.0x;(0.0x)";
        };
        if (v instanceof PercentageNoDecimal) {
            return "0%;(0%)";
        };
        if (v instanceof PercentageOneDecimal) {
            return "0.0%;(0.0%)";
        };
        if (v instanceof DateYearOnly) {
            return "yyyy";
        };
        throw new Error("Failed pattern match at BankingReady.Utility.Excel.Cell (line 33, column 10 - line 40, column 27): " + [ v.constructor.name ]);
    }
};
var show = /* #__PURE__ */ Data_Show.show(showNumberFormat);
var setCellValue = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (value) {
                var updateData = encodeJson({
                    values: [ [ value ] ]
                });
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.set(cellRange)(updateData);
            };
        };
    };
};
var setCellNumberFormat = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (num_format) {
                var updateData = encodeJson1({
                    numberFormat: show(num_format)
                });
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.set(cellRange)(updateData);
            };
        };
    };
};
var setCellHorizontalAlignment = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (alignment) {
                var updateFormat = [ [ encodeJson2({
                    format: {
                        horizontalAlignment: alignment
                    }
                }) ] ];
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.setCellProperties(cellRange)(updateFormat);
            };
        };
    };
};
var setCellFontItalic = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (bool) {
                var updateFormat = [ [ encodeJson3({
                    format: {
                        font: {
                            italic: bool
                        }
                    }
                }) ] ];
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.setCellProperties(cellRange)(updateFormat);
            };
        };
    };
};
var setCellFontColor = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (colorCode) {
                var updateFormat = [ [ encodeJson4({
                    format: {
                        font: {
                            color: colorCode
                        }
                    }
                }) ] ];
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.setCellProperties(cellRange)(updateFormat);
            };
        };
    };
};
var setCellFontBold = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (bool) {
                var updateFormat = [ [ encodeJson5({
                    format: {
                        font: {
                            bold: bool
                        }
                    }
                }) ] ];
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.setCellProperties(cellRange)(updateFormat);
            };
        };
    };
};
var setCellFillColor = function (worksheet) {
    return function (rowIdx) {
        return function (colIdx) {
            return function (colorCode) {
                var updateFormat = [ [ encodeJson6({
                    format: {
                        fill: {
                            color: colorCode
                        }
                    }
                }) ] ];
                var cellRange = BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(rowIdx)(colIdx)(1)(1);
                return BankingReady_Foreign_Excel_Range.setCellProperties(cellRange)(updateFormat);
            };
        };
    };
};
var getRangeByRangeSelector = function (worksheet) {
    return function (v) {
        return BankingReady_Foreign_Excel_Worksheet.getRangeByIndexes(worksheet)(v.rowIdx)(v.colIdx)(v.rowCount)(v.columnCount);
    };
};
export {
    getRangeByRangeSelector,
    setCellValue,
    StandardNumberFormat,
    NoDecimalsNumberFormat,
    TwoDecimalsNumberFormat,
    MultipleOneDecimal,
    PercentageNoDecimal,
    PercentageOneDecimal,
    DateYearOnly,
    setCellNumberFormat,
    setCellHorizontalAlignment,
    setCellFillColor,
    setCellFontBold,
    setCellFontItalic,
    setCellFontColor,
    showNumberFormat
};
