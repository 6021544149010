// Generated by purs version 0.15.13
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Bifoldable from "../Data.Bifoldable/index.js";
import * as Data_Bitraversable from "../Data.Bitraversable/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Lens_Internal_Market from "../Data.Lens.Internal.Market/index.js";
import * as Data_Lens_Prism from "../Data.Lens.Prism/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var is = /* #__PURE__ */ Data_Lens_Prism.is(Data_HeytingAlgebra.heytingAlgebraBoolean);
var NotAsked = /* #__PURE__ */ (function () {
    function NotAsked() {

    };
    NotAsked.value = new NotAsked();
    return NotAsked;
})();
var Loading = /* #__PURE__ */ (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var Failure = /* #__PURE__ */ (function () {
    function Failure(value0) {
        this.value0 = value0;
    };
    Failure.create = function (value0) {
        return new Failure(value0);
    };
    return Failure;
})();
var Success = /* #__PURE__ */ (function () {
    function Success(value0) {
        this.value0 = value0;
    };
    Success.create = function (value0) {
        return new Success(value0);
    };
    return Success;
})();
var toMaybe = function (v) {
    if (v instanceof Success) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var showRemoteData = function (dictShow) {
    var show = Data_Show.show(dictShow);
    return function (dictShow1) {
        var show1 = Data_Show.show(dictShow1);
        return {
            show: function (v) {
                if (v instanceof NotAsked) {
                    return "RemoteData.NotAsked";
                };
                if (v instanceof Loading) {
                    return "RemoteData.Loading";
                };
                if (v instanceof Failure) {
                    return "RemoteData.Failure " + show(v.value0);
                };
                if (v instanceof Success) {
                    return "RemoteData.Success " + show1(v.value0);
                };
                throw new Error("Failed pattern match at Network.RemoteData (line 47, column 1 - line 51, column 61): " + [ v.constructor.name ]);
            }
        };
    };
};
var maybe = function (v) {
    return function (v1) {
        return function (v2) {
            if (v2 instanceof Success) {
                return v1(v2.value0);
            };
            return v;
        };
    };
};
var withDefault = function (default$prime) {
    return maybe(default$prime)(identity);
};
var genericRemoteData = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return NotAsked.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Loading.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Failure(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new Success(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Network.RemoteData (line 41, column 1 - line 41, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof NotAsked) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Loading) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Failure) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof Success) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at Network.RemoteData (line 41, column 1 - line 41, column 64): " + [ x.constructor.name ]);
    }
};
var functorRemoteData = {
    map: function (f) {
        return function (m) {
            if (m instanceof NotAsked) {
                return NotAsked.value;
            };
            if (m instanceof Loading) {
                return Loading.value;
            };
            if (m instanceof Failure) {
                return new Failure(m.value0);
            };
            if (m instanceof Success) {
                return new Success(f(m.value0));
            };
            throw new Error("Failed pattern match at Network.RemoteData (line 0, column 0 - line 0, column 0): " + [ m.constructor.name ]);
        };
    }
};
var fromMaybe = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return NotAsked.value;
    };
    if (v instanceof Data_Maybe.Just) {
        return new Success(v.value0);
    };
    throw new Error("Failed pattern match at Network.RemoteData (line 129, column 1 - line 129, column 51): " + [ v.constructor.name ]);
};
var fromEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Failure(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return new Success(v.value0);
    };
    throw new Error("Failed pattern match at Network.RemoteData (line 134, column 1 - line 134, column 55): " + [ v.constructor.name ]);
};
var foldableRemoteData = {
    foldMap: function (dictMonoid) {
        var mempty = Data_Monoid.mempty(dictMonoid);
        return function (v) {
            return function (v1) {
                if (v1 instanceof Success) {
                    return v(v1.value0);
                };
                if (v1 instanceof Failure) {
                    return mempty;
                };
                if (v1 instanceof NotAsked) {
                    return mempty;
                };
                if (v1 instanceof Loading) {
                    return mempty;
                };
                throw new Error("Failed pattern match at Network.RemoteData (line 91, column 1 - line 97, column 27): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    },
    foldr: function (f) {
        return Data_Foldable.foldrDefault(foldableRemoteData)(f);
    },
    foldl: function (f) {
        return Data_Foldable.foldlDefault(foldableRemoteData)(f);
    }
};
var traversableRemoteData = {
    traverse: function (dictApplicative) {
        var map = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        var pure = Control_Applicative.pure(dictApplicative);
        return function (v) {
            return function (v1) {
                if (v1 instanceof Success) {
                    return map(Success.create)(v(v1.value0));
                };
                if (v1 instanceof Failure) {
                    return pure(new Failure(v1.value0));
                };
                if (v1 instanceof NotAsked) {
                    return pure(NotAsked.value);
                };
                if (v1 instanceof Loading) {
                    return pure(Loading.value);
                };
                throw new Error("Failed pattern match at Network.RemoteData (line 99, column 1 - line 104, column 29): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    },
    sequence: function (dictApplicative) {
        return Data_Traversable.sequenceDefault(traversableRemoteData)(dictApplicative);
    },
    Functor0: function () {
        return functorRemoteData;
    },
    Foldable1: function () {
        return foldableRemoteData;
    }
};
var eqRemoteData = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (dictEq1) {
        var eq1 = Data_Eq.eq(dictEq1);
        return {
            eq: function (x) {
                return function (y) {
                    if (x instanceof NotAsked && y instanceof NotAsked) {
                        return true;
                    };
                    if (x instanceof Loading && y instanceof Loading) {
                        return true;
                    };
                    if (x instanceof Failure && y instanceof Failure) {
                        return eq(x.value0)(y.value0);
                    };
                    if (x instanceof Success && y instanceof Success) {
                        return eq1(x.value0)(y.value0);
                    };
                    return false;
                };
            }
        };
    };
};
var bifunctorRemoteData = {
    bimap: function (v) {
        return function (v1) {
            return function (v2) {
                if (v2 instanceof NotAsked) {
                    return NotAsked.value;
                };
                if (v2 instanceof Loading) {
                    return Loading.value;
                };
                if (v2 instanceof Failure) {
                    return new Failure(v(v2.value0));
                };
                if (v2 instanceof Success) {
                    return new Success(v1(v2.value0));
                };
                throw new Error("Failed pattern match at Network.RemoteData (line 54, column 1 - line 58, column 48): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    }
};
var bifoldableRemoteData = {
    bifoldMap: function (dictMonoid) {
        var mempty = Data_Monoid.mempty(dictMonoid);
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2 instanceof Success) {
                        return v1(v2.value0);
                    };
                    if (v2 instanceof Failure) {
                        return v(v2.value0);
                    };
                    if (v2 instanceof Loading) {
                        return mempty;
                    };
                    if (v2 instanceof NotAsked) {
                        return mempty;
                    };
                    throw new Error("Failed pattern match at Network.RemoteData (line 106, column 1 - line 112, column 31): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
    },
    bifoldr: function (f) {
        return Data_Bifoldable.bifoldrDefault(bifoldableRemoteData)(f);
    },
    bifoldl: function (f) {
        return Data_Bifoldable.bifoldlDefault(bifoldableRemoteData)(f);
    }
};
var bitraversableRemoteData = {
    bitraverse: function (dictApplicative) {
        var map = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        var pure = Control_Applicative.pure(dictApplicative);
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2 instanceof Success) {
                        return map(Success.create)(v1(v2.value0));
                    };
                    if (v2 instanceof Failure) {
                        return map(Failure.create)(v(v2.value0));
                    };
                    if (v2 instanceof NotAsked) {
                        return pure(NotAsked.value);
                    };
                    if (v2 instanceof Loading) {
                        return pure(Loading.value);
                    };
                    throw new Error("Failed pattern match at Network.RemoteData (line 114, column 1 - line 119, column 33): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
    },
    bisequence: function (dictApplicative) {
        return Data_Bitraversable.bisequenceDefault(bitraversableRemoteData)(dictApplicative);
    },
    Bifunctor0: function () {
        return bifunctorRemoteData;
    },
    Bifoldable1: function () {
        return bifoldableRemoteData;
    }
};
var applyRemoteData = {
    apply: function (v) {
        return function (v1) {
            if (v instanceof Success && v1 instanceof Success) {
                return new Success(v.value0(v1.value0));
            };
            if (v instanceof Failure) {
                return new Failure(v.value0);
            };
            if (v1 instanceof Failure) {
                return new Failure(v1.value0);
            };
            if (v instanceof NotAsked) {
                return NotAsked.value;
            };
            if (v1 instanceof NotAsked) {
                return NotAsked.value;
            };
            if (v instanceof Loading) {
                return Loading.value;
            };
            if (v1 instanceof Loading) {
                return Loading.value;
            };
            throw new Error("Failed pattern match at Network.RemoteData (line 62, column 1 - line 69, column 28): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Functor0: function () {
        return functorRemoteData;
    }
};
var bindRemoteData = {
    bind: function (v) {
        return function (v1) {
            if (v instanceof NotAsked) {
                return NotAsked.value;
            };
            if (v instanceof Loading) {
                return Loading.value;
            };
            if (v instanceof Failure) {
                return new Failure(v.value0);
            };
            if (v instanceof Success) {
                return v1(v.value0);
            };
            throw new Error("Failed pattern match at Network.RemoteData (line 71, column 1 - line 75, column 35): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Apply0: function () {
        return applyRemoteData;
    }
};
var applicativeRemoteData = {
    pure: function (value) {
        return new Success(value);
    },
    Apply0: function () {
        return applyRemoteData;
    }
};
var monadRemoteData = {
    Applicative0: function () {
        return applicativeRemoteData;
    },
    Bind1: function () {
        return bindRemoteData;
    }
};
var monadThrowRemoteData = /* #__PURE__ */ (function () {
    return {
        throwError: Failure.create,
        Monad0: function () {
            return monadRemoteData;
        }
    };
})();
var monadErrorRemoteData = {
    catchError: function (v) {
        return function (v1) {
            if (v instanceof Failure) {
                return v1(v.value0);
            };
            if (v instanceof Success) {
                return new Success(v.value0);
            };
            if (v instanceof NotAsked) {
                return NotAsked.value;
            };
            if (v instanceof Loading) {
                return Loading.value;
            };
            throw new Error("Failed pattern match at Network.RemoteData (line 85, column 1 - line 89, column 33): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    MonadThrow0: function () {
        return monadThrowRemoteData;
    }
};
var _Success = function (dictChoice) {
    var unwrap = function (v) {
        if (v instanceof Success) {
            return new Data_Either.Right(v.value0);
        };
        return new Data_Either.Left(v);
    };
    return Data_Lens_Prism.prism(Success.create)(unwrap)(dictChoice);
};
var isSuccess = /* #__PURE__ */ is(/* #__PURE__ */ _Success(Data_Lens_Internal_Market.choiceMarket));
var _NotAsked = function (dictChoice) {
    var unwrap = function (v) {
        if (v instanceof NotAsked) {
            return new Data_Either.Right(Data_Unit.unit);
        };
        return new Data_Either.Left(v);
    };
    return Data_Lens_Prism.prism(Data_Function["const"](NotAsked.value))(unwrap)(dictChoice);
};
var isNotAsked = /* #__PURE__ */ is(/* #__PURE__ */ _NotAsked(Data_Lens_Internal_Market.choiceMarket));
var _Loading = function (dictChoice) {
    var unwrap = function (v) {
        if (v instanceof Loading) {
            return new Data_Either.Right(Data_Unit.unit);
        };
        return new Data_Either.Left(v);
    };
    return Data_Lens_Prism.prism(Data_Function["const"](Loading.value))(unwrap)(dictChoice);
};
var isLoading = /* #__PURE__ */ is(/* #__PURE__ */ _Loading(Data_Lens_Internal_Market.choiceMarket));
var _Failure = function (dictChoice) {
    var unwrap = function (v) {
        if (v instanceof Failure) {
            return new Data_Either.Right(v.value0);
        };
        return new Data_Either.Left(v);
    };
    return Data_Lens_Prism.prism(Failure.create)(unwrap)(dictChoice);
};
var isFailure = /* #__PURE__ */ is(/* #__PURE__ */ _Failure(Data_Lens_Internal_Market.choiceMarket));
export {
    NotAsked,
    Loading,
    Failure,
    Success,
    toMaybe,
    fromMaybe,
    fromEither,
    maybe,
    withDefault,
    _NotAsked,
    _Loading,
    _Failure,
    _Success,
    isNotAsked,
    isLoading,
    isFailure,
    isSuccess,
    genericRemoteData,
    eqRemoteData,
    functorRemoteData,
    showRemoteData,
    bifunctorRemoteData,
    applyRemoteData,
    bindRemoteData,
    applicativeRemoteData,
    monadRemoteData,
    monadThrowRemoteData,
    monadErrorRemoteData,
    foldableRemoteData,
    traversableRemoteData,
    bifoldableRemoteData,
    bitraversableRemoteData
};
