// Generated by purs version 0.15.13
import * as BankingReady_Capability_ExcelEditor from "../BankingReady.Capability.ExcelEditor/index.js";
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Data_Description_IntroToFinance_RoleOfIB from "../BankingReady.Data.Description.IntroToFinance.RoleOfIB/index.js";
import * as BankingReady_Data_Description_IntroToFinance_Terminology from "../BankingReady.Data.Description.IntroToFinance.Terminology/index.js";
import * as BankingReady_Data_Description_IntroToFinance_TimeValueOfMoney from "../BankingReady.Data.Description.IntroToFinance.TimeValueOfMoney/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Page_Lesson from "../BankingReady.Page.Lesson/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var slot = /* #__PURE__ */ Halogen_HTML.slot()({
    reflectSymbol: function () {
        return "lesson";
    }
})(Data_Ord.ordInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var when = /* #__PURE__ */ Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM);
var lessThan = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordIntroToFinanceRoute);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var pred = /* #__PURE__ */ Data_Enum.pred(BankingReady_Data_Route.enumIntroToFinanceRoute);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var succ = /* #__PURE__ */ Data_Enum.succ(BankingReady_Data_Route.enumIntroToFinanceRoute);
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Finalize = /* #__PURE__ */ (function () {
    function Finalize() {

    };
    Finalize.value = new Finalize();
    return Finalize;
})();
var Receive = /* #__PURE__ */ (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var HandleLessonOutput = /* #__PURE__ */ (function () {
    function HandleLessonOutput(value0) {
        this.value0 = value0;
    };
    HandleLessonOutput.create = function (value0) {
        return new HandleLessonOutput(value0);
    };
    return HandleLessonOutput;
})();
var component = function (dictMonadAff) {
    var component1 = BankingReady_Page_Lesson.component(dictMonadAff);
    return function (dictMonadStore) {
        return function (dictNavigate) {
            var navigate = BankingReady_Capability_Navigate.navigate(BankingReady_Capability_Navigate.navigateHalogenM(dictNavigate));
            return function (dictManageExcelEditor) {
                var component2 = component1(dictManageExcelEditor);
                var manageExcelEditorHalogenM = BankingReady_Capability_ExcelEditor.manageExcelEditorHalogenM(dictManageExcelEditor);
                var createWorksheetsBeforeAndIncluding = BankingReady_Capability_ExcelEditor.createWorksheetsBeforeAndIncluding(manageExcelEditorHalogenM);
                var deleteWorksheetsBeforeAndIncluding = BankingReady_Capability_ExcelEditor.deleteWorksheetsBeforeAndIncluding(manageExcelEditorHalogenM);
                var deleteWorksheetsAfterAndCreateCurrent = BankingReady_Capability_ExcelEditor.deleteWorksheetsAfterAndCreateCurrent(manageExcelEditorHalogenM);
                var createWorksheet = BankingReady_Capability_ExcelEditor.createWorksheet(manageExcelEditorHalogenM);
                var render = function (v) {
                    if (v.introToFinanceRoute instanceof BankingReady_Data_Route.Terminology) {
                        return slot(Type_Proxy["Proxy"].value)(1)(component2)({
                            route: new BankingReady_Data_Route.IntroToFinance(v.introToFinanceRoute),
                            upcomingDescList: BankingReady_Data_Description_IntroToFinance_Terminology.terminologyDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.introToFinanceRoute instanceof BankingReady_Data_Route.RoleOfIB) {
                        return slot(Type_Proxy["Proxy"].value)(2)(component2)({
                            route: new BankingReady_Data_Route.IntroToFinance(v.introToFinanceRoute),
                            upcomingDescList: BankingReady_Data_Description_IntroToFinance_RoleOfIB.roleOfIBDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.introToFinanceRoute instanceof BankingReady_Data_Route.TimeValueOfMoney) {
                        return slot(Type_Proxy["Proxy"].value)(3)(component2)({
                            route: new BankingReady_Data_Route.IntroToFinance(v.introToFinanceRoute),
                            upcomingDescList: BankingReady_Data_Description_IntroToFinance_TimeValueOfMoney.timeValueOfMoneyDescriptions
                        })(HandleLessonOutput.create);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.IntroToFinanceRouter (line 94, column 36 - line 123, column 27): " + [ v.introToFinanceRoute.constructor.name ]);
                };
                var handleAction = function (v) {
                    if (v instanceof Initialize) {
                        return bind(get)(function (v1) {
                            return createWorksheetsBeforeAndIncluding(new BankingReady_Data_Route.IntroToFinance(v1.introToFinanceRoute));
                        });
                    };
                    if (v instanceof Finalize) {
                        return bind(get)(function (v1) {
                            return deleteWorksheetsBeforeAndIncluding(new BankingReady_Data_Route.IntroToFinance(v1.introToFinanceRoute));
                        });
                    };
                    if (v instanceof Receive) {
                        return bind(get)(function (v1) {
                            return discard(when(lessThan(v.value0)(v1.introToFinanceRoute))(discard(deleteWorksheetsAfterAndCreateCurrent(new BankingReady_Data_Route.IntroToFinance(v1.introToFinanceRoute))(new BankingReady_Data_Route.IntroToFinance(v.value0)))(function () {
                                return modify_(function (v2) {
                                    var $47 = {};
                                    for (var $48 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $48)) {
                                            $47[$48] = v2[$48];
                                        };
                                    };
                                    $47.introToFinanceRoute = v.value0;
                                    return $47;
                                });
                            })))(function () {
                                return when(lessThan(v1.introToFinanceRoute)(v.value0))(discard(modify_(function (v2) {
                                    var $50 = {};
                                    for (var $51 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $51)) {
                                            $50[$51] = v2[$51];
                                        };
                                    };
                                    $50.introToFinanceRoute = v.value0;
                                    return $50;
                                }))(function () {
                                    return createWorksheet(new BankingReady_Data_Route.IntroToFinance(v.value0));
                                }));
                            });
                        });
                    };
                    if (v instanceof HandleLessonOutput) {
                        if (v.value0 instanceof BankingReady_Page_Lesson.PreviousLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.IntroToFinance) {
                                var v1 = pred(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.IntroToFinance(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.IntroToFinanceRouter (line 80, column 47 - line 82, column 66): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        if (v.value0 instanceof BankingReady_Page_Lesson.NextLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.IntroToFinance) {
                                var v1 = succ(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.IntroToFinance(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.IntroToFinanceRouter (line 87, column 47 - line 89, column 66): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at BankingReady.Component.Router.IntroToFinanceRouter (line 78, column 34 - line 91, column 23): " + [ v.value0.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.IntroToFinanceRouter (line 59, column 18 - line 91, column 23): " + [ v.constructor.name ]);
                };
                return Halogen_Component.mkComponent({
                    initialState: function (introToFinanceRoute) {
                        return {
                            introToFinanceRoute: introToFinanceRoute
                        };
                    },
                    render: render,
                    "eval": Halogen_Component.mkEval({
                        handleQuery: Halogen_Component.defaultEval.handleQuery,
                        handleAction: handleAction,
                        initialize: new Data_Maybe.Just(Initialize.value),
                        finalize: new Data_Maybe.Just(Finalize.value),
                        receive: function ($69) {
                            return Data_Maybe.Just.create(Receive.create($69));
                        }
                    })
                });
            };
        };
    };
};
export {
    Initialize,
    Finalize,
    Receive,
    HandleLessonOutput,
    component
};
