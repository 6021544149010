// Generated by purs version 0.15.13
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Data_Codec from "../Data.Codec/index.js";
import * as Data_Codec_Argonaut from "../Data.Codec.Argonaut/index.js";
import * as Data_Codec_Argonaut_Record from "../Data.Codec.Argonaut.Record/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Profunctor from "../Data.Profunctor/index.js";
var wrapIso = /* #__PURE__ */ Data_Profunctor.wrapIso(/* #__PURE__ */ Data_Codec.profunctorGCodec(/* #__PURE__ */ Control_Monad_Reader_Trans.functorReaderT(Data_Either.functorEither))(/* #__PURE__ */ Control_Monad_Writer_Trans.functorWriterT(Data_Identity.functorIdentity)))();
var UserId = function (x) {
    return x;
};
var Name = function (x) {
    return x;
};
var Email = function (x) {
    return x;
};
var AccessToken = function (x) {
    return x;
};
var LoginUser = /* #__PURE__ */ (function () {
    function LoginUser(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    LoginUser.create = function (value0) {
        return function (value1) {
            return new LoginUser(value0, value1);
        };
    };
    return LoginUser;
})();
var showName = {
    show: function (v) {
        return v;
    }
};
var reduce = function (store) {
    return function (v) {
        return {
            environment: store.environment,
            os: store.os,
            baseUrl: store.baseUrl,
            auth0ClientId: store.auth0ClientId,
            betterStackToken: store.betterStackToken,
            defaultFontWidth: store.defaultFontWidth,
            accessToken: new Data_Maybe.Just(v.value0),
            currentUser: new Data_Maybe.Just(v.value1)
        };
    };
};
var newtypeUserId = {
    Coercible0: function () {
        return undefined;
    }
};
var userIdCodec = /* #__PURE__ */ wrapIso(UserId)(Data_Codec_Argonaut.string);
var newtypeName = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeEmail = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeAccessToken = {
    Coercible0: function () {
        return undefined;
    }
};
var nameCodec = /* #__PURE__ */ wrapIso(Name)(Data_Codec_Argonaut.string);
var eqUserId = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var eqName = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var eqEmail = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var eqAccessToken = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var emailCodec = /* #__PURE__ */ wrapIso(Email)(Data_Codec_Argonaut.string);
var profileCodec = /* #__PURE__ */ Data_Codec_Argonaut.object("Profile")(/* #__PURE__ */ Data_Codec_Argonaut_Record.record()(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(Data_Codec_Argonaut_Record.rowListCodecNil)()()({
    reflectSymbol: function () {
        return "userId";
    }
}))()()({
    reflectSymbol: function () {
        return "name";
    }
}))()()({
    reflectSymbol: function () {
        return "email";
    }
}))({
    userId: userIdCodec,
    email: emailCodec,
    name: nameCodec
}));
var accessTokenCodec = /* #__PURE__ */ wrapIso(AccessToken)(Data_Codec_Argonaut.string);
export {
    AccessToken,
    accessTokenCodec,
    UserId,
    userIdCodec,
    Email,
    emailCodec,
    Name,
    nameCodec,
    profileCodec,
    LoginUser,
    reduce,
    newtypeAccessToken,
    eqAccessToken,
    newtypeUserId,
    eqUserId,
    newtypeEmail,
    eqEmail,
    newtypeName,
    eqName,
    showName
};
