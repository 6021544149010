// Generated by purs version 0.15.13
import * as BankingReady_AppM from "../BankingReady.AppM/index.js";
import * as BankingReady_Data_Quiz from "../BankingReady.Data.Quiz/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as BankingReady_Foreign_Excel_Range from "../BankingReady.Foreign.Excel.Range/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Control_Promise from "../Control.Promise/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_TraversableWithIndex from "../Data.TraversableWithIndex/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetQuizRoute);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "columnWidth";
    }
})())())(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "format";
    }
})())());
var forWithIndex = /* #__PURE__ */ Data_TraversableWithIndex.forWithIndex(Effect.applicativeEffect)(Data_TraversableWithIndex.traversableWithIndexArray);
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(BankingReady_AppM.monadAffAppM);
var discard2 = /* #__PURE__ */ discard(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var for_ = /* #__PURE__ */ Data_Foldable.for_(Effect_Aff.applicativeAff)(Data_Foldable.foldableArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var forWithIndex1 = /* #__PURE__ */ Data_TraversableWithIndex.forWithIndex(Effect_Aff.applicativeAff)(Data_TraversableWithIndex.traversableWithIndexArray);
var writeQuestionExplanation = function (dict) {
    return dict.writeQuestionExplanation;
};
var deleteQuestionWorksheet = function (dict) {
    return dict.deleteQuestionWorksheet;
};
var deleteAndCreateNewQuestionWorksheet = function (dict) {
    return dict.deleteAndCreateNewQuestionWorksheet;
};
var createQuestionWorksheetEffect = function (context) {
    return function (route) {
        return function (question) {
            var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
            var worksheet = BankingReady_Foreign_Excel_Workbook.addWorksheet(workbook)(showExcelWorksheet(route));
            return function __do() {
                BankingReady_Foreign_Excel_Worksheet.showGridlines(worksheet)(false)();
                var columnsAtoD = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)("A:D");
                BankingReady_Foreign_Excel_Range.set(columnsAtoD)(encodeJson({
                    format: {
                        columnWidth: 16
                    }
                }))();
                forWithIndex(question.questionTextArray)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(1 + idx | 0)(1)(elem);
                    };
                })();
                var qRows = Data_Array.length(question.questionTextArray);
                var answerALength = Data_Array.length(question.answerA);
                var answerBLength = Data_Array.length(question.answerB);
                var answerCLength = Data_Array.length(question.answerC);
                forWithIndex(question.answerA)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(((1 + qRows | 0) + 1 | 0) + idx | 0)(1 + 3 | 0)(elem);
                    };
                })();
                forWithIndex(question.answerB)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(((((1 + qRows | 0) + 1 | 0) + answerALength | 0) + 1 | 0) + idx | 0)(1 + 3 | 0)(elem);
                    };
                })();
                forWithIndex(question.answerC)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(((((((1 + qRows | 0) + 1 | 0) + answerALength | 0) + 1 | 0) + answerBLength | 0) + 1 | 0) + idx | 0)(1 + 3 | 0)(elem);
                    };
                })();
                forWithIndex(question.answerD)(function (idx) {
                    return function (elem) {
                        return BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(((((((((1 + qRows | 0) + 1 | 0) + answerALength | 0) + 1 | 0) + answerBLength | 0) + 1 | 0) + answerCLength | 0) + 1 | 0) + idx | 0)(1 + 3 | 0)(elem);
                    };
                })();
                BankingReady_Utility_Excel_Cell.setCellValue(worksheet)((1 + qRows | 0) + 1 | 0)(1 + 1 | 0)("A")();
                BankingReady_Utility_Excel_Cell.setCellHorizontalAlignment(worksheet)((1 + qRows | 0) + 1 | 0)(1 + 1 | 0)("Center")();
                BankingReady_Utility_Excel_Border.highlightBorder(worksheet)({
                    rowIdx: (1 + qRows | 0) + 1 | 0,
                    colIdx: 1 + 1 | 0,
                    rowCount: 1,
                    columnCount: 1
                })(BankingReady_Utility_Excel_Border.Black.value)();
                BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(((1 + qRows | 0) + answerALength | 0) + 2 | 0)(1 + 1 | 0)("B")();
                BankingReady_Utility_Excel_Cell.setCellHorizontalAlignment(worksheet)(((1 + qRows | 0) + answerALength | 0) + 2 | 0)(1 + 1 | 0)("Center")();
                BankingReady_Utility_Excel_Border.highlightBorder(worksheet)({
                    rowIdx: ((1 + qRows | 0) + answerALength | 0) + 2 | 0,
                    colIdx: 1 + 1 | 0,
                    rowCount: 1,
                    columnCount: 1
                })(BankingReady_Utility_Excel_Border.Black.value)();
                BankingReady_Utility_Excel_Cell.setCellValue(worksheet)((((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + 3 | 0)(1 + 1 | 0)("C")();
                BankingReady_Utility_Excel_Cell.setCellHorizontalAlignment(worksheet)((((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + 3 | 0)(1 + 1 | 0)("Center")();
                BankingReady_Utility_Excel_Border.highlightBorder(worksheet)({
                    rowIdx: (((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + 3 | 0,
                    colIdx: 1 + 1 | 0,
                    rowCount: 1,
                    columnCount: 1
                })(BankingReady_Utility_Excel_Border.Black.value)();
                BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(((((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + answerCLength | 0) + 4 | 0)(1 + 1 | 0)("D")();
                BankingReady_Utility_Excel_Cell.setCellHorizontalAlignment(worksheet)(((((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + answerCLength | 0) + 4 | 0)(1 + 1 | 0)("Center")();
                return BankingReady_Utility_Excel_Border.highlightBorder(worksheet)({
                    rowIdx: ((((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + answerCLength | 0) + 4 | 0,
                    colIdx: 1 + 1 | 0,
                    rowCount: 1,
                    columnCount: 1
                })(BankingReady_Utility_Excel_Border.Black.value)();
            };
        };
    };
};
var createQuestionWorksheet = function (dict) {
    return dict.createQuestionWorksheet;
};
var manageExcelQuizHalogenM = function (dictManageExcelQuiz) {
    var lift1 = lift(dictManageExcelQuiz.Monad0());
    var createQuestionWorksheet1 = createQuestionWorksheet(dictManageExcelQuiz);
    var deleteQuestionWorksheet1 = deleteQuestionWorksheet(dictManageExcelQuiz);
    var deleteAndCreateNewQuestionWorksheet1 = deleteAndCreateNewQuestionWorksheet(dictManageExcelQuiz);
    var writeQuestionExplanation1 = writeQuestionExplanation(dictManageExcelQuiz);
    return {
        createQuestionWorksheet: function (r) {
            return function (q) {
                return lift1(createQuestionWorksheet1(r)(q));
            };
        },
        deleteQuestionWorksheet: function (r) {
            return lift1(deleteQuestionWorksheet1(r));
        },
        deleteAndCreateNewQuestionWorksheet: function (r) {
            return function (q) {
                return lift1(deleteAndCreateNewQuestionWorksheet1(r)(q));
            };
        },
        writeQuestionExplanation: function (r) {
            return function (q) {
                return function (s) {
                    return lift1(writeQuestionExplanation1(r)(q)(s));
                };
            };
        },
        Monad0: function () {
            return Halogen_Query_HalogenM.monadHalogenM;
        }
    };
};
var activateWorksheetEffect = function (context) {
    return function (route) {
        var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
        var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
        return BankingReady_Foreign_Excel_Worksheet.activate(worksheet);
    };
};
var manageExcelQuizAppM = {
    createQuestionWorksheet: function (route) {
        return function (question) {
            return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                return Control_Promise.fromAff(discard2(liftEffect(createQuestionWorksheetEffect(context)(route)(question)))(function () {
                    return discard2(liftEffect(activateWorksheetEffect(context)(route)))(function () {
                        return BankingReady_Foreign_Excel.sync(context);
                    });
                }));
            }));
        };
    },
    deleteQuestionWorksheet: function (route) {
        return liftAff(BankingReady_Foreign_Excel.run(function (context) {
            return Control_Promise.fromAff((function () {
                var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
                var prevWorksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
                return discard2(liftEffect(BankingReady_Foreign_Excel_Worksheet.deleteWorksheet(prevWorksheet)))(function () {
                    return BankingReady_Foreign_Excel.sync(context);
                });
            })());
        }));
    },
    deleteAndCreateNewQuestionWorksheet: function (route) {
        return function (question) {
            return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                return Control_Promise.fromAff((function () {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
                    var prevWorksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
                    return discard2(liftEffect(BankingReady_Foreign_Excel_Worksheet.deleteWorksheet(prevWorksheet)))(function () {
                        return discard2(liftEffect(createQuestionWorksheetEffect(context)(route)(question)))(function () {
                            return discard2(liftEffect(activateWorksheetEffect(context)(route)))(function () {
                                return BankingReady_Foreign_Excel.sync(context);
                            });
                        });
                    });
                })());
            }));
        };
    },
    writeQuestionExplanation: function (route) {
        return function (question) {
            return function (userSelection) {
                return liftAff(BankingReady_Foreign_Excel.run(function (context) {
                    return Control_Promise.fromAff((function () {
                        var explanation = (function () {
                            if (userSelection instanceof BankingReady_Data_Quiz.A) {
                                return question.explanationA;
                            };
                            if (userSelection instanceof BankingReady_Data_Quiz.B) {
                                return question.explanationB;
                            };
                            if (userSelection instanceof BankingReady_Data_Quiz.C) {
                                return question.explanationC;
                            };
                            if (userSelection instanceof BankingReady_Data_Quiz.D) {
                                return question.explanationD;
                            };
                            throw new Error("Failed pattern match at BankingReady.Capability.Excel.Quiz (line 90, column 27 - line 94, column 41): " + [ userSelection.constructor.name ]);
                        })();
                        var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
                        var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(route));
                        var qRows = Data_Array.length(question.questionTextArray);
                        var answerALength = Data_Array.length(question.answerA);
                        var answerBLength = Data_Array.length(question.answerB);
                        var answerCLength = Data_Array.length(question.answerC);
                        var answerDLength = Data_Array.length(question.answerD);
                        var answerARowIdx = (1 + qRows | 0) + 1 | 0;
                        var answerBRowIdx = ((1 + qRows | 0) + answerALength | 0) + 2 | 0;
                        var answerCRowIdx = (((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + 3 | 0;
                        var answerDRowIdx = ((((1 + qRows | 0) + answerALength | 0) + answerBLength | 0) + answerCLength | 0) + 4 | 0;
                        var rowAddressToInsertDown = (function () {
                            if (userSelection instanceof BankingReady_Data_Quiz.A) {
                                return show((answerARowIdx + answerALength | 0) + 1 | 0) + (":" + show((answerARowIdx + answerALength | 0) + 1 | 0));
                            };
                            if (userSelection instanceof BankingReady_Data_Quiz.B) {
                                return show((answerBRowIdx + answerBLength | 0) + 1 | 0) + (":" + show((answerBRowIdx + answerBLength | 0) + 1 | 0));
                            };
                            if (userSelection instanceof BankingReady_Data_Quiz.C) {
                                return show((answerCRowIdx + answerCLength | 0) + 1 | 0) + (":" + show((answerCRowIdx + answerCLength | 0) + 1 | 0));
                            };
                            if (userSelection instanceof BankingReady_Data_Quiz.D) {
                                return show((answerDRowIdx + answerDLength | 0) + 1 | 0) + (":" + show((answerDRowIdx + answerDLength | 0) + 1 | 0));
                            };
                            throw new Error("Failed pattern match at BankingReady.Capability.Excel.Quiz (line 115, column 38 - line 119, column 125): " + [ userSelection.constructor.name ]);
                        })();
                        var rowsToInsert = 1 + Data_Array.length(explanation) | 0;
                        return discard2(for_(Data_Array.range(1)(rowsToInsert))(function (v) {
                            var rowRange = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)(rowAddressToInsertDown);
                            return bind1(liftEffect(BankingReady_Foreign_Excel_Range.insertDown(rowRange)))(function () {
                                return pure(Data_Unit.unit);
                            });
                        }))(function () {
                            var explanationColIdx = 1 + 3 | 0;
                            var explanationRowIdx = (function () {
                                if (userSelection instanceof BankingReady_Data_Quiz.A) {
                                    return (answerARowIdx + answerALength | 0) + 1 | 0;
                                };
                                if (userSelection instanceof BankingReady_Data_Quiz.B) {
                                    return (answerBRowIdx + answerBLength | 0) + 1 | 0;
                                };
                                if (userSelection instanceof BankingReady_Data_Quiz.C) {
                                    return (answerCRowIdx + answerCLength | 0) + 1 | 0;
                                };
                                if (userSelection instanceof BankingReady_Data_Quiz.D) {
                                    return (answerDRowIdx + answerDLength | 0) + 1 | 0;
                                };
                                throw new Error("Failed pattern match at BankingReady.Capability.Excel.Quiz (line 130, column 33 - line 134, column 53): " + [ userSelection.constructor.name ]);
                            })();
                            return bind1(forWithIndex1(explanation)(function (idx) {
                                return function (elem) {
                                    return liftEffect(BankingReady_Utility_Excel_Cell.setCellValue(worksheet)(explanationRowIdx + idx | 0)(explanationColIdx)(elem));
                                };
                            }))(function () {
                                return discard2(liftEffect(activateWorksheetEffect(context)(route)))(function () {
                                    return BankingReady_Foreign_Excel.sync(context);
                                });
                            });
                        });
                    })());
                }));
            };
        };
    },
    Monad0: function () {
        return BankingReady_AppM.monadAppM;
    }
};
export {
    createQuestionWorksheet,
    deleteAndCreateNewQuestionWorksheet,
    deleteQuestionWorksheet,
    writeQuestionExplanation,
    createQuestionWorksheetEffect,
    activateWorksheetEffect,
    manageExcelQuizHalogenM,
    manageExcelQuizAppM
};
