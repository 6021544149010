// Generated by purs version 0.15.13
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var cashFlowStatementWorksheetCommands = /* #__PURE__ */ (function () {
    return [ new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGridlines({
        showGridlines: false
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial.value), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "A:A",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "B:B",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "C:C",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "D:D",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "E:E",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "F:F",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "G:G",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "H:I",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "J:J",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "K:L",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "M:M",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth40.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "N:Z",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawOuterBorder({
        rangeSelector: {
            rowIdx: 1,
            colIdx: 1,
            rowCount: 4,
            columnCount: 13
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        title: "Income Statement"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertDate({
        rowIdx: 6,
        colIdx: 3,
        date: "12/31/2021",
        dateFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        title: "Balance Sheet"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertDate({
        rowIdx: 6,
        colIdx: 7,
        date: "12/31/2020",
        dateFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertDate({
        rowIdx: 6,
        colIdx: 8,
        date: "12/31/2021",
        dateFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        title: "Cash Flow Statement"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertDate({
        rowIdx: 6,
        colIdx: 13,
        date: "12/31/2021",
        dateFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 1,
        text: "Revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 7,
        colIdx: 3,
        number: 14181.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 1,
        text: "Cost of Goods Sold"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 3,
        number: -9621.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 9,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Gross Profit"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 9,
        colIdx: 3,
        number: 4560.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 1,
        text: "Sales, Marketing & General Admin"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 11,
        colIdx: 3,
        number: -2139.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 1,
        text: "Research & Development"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 12,
        colIdx: 3,
        number: -134.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 1,
        text: "Depreciation & Amortisation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 13,
        colIdx: 3,
        number: -467.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 1,
        text: "Stock Based Compensation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 14,
        colIdx: 3,
        number: -68.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Total Operating Expense"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 15,
        colIdx: 3,
        number: -2808.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldLine({
        rangeSelector: {
            rowIdx: 17,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Operating Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 17,
        colIdx: 3,
        number: 1752.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 1,
        text: "Interest Expense"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 19,
        colIdx: 3,
        number: -223.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 1,
        text: "Interest Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 20,
        colIdx: 3,
        number: 437.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 1,
        text: "Gain / (Loss) on Asset Sales"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 22,
        colIdx: 3,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 23,
        colIdx: 1,
        text: "Goodwill Impairment"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 23,
        colIdx: 3,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 24,
        colIdx: 1,
        text: "Asset Write-downs"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 24,
        colIdx: 3,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Pre-tax Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 25,
        colIdx: 3,
        number: 1966.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 1,
        text: "Income Tax"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 27,
        colIdx: 3,
        number: 1966.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 28,
        colIdx: 1,
        text: "Earnings (loss) from unconsolidated entities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 28,
        colIdx: 3,
        number: 3.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "Net Income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 3,
        number: 1495.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 7,
        colIdx: 5,
        text: "Current Assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 6,
        text: "Cash and cash equivalents"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 7,
        number: 435.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 8,
        number: 286.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 9,
        colIdx: 6,
        text: "Accounts receivable"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 9,
        colIdx: 7,
        number: 1537.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 9,
        colIdx: 8,
        number: 1489.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 6,
        text: "Inventories"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 10,
        colIdx: 7,
        number: 1284.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 10,
        colIdx: 8,
        number: 1398.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 6,
        text: "Prepaid expenses"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 11,
        colIdx: 7,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 11,
        colIdx: 8,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 6,
        text: "Other current assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 12,
        colIdx: 7,
        number: 226.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 12,
        colIdx: 8,
        number: 221.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total current assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 13,
        colIdx: 7,
        number: 3482.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 13,
        colIdx: 8,
        number: 3394.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 15,
        colIdx: 5,
        text: "Non-Current Assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 6,
        text: "Net property, plant and equipment (Net PP&E)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 16,
        colIdx: 7,
        number: 3713.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 16,
        colIdx: 8,
        number: 3827.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 6,
        text: "Operating lease assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 17,
        colIdx: 7,
        number: 658.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 17,
        colIdx: 8,
        number: 640.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 6,
        text: "Finance lease assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 18,
        colIdx: 7,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 18,
        colIdx: 8,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 6,
        text: "Goodwill"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 19,
        colIdx: 7,
        number: 5799.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 19,
        colIdx: 8,
        number: 5771.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 6,
        text: "Other intangibles, net"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 20,
        colIdx: 7,
        number: 2491.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 20,
        colIdx: 8,
        number: 2409.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 6,
        text: "Other assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 21,
        colIdx: 7,
        number: 1853.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 21,
        colIdx: 8,
        number: 2137.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 22,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total non-current assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 22,
        colIdx: 7,
        number: 14514.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 22,
        colIdx: 8,
        number: 14784.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 23,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 23,
        colIdx: 7,
        number: 17996.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 23,
        colIdx: 8,
        number: 18178.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 25,
        colIdx: 5,
        text: "Current Liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 26,
        colIdx: 6,
        text: "Current maturities of long-term debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 26,
        colIdx: 7,
        number: 627.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 26,
        colIdx: 8,
        number: 712.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 6,
        text: "Accounts payable"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 27,
        colIdx: 7,
        number: 2573.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 27,
        colIdx: 8,
        number: 2710.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 28,
        colIdx: 6,
        text: "Current operating lease liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 28,
        colIdx: 7,
        number: 117.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 28,
        colIdx: 8,
        number: 116.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 29,
        colIdx: 6,
        text: "Accrued expenses"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 7,
        number: 1154.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 8,
        number: 1014.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 30,
        colIdx: 6,
        text: "Deferred revenues"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 30,
        colIdx: 7,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 30,
        colIdx: 8,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 31,
        colIdx: 6,
        text: "Other current liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 31,
        colIdx: 7,
        number: 767.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 31,
        colIdx: 8,
        number: 763.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total current liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 32,
        colIdx: 7,
        number: 5238.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 32,
        colIdx: 8,
        number: 5315.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 34,
        colIdx: 5,
        text: "Non-Current Liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 35,
        colIdx: 6,
        text: "Long-term debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 35,
        colIdx: 7,
        number: 6746.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 35,
        colIdx: 8,
        number: 6262.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 36,
        colIdx: 6,
        text: "Operating lease liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 36,
        colIdx: 7,
        number: 520.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 36,
        colIdx: 8,
        number: 502.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 37,
        colIdx: 6,
        text: "Finance lease liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 37,
        colIdx: 7,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 37,
        colIdx: 8,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 38,
        colIdx: 6,
        text: "Deferred income taxes"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 38,
        colIdx: 7,
        number: 562.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 38,
        colIdx: 8,
        number: 722.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 39,
        colIdx: 6,
        text: "Other liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 39,
        colIdx: 7,
        number: 1294.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 39,
        colIdx: 8,
        number: 1162.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total non-current liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 40,
        colIdx: 7,
        number: 9122.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 40,
        colIdx: 8,
        number: 8648.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 41,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 41,
        colIdx: 7,
        number: 14360.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 41,
        colIdx: 8,
        number: 13963.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 43,
        colIdx: 5,
        text: "Equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 44,
        colIdx: 6,
        text: "Common stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 44,
        colIdx: 7,
        number: 105.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 44,
        colIdx: 8,
        number: 105.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 45,
        colIdx: 6,
        text: "Additional paid in capital"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 45,
        colIdx: 7,
        number: 972.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 45,
        colIdx: 8,
        number: 1023.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 46,
        colIdx: 6,
        text: "Retained earnings"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 46,
        colIdx: 7,
        number: 8326.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 46,
        colIdx: 8,
        number: 9028.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 47,
        colIdx: 6,
        text: "Treasury stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 47,
        colIdx: 7,
        number: -4559.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 47,
        colIdx: 8,
        number: -4715.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 48,
        colIdx: 6,
        text: "Other comprehensive income (loss)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 48,
        colIdx: 7,
        number: -1732.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 48,
        colIdx: 8,
        number: -1721.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 49,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Common Shareholder Equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 49,
        colIdx: 7,
        number: 3112.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 49,
        colIdx: 8,
        number: 3720.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 50,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Preferred stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 50,
        colIdx: 7,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 50,
        colIdx: 8,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 51,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Non-controlling interest"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 51,
        colIdx: 7,
        number: 524.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 51,
        colIdx: 8,
        number: 495.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 52,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 52,
        colIdx: 7,
        number: 3636.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 52,
        colIdx: 8,
        number: 4215.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 53,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        text: "Total liabilities and equity"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 53,
        colIdx: 7,
        number: 17996.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 53,
        colIdx: 8,
        number: 18178.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 7,
        colIdx: 10,
        text: "Operating Activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawBottomBorder({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 8,
        colIdx: 11,
        text: "Net income"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 9,
        colIdx: 11,
        text: "Non-cash adjustments"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 12,
        text: "(+) Depreciation & amortisation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 12,
        text: "(+) Stock based compensation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 12,
        text: "(-) Gain / (+) Loss on PP&E sales"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 12,
        text: "(+) Goodwill impairment"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 12,
        text: "(+) PP&E write-down"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 15,
        colIdx: 11,
        text: "Changes in operating assets & liabilities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 12,
        text: "Accounts receivable"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 17,
        colIdx: 12,
        text: "Inventories"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 12,
        text: "Prepaid expenses"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 12,
        text: "Accounts payable"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 12,
        text: "Accrued expenses"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 12,
        text: "Deferred revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 12,
        text: "(+/-) other changes"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 23,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        text: "Net cash provided by (used in) operating activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 23,
        colIdx: 13,
        formula: "=SUM(N9:N23)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 25,
        colIdx: 10,
        text: "Investing Activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawBottomBorder({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 26,
        colIdx: 11,
        text: "(-) Capital expenditure"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 11,
        text: "(+) PP&E sale proceeds (net of cash)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 28,
        colIdx: 11,
        text: "(+/-) Net sales (purchase) of securities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 29,
        colIdx: 11,
        text: "(-) Addition to finance lease assets"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 30,
        colIdx: 11,
        text: "(-) Acquisitions"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 31,
        colIdx: 11,
        text: "(+/-) Other"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 32,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        text: "Net cash provided by (used in) investing activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 32,
        colIdx: 13,
        formula: "=SUM(N27:N32)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 34,
        colIdx: 10,
        text: "Financing Activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawBottomBorder({
        rangeSelector: {
            rowIdx: 34,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 35,
        colIdx: 11,
        text: "Net issuances (reductions) of long-term debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 36,
        colIdx: 11,
        text: "Net issuances (repurchases) of common stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 37,
        colIdx: 11,
        text: "Cash dividends"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 38,
        colIdx: 11,
        text: "Other"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        text: "Net cash provided by (used in) financing activities"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 39,
        colIdx: 13,
        formula: "=SUM(N36:N39)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 41,
        colIdx: 10,
        text: "Effect of exchange rate changes"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawBottomBorder({
        rangeSelector: {
            rowIdx: 41,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 42,
        colIdx: 10,
        text: "Increase (decrease) in cash and cash equivalents"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 43,
        colIdx: 10,
        text: "Cash and cash equivalents at beginning of the period"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 44,
            colIdx: 10,
            rowCount: 1,
            columnCount: 4
        },
        text: "Cash and cash equivalents at the end of the period"
    })) ];
})();
export {
    cashFlowStatementWorksheetCommands
};
