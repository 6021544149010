// Generated by purs version 0.15.13
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Web_HTML_Common from "../Web.HTML.Common/index.js";
var safeHref = /* #__PURE__ */ (function () {
    var $2 = Data_Semigroup.append(Data_Semigroup.semigroupString)("#");
    var $3 = Routing_Duplex.print(BankingReady_Data_Route.routeCodec);
    return function ($4) {
        return Halogen_HTML_Properties.href($2($3($4)));
    };
})();
var css = function ($5) {
    return Halogen_HTML_Properties.class_(Web_HTML_Common.ClassName($5));
};
export {
    css,
    safeHref
};
