"use strict"

export const setChartName = function (chart) {
    return function (chartName) {
        return function () {
            chart.set({ name: chartName })
        };
    };
};

export const setChartFontSize = function (chart) {
    return function (fontSize) {
        return function () {
            chart.format.font.set({size: fontSize})
        };
    };
};

export const setChartPosition = function (chart) {
    return function (startCellRange) {
        return function (endCellRange) {
            return function () {
                chart.setPosition(startCellRange, endCellRange)
            };
        };
    };
};

export const setVerticalAxesGridlinesVisible = function (chart) {
    return function (gridlinesVisible) {
        return function () {
            chart.axes.valueAxis.majorGridlines.set({visible: gridlinesVisible})
        };
    };
};

export const setTitleVisible = function (chart) {
    return function (titleVisible) {
        return function () {
            chart.title.set({visible: titleVisible})
        };
    };
};

export const setLegendRight = function (chart) {
    return function () {
        chart.legend.set({ visible: true, position: "Right", overlay: false });
    };
};

export const setDataLabelsVisible = function (chart) {
    return function () {
        chart.dataLabels.set({ showValue: true })
    };
};
