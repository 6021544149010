// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as Control_Promise from "../Control.Promise/index.js";
var onReady = function ($1) {
    return Control_Promise.toAffE($foreign.onReadyImpl($1));
};
export {
    onReadyImpl
} from "./foreign.js";
export {
    onReady
};
