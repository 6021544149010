// Generated by purs version 0.15.13
var A = /* #__PURE__ */ (function () {
    function A() {

    };
    A.value = new A();
    return A;
})();
var B = /* #__PURE__ */ (function () {
    function B() {

    };
    B.value = new B();
    return B;
})();
var C = /* #__PURE__ */ (function () {
    function C() {

    };
    C.value = new C();
    return C;
})();
var D = /* #__PURE__ */ (function () {
    function D() {

    };
    D.value = new D();
    return D;
})();
var showSelection = {
    show: function (v) {
        if (v instanceof A) {
            return "A";
        };
        if (v instanceof B) {
            return "B";
        };
        if (v instanceof C) {
            return "C";
        };
        if (v instanceof D) {
            return "D";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Quiz (line 9, column 10 - line 13, column 13): " + [ v.constructor.name ]);
    }
};
var eqSelection = {
    eq: function (x) {
        return function (y) {
            if (x instanceof A && y instanceof A) {
                return true;
            };
            if (x instanceof B && y instanceof B) {
                return true;
            };
            if (x instanceof C && y instanceof C) {
                return true;
            };
            if (x instanceof D && y instanceof D) {
                return true;
            };
            return false;
        };
    }
};
export {
    A,
    B,
    C,
    D,
    eqSelection,
    showSelection
};
