// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as Data_Show from "../Data.Show/index.js";
var Utf8 = /* #__PURE__ */ (function () {
    function Utf8() {

    };
    Utf8.value = new Utf8();
    return Utf8;
})();
var Utf_16Be = /* #__PURE__ */ (function () {
    function Utf_16Be() {

    };
    Utf_16Be.value = new Utf_16Be();
    return Utf_16Be;
})();
var Utf_16Le = /* #__PURE__ */ (function () {
    function Utf_16Le() {

    };
    Utf_16Le.value = new Utf_16Le();
    return Utf_16Le;
})();
var showEncoding = {
    show: function (v) {
        if (v instanceof Utf8) {
            return "utf-8";
        };
        if (v instanceof Utf_16Be) {
            return "utf-16be";
        };
        if (v instanceof Utf_16Le) {
            return "utf-16le";
        };
        throw new Error("Failed pattern match at Data.TextEncoder (line 36, column 1 - line 39, column 29): " + [ v.constructor.name ]);
    }
};
var show = /* #__PURE__ */ Data_Show.show(showEncoding);
var encode = function (encoding) {
    return function (str) {
        return $foreign.encodeImpl(show(encoding), str);
    };
};
var encodeUtf8 = /* #__PURE__ */ (function () {
    return encode(Utf8.value);
})();
export {
    Utf8,
    Utf_16Be,
    Utf_16Le,
    encode,
    encodeUtf8,
    showEncoding
};
