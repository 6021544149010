// Generated by purs version 0.15.13
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var OPTIONS = /* #__PURE__ */ (function () {
    function OPTIONS() {

    };
    OPTIONS.value = new OPTIONS();
    return OPTIONS;
})();
var GET = /* #__PURE__ */ (function () {
    function GET() {

    };
    GET.value = new GET();
    return GET;
})();
var HEAD = /* #__PURE__ */ (function () {
    function HEAD() {

    };
    HEAD.value = new HEAD();
    return HEAD;
})();
var POST = /* #__PURE__ */ (function () {
    function POST() {

    };
    POST.value = new POST();
    return POST;
})();
var PUT = /* #__PURE__ */ (function () {
    function PUT() {

    };
    PUT.value = new PUT();
    return PUT;
})();
var DELETE = /* #__PURE__ */ (function () {
    function DELETE() {

    };
    DELETE.value = new DELETE();
    return DELETE;
})();
var TRACE = /* #__PURE__ */ (function () {
    function TRACE() {

    };
    TRACE.value = new TRACE();
    return TRACE;
})();
var CONNECT = /* #__PURE__ */ (function () {
    function CONNECT() {

    };
    CONNECT.value = new CONNECT();
    return CONNECT;
})();
var PROPFIND = /* #__PURE__ */ (function () {
    function PROPFIND() {

    };
    PROPFIND.value = new PROPFIND();
    return PROPFIND;
})();
var PROPPATCH = /* #__PURE__ */ (function () {
    function PROPPATCH() {

    };
    PROPPATCH.value = new PROPPATCH();
    return PROPPATCH;
})();
var MKCOL = /* #__PURE__ */ (function () {
    function MKCOL() {

    };
    MKCOL.value = new MKCOL();
    return MKCOL;
})();
var COPY = /* #__PURE__ */ (function () {
    function COPY() {

    };
    COPY.value = new COPY();
    return COPY;
})();
var MOVE = /* #__PURE__ */ (function () {
    function MOVE() {

    };
    MOVE.value = new MOVE();
    return MOVE;
})();
var LOCK = /* #__PURE__ */ (function () {
    function LOCK() {

    };
    LOCK.value = new LOCK();
    return LOCK;
})();
var UNLOCK = /* #__PURE__ */ (function () {
    function UNLOCK() {

    };
    UNLOCK.value = new UNLOCK();
    return UNLOCK;
})();
var PATCH = /* #__PURE__ */ (function () {
    function PATCH() {

    };
    PATCH.value = new PATCH();
    return PATCH;
})();
var CustomMethod = function (x) {
    return x;
};
var unCustomMethod = function (v) {
    return v;
};
var showMethod = {
    show: function (v) {
        if (v instanceof OPTIONS) {
            return "OPTIONS";
        };
        if (v instanceof GET) {
            return "GET";
        };
        if (v instanceof HEAD) {
            return "HEAD";
        };
        if (v instanceof POST) {
            return "POST";
        };
        if (v instanceof PUT) {
            return "PUT";
        };
        if (v instanceof DELETE) {
            return "DELETE";
        };
        if (v instanceof TRACE) {
            return "TRACE";
        };
        if (v instanceof CONNECT) {
            return "CONNECT";
        };
        if (v instanceof PROPFIND) {
            return "PROPFIND";
        };
        if (v instanceof PROPPATCH) {
            return "PROPPATCH";
        };
        if (v instanceof MKCOL) {
            return "MKCOL";
        };
        if (v instanceof COPY) {
            return "COPY";
        };
        if (v instanceof MOVE) {
            return "MOVE";
        };
        if (v instanceof LOCK) {
            return "LOCK";
        };
        if (v instanceof UNLOCK) {
            return "UNLOCK";
        };
        if (v instanceof PATCH) {
            return "PATCH";
        };
        throw new Error("Failed pattern match at Data.HTTP.Method (line 43, column 1 - line 59, column 23): " + [ v.constructor.name ]);
    }
};
var showCustomMethod = {
    show: function (v) {
        return "(CustomMethod " + (show(v) + ")");
    }
};
var print = /* #__PURE__ */ Data_Either.either(/* #__PURE__ */ Data_Show.show(showMethod))(unCustomMethod);
var parse = function (handleMethod) {
    return function (handleUnknown) {
        return function (s) {
            var v = Data_String_Common.toUpper(s);
            if (v === "OPTIONS") {
                return handleMethod(OPTIONS.value);
            };
            if (v === "GET") {
                return handleMethod(GET.value);
            };
            if (v === "HEAD") {
                return handleMethod(HEAD.value);
            };
            if (v === "POST") {
                return handleMethod(POST.value);
            };
            if (v === "PUT") {
                return handleMethod(PUT.value);
            };
            if (v === "DELETE") {
                return handleMethod(DELETE.value);
            };
            if (v === "TRACE") {
                return handleMethod(TRACE.value);
            };
            if (v === "CONNECT") {
                return handleMethod(CONNECT.value);
            };
            if (v === "PROPFIND") {
                return handleMethod(PROPFIND.value);
            };
            if (v === "PROPPATCH") {
                return handleMethod(PROPPATCH.value);
            };
            if (v === "MKCOL") {
                return handleMethod(MKCOL.value);
            };
            if (v === "COPY") {
                return handleMethod(COPY.value);
            };
            if (v === "MOVE") {
                return handleMethod(MOVE.value);
            };
            if (v === "LOCK") {
                return handleMethod(LOCK.value);
            };
            if (v === "UNLOCK") {
                return handleMethod(UNLOCK.value);
            };
            if (v === "PATCH") {
                return handleMethod(PATCH.value);
            };
            return handleUnknown(v);
        };
    };
};
var fromString = /* #__PURE__ */ (function () {
    return parse(Data_Either.Left.create)(function ($34) {
        return Data_Either.Right.create(CustomMethod($34));
    });
})();
var eqMethod = {
    eq: function (x) {
        return function (y) {
            if (x instanceof OPTIONS && y instanceof OPTIONS) {
                return true;
            };
            if (x instanceof GET && y instanceof GET) {
                return true;
            };
            if (x instanceof HEAD && y instanceof HEAD) {
                return true;
            };
            if (x instanceof POST && y instanceof POST) {
                return true;
            };
            if (x instanceof PUT && y instanceof PUT) {
                return true;
            };
            if (x instanceof DELETE && y instanceof DELETE) {
                return true;
            };
            if (x instanceof TRACE && y instanceof TRACE) {
                return true;
            };
            if (x instanceof CONNECT && y instanceof CONNECT) {
                return true;
            };
            if (x instanceof PROPFIND && y instanceof PROPFIND) {
                return true;
            };
            if (x instanceof PROPPATCH && y instanceof PROPPATCH) {
                return true;
            };
            if (x instanceof MKCOL && y instanceof MKCOL) {
                return true;
            };
            if (x instanceof COPY && y instanceof COPY) {
                return true;
            };
            if (x instanceof MOVE && y instanceof MOVE) {
                return true;
            };
            if (x instanceof LOCK && y instanceof LOCK) {
                return true;
            };
            if (x instanceof UNLOCK && y instanceof UNLOCK) {
                return true;
            };
            if (x instanceof PATCH && y instanceof PATCH) {
                return true;
            };
            return false;
        };
    }
};
var ordMethod = {
    compare: function (x) {
        return function (y) {
            if (x instanceof OPTIONS && y instanceof OPTIONS) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof OPTIONS) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof OPTIONS) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof GET && y instanceof GET) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof GET) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof GET) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof HEAD && y instanceof HEAD) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof HEAD) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof HEAD) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof POST && y instanceof POST) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof POST) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof POST) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PUT && y instanceof PUT) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof PUT) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PUT) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DELETE && y instanceof DELETE) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DELETE) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DELETE) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TRACE && y instanceof TRACE) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof TRACE) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TRACE) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CONNECT && y instanceof CONNECT) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CONNECT) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CONNECT) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PROPFIND && y instanceof PROPFIND) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof PROPFIND) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PROPFIND) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PROPPATCH && y instanceof PROPPATCH) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof PROPPATCH) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PROPPATCH) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof MKCOL && y instanceof MKCOL) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof MKCOL) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof MKCOL) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof COPY && y instanceof COPY) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof COPY) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof COPY) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof MOVE && y instanceof MOVE) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof MOVE) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof MOVE) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof LOCK && y instanceof LOCK) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof LOCK) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof LOCK) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UNLOCK && y instanceof UNLOCK) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof UNLOCK) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof UNLOCK) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof PATCH && y instanceof PATCH) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Data.HTTP.Method (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqMethod;
    }
};
var eqCustomMethod = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordCustomMethod = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqCustomMethod;
    }
};
export {
    OPTIONS,
    GET,
    HEAD,
    POST,
    PUT,
    DELETE,
    TRACE,
    CONNECT,
    PROPFIND,
    PROPPATCH,
    MKCOL,
    COPY,
    MOVE,
    LOCK,
    UNLOCK,
    PATCH,
    unCustomMethod,
    fromString,
    print,
    eqMethod,
    ordMethod,
    showMethod,
    eqCustomMethod,
    ordCustomMethod,
    showCustomMethod
};
