// Generated by purs version 0.15.13
import * as BankingReady_Capability_ExcelEditor from "../BankingReady.Capability.ExcelEditor/index.js";
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Data_Description_CompanyValue_CalculatingEnterpriseValue from "../BankingReady.Data.Description.CompanyValue.CalculatingEnterpriseValue/index.js";
import * as BankingReady_Data_Description_CompanyValue_CalculatingEquityValue from "../BankingReady.Data.Description.CompanyValue.CalculatingEquityValue/index.js";
import * as BankingReady_Data_Description_CompanyValue_ChangesInEquityAndEnterpriseValue from "../BankingReady.Data.Description.CompanyValue.ChangesInEquityAndEnterpriseValue/index.js";
import * as BankingReady_Data_Description_CompanyValue_EquityAndEnterpriseValue from "../BankingReady.Data.Description.CompanyValue.EquityAndEnterpriseValue/index.js";
import * as BankingReady_Data_Description_CompanyValue_IncomeStatementMultiples from "../BankingReady.Data.Description.CompanyValue.IncomeStatementMultiples/index.js";
import * as BankingReady_Data_Description_CompanyValue_MarketVsIntrinsic from "../BankingReady.Data.Description.CompanyValue.MarketVsIntrinsic/index.js";
import * as BankingReady_Data_Description_CompanyValue_RealLifeAnalogy from "../BankingReady.Data.Description.CompanyValue.RealLifeAnalogy/index.js";
import * as BankingReady_Data_Description_CompanyValue_TreasuryStockMethod from "../BankingReady.Data.Description.CompanyValue.TreasuryStockMethod/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Page_IntroLesson from "../BankingReady.Page.IntroLesson/index.js";
import * as BankingReady_Page_Lesson from "../BankingReady.Page.Lesson/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var slot = /* #__PURE__ */ Halogen_HTML.slot();
var slot1 = /* #__PURE__ */ slot({
    reflectSymbol: function () {
        return "introLesson";
    }
})(Data_Ord.ordInt);
var slot2 = /* #__PURE__ */ slot({
    reflectSymbol: function () {
        return "lesson";
    }
})(Data_Ord.ordInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var when = /* #__PURE__ */ Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM);
var lessThan = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordCompanyValueRoute);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var pred = /* #__PURE__ */ Data_Enum.pred(BankingReady_Data_Route.enumCompanyValueRoute);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var succ = /* #__PURE__ */ Data_Enum.succ(BankingReady_Data_Route.enumCompanyValueRoute);
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Finalize = /* #__PURE__ */ (function () {
    function Finalize() {

    };
    Finalize.value = new Finalize();
    return Finalize;
})();
var Receive = /* #__PURE__ */ (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var HandleLessonOutput = /* #__PURE__ */ (function () {
    function HandleLessonOutput(value0) {
        this.value0 = value0;
    };
    HandleLessonOutput.create = function (value0) {
        return new HandleLessonOutput(value0);
    };
    return HandleLessonOutput;
})();
var HandleIntroLessonOutput = /* #__PURE__ */ (function () {
    function HandleIntroLessonOutput(value0) {
        this.value0 = value0;
    };
    HandleIntroLessonOutput.create = function (value0) {
        return new HandleIntroLessonOutput(value0);
    };
    return HandleIntroLessonOutput;
})();
var component = function (dictMonadAff) {
    var component1 = BankingReady_Page_IntroLesson.component(dictMonadAff);
    var component2 = BankingReady_Page_Lesson.component(dictMonadAff);
    return function (dictMonadStore) {
        return function (dictNavigate) {
            var navigate = BankingReady_Capability_Navigate.navigate(BankingReady_Capability_Navigate.navigateHalogenM(dictNavigate));
            return function (dictManageExcelEditor) {
                var component3 = component2(dictManageExcelEditor);
                var manageExcelEditorHalogenM = BankingReady_Capability_ExcelEditor.manageExcelEditorHalogenM(dictManageExcelEditor);
                var createWorksheetsBeforeAndIncluding = BankingReady_Capability_ExcelEditor.createWorksheetsBeforeAndIncluding(manageExcelEditorHalogenM);
                var deleteWorksheetsBeforeAndIncluding = BankingReady_Capability_ExcelEditor.deleteWorksheetsBeforeAndIncluding(manageExcelEditorHalogenM);
                var deleteWorksheetsAfterAndCreateCurrent = BankingReady_Capability_ExcelEditor.deleteWorksheetsAfterAndCreateCurrent(manageExcelEditorHalogenM);
                var createWorksheet = BankingReady_Capability_ExcelEditor.createWorksheet(manageExcelEditorHalogenM);
                var render = function (v) {
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.CompanyValueIntro) {
                        return slot1(Type_Proxy["Proxy"].value)(1)(component1)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute)
                        })(HandleIntroLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.MarketVsIntrinsic) {
                        return slot2(Type_Proxy["Proxy"].value)(2)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_MarketVsIntrinsic.marketVsIntrinsicDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.CalculatingEquityValue) {
                        return slot2(Type_Proxy["Proxy"].value)(3)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_CalculatingEquityValue.calculatingEquityValueDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.CalculatingEnterpriseValue) {
                        return slot2(Type_Proxy["Proxy"].value)(4)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_CalculatingEnterpriseValue.calculatingEnterpriseValueDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.EquityAndEnterpriseValue) {
                        return slot2(Type_Proxy["Proxy"].value)(5)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_EquityAndEnterpriseValue.equityAndEnterpriseValueDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.ChangesInEquityAndEnterpriseValue) {
                        return slot2(Type_Proxy["Proxy"].value)(6)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_ChangesInEquityAndEnterpriseValue.changesInEquityAndEnterpriseValueDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.RealLifeAnalogy) {
                        return slot2(Type_Proxy["Proxy"].value)(7)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_RealLifeAnalogy.realLifeAnalogyDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.DilutionToEquity) {
                        return slot1(Type_Proxy["Proxy"].value)(8)(component1)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute)
                        })(HandleIntroLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.TreasuryStockMethod) {
                        return slot2(Type_Proxy["Proxy"].value)(9)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_TreasuryStockMethod.treasuryStockMethodDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.MultiplesIntro) {
                        return slot1(Type_Proxy["Proxy"].value)(10)(component1)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute)
                        })(HandleIntroLessonOutput.create);
                    };
                    if (v.companyValueRoute instanceof BankingReady_Data_Route.IncomeStatementMultiples) {
                        return slot2(Type_Proxy["Proxy"].value)(11)(component3)({
                            route: new BankingReady_Data_Route.CompanyValue(v.companyValueRoute),
                            upcomingDescList: BankingReady_Data_Description_CompanyValue_IncomeStatementMultiples.incomeStatementMultiplesDescriptions
                        })(HandleLessonOutput.create);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 114, column 34 - line 220, column 27): " + [ v.companyValueRoute.constructor.name ]);
                };
                var handleAction = function (v) {
                    if (v instanceof Initialize) {
                        return bind(get)(function (v1) {
                            return createWorksheetsBeforeAndIncluding(new BankingReady_Data_Route.CompanyValue(v1.companyValueRoute));
                        });
                    };
                    if (v instanceof Finalize) {
                        return bind(get)(function (v1) {
                            return deleteWorksheetsBeforeAndIncluding(new BankingReady_Data_Route.CompanyValue(v1.companyValueRoute));
                        });
                    };
                    if (v instanceof Receive) {
                        return bind(get)(function (v1) {
                            return discard(when(lessThan(v.value0)(v1.companyValueRoute))(discard(deleteWorksheetsAfterAndCreateCurrent(new BankingReady_Data_Route.CompanyValue(v1.companyValueRoute))(new BankingReady_Data_Route.CompanyValue(v.value0)))(function () {
                                return modify_(function (v2) {
                                    var $53 = {};
                                    for (var $54 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $54)) {
                                            $53[$54] = v2[$54];
                                        };
                                    };
                                    $53.companyValueRoute = v.value0;
                                    return $53;
                                });
                            })))(function () {
                                return when(lessThan(v1.companyValueRoute)(v.value0))(discard(modify_(function (v2) {
                                    var $56 = {};
                                    for (var $57 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $57)) {
                                            $56[$57] = v2[$57];
                                        };
                                    };
                                    $56.companyValueRoute = v.value0;
                                    return $56;
                                }))(function () {
                                    return createWorksheet(new BankingReady_Data_Route.CompanyValue(v.value0));
                                }));
                            });
                        });
                    };
                    if (v instanceof HandleLessonOutput) {
                        if (v.value0 instanceof BankingReady_Page_Lesson.PreviousLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.CompanyValue) {
                                var v1 = pred(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.CompanyValue(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 85, column 43 - line 87, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        if (v.value0 instanceof BankingReady_Page_Lesson.NextLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.CompanyValue) {
                                var v1 = succ(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.CompanyValue(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 92, column 43 - line 94, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 83, column 34 - line 96, column 23): " + [ v.value0.constructor.name ]);
                    };
                    if (v instanceof HandleIntroLessonOutput) {
                        if (v.value0 instanceof BankingReady_Page_IntroLesson.PreviousLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.CompanyValue) {
                                var v1 = pred(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.CompanyValue(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 100, column 43 - line 102, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        if (v.value0 instanceof BankingReady_Page_IntroLesson.NextLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.CompanyValue) {
                                var v1 = succ(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.CompanyValue(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 107, column 43 - line 109, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 98, column 39 - line 111, column 23): " + [ v.value0.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.CompanyValueRouter (line 64, column 18 - line 111, column 23): " + [ v.constructor.name ]);
                };
                return Halogen_Component.mkComponent({
                    initialState: function (companyValueRoute) {
                        return {
                            companyValueRoute: companyValueRoute
                        };
                    },
                    render: render,
                    "eval": Halogen_Component.mkEval({
                        handleQuery: Halogen_Component.defaultEval.handleQuery,
                        handleAction: handleAction,
                        initialize: new Data_Maybe.Just(Initialize.value),
                        finalize: new Data_Maybe.Just(Finalize.value),
                        receive: function ($89) {
                            return Data_Maybe.Just.create(Receive.create($89));
                        }
                    })
                });
            };
        };
    };
};
export {
    Initialize,
    Finalize,
    Receive,
    HandleLessonOutput,
    HandleIntroLessonOutput,
    component
};
