// Generated by purs version 0.15.13
var AddAndHideWorksheet = /* #__PURE__ */ (function () {
    function AddAndHideWorksheet() {

    };
    AddAndHideWorksheet.value = new AddAndHideWorksheet();
    return AddAndHideWorksheet;
})();
var GroupAndHideRows = /* #__PURE__ */ (function () {
    function GroupAndHideRows(value0) {
        this.value0 = value0;
    };
    GroupAndHideRows.create = function (value0) {
        return new GroupAndHideRows(value0);
    };
    return GroupAndHideRows;
})();
var ShowGroupedRows = /* #__PURE__ */ (function () {
    function ShowGroupedRows(value0) {
        this.value0 = value0;
    };
    ShowGroupedRows.create = function (value0) {
        return new ShowGroupedRows(value0);
    };
    return ShowGroupedRows;
})();
var ShowGridlines = /* #__PURE__ */ (function () {
    function ShowGridlines(value0) {
        this.value0 = value0;
    };
    ShowGridlines.create = function (value0) {
        return new ShowGridlines(value0);
    };
    return ShowGridlines;
})();
var SetColumnWidth = /* #__PURE__ */ (function () {
    function SetColumnWidth(value0) {
        this.value0 = value0;
    };
    SetColumnWidth.create = function (value0) {
        return new SetColumnWidth(value0);
    };
    return SetColumnWidth;
})();
var SetDefaultFontArial = /* #__PURE__ */ (function () {
    function SetDefaultFontArial() {

    };
    SetDefaultFontArial.value = new SetDefaultFontArial();
    return SetDefaultFontArial;
})();
var SetHorizontalAlignmentCenter = /* #__PURE__ */ (function () {
    function SetHorizontalAlignmentCenter(value0) {
        this.value0 = value0;
    };
    SetHorizontalAlignmentCenter.create = function (value0) {
        return new SetHorizontalAlignmentCenter(value0);
    };
    return SetHorizontalAlignmentCenter;
})();
var FreezeRows = /* #__PURE__ */ (function () {
    function FreezeRows(value0) {
        this.value0 = value0;
    };
    FreezeRows.create = function (value0) {
        return new FreezeRows(value0);
    };
    return FreezeRows;
})();
var InsertBoxText = /* #__PURE__ */ (function () {
    function InsertBoxText(value0) {
        this.value0 = value0;
    };
    InsertBoxText.create = function (value0) {
        return new InsertBoxText(value0);
    };
    return InsertBoxText;
})();
var InsertText = /* #__PURE__ */ (function () {
    function InsertText(value0) {
        this.value0 = value0;
    };
    InsertText.create = function (value0) {
        return new InsertText(value0);
    };
    return InsertText;
})();
var InsertTempText = /* #__PURE__ */ (function () {
    function InsertTempText(value0) {
        this.value0 = value0;
    };
    InsertTempText.create = function (value0) {
        return new InsertTempText(value0);
    };
    return InsertTempText;
})();
var InsertBoldText = /* #__PURE__ */ (function () {
    function InsertBoldText(value0) {
        this.value0 = value0;
    };
    InsertBoldText.create = function (value0) {
        return new InsertBoldText(value0);
    };
    return InsertBoldText;
})();
var InsertItalicText = /* #__PURE__ */ (function () {
    function InsertItalicText(value0) {
        this.value0 = value0;
    };
    InsertItalicText.create = function (value0) {
        return new InsertItalicText(value0);
    };
    return InsertItalicText;
})();
var InsertNumber = /* #__PURE__ */ (function () {
    function InsertNumber(value0) {
        this.value0 = value0;
    };
    InsertNumber.create = function (value0) {
        return new InsertNumber(value0);
    };
    return InsertNumber;
})();
var InsertDate = /* #__PURE__ */ (function () {
    function InsertDate(value0) {
        this.value0 = value0;
    };
    InsertDate.create = function (value0) {
        return new InsertDate(value0);
    };
    return InsertDate;
})();
var InsertFormula = /* #__PURE__ */ (function () {
    function InsertFormula(value0) {
        this.value0 = value0;
    };
    InsertFormula.create = function (value0) {
        return new InsertFormula(value0);
    };
    return InsertFormula;
})();
var InsertAndShowFormula = /* #__PURE__ */ (function () {
    function InsertAndShowFormula(value0) {
        this.value0 = value0;
    };
    InsertAndShowFormula.create = function (value0) {
        return new InsertAndShowFormula(value0);
    };
    return InsertAndShowFormula;
})();
var InsertInputNumber = /* #__PURE__ */ (function () {
    function InsertInputNumber(value0) {
        this.value0 = value0;
    };
    InsertInputNumber.create = function (value0) {
        return new InsertInputNumber(value0);
    };
    return InsertInputNumber;
})();
var InsertInputCell = /* #__PURE__ */ (function () {
    function InsertInputCell(value0) {
        this.value0 = value0;
    };
    InsertInputCell.create = function (value0) {
        return new InsertInputCell(value0);
    };
    return InsertInputCell;
})();
var InsertHeader = /* #__PURE__ */ (function () {
    function InsertHeader(value0) {
        this.value0 = value0;
    };
    InsertHeader.create = function (value0) {
        return new InsertHeader(value0);
    };
    return InsertHeader;
})();
var InsertSubHeader = /* #__PURE__ */ (function () {
    function InsertSubHeader(value0) {
        this.value0 = value0;
    };
    InsertSubHeader.create = function (value0) {
        return new InsertSubHeader(value0);
    };
    return InsertSubHeader;
})();
var InsertTotal = /* #__PURE__ */ (function () {
    function InsertTotal(value0) {
        this.value0 = value0;
    };
    InsertTotal.create = function (value0) {
        return new InsertTotal(value0);
    };
    return InsertTotal;
})();
var InsertBoldLine = /* #__PURE__ */ (function () {
    function InsertBoldLine(value0) {
        this.value0 = value0;
    };
    InsertBoldLine.create = function (value0) {
        return new InsertBoldLine(value0);
    };
    return InsertBoldLine;
})();
var InsertItalicLine = /* #__PURE__ */ (function () {
    function InsertItalicLine(value0) {
        this.value0 = value0;
    };
    InsertItalicLine.create = function (value0) {
        return new InsertItalicLine(value0);
    };
    return InsertItalicLine;
})();
var InsertBoldItalicLine = /* #__PURE__ */ (function () {
    function InsertBoldItalicLine(value0) {
        this.value0 = value0;
    };
    InsertBoldItalicLine.create = function (value0) {
        return new InsertBoldItalicLine(value0);
    };
    return InsertBoldItalicLine;
})();
var Highlight = /* #__PURE__ */ (function () {
    function Highlight(value0) {
        this.value0 = value0;
    };
    Highlight.create = function (value0) {
        return new Highlight(value0);
    };
    return Highlight;
})();
var DrawOuterBorder = /* #__PURE__ */ (function () {
    function DrawOuterBorder(value0) {
        this.value0 = value0;
    };
    DrawOuterBorder.create = function (value0) {
        return new DrawOuterBorder(value0);
    };
    return DrawOuterBorder;
})();
var DrawBottomBorder = /* #__PURE__ */ (function () {
    function DrawBottomBorder(value0) {
        this.value0 = value0;
    };
    DrawBottomBorder.create = function (value0) {
        return new DrawBottomBorder(value0);
    };
    return DrawBottomBorder;
})();
var CenterAlignCell = /* #__PURE__ */ (function () {
    function CenterAlignCell(value0) {
        this.value0 = value0;
    };
    CenterAlignCell.create = function (value0) {
        return new CenterAlignCell(value0);
    };
    return CenterAlignCell;
})();
var CopyRight = /* #__PURE__ */ (function () {
    function CopyRight(value0) {
        this.value0 = value0;
    };
    CopyRight.create = function (value0) {
        return new CopyRight(value0);
    };
    return CopyRight;
})();
var CopyRightOnClean = /* #__PURE__ */ (function () {
    function CopyRightOnClean(value0) {
        this.value0 = value0;
    };
    CopyRightOnClean.create = function (value0) {
        return new CopyRightOnClean(value0);
    };
    return CopyRightOnClean;
})();
var SetBoldFalse = /* #__PURE__ */ (function () {
    function SetBoldFalse(value0) {
        this.value0 = value0;
    };
    SetBoldFalse.create = function (value0) {
        return new SetBoldFalse(value0);
    };
    return SetBoldFalse;
})();
var Scroll = /* #__PURE__ */ (function () {
    function Scroll(value0) {
        this.value0 = value0;
    };
    Scroll.create = function (value0) {
        return new Scroll(value0);
    };
    return Scroll;
})();
var AddFCFChart = /* #__PURE__ */ (function () {
    function AddFCFChart() {

    };
    AddFCFChart.value = new AddFCFChart();
    return AddFCFChart;
})();
var AddRealLifeAnalogyChart = /* #__PURE__ */ (function () {
    function AddRealLifeAnalogyChart(value0) {
        this.value0 = value0;
    };
    AddRealLifeAnalogyChart.create = function (value0) {
        return new AddRealLifeAnalogyChart(value0);
    };
    return AddRealLifeAnalogyChart;
})();
var SideText = /* #__PURE__ */ (function () {
    function SideText(value0) {
        this.value0 = value0;
    };
    SideText.create = function (value0) {
        return new SideText(value0);
    };
    return SideText;
})();
var General = /* #__PURE__ */ (function () {
    function General(value0) {
        this.value0 = value0;
    };
    General.create = function (value0) {
        return new General(value0);
    };
    return General;
})();
var Worksheet = /* #__PURE__ */ (function () {
    function Worksheet(value0) {
        this.value0 = value0;
    };
    Worksheet.create = function (value0) {
        return new Worksheet(value0);
    };
    return Worksheet;
})();
var CompanyValueCmd = /* #__PURE__ */ (function () {
    function CompanyValueCmd(value0) {
        this.value0 = value0;
    };
    CompanyValueCmd.create = function (value0) {
        return new CompanyValueCmd(value0);
    };
    return CompanyValueCmd;
})();
var AccountingCmd = /* #__PURE__ */ (function () {
    function AccountingCmd(value0) {
        this.value0 = value0;
    };
    AccountingCmd.create = function (value0) {
        return new AccountingCmd(value0);
    };
    return AccountingCmd;
})();
var DCFValuationCmd = /* #__PURE__ */ (function () {
    function DCFValuationCmd(value0) {
        this.value0 = value0;
    };
    DCFValuationCmd.create = function (value0) {
        return new DCFValuationCmd(value0);
    };
    return DCFValuationCmd;
})();
export {
    General,
    Worksheet,
    CompanyValueCmd,
    AccountingCmd,
    DCFValuationCmd,
    InsertBoxText,
    InsertText,
    InsertTempText,
    InsertBoldText,
    InsertItalicText,
    InsertNumber,
    InsertDate,
    InsertFormula,
    InsertAndShowFormula,
    InsertInputNumber,
    InsertInputCell,
    InsertHeader,
    InsertSubHeader,
    InsertTotal,
    InsertBoldLine,
    InsertItalicLine,
    InsertBoldItalicLine,
    Highlight,
    DrawOuterBorder,
    DrawBottomBorder,
    CenterAlignCell,
    CopyRight,
    CopyRightOnClean,
    SetBoldFalse,
    Scroll,
    AddAndHideWorksheet,
    GroupAndHideRows,
    ShowGroupedRows,
    ShowGridlines,
    SetColumnWidth,
    SetDefaultFontArial,
    SetHorizontalAlignmentCenter,
    FreezeRows,
    SideText,
    AddRealLifeAnalogyChart,
    AddFCFChart
};
