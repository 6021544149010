// Generated by purs version 0.15.13
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
var waccAndDiscountingWorksheetCommands = /* #__PURE__ */ (function () {
    return [ new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGridlines({
        showGridlines: false
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial.value), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "A:B",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "C:C",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "D:E",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "F:F",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "G:K",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "L:M",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawOuterBorder({
        rangeSelector: {
            rowIdx: 1,
            colIdx: 2,
            rowCount: 3,
            columnCount: 9
        }
    })) ];
})();
export {
    waccAndDiscountingWorksheetCommands
};
