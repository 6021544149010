// Generated by purs version 0.15.13
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_Writer from "../Control.Monad.Writer/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Control_Semigroupoid from "../Control.Semigroupoid/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Functor_Invariant from "../Data.Functor.Invariant/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Profunctor from "../Data.Profunctor/index.js";
import * as Data_Profunctor_Star from "../Data.Profunctor.Star/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var un = /* #__PURE__ */ Data_Newtype.un();
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var GCodec = /* #__PURE__ */ (function () {
    function GCodec(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    GCodec.create = function (value0) {
        return function (value1) {
            return new GCodec(value0, value1);
        };
    };
    return GCodec;
})();
var semigroupoidGCodec = function (dictBind) {
    var compose1 = Control_Semigroupoid.compose(Data_Profunctor_Star.semigroupoidStar(dictBind));
    return {
        compose: function (v) {
            return function (v1) {
                return new GCodec(v.value0, compose1(v.value1)(v1.value1));
            };
        }
    };
};
var profunctorGCodec = function (dictFunctor) {
    var map = Data_Functor.map(dictFunctor);
    return function (dictFunctor1) {
        var dimap = Data_Profunctor.dimap(Data_Profunctor_Star.profunctorStar(dictFunctor1));
        return {
            dimap: function (f) {
                return function (g) {
                    return function (v) {
                        return new GCodec(map(g)(v.value0), dimap(f)(g)(v.value1));
                    };
                };
            }
        };
    };
};
var mapCodec = function (dictBind) {
    var bindFlipped = Control_Bind.bindFlipped(dictBind);
    return function (f) {
        return function (g) {
            return function (v) {
                var enc = function (a) {
                    var v1 = Control_Monad_Writer.runWriter(un(Data_Profunctor_Star.Star)(v.value1)(g(a)));
                    return Control_Monad_Writer.writer(new Data_Tuple.Tuple(a, v1.value1));
                };
                var dec = function (x) {
                    return bindFlipped(f)(Control_Monad_Reader_Trans.runReaderT(v.value0)(x));
                };
                return new GCodec(dec, enc);
            };
        };
    };
};
var functorGCodec = function (dictFunctor) {
    var map = Data_Functor.map(dictFunctor);
    return function (dictFunctor1) {
        var map1 = Data_Functor.map(Data_Profunctor_Star.functorStar(dictFunctor1));
        return {
            map: function (f) {
                return function (v) {
                    return new GCodec(map(f)(v.value0), map1(f)(v.value1));
                };
            }
        };
    };
};
var invariantGCodec = function (dictFunctor) {
    var functorGCodec1 = functorGCodec(dictFunctor);
    return function (dictFunctor1) {
        return {
            imap: Data_Functor_Invariant.imapF(functorGCodec1(dictFunctor1))
        };
    };
};
var encoder = function (v) {
    return v.value1;
};
var encode = function (c) {
    var $179 = un(Data_Profunctor_Star.Star)(encoder(c));
    return function ($180) {
        return Control_Monad_Writer.execWriter($179($180));
    };
};
var decoder = function (v) {
    return v.value0;
};
var decode = function ($181) {
    return Control_Monad_Reader_Trans.runReaderT(decoder($181));
};
var composeCodec = function (dictBind) {
    var bindFlipped = Control_Bind.bindFlipped(dictBind);
    return function (v) {
        return function (v1) {
            return new GCodec(function (x) {
                return bindFlipped(Control_Monad_Reader_Trans.runReaderT(v.value0))(Control_Monad_Reader_Trans.runReaderT(v1.value0)(x));
            }, function (c) {
                var v2 = Control_Monad_Writer.runWriter(un(Data_Profunctor_Star.Star)(v.value1)(c));
                return Control_Monad_Writer.writer(new Data_Tuple.Tuple(v2.value0, Control_Monad_Writer.execWriter(un(Data_Profunctor_Star.Star)(v1.value1)(v2.value1))));
            });
        };
    };
};
var composeCodecFlipped = function (dictBind) {
    return Data_Function.flip(composeCodec(dictBind));
};
var codec = function (dec) {
    return function (enc) {
        return new GCodec(dec, function (x) {
            return Control_Monad_Writer.writer(enc(x));
        });
    };
};
var bihoistGCodec = function (f) {
    return function (g) {
        return function (v) {
            return new GCodec(f(v.value0), function ($182) {
                return g(v.value1($182));
            });
        };
    };
};
var hoistCodec = function (f) {
    return bihoistGCodec(Control_Monad_Reader_Trans.mapReaderT(f))(identity);
};
var basicCodec = function (f) {
    return function (g) {
        return new GCodec(f, function (x) {
            return Control_Monad_Writer.writer(new Data_Tuple.Tuple(x, g(x)));
        });
    };
};
var applyGCodec = function (dictApply) {
    var apply = Control_Apply.apply(dictApply);
    var functorGCodec1 = functorGCodec(dictApply.Functor0());
    return function (dictApply1) {
        var apply1 = Control_Apply.apply(Data_Profunctor_Star.applyStar(dictApply1));
        var functorGCodec2 = functorGCodec1(dictApply1.Functor0());
        return {
            apply: function (v) {
                return function (v1) {
                    return new GCodec(apply(v.value0)(v1.value0), apply1(v.value1)(v1.value1));
                };
            },
            Functor0: function () {
                return functorGCodec2;
            }
        };
    };
};
var bindGCodec = function (dictBind) {
    var bind = Control_Bind.bind(dictBind);
    var applyGCodec1 = applyGCodec(dictBind.Apply0());
    return function (dictBind1) {
        var bind1 = Control_Bind.bind(Data_Profunctor_Star.bindStar(dictBind1));
        var applyGCodec2 = applyGCodec1(dictBind1.Apply0());
        return {
            bind: function (v) {
                return function (f) {
                    return new GCodec(bind(v.value0)(function ($183) {
                        return decoder(f($183));
                    }), bind1(v.value1)(function ($184) {
                        return encoder(f($184));
                    }));
                };
            },
            Apply0: function () {
                return applyGCodec2;
            }
        };
    };
};
var applicativeGCodec = function (dictApplicative) {
    var pure = Control_Applicative.pure(dictApplicative);
    var applyGCodec1 = applyGCodec(dictApplicative.Apply0());
    return function (dictApplicative1) {
        var pure1 = Control_Applicative.pure(Data_Profunctor_Star.applicativeStar(dictApplicative1));
        var applyGCodec2 = applyGCodec1(dictApplicative1.Apply0());
        return {
            pure: function (x) {
                return new GCodec(pure(x), pure1(x));
            },
            Apply0: function () {
                return applyGCodec2;
            }
        };
    };
};
var monadGCodec = function (dictMonad) {
    var applicativeGCodec1 = applicativeGCodec(dictMonad.Applicative0());
    var bindGCodec1 = bindGCodec(dictMonad.Bind1());
    return function (dictMonad1) {
        var applicativeGCodec2 = applicativeGCodec1(dictMonad1.Applicative0());
        var bindGCodec2 = bindGCodec1(dictMonad1.Bind1());
        return {
            Applicative0: function () {
                return applicativeGCodec2;
            },
            Bind1: function () {
                return bindGCodec2;
            }
        };
    };
};
var altGCodec = function (dictAlt) {
    var alt = Control_Alt.alt(dictAlt);
    var functorGCodec1 = functorGCodec(dictAlt.Functor0());
    return function (dictAlt1) {
        var alt1 = Control_Alt.alt(Data_Profunctor_Star.altStar(dictAlt1));
        var functorGCodec2 = functorGCodec1(dictAlt1.Functor0());
        return {
            alt: function (v) {
                return function (v1) {
                    return new GCodec(alt(v.value0)(v1.value0), alt1(v.value1)(v1.value1));
                };
            },
            Functor0: function () {
                return functorGCodec2;
            }
        };
    };
};
var plusGCodec = function (dictPlus) {
    var empty = Control_Plus.empty(dictPlus);
    var altGCodec1 = altGCodec(dictPlus.Alt0());
    return function (dictPlus1) {
        var altGCodec2 = altGCodec1(dictPlus1.Alt0());
        return {
            empty: new GCodec(empty, Control_Plus.empty(Data_Profunctor_Star.plusStar(dictPlus1))),
            Alt0: function () {
                return altGCodec2;
            }
        };
    };
};
var alternativeGCodec = function (dictAlternative) {
    var applicativeGCodec1 = applicativeGCodec(dictAlternative.Applicative0());
    var plusGCodec1 = plusGCodec(dictAlternative.Plus1());
    return function (dictAlternative1) {
        var applicativeGCodec2 = applicativeGCodec1(dictAlternative1.Applicative0());
        var plusGCodec2 = plusGCodec1(dictAlternative1.Plus1());
        return {
            Applicative0: function () {
                return applicativeGCodec2;
            },
            Plus1: function () {
                return plusGCodec2;
            }
        };
    };
};
var monadPlusGCodec = function (dictMonadPlus) {
    var monadGCodec1 = monadGCodec(dictMonadPlus.Monad0());
    var alternativeGCodec1 = alternativeGCodec(dictMonadPlus.Alternative1());
    return function (dictMonadPlus1) {
        var monadGCodec2 = monadGCodec1(dictMonadPlus1.Monad0());
        var alternativeGCodec2 = alternativeGCodec1(dictMonadPlus1.Alternative1());
        return {
            Monad0: function () {
                return monadGCodec2;
            },
            Alternative1: function () {
                return alternativeGCodec2;
            }
        };
    };
};
export {
    GCodec,
    decoder,
    encoder,
    bihoistGCodec,
    codec,
    decode,
    encode,
    mapCodec,
    composeCodec,
    composeCodecFlipped,
    hoistCodec,
    basicCodec,
    functorGCodec,
    invariantGCodec,
    applyGCodec,
    applicativeGCodec,
    bindGCodec,
    monadGCodec,
    profunctorGCodec,
    altGCodec,
    plusGCodec,
    alternativeGCodec,
    monadPlusGCodec,
    semigroupoidGCodec
};
