// Generated by purs version 0.15.13
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
var companyValueIntroWorksheetCommands = /* #__PURE__ */ (function () {
    return [ new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGridlines({
        showGridlines: false
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial.value), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "A:A",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "B:B",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "C:Z",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth10.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 1,
        colIdx: 1,
        text: "What is a company worth?"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 3,
        colIdx: 1,
        text: "This question has many answers because investors will prescribe different amounts of value to a company. Think does someone invested in the stock share the same ideas of value as someone who bought the debt?"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 4,
        colIdx: 1,
        text: "There are 2 key ways that investors may approach a company's value:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 6,
        colIdx: 1,
        text: "1. Market Valuation:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "The value of the company today which is determined by its stock market valuation or what its existing investors have prescribed in the prevailing price."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 9,
        colIdx: 1,
        text: "2. Intrinsic Valuation:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 2,
        text: "The value of the company based on our own thoughts and hopefully analysis."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 1,
        text: "First we will define the 2 key definitions and from there explain why they differ. This will solve why market and intrinsic value are not the same."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 14,
        colIdx: 1,
        text: "Equity Value: "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 2,
        text: "The total value of the company, or recalling from our accounting module the difference between the total assets and total liabilities, available to the common stock or equity investors."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 17,
        colIdx: 1,
        text: "Enterprise Value: "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 2,
        text: "The value of the company's core operating business or the difference betweent its operating assets and its operating liabilities, available to all investors."
    })) ];
})();
export {
    companyValueIntroWorksheetCommands
};
