// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
var incomeStatementDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 6,
        targetColIdx: 5,
        textArray: [ "We start the Income Statement with Revenue. This is the total amount the company ", "receives (is paid) for the delivery/provision of goods and services. ", "", "For example, when Kellogg sells 1000s of its $3 boxes of cereal to a supermarket chain and ", "ships the boxes to the supermarket it recognises the $3000. However, it is key to ", "understand the timing of this known as the Revenue Principle. This means a firm can ", "recognise revenue only once it has satisfied the performance obligation(s) of its contract. ", "", "So let\u2019s say the supermarket decides to order the $3000 worth of boxes, but for the ", "following year. The revenue cannot be recognised (regardless of whether they pay today) ", "until next year when Kellogg ships the product to the customer. ", "", "Synonyms: Sales, Net Sales, Turnover." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 7,
        targetColIdx: 5,
        textArray: [ "The costs associated with the goods or services sold during this period. This represents the ", "cost to make and/or buy the grain and materials to create cereal boxes. ", "", "For example if Kellogg is selling 1000 boxes of cereal to its customers that cost $0.5 for the ", "cereal and $0.5 for the box, then Kellogg would have the cost which is $1000 for the ", "making and selling of the product.", "", "Like above this follows the Matching Principle. This means that the expenses are ", "recognised when the benefit (or revenue is). Both of this principles hold even when cash is ", "received." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 8,
        targetColIdx: 5,
        textArray: [ "Gross Profit = Revenue - Cost of Goods Sold. ", "", "Alongside this we will define the gross margin as the ratio of gross profit and revenue. ", "", "Gross Margin (%) = Gross Profit / Revenue * 100. ", "", "These figures represent 2 key things. ", "", "First it represents the pricing power. The more you can charge above the cost of your ", "product shows that the company has pricing power. For example, Kellogg can charge more ", "for its products because people prefer the brand and are willing to pay for it. ", "", "Secondly, if gross margin is improving, it could also suggest the company is able to ", "purchase its raw materials at a cheaper cost (which we call purchasing power)." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 10,
        targetColIdx: 5,
        textArray: [ "The next part to consider is the costs associated with the ongoing running of the business. ", "Often considered the cost of developing or providing non-consumable parts for the product ", "or system." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 1,
            rowCount: 5,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 10,
        targetColIdx: 5,
        textArray: [ "Costs of selling the product such as the advertisement campaign, to pay for upkeep of ", "offices such as supplies of paper etc." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 11,
        targetColIdx: 5,
        textArray: [ "The cost of developing new products. For example Kellogg research on its Special K line of ", "products." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 12,
        targetColIdx: 5,
        textArray: [ "This is the cost of a long-term asset that we allocate over time instead of upfront. To do so ", "we take the cost of an asset and spread it over its useful life. Intuitively we can consider ", "this as the wearing down of equipment over time. ", "", "For example when Kellogg buys a delivery van its value the following year is less than what ", "they spent on it. ", "", "Depreciation is for physical (tangible) assets and Amortization is for non-physical ", "(intangible). ", "", "D&A is tax-deductible meaning the company is able to expense it in order to reduce the ", "profits it makes and save on the taxes it pays. However, it is also important to note that ", "this is a non-cash expense because the company doesn\u2019t pay for depreciation in cash. ", "", "Remember, it represents the wearing of long-term assets that\u2019s actually not paid for but ", "realised in their value. It\u2019s this loss of value that allows companies to expense it and reduce ", "their taxes." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 13,
        targetColIdx: 5,
        textArray: [ "When companies pay employees bonuses, they can pay it in stock. It will reduce pre-tax ", "income and is non-cash much like depreciation." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 16,
        targetColIdx: 5,
        textArray: [ "Operating Income = Gross Profit - Operating Expense. ", "", "This looks at the income prior to any side activities of the company and any capital ", "structure or tax implications. ", "", "Often this figure can be used to evaluate economies of scale. For example can my SG&A ", "stay the same despite selling more products. This would show economies of scale." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 18,
        targetColIdx: 5,
        textArray: [ "Interest expense is the cost of borrowing money. This is the sum of the debt repayments ", "each period for a company." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 19,
        targetColIdx: 5,
        textArray: [ "Interest income is the money received on savings/investments." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 24,
        targetColIdx: 5,
        textArray: [ "Income Before Income Tax = Operating Income + Net Interest +/- Other Gains or Losses", "", "where Net Interest = Interest Income - Interest Expense " ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 26,
        targetColIdx: 5,
        textArray: [ "Next is the tax paid on the income generated. Much like how people\u2019s income is taxed, a ", "company must pay Corporation Tax on the profits that it generates. Tax rules are ", "complicated, and many companies try to reduce their tax expenses to as low as possible ", "without damaging their reputation. What is worth knowing for an interview is that ", "companies have a \u201csingle\u201d tax rate that is the average rate across all the countries it ", "operates." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 26,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 28,
        targetColIdx: 5,
        textArray: [ "We finish the Income Statement with Net Income. ", "", "Net Income is the entity's income after accounting for all costs, interest, and tax expensed ", "in that period: Net Income = Income Before Tax - Income Tax. ", "", "Net Income looks at the value or profits available to the common shareholders. In this ", "example it is the value available to the Kellogg Company public shareholders.", "", "If things were simple, when we make a sale, the cash is paid immediately by the customer ", "and the product is delivered. We also pay are expenses immediately. As a result, our profits ", "would be our cash flow. But, in real life, it is never this simple because we allow customers ", "to pay later, and we need recognise the revenue when we deliver the product even if we ", "have not been paid. Now you can see how this creates a difference in the profit we ", "generate and the cash flows. ", "", "Once we conclude all the statements we will run through some examples." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 1,
        targetColIdx: 5,
        textArray: [ "To recap what the Income Statement does. ", "", "It takes what the company is paid for delivering a product or service (the top line) and  ", "charges the costs of making that product or giving that service. ", "", "We then subtract any additional costs of running the business throughout that period to get ", "us to our operating income, the profit from the business running its core operations. ", "", "We then charge for the cost of borrowing (or what is owed to debt providers) and pay the ", "taxes on what remains. ", "", "This leaves us with Net Income (or the bottom line) which encompasses the profitability ", "available to just the shareholders (equity investors) of the business." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 1,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ] ];
})();
export {
    incomeStatementDescriptions
};
