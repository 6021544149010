// Generated by purs version 0.15.13
import * as Affjax from "../Affjax/index.js";
import * as Affjax_RequestBody from "../Affjax.RequestBody/index.js";
import * as Affjax_RequestHeader from "../Affjax.RequestHeader/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_Web from "../Affjax.Web/index.js";
import * as BankingReady_Capability_Dialog from "../BankingReady.Capability.Dialog/index.js";
import * as BankingReady_Foreign_MessageEvent from "../BankingReady.Foreign.MessageEvent/index.js";
import * as BankingReady_Store from "../BankingReady.Store/index.js";
import * as BankingReady_Utility_HTML from "../BankingReady.Utility.HTML/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Data_Argonaut_Decode from "../Data.Argonaut.Decode/index.js";
import * as Data_Argonaut_Decode_Class from "../Data.Argonaut.Decode.Class/index.js";
import * as Data_Argonaut_Decode_Error from "../Data.Argonaut.Decode.Error/index.js";
import * as Data_Argonaut_Encode_Class from "../Data.Argonaut.Encode.Class/index.js";
import * as Data_Codec from "../Data.Codec/index.js";
import * as Data_Codec_Argonaut from "../Data.Codec.Argonaut/index.js";
import * as Data_Codec_Argonaut_Record from "../Data.Codec.Argonaut.Record/index.js";
import * as Data_Codec_Argonaut_Variant from "../Data.Codec.Argonaut.Variant/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_HTTP_Method from "../Data.HTTP.Method/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Profunctor from "../Data.Profunctor/index.js";
import * as Data_Variant from "../Data.Variant/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_Query_Event from "../Halogen.Query.Event/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_Store_Monad from "../Halogen.Store.Monad/index.js";
import * as Jwt from "../Jwt/index.js";
import * as Network_RemoteData from "../Network.RemoteData/index.js";
import * as Type_Equality from "../Type.Equality/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var record = /* #__PURE__ */ Data_Codec_Argonaut_Record.record();
var rowListCodecCons = /* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(Data_Codec_Argonaut_Record.rowListCodecNil)()();
var inj = /* #__PURE__ */ Data_Variant.inj();
var codeVerifierIsSymbol = {
    reflectSymbol: function () {
        return "codeVerifier";
    }
};
var inj1 = /* #__PURE__ */ inj(codeVerifierIsSymbol);
var authorizationCodeIsSymbol = {
    reflectSymbol: function () {
        return "authorizationCode";
    }
};
var inj2 = /* #__PURE__ */ inj(authorizationCodeIsSymbol);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var fromJsonString = /* #__PURE__ */ Data_Argonaut_Decode.fromJsonString(Data_Argonaut_Decode_Class.decodeJsonJson);
var gets = /* #__PURE__ */ Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM);
var gEncodeJsonCons = /* #__PURE__ */ Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString);
var encodeJson = /* #__PURE__ */ Data_Argonaut_Encode_Class.encodeJson(/* #__PURE__ */ Data_Argonaut_Encode_Class.encodeRecord(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(/* #__PURE__ */ gEncodeJsonCons(Data_Argonaut_Encode_Class.gEncodeJsonNil)({
    reflectSymbol: function () {
        return "redirect_uri";
    }
})())({
    reflectSymbol: function () {
        return "grant_type";
    }
})())({
    reflectSymbol: function () {
        return "code_verifier";
    }
})())({
    reflectSymbol: function () {
        return "code";
    }
})())({
    reflectSymbol: function () {
        return "client_id";
    }
})())());
var LoggedIn = /* #__PURE__ */ (function () {
    function LoggedIn() {

    };
    LoggedIn.value = new LoggedIn();
    return LoggedIn;
})();
var CodeVerifier = /* #__PURE__ */ (function () {
    function CodeVerifier(value0) {
        this.value0 = value0;
    };
    CodeVerifier.create = function (value0) {
        return new CodeVerifier(value0);
    };
    return CodeVerifier;
})();
var AuthorizationCode = /* #__PURE__ */ (function () {
    function AuthorizationCode(value0) {
        this.value0 = value0;
    };
    AuthorizationCode.create = function (value0) {
        return new AuthorizationCode(value0);
    };
    return AuthorizationCode;
})();
var LogIn = /* #__PURE__ */ (function () {
    function LogIn() {

    };
    LogIn.value = new LogIn();
    return LogIn;
})();
var HandleMessage = /* #__PURE__ */ (function () {
    function HandleMessage(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    HandleMessage.create = function (value0) {
        return function (value1) {
            return new HandleMessage(value0, value1);
        };
    };
    return HandleMessage;
})();
var RequestTokens = /* #__PURE__ */ (function () {
    function RequestTokens() {

    };
    RequestTokens.value = new RequestTokens();
    return RequestTokens;
})();
var tokenResponseCodec = /* #__PURE__ */ Data_Codec_Argonaut.object("TokenResponse")(/* #__PURE__ */ record(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ rowListCodecCons({
    reflectSymbol: function () {
        return "id_token";
    }
}))()()({
    reflectSymbol: function () {
        return "access_token";
    }
}))({
    access_token: Data_Codec_Argonaut.string,
    id_token: Data_Codec_Argonaut.string
}));
var loginMessageCodec = /* #__PURE__ */ (function () {
    var toVariant = function (v) {
        if (v instanceof CodeVerifier) {
            return inj1(Type_Proxy["Proxy"].value)(v.value0);
        };
        if (v instanceof AuthorizationCode) {
            return inj2(Type_Proxy["Proxy"].value)(v.value0);
        };
        throw new Error("Failed pattern match at BankingReady.Component.Login (line 57, column 15 - line 59, column 70): " + [ v.constructor.name ]);
    };
    var fromVariant = Data_Variant.match()()()({
        codeVerifier: CodeVerifier.create,
        authorizationCode: AuthorizationCode.create
    });
    return Data_Profunctor.dimap(Data_Codec.profunctorGCodec(Control_Monad_Reader_Trans.functorReaderT(Data_Either.functorEither))(Control_Monad_Writer_Trans.functorWriterT(Data_Identity.functorIdentity)))(toVariant)(fromVariant)(Data_Codec_Argonaut_Variant.variantMatch()(Data_Codec_Argonaut_Variant.variantCodecCons(Data_Codec_Argonaut_Variant.variantCodecCons(Data_Codec_Argonaut_Variant.variantCodecNil)()()(codeVerifierIsSymbol)(Type_Equality.refl))()()(authorizationCodeIsSymbol)(Type_Equality.refl))({
        codeVerifier: new Data_Either.Right(Data_Codec_Argonaut.object("CodeVerifier")(record(rowListCodecCons(codeVerifierIsSymbol))({
            codeVerifier: Data_Codec_Argonaut.string
        }))),
        authorizationCode: new Data_Either.Right(Data_Codec_Argonaut.object("AuthorizationCode")(record(rowListCodecCons(authorizationCodeIsSymbol))({
            authorizationCode: Data_Codec_Argonaut.string
        })))
    }));
})();
var idTokenPayloadCodec = /* #__PURE__ */ Data_Codec_Argonaut.object("IdTokenPayload")(/* #__PURE__ */ record(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ Data_Codec_Argonaut_Record.rowListCodecCons(/* #__PURE__ */ rowListCodecCons({
    reflectSymbol: function () {
        return "sub";
    }
}))()()({
    reflectSymbol: function () {
        return "name";
    }
}))()()({
    reflectSymbol: function () {
        return "email";
    }
}))({
    sub: Data_Codec_Argonaut.string,
    email: Data_Codec_Argonaut.string,
    name: Data_Codec_Argonaut.string
}));
var component = function (dictMonadAff) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()));
    var liftAff = Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff));
    return function (dictMonadStore) {
        var monadStoreHalogenM = Halogen_Store_Monad.monadStoreHalogenM(dictMonadStore);
        var getStore = Halogen_Store_Monad.getStore(monadStoreHalogenM);
        var updateStore = Halogen_Store_Monad.updateStore(monadStoreHalogenM);
        return function (dictManageDialog) {
            var manageDialogHalogenM = BankingReady_Capability_Dialog.manageDialogHalogenM(dictManageDialog);
            var openDialog = BankingReady_Capability_Dialog.openDialog(manageDialogHalogenM);
            var closeDialog = BankingReady_Capability_Dialog.closeDialog(manageDialogHalogenM);
            var render = function (v) {
                return Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex min-h-screen flex-col items-center justify-center bg-white") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex grow flex-col items-center justify-center pt-14") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a                  flex h-12 w-32 cursor-pointer items-center justify-center rounded \x0a                  bg-blue-600 text-base text-white shadow hover:bg-blue-700 active:shadow-md\x0a                  "), Halogen_HTML_Events.onClick(function (v1) {
                    return LogIn.value;
                }) ])([ Halogen_HTML_Core.text("Login") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("h-14 px-8 pb-3 text-center text-sm") ])([ Halogen_HTML_Elements.span([  ])([ Halogen_HTML_Core.text("If you need any help, please don't hesitate to contact ") ]), Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("text-blue-500") ])([ Halogen_HTML_Core.text("hello@bankingready.com") ]) ]) ]);
            };
            var handleAction = function (v) {
                if (v instanceof LogIn) {
                    return bind(liftEffect(Web_HTML.window))(function (window$prime) {
                        return discard(Halogen_Query_HalogenM["subscribe$prime"](function (sid) {
                            return Halogen_Query_Event.eventListener("message")(Web_HTML_Window.toEventTarget(window$prime))(function (ev) {
                                return new Data_Maybe.Just(new HandleMessage(sid, ev));
                            });
                        }))(function () {
                            return discard(modify_(function (v1) {
                                var $122 = {};
                                for (var $123 in v1) {
                                    if ({}.hasOwnProperty.call(v1, $123)) {
                                        $122[$123] = v1[$123];
                                    };
                                };
                                $122.codeVerifier = Network_RemoteData.Loading.value;
                                $122.authorizationCode = Network_RemoteData.Loading.value;
                                return $122;
                            }))(function () {
                                return openDialog;
                            });
                        });
                    });
                };
                if (v instanceof HandleMessage) {
                    var v1 = BankingReady_Foreign_MessageEvent.messageData(v.value1);
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return liftEffect(Effect_Console.log("There was no data in MessageEvent."));
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        var v2 = fromJsonString(v1.value0);
                        if (v2 instanceof Data_Either.Left) {
                            return liftEffect(Effect_Console.log(Data_Argonaut_Decode_Error.printJsonDecodeError(v2.value0)));
                        };
                        if (v2 instanceof Data_Either.Right) {
                            var v3 = Data_Codec.decode(loginMessageCodec)(v2.value0);
                            if (v3 instanceof Data_Either.Left) {
                                return liftEffect(Effect_Console.log(Data_Codec_Argonaut.printJsonDecodeError(v3.value0)));
                            };
                            if (v3 instanceof Data_Either.Right) {
                                if (v3.value0 instanceof CodeVerifier) {
                                    return modify_(function (v4) {
                                        var $131 = {};
                                        for (var $132 in v4) {
                                            if ({}.hasOwnProperty.call(v4, $132)) {
                                                $131[$132] = v4[$132];
                                            };
                                        };
                                        $131.codeVerifier = new Network_RemoteData.Success(v3.value0.value0.codeVerifier);
                                        return $131;
                                    });
                                };
                                if (v3.value0 instanceof AuthorizationCode) {
                                    return discard(modify_(function (v4) {
                                        var $136 = {};
                                        for (var $137 in v4) {
                                            if ({}.hasOwnProperty.call(v4, $137)) {
                                                $136[$137] = v4[$137];
                                            };
                                        };
                                        $136.authorizationCode = new Network_RemoteData.Success(v3.value0.value0.authorizationCode);
                                        return $136;
                                    }))(function () {
                                        return discard(closeDialog)(function () {
                                            return discard(Halogen_Query_HalogenM.unsubscribe(v.value0))(function () {
                                                return handleAction(RequestTokens.value);
                                            });
                                        });
                                    });
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Login (line 137, column 39 - line 146, column 47): " + [ v3.value0.constructor.name ]);
                            };
                            throw new Error("Failed pattern match at BankingReady.Component.Login (line 135, column 15 - line 146, column 47): " + [ v3.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at BankingReady.Component.Login (line 132, column 11 - line 146, column 47): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Login (line 129, column 7 - line 146, column 47): " + [ v1.constructor.name ]);
                };
                if (v instanceof RequestTokens) {
                    return bind(getStore)(function (v1) {
                        return bind(gets(function (v2) {
                            return v2.codeVerifier;
                        }))(function (mbCodeVerifier) {
                            return bind(gets(function (v2) {
                                return v2.authorizationCode;
                            }))(function (mbAuthorizationCode) {
                                if (mbCodeVerifier instanceof Network_RemoteData.Success && mbAuthorizationCode instanceof Network_RemoteData.Success) {
                                    var reqContent = encodeJson({
                                        grant_type: "authorization_code",
                                        client_id: v1.auth0ClientId,
                                        code_verifier: mbCodeVerifier.value0,
                                        code: mbAuthorizationCode.value0,
                                        redirect_uri: v1.baseUrl + "/dialog/logged-in"
                                    });
                                    return bind(liftAff(Affjax_Web.request({
                                        username: Affjax.defaultRequest.username,
                                        password: Affjax.defaultRequest.password,
                                        withCredentials: Affjax.defaultRequest.withCredentials,
                                        timeout: Affjax.defaultRequest.timeout,
                                        method: new Data_Either.Left(Data_HTTP_Method.POST.value),
                                        url: v1.baseUrl + "/auth0/oauth/token",
                                        headers: [ new Affjax_RequestHeader.ContentType("application/json") ],
                                        content: new Data_Maybe.Just(Affjax_RequestBody.json(reqContent)),
                                        responseFormat: Affjax_ResponseFormat.json
                                    })))(function (mbResponse) {
                                        if (mbResponse instanceof Data_Either.Left) {
                                            return liftEffect(Effect_Console.log(Affjax.printError(mbResponse.value0)));
                                        };
                                        if (mbResponse instanceof Data_Either.Right) {
                                            var v2 = Data_Codec.decode(tokenResponseCodec)(mbResponse.value0.body);
                                            if (v2 instanceof Data_Either.Left) {
                                                return liftEffect(Effect_Console.log(Data_Codec_Argonaut.printJsonDecodeError(v2.value0)));
                                            };
                                            if (v2 instanceof Data_Either.Right) {
                                                var v3 = Jwt.decode(v2.value0.id_token);
                                                if (v3 instanceof Data_Either.Left) {
                                                    return liftEffect(Effect_Console.log("Failed JWT decoding."));
                                                };
                                                if (v3 instanceof Data_Either.Right) {
                                                    var v4 = Data_Codec.decode(idTokenPayloadCodec)(v3.value0);
                                                    if (v4 instanceof Data_Either.Left) {
                                                        return liftEffect(Effect_Console.log(Data_Codec_Argonaut.printJsonDecodeError(v4.value0)));
                                                    };
                                                    if (v4 instanceof Data_Either.Right) {
                                                        var profile = {
                                                            userId: v4.value0.sub,
                                                            email: v4.value0.email,
                                                            name: v4.value0.name
                                                        };
                                                        return discard(updateStore(new BankingReady_Store.LoginUser(v2.value0.access_token, profile)))(function () {
                                                            return Halogen_Query_HalogenM.raise(LoggedIn.value);
                                                        });
                                                    };
                                                    throw new Error("Failed pattern match at BankingReady.Component.Login (line 183, column 40 - line 199, column 39): " + [ v4.constructor.name ]);
                                                };
                                                throw new Error("Failed pattern match at BankingReady.Component.Login (line 181, column 17 - line 199, column 39): " + [ v3.constructor.name ]);
                                            };
                                            throw new Error("Failed pattern match at BankingReady.Component.Login (line 178, column 31 - line 199, column 39): " + [ v2.constructor.name ]);
                                        };
                                        throw new Error("Failed pattern match at BankingReady.Component.Login (line 175, column 11 - line 199, column 39): " + [ mbResponse.constructor.name ]);
                                    });
                                };
                                return liftEffect(Effect_Console.log("Can't request tokens if both codeVerifier and authorizationCode haven't been loaded successfully."));
                            });
                        });
                    });
                };
                throw new Error("Failed pattern match at BankingReady.Component.Login (line 115, column 18 - line 201, column 137): " + [ v.constructor.name ]);
            };
            return Halogen_Component.mkComponent({
                initialState: function (v) {
                    return {
                        codeVerifier: Network_RemoteData.NotAsked.value,
                        authorizationCode: Network_RemoteData.NotAsked.value
                    };
                },
                render: render,
                "eval": Halogen_Component.mkEval({
                    handleQuery: Halogen_Component.defaultEval.handleQuery,
                    receive: Halogen_Component.defaultEval.receive,
                    initialize: Halogen_Component.defaultEval.initialize,
                    finalize: Halogen_Component.defaultEval.finalize,
                    handleAction: handleAction
                })
            });
        };
    };
};
export {
    CodeVerifier,
    AuthorizationCode,
    loginMessageCodec,
    tokenResponseCodec,
    idTokenPayloadCodec,
    LoggedIn,
    LogIn,
    HandleMessage,
    RequestTokens,
    component
};
