// Generated by purs version 0.15.13
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Utility_HTML from "../BankingReady.Utility.HTML/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var PreviousLesson = /* #__PURE__ */ (function () {
    function PreviousLesson(value0) {
        this.value0 = value0;
    };
    PreviousLesson.create = function (value0) {
        return new PreviousLesson(value0);
    };
    return PreviousLesson;
})();
var NextLesson = /* #__PURE__ */ (function () {
    function NextLesson(value0) {
        this.value0 = value0;
    };
    NextLesson.create = function (value0) {
        return new NextLesson(value0);
    };
    return NextLesson;
})();
var Next = /* #__PURE__ */ (function () {
    function Next() {

    };
    Next.value = new Next();
    return Next;
})();
var Back = /* #__PURE__ */ (function () {
    function Back() {

    };
    Back.value = new Back();
    return Back;
})();
var component = function (dictMonadAff) {
    var render = function (v) {
        return Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex min-h-screen flex-col items-center justify-center bg-white text-zinc-900") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("h-9 min-w-full pl-6 pt-4 text-sm") ])([ Halogen_HTML_Elements.a([ BankingReady_Utility_HTML.css("cursor-pointer hover:underline hover:underline-offset-2"), BankingReady_Utility_HTML.safeHref(BankingReady_Data_Route.Home.value) ])([ Halogen_HTML_Core.text("Home") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("flex grow select-none flex-col items-center justify-center pb-9") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a                  my-3 flex h-12 w-32 cursor-pointer items-center justify-center\x0a                  rounded bg-blue-600 text-base text-white shadow hover:bg-blue-700\x0a                  active:shadow-md\x0a                  "), Halogen_HTML_Events.onClick(function (v1) {
            return Next.value;
        }) ])([ Halogen_HTML_Core.text("Next Lesson") ]) ]) ]);
    };
    var initialState = function (v) {
        return {
            route: v.route
        };
    };
    var handleAction = function (v) {
        if (v instanceof Next) {
            return bind(get)(function (v1) {
                return Halogen_Query_HalogenM.raise(new NextLesson({
                    currentRoute: v1.route
                }));
            });
        };
        if (v instanceof Back) {
            return bind(get)(function (v1) {
                return Halogen_Query_HalogenM.raise(new PreviousLesson({
                    currentRoute: v1.route
                }));
            });
        };
        throw new Error("Failed pattern match at BankingReady.Page.IntroLesson (line 44, column 18 - line 52, column 55): " + [ v.constructor.name ]);
    };
    return Halogen_Component.mkComponent({
        initialState: initialState,
        render: render,
        "eval": Halogen_Component.mkEval({
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: Halogen_Component.defaultEval.initialize,
            finalize: Halogen_Component.defaultEval.finalize,
            handleAction: handleAction
        })
    });
};
export {
    PreviousLesson,
    NextLesson,
    Next,
    Back,
    component
};
