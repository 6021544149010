// Generated by purs version 0.15.13
var Center = /* #__PURE__ */ (function () {
    function Center() {

    };
    Center.value = new Center();
    return Center;
})();
var showHorizontalAlignment = {
    show: function (v) {
        return "Center";
    }
};
export {
    Center,
    showHorizontalAlignment
};
