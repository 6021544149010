"use strict"

// Custom function, not part of the excel.js API
export const openDialog = function (baseUrl) {
    return function (path) {
        return function () {
            window.Office.context.ui.displayDialogAsync(baseUrl.concat(path),
                { height: 30, width: 12 },
                function (asyncResult) {
                    window.dialog = asyncResult.value;
                    window.dialog.addEventHandler(window.Office.EventType.DialogMessageReceived,
                        function (args) {
                            window.postMessage(args.message, baseUrl)
                        }
                    );
                }
            );
        };
    };
};

export const closeDialog = function () {
    window.dialog.close()
    // TODO: Should window.dialog be deleted? 
}
