"use strict"

export const addChartImpl = function (chartCollection) {
    return function (chartType) {
        return function (sourceDataRange) {
            return function (seriesBy) {
                return function (){
                    return chartCollection.add(chartType, sourceDataRange, seriesBy)
                };
            };
        };
    };
};

export const getChart = function (chartCollection) {
    return function (chartName) {
        return chartCollection.getItem(chartName);
    };
};
