// Generated by purs version 0.15.13
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Argonaut_Parser from "../Data.Argonaut.Parser/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Prism_Either from "../Data.Lens.Prism.Either/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_Profunctor_Choice from "../Data.Profunctor.Choice/index.js";
import * as Data_String_Base64 from "../Data.String.Base64/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var _Left = /* #__PURE__ */ Data_Lens_Prism_Either["_Left"](Data_Profunctor_Choice.choiceFn);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var MalformedToken = /* #__PURE__ */ (function () {
    function MalformedToken() {

    };
    MalformedToken.value = new MalformedToken();
    return MalformedToken;
})();
var Base64DecodeError = /* #__PURE__ */ (function () {
    function Base64DecodeError(value0) {
        this.value0 = value0;
    };
    Base64DecodeError.create = function (value0) {
        return new Base64DecodeError(value0);
    };
    return Base64DecodeError;
})();
var JsonDecodeError = /* #__PURE__ */ (function () {
    function JsonDecodeError(value0) {
        this.value0 = value0;
    };
    JsonDecodeError.create = function (value0) {
        return new JsonDecodeError(value0);
    };
    return JsonDecodeError;
})();
var JsonParseError = /* #__PURE__ */ (function () {
    function JsonParseError(value0) {
        this.value0 = value0;
    };
    JsonParseError.create = function (value0) {
        return new JsonParseError(value0);
    };
    return JsonParseError;
})();
var decode = function (token) {
    var payload = Data_Either.note(MalformedToken.value)((function (v) {
        return Data_Array.index(v)(1);
    })(Data_String_Common.split(".")(token)));
    var parseAsJson = map(Data_Lens_Setter.over(_Left)(JsonParseError.create))(Data_Argonaut_Parser.jsonParser);
    var decodeBase64 = map(Data_Lens_Setter.over(_Left)(Base64DecodeError.create))(Data_String_Base64.decode);
    return bind(bind(payload)(decodeBase64))(parseAsJson);
};
var decodeWith = function (f) {
    return function (token) {
        var decodeJson = map(Data_Lens_Setter.over(_Left)(JsonDecodeError.create))(f);
        return bind(decode(token))(decodeJson);
    };
};
export {
    MalformedToken,
    Base64DecodeError,
    JsonDecodeError,
    JsonParseError,
    decode,
    decodeWith
};
