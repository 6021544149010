// Generated by purs version 0.15.13
import * as BankingReady_Store from "../BankingReady.Store/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Halogen_Store_Monad from "../Halogen.Store.Monad/index.js";
import * as Safe_Coerce from "../Safe.Coerce/index.js";
var runStoreT = /* #__PURE__ */ Halogen_Store_Monad.runStoreT(Effect_Aff.monadAff);
var coerce = /* #__PURE__ */ Safe_Coerce.coerce();
var AppM = function (x) {
    return x;
};
var runAppM = function (store) {
    var $11 = runStoreT(store)(BankingReady_Store.reduce);
    return function ($12) {
        return $11(coerce($12));
    };
};
var monadStoreAppM = /* #__PURE__ */ Halogen_Store_Monad.monadStoreStoreT(Effect_Aff.monadEffectAff);
var monadEffectAppM = /* #__PURE__ */ Halogen_Store_Monad.monadEffectStoreT(Effect_Aff.monadEffectAff);
var monadAppM = /* #__PURE__ */ Halogen_Store_Monad.monadStoreT(Effect_Aff.monadAff);
var monadAffAppM = /* #__PURE__ */ Halogen_Store_Monad.monadAffStoreT(Effect_Aff_Class.monadAffAff);
var functorAppM = /* #__PURE__ */ Halogen_Store_Monad.functorStoreT(Effect_Aff.functorAff);
var bindAppM = /* #__PURE__ */ Halogen_Store_Monad.bindStoreT(Effect_Aff.bindAff);
var applyAppM = /* #__PURE__ */ Halogen_Store_Monad.applyStoreT(Effect_Aff.applyAff);
var applicativeAppM = /* #__PURE__ */ Halogen_Store_Monad.applicativeStoreT(Effect_Aff.applicativeAff);
export {
    AppM,
    runAppM,
    functorAppM,
    applyAppM,
    applicativeAppM,
    bindAppM,
    monadAppM,
    monadEffectAppM,
    monadAffAppM,
    monadStoreAppM
};
