// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
var balanceSheetDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 6,
        targetColIdx: 6,
        textArray: [ "The Balance Sheet begins with the Current Assets section. These are assets ", "that are expected to be converted into cash within a year. Assets are listed in ", "liquidity hence the most liquid assets are first." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 1,
            rowCount: 7,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 7,
        targetColIdx: 6,
        textArray: [ "Cash is the money the firm possesses in physical and digital forms. ", "", "Cash equivalents are short-term investments that can be easily liquidated, ", "carry low risk of loss, and have an active marketplace to ensure a quick ", "transaction. Essentially, they can be converted into cash quickly. For example, ", "investments in government bonds (that can be sold in the market for cash)." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 8,
        targetColIdx: 6,
        textArray: [ "Accounts Receivable is often considered the future cash. These are the funds ", "that customers owe the company for a product or service that has been ", "invoiced. ", "", "This means revenue has been recognised for the product / service, but cash ", "has not been received. ", "For example, a customer who buys 3 months\u2019 worth of Kellogg goods and ", "receive cereals but pay at the end of the 3 months (we would say on credit for ", "3 months). ", "", "However, remember the Matching Principle for the Income Statement. ", "It states we cannot recognise the Cost of Goods Sold (which reflected the cost ", "of making/buying the product) until these products are sold. However, they ", "are paid for upfront. So on the Balance Sheet we record the cost (or value) of ", "making of these products as Inventories, where the cash has been paid for ", "them, but the expense has not been recognised. ", "", "As we sell products and recognise the COGS on the Income Statement, the ", "Balance Sheet value of inventories will decrease. When we make or buy more ", "materials and products this will increase the inventories value." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 9,
        targetColIdx: 6,
        textArray: [ "Inventories refers to the raw materials, semifinished products used in the ", "production and the final products that are available to be sold. ", "", "For example, the packaging, the physical cereal, and the finished boxes that ", "Kellogg produces. ", "", "Remember from the Income Statement we had the Cost of Goods Sold which ", "reflected the cost of making the product. On the Balance Sheet we record the ", "cost (or value) of making of these products as Inventories. So as we sell ", "products and recognise the COGS on the Income Statement, the Balance ", "Sheet value of inventories will decrease. When we make or buy more ", "materials and products this will increase the value." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 9,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 10,
        targetColIdx: 6,
        textArray: [ "Prepaid Expenses occur when the company pays (in cash) for expenses it has not ", "yet recognised on the Income Statement. ", "", "For example, when Kellogg purchases IT service for multiple years but decides ", "to pay it all upfront because it gets a deal. On the Income Statement we do ", "not recognise the entire cost but just the portion that corresponds to the year ", "we are in. ", "", "The remaining expenses that we have paid for remain on the Balance Sheet ", "as an asset and represent the additional expense we must still pay. Over time ", "as the expense is recognised on the Income Statement the Balance Sheet line ", "will decrease." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 10,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 14,
        targetColIdx: 6,
        textArray: [ "The next section is the Non-Current Assets. These are assets of a long-term ", "nature, that cannot be converted into cash quickly (within 1 year)." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 1,
            rowCount: 8,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 15,
        targetColIdx: 6,
        textArray: [ "This refers to land and buildings owned by the Kellogg such as production and ", "packaging facilities. Often recorded as Property, Plant and Equipment to ", "reflect the contents within land, property and buildings used for production ", "such as the conveyor belts in the plants." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 16,
        targetColIdx: 6,
        textArray: [ "These represents the lessee's (person who purchases the lease) authority to ", "use an asset over the life of a lease. In other words the lessee is granted the ", "right to obtain the economic benefits of the usage of an asset owned by ", "another entity, which this asset represents (an advanced topic not covered in ", "this course)." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 17,
        targetColIdx: 6,
        textArray: [ "The difference (albeit you probably won\u2019t need it for most interviews) ", "between Finance and Operating Lease Assets is there is usually a transfer of ", "ownership at the end of the financial lease." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 17,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 18,
        targetColIdx: 6,
        textArray: [ "Non-physical assets that are clearly identifiable. For example proprietary ", "technology, copyrights, patents, licensing agreements, branding. Kellogg has ", "the intangible assets of its branding." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 24,
        targetColIdx: 6,
        textArray: [ "Next, we have Current Liabilities which are liabilities that are expected to be ", "payable (cost the company cash) within a year." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 1,
            rowCount: 8,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 25,
        targetColIdx: 6,
        textArray: [ "The portion of long-term debt that will come due (matures or is payable) ", "within the next 12 months. For example, $712M of the $7B Kellogg long-term ", "debt has less than 1 year until maturity (payment date)." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 26,
        targetColIdx: 6,
        textArray: [ "The funds that external parties are owed for providing supplies/service to the ", "company. Specifically these are what we owe that has been invoiced and is ", "usually seen as a short-term obligation (often to suppliers). ", "", "For example, those who have supplied Kellogg with raw materials but will not ", "be paid until 3 months\u2019 time. The company would have received the materials ", "or service and so the cost of them will be recognised on the Income ", "Statement, but the actual cash has not been paid. ", "", "On the Balance Sheet, Accounts Payable represents this future cash outflow ", "and keeps track of what is due to be paid in cash. When the cash is paid to ", "suppliers this line decreases. When our suppliers allow us to pay more of the ", "costs later, this line will increase." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 26,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 28,
        targetColIdx: 6,
        textArray: [ "Accrued expenses are much like accounts payable but are for the expenses we ", "have not yet invoiced and are estimated such as the monthly wages we ", "expense. Although we have a rough idea what they will be they are not exact ", "and have not been invoiced yet. ", "", "When expenses have been charged to the Income Statement but have not ", "been paid in cash, we recognise this future cash payment as an accrued ", "expense that will be paid in the future. ", "", "For example, wages may be paid a month later (in arrears). The expense of ", "the wages is recognised on the Income Statement in the month the ", "employees work. On the Balance Sheet the Accrued Expenses line will ", "increase to show the future cash outflow. The following month when the ", "wages are paid in cash this line will decrease.", "", "These can often (but not always) link to the Income Statement\xa0. When the ", "expense is charged the Income Statement recognises the expense (following ", "the Matching Principle). However, if the expense is not paid in cash, then it is ", "added to the Balance Sheet. It is most typical that Accounts Payable may link ", "to the payment of suppliers or for sales and marketing. Accrued Expenses are ", "compensation related." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 28,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 29,
        targetColIdx: 6,
        textArray: [ "This is when the company collects the cash for a product/service upfront but ", "has not yet recognised the revenue (expenses and taxes) for the product it has ", "provided. ", "", "For example, if Kellogg organises a contract where it will deliver its cereals at ", "the end of the month, but the customer pays for it now and in full. Another ", "typical way companies accrue Deferred Revenues is through pre-ordering. ", "They would not recognise the revenue of the Income Statement until the ", "products are delivered but we have received the cash. The Balance Sheet line ", "for Deferred Revenues would increase to recognise that there are future ", "revenues to recognise. When the cereals are all delivered at the end of the ", "year the Income Statement will recognise these revenues and the Balance ", "Sheet line for Deferred Revenues will decrease." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 33,
        targetColIdx: 6,
        textArray: [ "The next section is the Non-Current Liabilities. These are liabilities of a ", "long-term nature, that are not payable within a period of 1 year." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 1,
            rowCount: 7,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 34,
        targetColIdx: 6,
        textArray: [ "Debt with a maturity (period before payment) of greater than 1 year." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 34,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 35,
        targetColIdx: 6,
        textArray: [ "Other Non-Current Liabilities include items such as Operating and Financial ", "Leases, Deferred Taxes, and many others. However for the scope of this ", "course, they are far too complex to introduce. Do not worry it is highly unlikely ", "to be asked in interviews at this stage." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 2,
            rowCount: 4,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 42,
        targetColIdx: 6,
        textArray: [ "The Balance Sheet concludes with Equity. This represents the shareholders' ", "stake in the company (the claims they have on the business)." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 42,
            colIdx: 1,
            rowCount: 10,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 43,
        targetColIdx: 6,
        textArray: [ "A security that represents ownership (share) in the company. It also gives ", "rights to a dividend if paid. ", "", "Common Stock =  par value of common stock * number of shares outstanding ", "", "The par value is the value of each share that was assigned by the company in ", "its corporate charter (Often very low, for example Kellogg has common stock ", "with a par value of $0.25). A low stock par value is to avoid any future ", "liabilities. For example, if they had stock issued with a par value of $10 but ", "the stock price is $5 then theoretically, they would have a $5 liability for each ", "stock." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 43,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 44,
        targetColIdx: 6,
        textArray: [ "The amount paid by investors to a company in excess of the par value when it ", "is issued. A simple example is when Kellogg issued its $0.25 common stock at ", "par, if we bought it for $1 there would be a $0.75 additional paid in capital." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 44,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 45,
        targetColIdx: 6,
        textArray: [ "All the earnings (or net income) available to the shareholders that has not yet ", "been paid. ", "Retained earnings = Previous years retained earnings ", "'                                        + net income for the period", "'                                        - dividend paid in the period." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 45,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 46,
        targetColIdx: 6,
        textArray: [ "The previously issued stock reacquired by the company from shareholders." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 46,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 49,
        targetColIdx: 6,
        textArray: [ "Preferred stock is another type of security a company can issue instead of ", "traditional loans or investments from shareholders. ", "", "Companies traditionally issue common shares, commonly seen on the stock ", "market. These allow investors to vote on decisions and benefit in any upside ", "the stock price might yield. However, they are not entitled to cash flows of the ", "business, unless the company decides to pay a dividend. ", "", "Preferred Stock provides shares of the company with a fixed coupon ", "payment (dividend) each year but do not come with voting rights like ", "traditional common shares. It is much closer to debt than equity because of ", "the fixed coupon payment (dividend) which appears as an expense on the ", "income statement. Additionally, the owner does not participate in the same ", "equity upside that common shares possess because they receive the cash ", "flows through the dividends." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 49,
            colIdx: 1,
            rowCount: 1,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.TopBottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 50,
        targetColIdx: 6,
        textArray: [ "The ownership position whereby a shareholder owns less than 50% of ", "outstanding shares of a subsidiary." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 50,
            colIdx: 1,
            rowCount: 1,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.TopBottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.AccountingCmd(new BankingReady_Data_ExcelEditorCommand.SideText({
        targetRowIdx: 54,
        targetColIdx: 6,
        textArray: [ "This is the balance sheet identity. It simply explains that the total value of the ", "assets a company owns is equal the total value of the liabilities and equities ", "or claims on the company\u2019s value." ]
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 54,
            colIdx: 1,
            rowCount: 2,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ] ];
})();
export {
    balanceSheetDescriptions
};
