// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var calculatingEnterpriseValueDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The next step is to bridge across to Enterprise Value. Recall that Enterprise Value is the Net Operating Assets or the value of the operations of the business. Remember, this is the parts of the business used daily in production or provision of the good/service the business has chosen. This can be written as Operating Assets \u2013 Operating Liabilities, but available to all the capital providers (equity and debt and any other not covered in this course)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 6,
        text: "Enterprise Value = Operating Assets \u2013 Operating Liabilities"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Writing this as a formula we get: Enterprise Value = (Market Value of Assets \u2013 Non-Operating Assets) \u2013 (Market Value of Liabilities \u2013 Non-Operating Liabilities and Equities) representing claims of those other than common shareholders."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 6,
        text: "EV = (Market Value of Assets \u2013 Non-Operating Assets) "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 6,
        text: "'         \u2013 (Market Value of Liabilities \u2013 Non-Operating Liabilities and Equities)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can rearrange this to get: Enterprise Value = (Market Value of Assets \u2013 Market Value of Liabilities) \u2013 (Non-Operating Assets - Liabilities and Equities of Others)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 6,
        text: "EV = (Market Value of Assets \u2013 Market Value of Liabilities) "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 6,
        text: "'         \u2013 (Non-Operating Assets - Liabilities and Equities of Others)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now recall the 2nd method of calculating the Equity Value in the previous section. We defined Equity Value = Market Value of Assets \u2013 Market Value of Liabilities. Now we can substitute this equation into our Enterprise Value formula."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 6,
        text: "Equity Value = Market Value of Assets \u2013 Market Value of Liabilities"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So the Enterprise Value formula becomes: Enterprise Value = Equity Value \u2013 Non-Operating Assets + Liabilities and Equities of Others"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 6,
        text: "Enterprise Value = Equity Value \u2013 Non-Operating Assets + Liabilities and Equities of Others"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The Enterprise Value takes our Equity Value and adjusts it for Balance Sheet items (our Non-Operating Assets, Liabilities, Equities and Other Items). "
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The most common non-operating asset is cash and (equivalents such as investments in bonds). This is because they are not a working capital item, despite being spent on items necessary for the operations the cash itself does not work to create products or revenue."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The most common non-operating liabilities are forms of debts, which can be considered a source of capital. This like equities and other types of investments in the business are not parts of selling the product and generation of the business\u2019s income and cash but just the source of funding."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Therefore, Enterprise Value = Equity Value + Debt \u2013 Cash + Noncontrolling Interest + Preferred Stock. Do not worry about Noncontrolling interest at this point. We also lay out the formula in this way because Debt \u2013 Cash is often referred to as Net Debt i.e. the debt level if we used the cash to reduce it as much as possible."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 20,
        colIdx: 6,
        text: "Enterprise Value = Equity Value + Debt \u2013 Cash + Noncontrolling Interest + Preferred Stock"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The reason Preferred Stock is added is because the company cannot generate it internally (much like debt). It requires external investors to borrow the funds from and they do so in exchange for a promise to pay interest (Preferred Dividend) each year."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Other items that that are often included in the equation but that are too advanced for this course include Capital Leases, Noncontrolling interest, Unfunded pensions. These are other non-operating liabilities or minimum cash balances which often change the cash we subtract. Do not worry about learning these in extensive detail."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Other Non-Operating Assets that can be included but are too advance to give full treatment in this course include financial investments (stocks and bonds), owned properties, net operating losses (NOLs)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So let us assume that the previous company that we calculated equity value for wants to know the worth of the operations of the business. We can follow the bridge to arrive at its Enterprise Value."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Let\u2019s assume that the company has 1500 in debt and 500 in cash. It also has 1000 in preferred stock and a 200 noncontrolling interest. We can use these values to calculate the Enterprise Value."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 11,
        colIdx: 3,
        number: 1500.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 12,
        colIdx: 3,
        number: 500.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 13,
        colIdx: 3,
        number: 1000.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 14,
        colIdx: 3,
        number: 200.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Try to work out the Enterprise Value yourself and then press Next to check the answer."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=D10+D12-D13+D14+D15",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 9,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 12,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 13,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 14,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As we can see the company\u2019s Enterprise Value is 6200. You can change these numbers to see how Equity Value and Enterprise Value adjust. For example, see what happens when the cash value is very high?"
    })) ] ];
})();
export {
    calculatingEnterpriseValueDescriptions
};
