// Generated by purs version 0.15.13
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Record from "../Record/index.js";
import * as Routing_Duplex_Parser from "../Routing.Duplex.Parser/index.js";
import * as Routing_Duplex_Printer from "../Routing.Duplex.Printer/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter);
var applyFirst = /* #__PURE__ */ Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser);
var pure = /* #__PURE__ */ Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var apply = /* #__PURE__ */ Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser);
var map = /* #__PURE__ */ Data_Functor.map(Routing_Duplex_Parser.functorRouteParser);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(Routing_Duplex_Printer.monoidRoutePRinter);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Routing_Duplex_Printer.monoidRoutePRinter);
var voidLeft = /* #__PURE__ */ Data_Functor.voidLeft(Routing_Duplex_Parser.functorRouteParser);
var apply1 = /* #__PURE__ */ Control_Apply.apply(Control_Apply.applyFn);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var RouteDuplex = /* #__PURE__ */ (function () {
    function RouteDuplex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    RouteDuplex.create = function (value0) {
        return function (value1) {
            return new RouteDuplex(value0, value1);
        };
    };
    return RouteDuplex;
})();
var suffix = function (v) {
    return function (s) {
        return new RouteDuplex(function (a) {
            return append(v.value0(a))(Routing_Duplex_Printer.put(s));
        }, applyFirst(v.value1)(Routing_Duplex_Parser.prefix(s)(pure(Data_Unit.unit))));
    };
};
var string = identity;
var segment = /* #__PURE__ */ (function () {
    return new RouteDuplex(Routing_Duplex_Printer.put, Routing_Duplex_Parser.take);
})();
var rest = /* #__PURE__ */ (function () {
    return new RouteDuplex(Data_Foldable.foldMap(Data_Foldable.foldableArray)(Routing_Duplex_Printer.monoidRoutePRinter)(Routing_Duplex_Printer.put), Routing_Duplex_Parser.rest);
})();
var record = /* #__PURE__ */ (function () {
    return new RouteDuplex(Data_Monoid.mempty(Data_Monoid.monoidFn(Routing_Duplex_Printer.monoidRoutePRinter)), pure({}));
})();
var prop = function (dictIsSymbol) {
    var get = Record.get(dictIsSymbol)();
    var insert = Record.insert(dictIsSymbol)()();
    return function () {
        return function () {
            return function () {
                return function (sym) {
                    return function (v) {
                        return function (v1) {
                            return new RouteDuplex(function (r) {
                                return append(v1.value0(r))(v.value0(get(sym)(r)));
                            }, apply(map(Data_Function.flip(insert(sym)))(v1.value1))(v.value1));
                        };
                    };
                };
            };
        };
    };
};
var profunctorRouteDuplex = {
    dimap: function (f) {
        return function (g) {
            return function (v) {
                return new RouteDuplex(function ($137) {
                    return v.value0(f($137));
                }, map(g)(v.value1));
            };
        };
    }
};
var print = function (v) {
    return function ($138) {
        return Routing_Duplex_Printer.run(v.value0($138));
    };
};
var prefix = function (s) {
    return function (v) {
        return new RouteDuplex(function (a) {
            return append(Routing_Duplex_Printer.put(s))(v.value0(a));
        }, Routing_Duplex_Parser.prefix(s)(v.value1));
    };
};
var path = /* #__PURE__ */ (function () {
    var $139 = Data_Function.flip(Data_Foldable.foldr(Data_Foldable.foldableArray)(prefix));
    var $140 = Data_String_Common.split("/");
    return function ($141) {
        return $139($140($141));
    };
})();
var root = /* #__PURE__ */ path("");
var parse = function (v) {
    return Routing_Duplex_Parser.run(v.value1);
};
var params = function (dict) {
    return dict.params;
};
var param = function (p) {
    return new RouteDuplex(Routing_Duplex_Printer.param(p), Routing_Duplex_Parser.param(p));
};
var optional = function (v) {
    return new RouteDuplex(foldMap(v.value0), Routing_Duplex_Parser.optional(v.value1));
};
var many1 = function (dictFoldable) {
    var foldMap1 = Data_Foldable.foldMap(dictFoldable)(Routing_Duplex_Printer.monoidRoutePRinter);
    return function (dictAlt) {
        var many11 = Routing_Duplex_Parser.many1(dictAlt);
        return function (dictApplicative) {
            var many12 = many11(dictApplicative);
            return function (v) {
                return new RouteDuplex(foldMap1(v.value0), many12(v.value1));
            };
        };
    };
};
var many = function (dictFoldable) {
    var foldMap1 = Data_Foldable.foldMap(dictFoldable)(Routing_Duplex_Printer.monoidRoutePRinter);
    return function (dictAlternative) {
        var many2 = Routing_Duplex_Parser.many(dictAlternative);
        return function (v) {
            return new RouteDuplex(foldMap1(v.value0), many2(v.value1));
        };
    };
};
var functorRouteDuplex = {
    map: function (f) {
        return function (m) {
            return new RouteDuplex(m.value0, map(f)(m.value1));
        };
    }
};
var flag = function (v) {
    var enc$prime = function (v1) {
        if (v1) {
            return v.value0("");
        };
        return mempty;
    };
    var dec$prime = Routing_Duplex_Parser["default"](false)(voidLeft(v.value1)(true));
    return new RouteDuplex(enc$prime, dec$prime);
};
var end = function (v) {
    return new RouteDuplex(v.value0, applyFirst(v.value1)(Routing_Duplex_Parser.end));
};
var $$default = function (d) {
    return function (v) {
        return new RouteDuplex(v.value0, Routing_Duplex_Parser["default"](d)(v.value1));
    };
};
var buildParamsNil = {
    buildParams: function (v) {
        return function (v1) {
            return identity;
        };
    }
};
var buildParams = function (dict) {
    return dict.buildParams;
};
var buildParamsCons = function (dictIsSymbol) {
    var prop1 = prop(dictIsSymbol)()()();
    var get = Record.get(dictIsSymbol)();
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    return function () {
        return function () {
            return function () {
                return function () {
                    return function (dictRouteDuplexBuildParams) {
                        var buildParams1 = buildParams(dictRouteDuplexBuildParams);
                        return {
                            buildParams: function (v) {
                                return function (r) {
                                    return function (prev) {
                                        return buildParams1(Type_Proxy["Proxy"].value)(r)(prop1(Type_Proxy["Proxy"].value)(get(Type_Proxy["Proxy"].value)(r)(param(reflectSymbol(Type_Proxy["Proxy"].value))))(prev));
                                    };
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var routeDuplexParams = function () {
    return function (dictRouteDuplexBuildParams) {
        var buildParams1 = buildParams(dictRouteDuplexBuildParams);
        return {
            params: function (r) {
                return buildParams1(Type_Proxy["Proxy"].value)(r)(record);
            }
        };
    };
};
var as = function (f) {
    return function (g) {
        return function (v) {
            return new RouteDuplex(function ($142) {
                return v.value0(f($142));
            }, Routing_Duplex_Parser.as(identity)(g)(v.value1));
        };
    };
};
var $$boolean = /* #__PURE__ */ as(/* #__PURE__ */ Data_Show.show(Data_Show.showBoolean))(Routing_Duplex_Parser["boolean"]);
var $$int = /* #__PURE__ */ as(/* #__PURE__ */ Data_Show.show(Data_Show.showInt))(Routing_Duplex_Parser["int"]);
var applyRouteDuplex = {
    apply: function (v) {
        return function (v1) {
            return new RouteDuplex(apply1(map1(append)(v.value0))(v1.value0), apply(v.value1)(v1.value1));
        };
    },
    Functor0: function () {
        return functorRouteDuplex;
    }
};
var applicativeRouteDuplex = {
    pure: /* #__PURE__ */ (function () {
        var $143 = RouteDuplex.create(Data_Function["const"](mempty));
        return function ($144) {
            return $143(pure($144));
        };
    })(),
    Apply0: function () {
        return applyRouteDuplex;
    }
};
export {
    RouteDuplex,
    parse,
    print,
    prefix,
    suffix,
    path,
    root,
    end,
    segment,
    param,
    flag,
    many1,
    many,
    rest,
    $$default as default,
    optional,
    as,
    $$int as int,
    $$boolean as boolean,
    string,
    record,
    prop,
    params,
    buildParams,
    functorRouteDuplex,
    applyRouteDuplex,
    applicativeRouteDuplex,
    profunctorRouteDuplex,
    routeDuplexParams,
    buildParamsCons,
    buildParamsNil
};
