// Generated by purs version 0.15.13
import * as BankingReady_Capability_ExcelEditor from "../BankingReady.Capability.ExcelEditor/index.js";
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Data_Description_DCFValuation_BusinessProjections from "../BankingReady.Data.Description.DCFValuation.BusinessProjections/index.js";
import * as BankingReady_Data_Description_DCFValuation_CostOfEquity from "../BankingReady.Data.Description.DCFValuation.CostOfEquity/index.js";
import * as BankingReady_Data_Description_DCFValuation_DCFValuationIntro from "../BankingReady.Data.Description.DCFValuation.DCFValuationIntro/index.js";
import * as BankingReady_Data_Description_DCFValuation_DCFValuationOutro from "../BankingReady.Data.Description.DCFValuation.DCFValuationOutro/index.js";
import * as BankingReady_Data_Description_DCFValuation_DiscountingAndTerminalValues from "../BankingReady.Data.Description.DCFValuation.DiscountingAndTerminalValues/index.js";
import * as BankingReady_Data_Description_DCFValuation_ProjectingFCF from "../BankingReady.Data.Description.DCFValuation.ProjectingFCF/index.js";
import * as BankingReady_Data_Description_DCFValuation_TypesOfFCF from "../BankingReady.Data.Description.DCFValuation.TypesOfFCF/index.js";
import * as BankingReady_Data_Description_DCFValuation_WACCAndDiscounting from "../BankingReady.Data.Description.DCFValuation.WACCAndDiscounting/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Page_IntroLesson from "../BankingReady.Page.IntroLesson/index.js";
import * as BankingReady_Page_Lesson from "../BankingReady.Page.Lesson/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var slot = /* #__PURE__ */ Halogen_HTML.slot()({
    reflectSymbol: function () {
        return "lesson";
    }
})(Data_Ord.ordInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var when = /* #__PURE__ */ Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM);
var lessThan = /* #__PURE__ */ Data_Ord.lessThan(BankingReady_Data_Route.ordDCFValuationRoute);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var pred = /* #__PURE__ */ Data_Enum.pred(BankingReady_Data_Route.enumDCFValuationRoute);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var succ = /* #__PURE__ */ Data_Enum.succ(BankingReady_Data_Route.enumDCFValuationRoute);
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var Finalize = /* #__PURE__ */ (function () {
    function Finalize() {

    };
    Finalize.value = new Finalize();
    return Finalize;
})();
var Receive = /* #__PURE__ */ (function () {
    function Receive(value0) {
        this.value0 = value0;
    };
    Receive.create = function (value0) {
        return new Receive(value0);
    };
    return Receive;
})();
var HandleLessonOutput = /* #__PURE__ */ (function () {
    function HandleLessonOutput(value0) {
        this.value0 = value0;
    };
    HandleLessonOutput.create = function (value0) {
        return new HandleLessonOutput(value0);
    };
    return HandleLessonOutput;
})();
var HandleIntroLessonOutput = /* #__PURE__ */ (function () {
    function HandleIntroLessonOutput(value0) {
        this.value0 = value0;
    };
    HandleIntroLessonOutput.create = function (value0) {
        return new HandleIntroLessonOutput(value0);
    };
    return HandleIntroLessonOutput;
})();
var component = function (dictMonadAff) {
    var component1 = BankingReady_Page_Lesson.component(dictMonadAff);
    return function (dictMonadStore) {
        return function (dictNavigate) {
            var navigate = BankingReady_Capability_Navigate.navigate(BankingReady_Capability_Navigate.navigateHalogenM(dictNavigate));
            return function (dictManageExcelEditor) {
                var component2 = component1(dictManageExcelEditor);
                var manageExcelEditorHalogenM = BankingReady_Capability_ExcelEditor.manageExcelEditorHalogenM(dictManageExcelEditor);
                var createWorksheetsBeforeAndIncluding = BankingReady_Capability_ExcelEditor.createWorksheetsBeforeAndIncluding(manageExcelEditorHalogenM);
                var deleteWorksheetsBeforeAndIncluding = BankingReady_Capability_ExcelEditor.deleteWorksheetsBeforeAndIncluding(manageExcelEditorHalogenM);
                var deleteWorksheetsAfterAndCreateCurrent = BankingReady_Capability_ExcelEditor.deleteWorksheetsAfterAndCreateCurrent(manageExcelEditorHalogenM);
                var createWorksheet = BankingReady_Capability_ExcelEditor.createWorksheet(manageExcelEditorHalogenM);
                var render = function (v) {
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.DCFValuationIntro) {
                        return slot(Type_Proxy["Proxy"].value)(1)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_DCFValuationIntro.dcfValuationIntroDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.TypesOfFCF) {
                        return slot(Type_Proxy["Proxy"].value)(2)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_TypesOfFCF.typesOfFCFDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.BusinessProjections) {
                        return slot(Type_Proxy["Proxy"].value)(3)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_BusinessProjections.businessProjectionsDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.ProjectingFCF) {
                        return slot(Type_Proxy["Proxy"].value)(4)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_ProjectingFCF.projectingFCFDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.WACCAndDiscounting) {
                        return slot(Type_Proxy["Proxy"].value)(5)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_WACCAndDiscounting.waccAndDiscountingDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.CostOfEquity) {
                        return slot(Type_Proxy["Proxy"].value)(6)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_CostOfEquity.costOfEquityDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.DiscountingAndTerminalValues) {
                        return slot(Type_Proxy["Proxy"].value)(7)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_DiscountingAndTerminalValues.discountingAndTerminalValuesDescriptions
                        })(HandleLessonOutput.create);
                    };
                    if (v.dcfValuationRoute instanceof BankingReady_Data_Route.DCFValuationOutro) {
                        return slot(Type_Proxy["Proxy"].value)(8)(component2)({
                            route: new BankingReady_Data_Route.DCFValuation(v.dcfValuationRoute),
                            upcomingDescList: BankingReady_Data_Description_DCFValuation_DCFValuationOutro.dcfValuationOutroDescriptions
                        })(HandleLessonOutput.create);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 114, column 34 - line 193, column 27): " + [ v.dcfValuationRoute.constructor.name ]);
                };
                var handleAction = function (v) {
                    if (v instanceof Initialize) {
                        return bind(get)(function (v1) {
                            return createWorksheetsBeforeAndIncluding(new BankingReady_Data_Route.DCFValuation(v1.dcfValuationRoute));
                        });
                    };
                    if (v instanceof Finalize) {
                        return bind(get)(function (v1) {
                            return deleteWorksheetsBeforeAndIncluding(new BankingReady_Data_Route.DCFValuation(v1.dcfValuationRoute));
                        });
                    };
                    if (v instanceof Receive) {
                        return bind(get)(function (v1) {
                            return discard(when(lessThan(v.value0)(v1.dcfValuationRoute))(discard(deleteWorksheetsAfterAndCreateCurrent(new BankingReady_Data_Route.DCFValuation(v1.dcfValuationRoute))(new BankingReady_Data_Route.DCFValuation(v.value0)))(function () {
                                return modify_(function (v2) {
                                    var $49 = {};
                                    for (var $50 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $50)) {
                                            $49[$50] = v2[$50];
                                        };
                                    };
                                    $49.dcfValuationRoute = v.value0;
                                    return $49;
                                });
                            })))(function () {
                                return when(lessThan(v1.dcfValuationRoute)(v.value0))(discard(modify_(function (v2) {
                                    var $52 = {};
                                    for (var $53 in v2) {
                                        if ({}.hasOwnProperty.call(v2, $53)) {
                                            $52[$53] = v2[$53];
                                        };
                                    };
                                    $52.dcfValuationRoute = v.value0;
                                    return $52;
                                }))(function () {
                                    return createWorksheet(new BankingReady_Data_Route.DCFValuation(v.value0));
                                }));
                            });
                        });
                    };
                    if (v instanceof HandleLessonOutput) {
                        if (v.value0 instanceof BankingReady_Page_Lesson.PreviousLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.DCFValuation) {
                                var v1 = pred(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.DCFValuation(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 85, column 43 - line 87, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        if (v.value0 instanceof BankingReady_Page_Lesson.NextLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.DCFValuation) {
                                var v1 = succ(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.DCFValuation(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 92, column 43 - line 94, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 83, column 34 - line 96, column 23): " + [ v.value0.constructor.name ]);
                    };
                    if (v instanceof HandleIntroLessonOutput) {
                        if (v.value0 instanceof BankingReady_Page_IntroLesson.PreviousLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.DCFValuation) {
                                var v1 = pred(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.DCFValuation(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 100, column 43 - line 102, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        if (v.value0 instanceof BankingReady_Page_IntroLesson.NextLesson) {
                            if (v.value0.value0.currentRoute instanceof BankingReady_Data_Route.DCFValuation) {
                                var v1 = succ(v.value0.value0.currentRoute.value0);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return navigate(BankingReady_Data_Route.Home.value);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return navigate(new BankingReady_Data_Route.DCFValuation(v1.value0));
                                };
                                throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 107, column 43 - line 109, column 64): " + [ v1.constructor.name ]);
                            };
                            return pure(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 98, column 39 - line 111, column 23): " + [ v.value0.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at BankingReady.Component.Router.DCFValuationRouter (line 64, column 18 - line 111, column 23): " + [ v.constructor.name ]);
                };
                return Halogen_Component.mkComponent({
                    initialState: function (dcfValuationRoute) {
                        return {
                            dcfValuationRoute: dcfValuationRoute
                        };
                    },
                    render: render,
                    "eval": Halogen_Component.mkEval({
                        handleQuery: Halogen_Component.defaultEval.handleQuery,
                        handleAction: handleAction,
                        initialize: new Data_Maybe.Just(Initialize.value),
                        finalize: new Data_Maybe.Just(Finalize.value),
                        receive: function ($85) {
                            return Data_Maybe.Just.create(Receive.create($85));
                        }
                    })
                });
            };
        };
    };
};
export {
    Initialize,
    Finalize,
    Receive,
    HandleLessonOutput,
    HandleIntroLessonOutput,
    component
};
