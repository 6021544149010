// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
var Address = function (x) {
    return x;
};
var getRange = function (worksheet) {
    return function (v) {
        return $foreign.getRangeImpl(worksheet)(v);
    };
};
export {
    getCharts,
    freezePanes,
    shapes,
    activate,
    deleteWorksheet,
    getRangeImpl,
    getRangeByIndexes,
    showGridlines,
    setVisible,
    setVeryHidden
} from "./foreign.js";
export {
    Address,
    getRange
};
