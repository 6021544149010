// Generated by purs version 0.15.13
import * as BankingReady_AppM from "../BankingReady.AppM/index.js";
import * as BankingReady_Capability_Dialog from "../BankingReady.Capability.Dialog/index.js";
import * as BankingReady_Capability_Excel_Quiz from "../BankingReady.Capability.Excel.Quiz/index.js";
import * as BankingReady_Capability_ExcelEditor from "../BankingReady.Capability.ExcelEditor/index.js";
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Component_Router from "../BankingReady.Component.Router/index.js";
import * as BankingReady_Data_Environment from "../BankingReady.Data.Environment/index.js";
import * as BankingReady_Data_Font from "../BankingReady.Data.Font/index.js";
import * as BankingReady_Data_OS from "../BankingReady.Data.OS/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Canvas from "../BankingReady.Foreign.Canvas/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as BankingReady_Foreign_Excel_RangeFont from "../BankingReady.Foreign.Excel.RangeFont/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Style from "../BankingReady.Foreign.Excel.Style/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Office from "../BankingReady.Foreign.Office/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Promise from "../Control.Promise/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Char from "../Data.Char/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
import * as Halogen_Aff_Util from "../Halogen.Aff.Util/index.js";
import * as Halogen_Query from "../Halogen.Query/index.js";
import * as Halogen_VDom_Driver from "../Halogen.VDom.Driver/index.js";
import * as Routing_Duplex from "../Routing.Duplex/index.js";
import * as Routing_Hash from "../Routing.Hash/index.js";
import * as Web_DOM_Document from "../Web.DOM.Document/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_HTMLDocument from "../Web.HTML.HTMLDocument/index.js";
import * as Web_HTML_Navigator from "../Web.HTML.Navigator/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show1 = /* #__PURE__ */ Data_Show.show(BankingReady_Data_Font.showFont);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var sequenceDefault = /* #__PURE__ */ Data_Traversable.sequenceDefault(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var fromFoldable = /* #__PURE__ */ Data_Map_Internal.fromFoldable(Data_String_CodePoints.ordCodePoint)(Data_Foldable.foldableArray);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var component = /* #__PURE__ */ BankingReady_Component_Router.component(BankingReady_AppM.monadAffAppM)(BankingReady_AppM.monadStoreAppM)(BankingReady_Capability_Navigate.navigateAppM)(BankingReady_Capability_Dialog.manageDialogAppM)(BankingReady_Capability_ExcelEditor.manageExcelEditorAppM)(BankingReady_Capability_Excel_Quiz.manageExcelQuizAppM);
var matchesWith = /* #__PURE__ */ Routing_Hash.matchesWith(Data_Foldable.foldableEither);
var when = /* #__PURE__ */ Control_Applicative.when(Effect.applicativeEffect);
var notEq = /* #__PURE__ */ Data_Eq.notEq(/* #__PURE__ */ Data_Maybe.eqMaybe(BankingReady_Data_Route.eqRoute));
var unicodeCharWidth = function (os) {
    return function (ctx) {
        return function (codepoint) {
            return function __do() {
                var charWidth = BankingReady_Foreign_Canvas.measureTextWidth(ctx)(Data_String_CodePoints.singleton(codepoint))();
                var charWidthOS = (function () {
                    if (os instanceof BankingReady_Data_OS.Windows) {
                        return charWidth;
                    };
                    if (os instanceof BankingReady_Data_OS.Mac) {
                        return charWidth / BankingReady_Data_OS.fontDPIRatio;
                    };
                    if (os instanceof BankingReady_Data_OS.Unknown) {
                        return charWidth;
                    };
                    throw new Error("Failed pattern match at Main (line 49, column 19 - line 52, column 27): " + [ os.constructor.name ]);
                })();
                return charWidthOS;
            };
        };
    };
};
var unicodeToCharWidthTuple = function (os) {
    return function (ctx) {
        return function (codepoint) {
            return function __do() {
                var value = unicodeCharWidth(os)(ctx)(codepoint)();
                return new Data_Tuple.Tuple(codepoint, value);
            };
        };
    };
};
var measureFontWidth = function (os) {
    return function (fontName) {
        return function (fontSize) {
            return function __do() {
                var window = Web_HTML.window();
                var htmlDocument = Web_HTML_Window.document(window)();
                var document = Web_HTML_HTMLDocument.toDocument(htmlDocument);
                var canvasElement = Web_DOM_Document.createElement("canvas")(document)();
                var ctxNoFont = BankingReady_Foreign_Canvas.getContext2D(canvasElement)();
                var fontString = show(BankingReady_Data_Font.pt(fontSize)) + ("pt " + show1(fontName));
                var ctx = BankingReady_Foreign_Canvas.setFont(ctxNoFont)(fontString)();
                var charInts = Data_Array.range(32)(255);
                var chars = map(Data_Maybe.fromMaybe("/"))(map(Data_Char.fromCharCode)(charInts));
                var charCodepoints = map(Data_String_CodePoints.codePointFromChar)(chars);
                var keyValueTupleArray = sequenceDefault(map(unicodeToCharWidthTuple(os)(ctx))(charCodepoints))();
                return fromFoldable(keyValueTupleArray);
            };
        };
    };
};
var getOS = function __do() {
    var window = Web_HTML.window();
    var navigator = Web_HTML_Window.navigator(window)();
    var platform = Web_HTML_Navigator.platform(navigator)();
    var $28 = Data_String_CodeUnits.contains("Win")(platform);
    if ($28) {
        return BankingReady_Data_OS.Windows.value;
    };
    var $29 = Data_String_CodeUnits.contains("Mac")(platform);
    if ($29) {
        return BankingReady_Data_OS.Mac.value;
    };
    return BankingReady_Data_OS.Unknown.value;
};
var getDefaultFont = /* #__PURE__ */ bind1(/* #__PURE__ */ liftEffect(/* #__PURE__ */ Effect_Ref["new"]("")))(function (fontNameRef) {
    return bind1(liftEffect(Effect_Ref["new"](0.0)))(function (fontSizeRef) {
        return discard(BankingReady_Foreign_Excel.run(function (context) {
            return Control_Promise.fromAff((function () {
                var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(context);
                var style = BankingReady_Foreign_Excel_Workbook.getStyle(workbook)("Normal");
                var defaultFont = BankingReady_Foreign_Excel_Style.getFont(style);
                return discard(liftEffect(BankingReady_Foreign_Excel_RangeFont.loadProperties(defaultFont)))(function () {
                    return discard(BankingReady_Foreign_Excel.sync(context))(function () {
                        var defaultFontName = BankingReady_Foreign_Excel_RangeFont.getName(defaultFont);
                        var defaultFontSize = BankingReady_Foreign_Excel_RangeFont.getSize(defaultFont);
                        return discard(liftEffect(Effect_Ref.write(defaultFontName)(fontNameRef)))(function () {
                            return liftEffect(Effect_Ref.write(defaultFontSize)(fontSizeRef));
                        });
                    });
                });
            })());
        }))(function () {
            return bind1(liftEffect(Effect_Ref.read(fontNameRef)))(function (fontName) {
                return bind1(liftEffect(Effect_Ref.read(fontSizeRef)))(function (fontSize) {
                    return pure1(new Data_Tuple.Tuple(fontName, fontSize));
                });
            });
        });
    });
});
var app = function (node_env) {
    return Halogen_Aff_Util.runHalogenAff(bind1(Halogen_Aff_Util.awaitBody)(function (body) {
        var environment = (function () {
            var $30 = node_env === "local";
            if ($30) {
                return BankingReady_Data_Environment.Local.value;
            };
            var $31 = node_env === "development";
            if ($31) {
                return BankingReady_Data_Environment.Dev.value;
            };
            return BankingReady_Data_Environment.Prod.value;
        })();
        return bind1(liftEffect(getOS))(function (os) {
            var baseUrl = (function () {
                if (environment instanceof BankingReady_Data_Environment.Local) {
                    return "https://localhost:9000";
                };
                if (environment instanceof BankingReady_Data_Environment.Dev) {
                    return "https://dev.bankingready.com";
                };
                if (environment instanceof BankingReady_Data_Environment.Prod) {
                    return "https://app.bankingready.com";
                };
                throw new Error("Failed pattern match at Main (line 137, column 15 - line 140, column 45): " + [ environment.constructor.name ]);
            })();
            var auth0ClientId = (function () {
                if (environment instanceof BankingReady_Data_Environment.Local) {
                    return "IqVVHFSMTPBzuSNJJaKZkDXO0lApP1vL";
                };
                if (environment instanceof BankingReady_Data_Environment.Dev) {
                    return "IqVVHFSMTPBzuSNJJaKZkDXO0lApP1vL";
                };
                if (environment instanceof BankingReady_Data_Environment.Prod) {
                    return "C3vz0hFqv0MdVVmyIHyGR7Cb7V8pC2K1";
                };
                throw new Error("Failed pattern match at Main (line 144, column 21 - line 147, column 49): " + [ environment.constructor.name ]);
            })();
            var betterStackToken = (function () {
                if (environment instanceof BankingReady_Data_Environment.Local) {
                    return "";
                };
                if (environment instanceof BankingReady_Data_Environment.Dev) {
                    return "uG9odzdZDQmRaJFPwxRB7ira";
                };
                if (environment instanceof BankingReady_Data_Environment.Prod) {
                    return "qnHste6cyvw6rhbXLaafy1S1";
                };
                throw new Error("Failed pattern match at Main (line 151, column 24 - line 154, column 41): " + [ environment.constructor.name ]);
            })();
            return bind1((function () {
                if (os instanceof BankingReady_Data_OS.Windows) {
                    return liftEffect(measureFontWidth(os)(BankingReady_Data_Font.Arial.value)(BankingReady_Data_Font.Size10.value));
                };
                if (os instanceof BankingReady_Data_OS.Mac) {
                    return liftEffect(measureFontWidth(os)(BankingReady_Data_Font.Arial.value)(BankingReady_Data_Font.Size13.value));
                };
                if (os instanceof BankingReady_Data_OS.Unknown) {
                    return liftEffect(measureFontWidth(os)(BankingReady_Data_Font.Arial.value)(BankingReady_Data_Font.Size10.value));
                };
                throw new Error("Failed pattern match at Main (line 164, column 23 - line 167, column 71): " + [ os.constructor.name ]);
            })())(function (defaultFontWidth) {
                var initialStore = {
                    environment: environment,
                    os: os,
                    baseUrl: baseUrl,
                    auth0ClientId: auth0ClientId,
                    betterStackToken: betterStackToken,
                    defaultFontWidth: defaultFontWidth,
                    accessToken: Data_Maybe.Nothing.value,
                    currentUser: Data_Maybe.Nothing.value
                };
                return bind1(BankingReady_AppM.runAppM(initialStore)(component))(function (rootComponent) {
                    return bind1(Halogen_VDom_Driver.runUI(rootComponent)(Data_Unit.unit)(body))(function (halogenIO) {
                        return bind1(liftEffect(matchesWith(Routing_Duplex.parse(BankingReady_Data_Route.routeCodec))(function (old) {
                            return function ($$new) {
                                return when(notEq(old)(new Data_Maybe.Just($$new)))(Effect_Aff.launchAff_(bind1(halogenIO.query(Halogen_Query.mkTell(BankingReady_Component_Router.Navigate.create($$new))))(function () {
                                    return pure1(Data_Unit.unit);
                                })));
                            };
                        })))(function () {
                            return pure1(Data_Unit.unit);
                        });
                    });
                });
            });
        });
    }));
};
var main = function (node_env) {
    return Halogen_Aff_Util.runHalogenAff(BankingReady_Foreign_Office.onReady(app(node_env)));
};
export {
    unicodeCharWidth,
    unicodeToCharWidthTuple,
    measureFontWidth,
    getDefaultFont,
    getOS,
    app,
    main
};
