// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Data_Font from "../BankingReady.Data.Font/index.js";
import * as BankingReady_Data_HorizontalAlignment from "../BankingReady.Data.HorizontalAlignment/index.js";
import * as BankingReady_Data_OS from "../BankingReady.Data.OS/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as BankingReady_Foreign_Excel_Range from "../BankingReady.Foreign.Excel.Range/index.js";
import * as BankingReady_Foreign_Excel_RequestContext from "../BankingReady.Foreign.Excel.RequestContext/index.js";
import * as BankingReady_Foreign_Excel_Workbook from "../BankingReady.Foreign.Excel.Workbook/index.js";
import * as BankingReady_Foreign_Excel_Worksheet from "../BankingReady.Foreign.Excel.Worksheet/index.js";
import * as BankingReady_Foreign_Excel_WorksheetFreezePanes from "../BankingReady.Foreign.Excel.WorksheetFreezePanes/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var showExcelWorksheet = /* #__PURE__ */ BankingReady_Data_Route.showExcelWorksheet(BankingReady_Data_Route.showExcelWorksheetRoute);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var postWorksheetCommandEffect = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.AddAndHideWorksheet) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.addWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Foreign_Excel_Worksheet.setVeryHidden(worksheet);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.GroupAndHideRows) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var rangeAddress = show(v3.value0.startRowIdx + 1 | 0) + (":" + show(v3.value0.endRowIdx + 1 | 0));
                    var rangeToHide = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)(rangeAddress);
                    return function __do() {
                        BankingReady_Foreign_Excel_Range.group(rangeToHide)(BankingReady_Foreign_Excel.ByRows.value)();
                        BankingReady_Foreign_Excel_Range.hideGroupDetails(rangeToHide)(BankingReady_Foreign_Excel.ByRows.value)();
                        return Data_Unit.unit;
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.ShowGroupedRows) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var rangeAddress = show(v3.value0.startRowIdx + 1 | 0) + (":" + show(v3.value0.endRowIdx + 2 | 0));
                    var rangeToShow = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)(rangeAddress);
                    return function __do() {
                        BankingReady_Foreign_Excel_Range.showGroupDetails(rangeToShow)(BankingReady_Foreign_Excel.ByRows.value)();
                        return Data_Unit.unit;
                    };
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.ShowGridlines) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    return BankingReady_Foreign_Excel_Worksheet.showGridlines(worksheet)(v3.value0.showGridlines);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.SetColumnWidth) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var columns = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)(v3.value0.columnAddress);
                    return BankingReady_Foreign_Excel_Range.setColumnWidth(columns)(v1.os)(v3.value0.columnWidth);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var rows = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)("1:1000");
                    var fontSize = (function () {
                        if (v1.os instanceof BankingReady_Data_OS.Windows) {
                            return BankingReady_Data_Font.Size10.value;
                        };
                        if (v1.os instanceof BankingReady_Data_OS.Mac) {
                            return BankingReady_Data_Font.Size13.value;
                        };
                        if (v1.os instanceof BankingReady_Data_OS.Unknown) {
                            return BankingReady_Data_Font.Size10.value;
                        };
                        throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.Worksheet (line 87, column 16 - line 90, column 29): " + [ v1.os.constructor.name ]);
                    })();
                    return BankingReady_Foreign_Excel_Range.setFont(rows)(BankingReady_Data_Font.Arial.value)(fontSize);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.SetHorizontalAlignmentCenter) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var range = BankingReady_Foreign_Excel_Worksheet.getRange(worksheet)(v3.value0.address);
                    return BankingReady_Foreign_Excel_Range.setHorizontalAlignment(range)(BankingReady_Data_HorizontalAlignment.Center.value);
                };
                if (v3 instanceof BankingReady_Data_ExcelEditorCommand.FreezeRows) {
                    var workbook = BankingReady_Foreign_Excel_RequestContext.getWorkbook(v);
                    var worksheet = BankingReady_Foreign_Excel_Workbook.getWorksheet(workbook)(showExcelWorksheet(v2));
                    var worksheetFreezePanes = BankingReady_Foreign_Excel_Worksheet.freezePanes(worksheet);
                    return BankingReady_Foreign_Excel_WorksheetFreezePanes.freezeRows(worksheetFreezePanes)(v3.value0.rowCount);
                };
                throw new Error("Failed pattern match at BankingReady.Capability.ExcelEditor.Effect.Worksheet (line 35, column 1 - line 36, column 73): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name ]);
            };
        };
    };
};
export {
    postWorksheetCommandEffect
};
