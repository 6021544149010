// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var timeValueOfMoneyDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The most important concept in finance is that a pound today is worth MORE than a pound tomorrow. i.e., \xa3100 today is worth more than it is in 10 years."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 6,
        colIdx: 1,
        text: "\xa3100 today is worth more than \xa3100 tomorrow"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now this is because we could invest the \xa3100 and end up with a sum larger than \xa3100 in 5 years."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "We can invest in the interim"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now many assume that this is because inflation erodes the value of money (the constant rising of prices means the \xa3100 can purchase less in the future) but the real reason is because you have the opportunity cost of investing that money in the interim."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 2,
        text: "The opportunity cost is the real reason tomorrow's money is worth less"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now to move between the future and present value of the funds we use what\u2019s known as the discount rate. This represents the opportunity cost. For example, if we know we can get a return of 5% for 5 years then any 5-year investment or project we assess can be done so against the return of 5%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 1,
        text: "Discount Rate: Using the opportunity cost to transfer the value of money forward and backwards in time"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "If we find that our investment has a higher return of 10% then we may take up this opportunity. However, if this is associated with greater risk then this must also be captured in the decision making."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We typically do this to boil down our analysis to answer whether this is the most profitable decision (investment) to make or could earn more elsewhere or in a quicker time frame. This simple decision rule we find for two similar investments is if the potential return is greater than the opportunity cost of a similar risk investment you should invest."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 2,
        text: "Decision Rule: Investment Return > Discount Rate/Opportunity Cost ----> Invest "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 12,
        colIdx: 2,
        text: "Must consider the risk of the higher investment return. If it is of equal risk then Yes!"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "When we use the discount rate, we often use a multiplying factor. To calculate this, we add the discount to 1. For example, in the following example, we use the discount rate of 5% so our factor is 1 + 5% or 1.05."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now what we can do with the discount rate is calculate the Present Value of a stream of income from a project. For example, if a project will provide us with income in 5 years (does not necessarily need to be the same). When we compare projects and investments, we do so by comparing the income they generate in the same period. But if one project offers income in 5 years and one today then how do we compare? Well, we can use the discount rate to transfer the value through time."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 1,
        text: "Present Value: The value of investments in the future and past today"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 15,
        colIdx: 2,
        text: "An income we receive in 5 years is not worth the same as if we earn it today"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 2,
        text: "We can use the discount rate to transfer money back and forward in time to compare the value in the same period"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldText({
        rowIdx: 18,
        colIdx: 1,
        text: "Discount Rate:"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 18,
        colIdx: 3
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 1,
            rowCount: 1,
            columnCount: 8
        },
        title: "Present Value Example"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 3,
        text: "Year 0"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 4,
        text: "Year 1"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 5,
        text: "Year 2"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 6,
        text: "Year 3"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 7,
        text: "Year 4"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 20,
        colIdx: 8,
        text: "Year 5"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 1,
        text: "Cash Flow 1"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 2,
        text: "Present Value Calculation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 23,
        colIdx: 1,
        text: "Cash Flow 2"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 24,
        colIdx: 2,
        text: "Future Value Calculation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 1,
            rowCount: 1,
            columnCount: 8
        },
        text: "Profit (NPV) Calculation"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For example, would you rather earn \xa3150 in 5 years or earn \xa3110 today? Assume the opportunity cost is 5%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 18,
        colIdx: 3,
        number: 5.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 21,
        colIdx: 8,
        number: 150.0,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 21,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 23,
        colIdx: 3,
        number: 110.0,
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 23,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we could take this \xa3150 Future Value and discount it to the Present. To do this we take the \xa3150 and divide it by (1 + 5%) for each year we discount. So our calculation becomes \xa3150/(1.05)^5 = \xa3117.53."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 22,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I22/(1+D19)^5",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 18,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 21,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Which is indeed greater than the \xa3110 offered today, with a profit of \xa37.53. Therefore, this project is net positive, and we would take this opportunity."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 25,
        colIdx: 3,
        formula: "=D23-D24",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Alternatively, we could take the \xa3110 and invest it for 5 years to see what the Future Value of it is compared to the \xa3150 we could receive. This would be the opposite process by taking \xa3110 and multiplying it by (1 + 5%) for each period. So, our calculation becomes \xa3110 * (1.05)^5 = \xa3140.39."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=D24*(1+D19)^5",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value,
        references: [ {
            rangeSelector: {
                rowIdx: 18,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 23,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Which is lower than the Future Value we are promised. This represents a profit of \xa39.61 in the future. Therefore, we should still take the \xa3150 in 5 years."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 25,
        colIdx: 8,
        formula: "=I22-I25",
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 8,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can do this for all manner of incomes and costs and sum them up to find the most profitable. By the end of this course, you should be able to see how these apply to the valuation methods of a discounted cash flow."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The most typical discount rate we use is the Weighted Average Cost of Capital, which we discuss in a later Module. Now we choose this discount rate because it gives the best reflection of the cost of capital (what investors are being promised on their investments) of similar investments."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 1,
        text: "WACC: The best reflection of the cost of capital (cost to investors who have provided capital for a return to the business)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The final term to consider with this is the Internal Rate of Return (IRR). Its use is common in the Bond Market and Private Equity for either calculating a Bond Yield or finding the return of an LBO transaction. However, in its purest form, we find the discount rate that sets our Net Present Value (difference between two projects or the net present sum of profits and costs) to 0. For example, in our basic example, if we find the discount rate that equates the \xa3150 in 5 years to the \xa3110 today."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 28,
        colIdx: 1,
        text: "IRR: The discount rate/rate of return that sets the PV of both opportunities equal to 0 (or the NPV equal to 0)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 30,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        title: "IRR Example"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 30,
            colIdx: 5,
            rowCount: 1,
            columnCount: 4
        },
        title: "IRR Formula Derivation"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 31,
        colIdx: 1,
        text: "Future Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 31,
        colIdx: 3,
        number: 150.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 32,
        colIdx: 1,
        text: "Present Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 32,
        colIdx: 3,
        number: 110.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 1,
            rowCount: 1,
            columnCount: 3
        },
        text: "IRR"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We know the project returns \xa3150 in 5 years. We must commit to \xa3110 today which is our present value of the project. So we know the \xa3110 will grow each year to \xa3150. Let\u2019s assume the rate of growth each year (our IRR) is d."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We know the project returns \xa3150 in 5 years. We must commit to \xa3110 today which is our present value of the project. So we know the \xa3110 will grow each year to \xa3150. Let\u2019s assume the rate of growth each year (our IRR) is d."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Recalling our growth formula we can set up \xa3110 * (1 + d%)^5 = \xa3150."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 31,
        colIdx: 5,
        text: "'\xa3110 * (1 + d%)^5 = \xa3150"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can rearrange this by dividing both sides by \xa3110 to give (1 + d%)^5 = \xa3150 / \xa3110."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 32,
        colIdx: 5,
        text: "'(1 + d%)^5 = \xa3150 / \xa3110"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can then use the laws of powers to remove the power of 5. (1 + d%) = (\xa3150 / \xa3110)^(1/5)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 33,
        colIdx: 5,
        text: "'(1 + d%) = (\xa3150 / \xa3110)^(1/5)"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can subtract 1 from each side to leave us with d% = (\xa3150 / \xa3110)^(1/5) \u2013 1 = 6.4%. In our IRR cell, we have summarised this in one formula containing all the above steps."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 34,
        colIdx: 5,
        text: "'d% = (\xa3150 / \xa3110)^(1/5) - 1"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=(D32/D33)^(1/5)-1",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value,
        references: [ {
            rangeSelector: {
                rowIdx: 31,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 32,
                colIdx: 3,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ]
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "If we set our discount rate to 6.4% both the projects yield the same results, and we would be indifferent to either. It also sets the NPV (difference between taking the money later and today) to 0. If the actual discount rate is greater than the IRR, we would take the \xa3110 today, and if lower we would take \xa3150 in 5 years. The IRR sets the projects to equal each other and helps discover where we place our decision rules for investments."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Before you press next, input 6.4% in the discount rate box check what happens."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 18,
            colIdx: 3,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we will return to these tools throughout the course modules, but this has provided a quick overview of some of the key topics any beginner should know. Please ensure you understand these concepts before progressing to get the most out of the following Modules."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The next section will dive into the Accounting Module which aims to build the baseline knowledge for a beginner (and cover some untouched areas in Excel for those already accustomed)."
    })) ] ];
})();
export {
    timeValueOfMoneyDescriptions
};
