// Generated by purs version 0.15.13
var textXML = "text/xml";
var textPlain = "text/plain";
var textHTML = "text/html";
var textCSV = "text/csv";
var textCSS = "text/css";
var multipartFormData = "multipart/form-data";
var imagePNG = "image/png";
var imageJPEG = "image/jpeg";
var imageGIF = "image/gif";
var applicationXML = "application/xml";
var applicationOctetStream = "application/octet-stream";
var applicationJavascript = "application/javascript";
var applicationJSON = "application/json";
var applicationFormURLEncoded = "application/x-www-form-urlencoded";
export {
    applicationFormURLEncoded,
    applicationJSON,
    applicationJavascript,
    applicationOctetStream,
    applicationXML,
    imageGIF,
    imageJPEG,
    imagePNG,
    multipartFormData,
    textCSV,
    textHTML,
    textPlain,
    textXML,
    textCSS
};
