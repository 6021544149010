// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as Control_Promise from "../Control.Promise/index.js";
var CopyTypeAll = /* #__PURE__ */ (function () {
    function CopyTypeAll() {

    };
    CopyTypeAll.value = new CopyTypeAll();
    return CopyTypeAll;
})();
var CopyTypeFormulas = /* #__PURE__ */ (function () {
    function CopyTypeFormulas() {

    };
    CopyTypeFormulas.value = new CopyTypeFormulas();
    return CopyTypeFormulas;
})();
var CopyTypeValues = /* #__PURE__ */ (function () {
    function CopyTypeValues() {

    };
    CopyTypeValues.value = new CopyTypeValues();
    return CopyTypeValues;
})();
var CopyTypeFormats = /* #__PURE__ */ (function () {
    function CopyTypeFormats() {

    };
    CopyTypeFormats.value = new CopyTypeFormats();
    return CopyTypeFormats;
})();
var ByColumns = /* #__PURE__ */ (function () {
    function ByColumns() {

    };
    ByColumns.value = new ByColumns();
    return ByColumns;
})();
var ByRows = /* #__PURE__ */ (function () {
    function ByRows() {

    };
    ByRows.value = new ByRows();
    return ByRows;
})();
var Rectangle = /* #__PURE__ */ (function () {
    function Rectangle() {

    };
    Rectangle.value = new Rectangle();
    return Rectangle;
})();
var ColumnStacked = /* #__PURE__ */ (function () {
    function ColumnStacked() {

    };
    ColumnStacked.value = new ColumnStacked();
    return ColumnStacked;
})();
var Auto = /* #__PURE__ */ (function () {
    function Auto() {

    };
    Auto.value = new Auto();
    return Auto;
})();
var Columns = /* #__PURE__ */ (function () {
    function Columns() {

    };
    Columns.value = new Columns();
    return Columns;
})();
var Rows = /* #__PURE__ */ (function () {
    function Rows() {

    };
    Rows.value = new Rows();
    return Rows;
})();
var sync = function ($11) {
    return Control_Promise.toAffE($foreign.syncImpl($11));
};
var showRangeCopyType = {
    show: function (v) {
        if (v instanceof CopyTypeAll) {
            return "All";
        };
        if (v instanceof CopyTypeFormulas) {
            return "Formulas";
        };
        if (v instanceof CopyTypeValues) {
            return "Values";
        };
        if (v instanceof CopyTypeFormats) {
            return "Formats";
        };
        throw new Error("Failed pattern match at BankingReady.Foreign.Excel (line 46, column 1 - line 50, column 37): " + [ v.constructor.name ]);
    }
};
var showGroupOption = {
    show: function (v) {
        if (v instanceof ByColumns) {
            return "ByColumns";
        };
        if (v instanceof ByRows) {
            return "ByRows";
        };
        throw new Error("Failed pattern match at BankingReady.Foreign.Excel (line 56, column 1 - line 58, column 27): " + [ v.constructor.name ]);
    }
};
var showGeometricShapeType = {
    show: function (v) {
        return "Rectangle";
    }
};
var showChartType = {
    show: function (v) {
        return "ColumnStacked";
    }
};
var showChartSeriesBy = {
    show: function (v) {
        if (v instanceof Auto) {
            return "Auto";
        };
        if (v instanceof Columns) {
            return "Columns";
        };
        if (v instanceof Rows) {
            return "Rows";
        };
        throw new Error("Failed pattern match at BankingReady.Foreign.Excel (line 35, column 1 - line 38, column 23): " + [ v.constructor.name ]);
    }
};
var run = function ($12) {
    return Control_Promise.toAffE($foreign.runImpl($12));
};
export {
    runImpl,
    syncImpl
} from "./foreign.js";
export {
    ColumnStacked,
    Rectangle,
    Auto,
    Columns,
    Rows,
    CopyTypeAll,
    CopyTypeFormulas,
    CopyTypeValues,
    CopyTypeFormats,
    ByColumns,
    ByRows,
    run,
    sync,
    showChartType,
    showGeometricShapeType,
    showChartSeriesBy,
    showRangeCopyType,
    showGroupOption
};
