// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_Font from "../BankingReady.Data.Font/index.js";
import * as BankingReady_Data_HorizontalAlignment from "../BankingReady.Data.HorizontalAlignment/index.js";
import * as BankingReady_Foreign_Excel from "../BankingReady.Foreign.Excel/index.js";
import * as Data_Show from "../Data.Show/index.js";
var show = /* #__PURE__ */ Data_Show.show(BankingReady_Foreign_Excel.showGroupOption);
var show1 = /* #__PURE__ */ Data_Show.show(BankingReady_Data_HorizontalAlignment.showHorizontalAlignment);
var show2 = /* #__PURE__ */ Data_Show.show(BankingReady_Data_Font.showFont);
var show3 = /* #__PURE__ */ Data_Show.show(BankingReady_Foreign_Excel.showRangeCopyType);
var ungroup = function (range) {
    return function (groupOption) {
        return $foreign.ungroupImpl(range)(show(groupOption));
    };
};
var showGroupDetails = function (range) {
    return function (groupOption) {
        return $foreign.showGroupDetailsImpl(range)(show(groupOption));
    };
};
var setHorizontalAlignment = function (range) {
    return function (ha) {
        return $foreign.setHorizontalAlignmentImpl(range)(show1(ha));
    };
};
var setFont = function (range) {
    return function (font) {
        return function (fontSize) {
            return $foreign.setFontImpl(range)(show2(font))(BankingReady_Data_Font.pt(fontSize));
        };
    };
};
var setColumnWidth = function (range) {
    return function (os) {
        return function (columnWidth) {
            return $foreign.setColumnWidthImpl(range)(BankingReady_Data_ColumnWidth.pxOS(os)(columnWidth));
        };
    };
};
var hideGroupDetails = function (range) {
    return function (groupOption) {
        return $foreign.hideGroupDetailsImpl(range)(show(groupOption));
    };
};
var group = function (range) {
    return function (groupOption) {
        return $foreign.groupImpl(range)(show(groupOption));
    };
};
var copyFrom = function (range) {
    return function (sourceRange) {
        return function (rangeCopyType) {
            return $foreign.copyFromImpl(range)(sourceRange)(show3(rangeCopyType));
        };
    };
};
export {
    rowIndex,
    getWorksheet,
    copyFromImpl,
    insertDown,
    groupImpl,
    hideGroupDetailsImpl,
    loadAll,
    select,
    set,
    setCellProperties,
    setColumnWidthImpl,
    setHorizontalAlignmentImpl,
    setFontImpl,
    showGroupDetailsImpl,
    ungroupImpl
} from "./foreign.js";
export {
    copyFrom,
    group,
    hideGroupDetails,
    setColumnWidth,
    setHorizontalAlignment,
    setFont,
    showGroupDetails,
    ungroup
};
