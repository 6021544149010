// Generated by purs version 0.15.13
import * as $foreign from "./foreign.js";
import * as BankingReady_Data_Color from "../BankingReady.Data.Color/index.js";
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as Data_Show from "../Data.Show/index.js";
var show = /* #__PURE__ */ Data_Show.show(BankingReady_Data_Color.showColor);
var setWidth = function (shape) {
    return function (os) {
        return function (cw) {
            return $foreign.setWidthImpl(shape)(BankingReady_Data_ColumnWidth.pxOS(os)(cw));
        };
    };
};
var setFillColor = function (shape) {
    return function (color) {
        return $foreign.setFillColorImpl(shape)(show(color));
    };
};
export {
    setFillColorImpl,
    setVisible,
    setWidthImpl
} from "./foreign.js";
export {
    setFillColor,
    setWidth
};
