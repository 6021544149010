// Generated by purs version 0.15.13
var Windows = /* #__PURE__ */ (function () {
    function Windows() {

    };
    Windows.value = new Windows();
    return Windows;
})();
var Mac = /* #__PURE__ */ (function () {
    function Mac() {

    };
    Mac.value = new Mac();
    return Mac;
})();
var Unknown = /* #__PURE__ */ (function () {
    function Unknown() {

    };
    Unknown.value = new Unknown();
    return Unknown;
})();
var fontDPIRatio = /* #__PURE__ */ (function () {
    return 96.0 / 72.0;
})();
export {
    Windows,
    Mac,
    Unknown,
    fontDPIRatio
};
