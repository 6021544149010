// Generated by purs version 0.15.13
var LightBlue = /* #__PURE__ */ (function () {
    function LightBlue() {

    };
    LightBlue.value = new LightBlue();
    return LightBlue;
})();
var DarkBlue = /* #__PURE__ */ (function () {
    function DarkBlue() {

    };
    DarkBlue.value = new DarkBlue();
    return DarkBlue;
})();
var LightYellow = /* #__PURE__ */ (function () {
    function LightYellow() {

    };
    LightYellow.value = new LightYellow();
    return LightYellow;
})();
var White = /* #__PURE__ */ (function () {
    function White() {

    };
    White.value = new White();
    return White;
})();
var showColor = {
    show: function (v) {
        if (v instanceof LightBlue) {
            return "#D9E1F2";
        };
        if (v instanceof DarkBlue) {
            return "#305496";
        };
        if (v instanceof LightYellow) {
            return "#fffbeb";
        };
        if (v instanceof White) {
            return "#FFFFFF";
        };
        throw new Error("Failed pattern match at BankingReady.Data.Color (line 12, column 10 - line 16, column 23): " + [ v.constructor.name ]);
    }
};
export {
    LightBlue,
    DarkBlue,
    LightYellow,
    White,
    showColor
};
