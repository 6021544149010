// Generated by purs version 0.15.13
import * as BankingReady_Data_ColumnWidth from "../BankingReady.Data.ColumnWidth/index.js";
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var projectingFCFWorksheetCommands = /* #__PURE__ */ (function () {
    return [ new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.ShowGridlines({
        showGridlines: false
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(BankingReady_Data_ExcelEditorCommand.SetDefaultFontArial.value), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "A:B",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "C:C",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth1.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "D:D",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth30.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "E:P",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth8.value
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetColumnWidth({
        columnAddress: "Q:R",
        columnWidth: BankingReady_Data_ColumnWidth.ColumnWidth2.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.DrawOuterBorder({
        rangeSelector: {
            rowIdx: 1,
            colIdx: 2,
            rowCount: 3,
            columnCount: 14
        }
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.FreezeRows({
        rowCount: 6
    })), new BankingReady_Data_ExcelEditorCommand.Worksheet(new BankingReady_Data_ExcelEditorCommand.SetHorizontalAlignmentCenter({
        address: "E:E"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertHeader({
        rangeSelector: {
            rowIdx: 5,
            colIdx: 2,
            rowCount: 1,
            columnCount: 14
        },
        title: "Projecting FCF"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertDate({
        rowIdx: 5,
        colIdx: 5,
        date: "01/01/2021",
        dateFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 5,
        colIdx: 6,
        formula: "=DATE(YEAR(F6)+1,1,1)",
        numberFormat: BankingReady_Utility_Excel_Cell.DateYearOnly.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 5,
            colIdx: 6,
            rowCount: 1,
            columnCount: 10
        }
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 2,
        text: "Revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 7,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 7,
        colIdx: 5,
        number: 14181.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 3,
            rowCount: 1,
            columnCount: 14
        },
        text: "Growth Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 8,
        colIdx: 4,
        text: "%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 2,
        text: "Operating Income (EBIT)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 10,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 10,
        colIdx: 5,
        number: 1752.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 11,
            colIdx: 3,
            rowCount: 1,
            columnCount: 14
        },
        text: "Growth Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 11,
        colIdx: 4,
        text: "%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 2,
        text: "Tax Paid"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 13,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 13,
        colIdx: 5,
        number: -471.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 3,
            rowCount: 1,
            columnCount: 14
        },
        text: "Tax Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 14,
        colIdx: 4,
        text: "%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 2,
            rowCount: 1,
            columnCount: 14
        },
        text: "NOPAT"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 16,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 2,
        text: "D&A and Other Non-Cash Expenses"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 18,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 18,
        colIdx: 5,
        number: 467.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 3,
            rowCount: 1,
            columnCount: 14
        },
        text: "% of Revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 19,
        colIdx: 4,
        text: "%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 19,
        colIdx: 5,
        formula: "=F19/F8",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 2,
        text: "Change in Working Capital"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 21,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 22,
            colIdx: 3,
            rowCount: 1,
            columnCount: 14
        },
        text: "% of Change in Revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 22,
        colIdx: 4,
        text: "%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 24,
        colIdx: 2,
        text: "Capex"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 24,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 25,
            colIdx: 3,
            rowCount: 1,
            columnCount: 14
        },
        text: "% of Revenue"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 25,
        colIdx: 4,
        text: "%"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 27,
            colIdx: 2,
            rowCount: 1,
            columnCount: 14
        },
        text: "Free Cash Flow"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 27,
        colIdx: 4,
        text: "$mn"
    })) ];
})();
export {
    projectingFCFWorksheetCommands
};
