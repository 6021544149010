// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Data_Worksheet_DCFValuation_ProjectingFCF from "../BankingReady.Data.Worksheet.DCFValuation.ProjectingFCF/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
var discountingAndTerminalValuesWorksheetCommands = /* #__PURE__ */ (function () {
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(BankingReady_Data_Worksheet_DCFValuation_ProjectingFCF.projectingFCFWorksheetCommands)([ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 11,
        colIdx: 5,
        formula: "=F11/F8",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 8,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 8,
        colIdx: 6,
        number: 2.5e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 7,
        colIdx: 6,
        formula: "=(1+G9)*F8",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 11,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 11,
        colIdx: 6,
        formula: "=F12",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 10,
        colIdx: 6,
        formula: "=G8*G12",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 14,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 14,
        colIdx: 6,
        number: 0.2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 13,
        colIdx: 6,
        formula: "=(-1)*G11*G15",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 16,
        colIdx: 6,
        formula: "=SUM(G11,G14)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 19,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 19,
        colIdx: 6,
        formula: "=F20",
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 18,
        colIdx: 6,
        formula: "=G8*G20",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 22,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 22,
        colIdx: 6,
        number: 0.15,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 21,
        colIdx: 6,
        formula: "=G23*(G8-F8)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 25,
        colIdx: 6
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 25,
        colIdx: 6,
        number: 3.9e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 24,
        colIdx: 6,
        formula: "=(-1)*G8*G26",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 27,
        colIdx: 6,
        formula: "=SUM(G17,G19,G22,G25)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRight({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 6,
            rowCount: 21,
            columnCount: 10
        }
    })) ]);
})();
export {
    discountingAndTerminalValuesWorksheetCommands
};
