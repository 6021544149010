// Generated by purs version 0.15.13
import * as BankingReady_Capability_Navigate from "../BankingReady.Capability.Navigate/index.js";
import * as BankingReady_Data_Route from "../BankingReady.Data.Route/index.js";
import * as BankingReady_Utility_HTML from "../BankingReady.Utility.HTML/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var StartModule = /* #__PURE__ */ (function () {
    function StartModule(value0) {
        this.value0 = value0;
    };
    StartModule.create = function (value0) {
        return new StartModule(value0);
    };
    return StartModule;
})();
var component = function (dictMonadAff) {
    return function (dictNavigate) {
        var navigate = BankingReady_Capability_Navigate.navigate(BankingReady_Capability_Navigate.navigateHalogenM(dictNavigate));
        var render = function (v) {
            return Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a          flex min-h-screen select-none flex-col items-center justify-center \x0a          bg-white text-zinc-700\x0a          ") ])([ Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.IntroToFinance(BankingReady_Data_Route.Terminology.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("Intro To Finance") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.Accounting(BankingReady_Data_Route.AccountingIntro.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("Accounting Lesson") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.Quiz(BankingReady_Data_Route.AccountingQuiz.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("Accounting Quiz") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.CompanyValue(BankingReady_Data_Route.CompanyValueIntro.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("Company Value Lesson") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.Quiz(BankingReady_Data_Route.CompanyValueQuiz.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("Company Value Quiz") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.DCFValuation(BankingReady_Data_Route.DCFValuationIntro.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("DCF Valuation Lesson") ]) ]), Halogen_HTML_Elements.div([ BankingReady_Utility_HTML.css("\x0a              my-3 flex h-16 w-72 cursor-pointer items-center rounded border border-slate-700 \x0a              bg-gray-50 text-base shadow hover:border-slate-950 hover:text-zinc-950 \x0a              active:shadow-md\x0a              "), Halogen_HTML_Events.onClick(function (v1) {
                return new StartModule(new BankingReady_Data_Route.Quiz(BankingReady_Data_Route.DCFValuationQuiz.value));
            }) ])([ Halogen_HTML_Elements.span([ BankingReady_Utility_HTML.css("pl-3 font-semibold") ])([ Halogen_HTML_Core.text("DCF Valuation Quiz") ]) ]) ]);
        };
        var handleAction = function (v) {
            if (v instanceof Initialize) {
                return pure(Data_Unit.unit);
            };
            if (v instanceof StartModule) {
                return navigate(v.value0);
            };
            throw new Error("Failed pattern match at BankingReady.Page.Home (line 45, column 18 - line 47, column 40): " + [ v.constructor.name ]);
        };
        return Halogen_Component.mkComponent({
            initialState: function (v) {
                return Data_Unit.unit;
            },
            render: render,
            "eval": Halogen_Component.mkEval({
                handleQuery: Halogen_Component.defaultEval.handleQuery,
                receive: Halogen_Component.defaultEval.receive,
                finalize: Halogen_Component.defaultEval.finalize,
                handleAction: handleAction,
                initialize: new Data_Maybe.Just(Initialize.value)
            })
        });
    };
};
export {
    Initialize,
    StartModule,
    component
};
