"use strict"

// Methods

export const freezeAt = function (worksheetFreezePanes) {
    return function (frozenRange) {
        return function () {
            return worksheetFreezePanes.freezeAt(frozenRange);
        };
    };
};

export const freezeColumns = function (worksheetFreezePanes) {
    return function (count) {
        return function () {
            return worksheetFreezePanes.freezeColumns(count);
        };
    };
};

export const freezeRows = function (worksheetFreezePanes) {
    return function (count) {
        return function () {
            return worksheetFreezePanes.freezeRows(count);
        };
    };
};
